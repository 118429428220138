/* eslint-disable */
import { ICreateCommissionsPayload } from 'src/services/CommissionService';
import { DefaultInitialState, IPaginationParams } from './types';
import * as actionTypes from '../constants/index';
import { notification } from 'antd';
import { IProducts } from 'src/services/Types';
import { IUser } from 'src/services/UserService';

export interface ICommisionsDetails extends ICreateCommissionsPayload {
  minimumValue: number;
  maximumValue: number;
  planActive: boolean;
  planName: string;
  createdBy: string;
  id: IUser['userId'];
  createdAt: string;
  updatedAt: string;
  planExpiryDate: string;
}

export interface ICommissionPlansList
  extends IPaginationParams,
    DefaultInitialState {
  items: ICommisionsDetails[];
}

export interface ICommisionsDetailsByPlanName extends DefaultInitialState {
  data: ICommisionsDetails;
}

export interface ITransactions {
  id: string;
  event: string;
  userId: string;
  product: IProducts;
  directDownline: string;
  commissionPaid: number;
  transactionType: string;
  transactionAmount: number;
  transactionOriginator: string;
  transactionOriginatorName?: string;
  originatingTransactionTime: string;
}

export interface IGetTransactionsPayload
  extends Partial<IPaginationParams>,
    Partial<DefaultInitialState> {
  count?: number; // Pagination field
  items: {
    summary: ITransactionSummary;
    transactions: ITransactions[];
  };
}

export interface ITransactionSummary {
  totalTransactionVolume: number;
  totalCommissionEarned: number;
}

export interface IAggregatorSummary {
  volume: number;
  commission: number;
  totalAgentCount: number;
  activeDirectDownlines: number;
  transactionCount?: number;
}

export interface IAgentPerformance {
  id: string;
  volume: number;
  agentName: string;
  commission: number;
  phoneNumber: string;
  transactionCount: number;
}

export interface IGetAgentsPerformancePayload
  extends Partial<Omit<IPaginationParams, 'limit'>> {
  count?: number;
  items: {
    directDownlines: IAgentPerformance[];
    aggregatorSummary: IAggregatorSummary;
  };
}

const initialState = <ICommisionsState>{
  transactions: {},
  agentTransactionsById: {},
  agentProfileTransactionsById: {},
  agentPerformance: {
    items: {
      directDownlines: [{}],
      aggregatorSummary: {},
    },
  },
  commissionDetails: {},
  commissionPlansList: {},
  loading: false,
  error: null,
};

export interface ICommisionsState {
  transactions: IGetTransactionsPayload;
  agentTransactionsById: IGetTransactionsPayload;
  agentProfileTransactionsById: IGetTransactionsPayload;
  agentPerformance: IGetAgentsPerformancePayload;
  commissionDetails: ICommisionsDetailsByPlanName;
  commissionPlansList: ICommissionPlansList;
  loading: boolean;
  error: any;
}

const commissionReducer = (
  state: ICommisionsState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case actionTypes.GET_COMMISSION_PLANS_LIST_PENDING:
      return {
        ...state,
        commissionPlansList: {
          ...state.commissionPlansList,
          loading: true,
          error: null,
        },
      };

      break;
    case actionTypes.GET_COMMISSION_PLANS_LIST_SUCCESS:
      return {
        ...state,
        commissionPlansList: {
          ...state.commissionPlansList,
          items: action.payload.items,
          loading: false,
          error: null,
        },
      };
      break;
    case actionTypes.GET_COMMISSION_PLANS_LIST_FAILED:
      notification.error({ message: 'Something went wrong!' });

      return {
        ...state,
        commissionPlansList: {
          ...state.commissionPlansList,
          loading: false,
          error: action.payload,
        },
      };

      break;

    case actionTypes.GET_COMMISSION_PLAN_DETAILS_BY_NAME_PENDING:
      return {
        ...state,
        commissionDetails: {
          ...state.commissionDetails,
          loading: true,
          error: null,
        },
      };

      break;
    case actionTypes.GET_COMMISSION_PLAN_DETAILS_BY_NAME_SUCCESS:
      return {
        ...state,
        commissionDetails: {
          ...state.commissionDetails,
          data: action.payload,
          loading: false,
          error: null,
        },
      };
      break;
    case actionTypes.GET_COMMISSION_PLAN_DETAILS_BY_NAME_FAILED:
      notification.error({ message: 'Something went wrong!' });

      return {
        ...state,
        commissionDetails: {
          ...state.commissionDetails,
          loading: false,
          error: action.payload,
        },
      };

      break;

    case actionTypes.CREATE_COMMISSION_PLAN_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
      break;

    case actionTypes.CREATE_COMMISSION_PLAN_SUCCESS:
      return {
        ...state,
        commissionDetails: {
          ...state.commissionDetails,
          data: action.payload,
        },
        loading: false,
        error: null,
      };
      break;

    case actionTypes.CREATE_COMMISSION_PLAN_FAILED:
      notification.error({ message: 'Something went wrong!' });

      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      break;

    case actionTypes.GET_TRANSACTIONS_PENDING:
      return {
        ...state,
        transactions: { ...state.transactions, loading: true, error: null },
      };

    case actionTypes.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: {
          ...action.payload,
          loading: false,
          error: null,
        },
      };

    case actionTypes.GET_TRANSACTIONS_FAILED:
      return {
        ...state,
        transactions: {
          loading: false,
          error: action.payload,
        },
      };

    case actionTypes.GET_AGENT_TRANSACTIONS_BY_ID_PENDING:
      return {
        ...state,
        agentTransactionsById: {
          ...state.agentTransactionsById,
          loading: true,
          error: null,
        },
      };

    case actionTypes.GET_AGENT_TRANSACTIONS_BY_ID_SUCCESS:
      return {
        ...state,
        agentTransactionsById: {
          ...action.payload,
          loading: false,
          error: null,
        },
      };

    case actionTypes.GET_AGENT_TRANSACTIONS_BY_ID_FAILED:
      notification.error({ message: 'Failed to fetch agent transactions' });

      return {
        ...state,
        agentTransactionsById: {
          loading: false,
          error: action.payload,
        },
      };

    case actionTypes.GET_AGENT_PROFILE_TRANSACTIONS_BY_ID_PENDING:
      return {
        ...state,
        agentProfileTransactionsById: {
          ...state.agentProfileTransactionsById,
          loading: true,
          error: null,
        },
      };

    case actionTypes.GET_AGENT_PROFILE_TRANSACTIONS_BY_ID_SUCCESS:
      return {
        ...state,
        agentProfileTransactionsById: {
          ...action.payload,
          loading: false,
          error: null,
        },
      };

    case actionTypes.GET_AGENT_PROFILE_TRANSACTIONS_BY_ID_SUCCESS:
      notification.error({ message: 'Failed to fetch agent transactions' });

      return {
        ...state,
        agentProfileTransactionsById: {
          loading: false,
          error: action.payload,
        },
      };

    case actionTypes.GET_AGENT_PERFORMANCE_PENDING:
      return {
        ...state,
        agentPerformance: {
          ...state.agentPerformance,
          loading: true,
          error: null,
        },
      };

    case actionTypes.GET_AGENT_PERFORMANCE_SUCCESS:
      return {
        ...state,
        agentPerformance: {
          ...action.payload,
          // ...state.agentPerformance,
          loading: false,
          error: null,
        },
      };

    case actionTypes.GET_AGENT_PERFORMANCE_FAILED:
      notification.error({ message: 'Failed to fetch agent performance' });

      return {
        ...state,
        agentPerformance: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
      break;
  }
};

export default commissionReducer;
