/* eslint-disable */
import axios from 'axios';
import { message, notification } from 'antd';
import Utils from 'src/utils';
import {
  API_BASE_URL,
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  INSURANCE_BASE_URL,
} from '../configs/AppConfig';
import history from '../history';
import { AUTH_TOKEN } from '../redux/constants';
//TODO: Suspend Sentry for every failed network call. This will be reverted once all errors are fixed.
// import { withScope, captureException } from '@sentry/react';
import { env } from 'src/configs/EnvironmentConfig';
import { setLastUrl } from 'src/utils/lastUrl';
import isValidLastUrl from 'src/utils/isValidLastUrl';
interface NotificationProps {
  message: string;
  description?: string | null;
}

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});
export const insuranceService = axios.create({
  baseURL: INSURANCE_BASE_URL,
  timeout: 60000,
});
// Config
const ENTRY_ROUTE = '/auth/login';
const TOKEN_PAYLOAD_KEY = 'Authorization';
const PUBLIC_REQUEST_KEY = 'public-request';
// const X_AJOCARD_USER = 'X-AJOCARD-USER';

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);
    // const aggId = localStorage.getItem(AUTH_TOKEN_AGG_ID);

    // if (aggId) {
    //   config.headers[X_AJOCARD_USER] = aggId;
    // }

    config.headers = config.headers || {};

    if (jwtToken) {
      Utils.isTokenExpired(jwtToken);
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }

    if (!Utils.getSession(jwtToken) || !jwtToken) {
      const fullPath = window.location.pathname + window.location.search;
      if (isValidLastUrl(fullPath) && !fullPath.includes(AUTH_PREFIX_PATH)) {
        setLastUrl(fullPath);
      } else {
        setLastUrl(`${APP_PREFIX_PATH}/home`);
      }
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    return config;
  },
  (error: any) => {
    // Do something with request error here
    notification.error({
      message: 'Error',
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const notificationParam: NotificationProps = {
      message: error?.response?.data?.errors
        ? error?.response?.data?.errors.map((i: any) => i.message).join(' .')
        : error?.response?.data?.message || '',
      description: error?.response?.data?.errors
        ? error?.response?.data?.errors.map((i: any) => i.message).join(' .')
        : error?.response?.data?.message || '',
    };

    // Remove token and redirect
    if (error?.response?.status === 400) {
      notificationParam.message = 'Authentication Fail';
      // withScope((scope) => {
      //   scope.setExtra('status', error?.response?.status);
      //   scope.setExtra('data', error?.response?.data);
      //   scope.setExtra('payload', error?.response?.config.data);
      //   scope.setExtra('headers', error?.response?.config.headers);
      //   scope.setLevel('error');
      //   captureException(error);
      // });
    }

    if (error?.response?.status === 403) {
      notificationParam.message = 'Request Unauthentication';
      notificationParam.description += 'Please contact support.';
      // withScope((scope) => {
      //   scope.setExtra('status', error?.response?.status);
      //   scope.setExtra('data', error?.response?.data);
      //   scope.setExtra('payload', error?.response?.config.data);
      //   scope.setExtra('headers', error?.response?.config.headers);
      //   scope.setLevel('warning');
      //   captureException(error);
      // });
      return Promise.reject(error);
    }

    if (error?.response?.status === 404) {
      notificationParam.message = 'Not Found';
    }

    if (error?.response?.status === 405) {
      notificationParam.message = 'CORS';
      // withScope((scope) => {
      //   scope.setExtra('status', error?.response?.status);
      //   scope.setExtra('data', error?.response?.data);
      //   scope.setExtra('payload', error?.response?.config.data);
      //   scope.setExtra('headers', error?.response?.config.headers);
      //   scope.setLevel('error');
      //   captureException(error);
      // });
    }

    if (error?.response?.status === 500) {
      notificationParam.message = 'Internal Server Error';
      // withScope((scope) => {
      //   scope.setExtra('status', error?.response?.status);
      //   scope.setExtra('data', error?.response?.data);
      //   scope.setExtra('payload', error?.response?.config.data);
      //   scope.setExtra('headers', error?.response?.config.headers);
      //   scope.setLevel('error');
      //   captureException(error);
      // });
    }

    if (error?.response?.status === 508) {
      notificationParam.message = 'Time Out';
      // withScope((scope) => {
      //   scope.setExtra('status', error?.response?.status);
      //   scope.setExtra('data', error?.response?.data);
      //   scope.setExtra('payload', error?.response?.config.data);
      //   scope.setExtra('headers', error?.response?.config.headers);
      //   scope.setLevel('error');
      //   captureException(error);
      // });
    }

    // if (notificationParam.message) notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
