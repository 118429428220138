/* eslint-disable */
import React from 'react';
import { Table, TableProps } from 'antd';

interface IAjoTableProps extends TableProps<any> {

}

const AjoTable = (props: IAjoTableProps) => {
  return <Table {...props} />;
};

export default React.memo(AjoTable);
