import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Input, Divider, message as toast } from 'antd';
import { LockOutlined, PhoneOutlined } from '@ant-design/icons';
import { GoogleSVG, FacebookSVG } from '../../../assets/svg/icon';
import CustomIcon from '../../../components/util-components/CustomIcon';
import { loginUser } from '../../../redux/actions/Auth';
import { useAppSelector } from 'src/redux/store/hooks';
import { selectAuth } from 'src/redux/selectors/AuthSelector';

export const LoginForm = (props: any) => {
  const [form] = Form.useForm();
  const { otherSignIn, extra } = props;

  const dispatch = useDispatch();
  const auth = useAppSelector((store) => selectAuth(store));
  const { loading } = auth;

  const onLogin = () => {
    form
      .validateFields()
      .then((values) => {
        dispatch(loginUser(values));
      })
      .catch((info) => {
        toast.error('Error Occured while processing your request.');
      });
  };

  const onGoogleLogin = () => {};
  const onFacebookLogin = () => {};

  const renderOtherSignIn = (
    <div>
      <Divider>
        <span className="text-muted font-size-base font-weight-normal">or connect with</span>
      </Divider>
      <div className="d-flex justify-content-center">
        <Button
          onClick={() => onGoogleLogin()}
          className="mr-2"
          disabled={loading}
          icon={<CustomIcon svg={GoogleSVG} />}
        >
          Google
        </Button>
        <Button
          onClick={() => onFacebookLogin()}
          icon={<CustomIcon svg={FacebookSVG} />}
          disabled={loading}
        >
          Facebook
        </Button>
      </div>
    </div>
  );

  return (
    <Form form={form} layout="vertical" name="login-form" onFinish={onLogin}>
      <Form.Item
        name="email"
        label="Phone Number"
        rules={[
          {
            required: true,
            message: 'Please input your phone number',
          },
        ]}
      >
        <Input prefix={<PhoneOutlined className="text-primary" />} />
      </Form.Item>
      <Form.Item
        name="password"
        label={
          <div className="d-flex justify-content-between w-100 align-items-center">
            <span>Password</span>
          </div>
        }
        rules={[
          {
            required: true,
            message: 'Please input your password',
          },
        ]}
      >
        <Input.Password prefix={<LockOutlined className="text-primary" />} />
      </Form.Item>
      <Form.Item>
        <Button
          data-testid="login-submit_button"
          name="login-submit_button"
          type="primary"
          htmlType="submit"
          block
          role="button"
          loading={loading}
        >
          Log In
        </Button>
      </Form.Item>
      {otherSignIn ? renderOtherSignIn : null}
      {extra}
    </Form>
  );
};

export default LoginForm;
