/* eslint-disable */
import React, { Suspense, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'src/components/util-components/ErrorFallback';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { Row, Col, Anchor } from 'antd';
import { getOrCreateWallet, getUserWalletBalance } from 'src/redux/actions/Wallet';
import WalletBalance from './WalletBalance';
import AccountNumber from './AccountNumber';
import DateRangeSelector from '../components/DateRangeSelector';
const AjoPieChart = React.lazy(() => import('src/components/shared-components/Chart/AjoPieChart'));
const AjoLineChart = React.lazy(
  () => import('src/components/shared-components/Chart/AjoLineChart')
);
const Transactions = React.lazy(() => import('./Transactions'));
import { Greeting } from './Greeting';
import { selectCommissionWallet, selectWallet } from 'src/redux/selectors/WalletSelector';
import { IStore } from 'src/redux/store';
import { getBankAccountDetails, getCurrentUser } from 'src/redux/actions/user';
import Loading from 'src/components/shared-components/Loading';
import { getCommissionEventMap, getTransactions } from 'src/redux/actions/Commissions';
import { IGetTransactionsParams } from 'src/services/CommissionService';
import { selectCommissions } from 'src/redux/selectors/CommissionsSelectors';
import { selectBankAccountDetails } from 'src/redux/selectors/UserSelectors';
import { IUser } from 'src/services/UserService';
import useCurrentUser from 'src/hooks/useCurrentUser';
import If from 'src/components/shared-components/If';
import useLastUrl from 'src/hooks/useLastUrl';

type IHomeDashboard = ConnectedProps<typeof connector>;

const HomeDashboard: React.FC<IHomeDashboard> = (props) => {
  const { profileView } = props.ownProps;
  const user = profileView ? props.ownProps.user : props.user; //If user is passed, reference ownProps.
  const userId = profileView ? props.ownProps.userId : props.user.userId;
  const [baseParams, setBaseParams] = useState<IGetTransactionsParams>({});
  const abortController = new AbortController();

  const dispatch = useDispatch();

  const wallet = useSelector((store: IStore) => selectWallet(store));
  const commissionWallet = useSelector(() => selectCommissionWallet(wallet));

  const {
    loading,
    userWalletBalance: { availableBalance },
  } = wallet;

  const bankAccountDetails = useSelector((store: IStore) => selectBankAccountDetails(store));
  const commissionStore = useSelector((store: IStore) => selectCommissions(store));
  const { transactions } = commissionStore;
  const transactionsLoading = transactions.loading;

  const { isSavingsSDK } = useCurrentUser();

  const handleSubmit = (params: IGetTransactionsParams) => {
    dispatch(getTransactions({ ...params, userId }, abortController.signal));
  };

  React.useEffect(() => {
    dispatch(getCommissionEventMap());
    dispatch(
      getOrCreateWallet({
        walletClass: 'commission',
        walletOwner: userId,
        currency: 566,
      })
    );

    if (!profileView) {
      dispatch(getBankAccountDetails());
      dispatch(getUserWalletBalance(user));
      dispatch(getCurrentUser(userId));
    }

    return () => {
      abortController.abort();
    };
  }, []);

  const [error, setError] = React.useState(false);
  useLastUrl({ privateRoute: true });

  return (
    <>
      <Row gutter={16}>
        {profileView ?? (
          <>
            <Col span={24}>
              <ErrorBoundary
                resetKeys={[error]}
                onReset={() => setError(false)}
                FallbackComponent={ErrorFallback}
              >
                <Greeting {...props} />
              </ErrorBoundary>
            </Col>
            <Col xs={24} sm={14}>
              <Row gutter={12}>
                <ErrorBoundary
                  resetKeys={[error]}
                  onReset={() => setError(false)}
                  FallbackComponent={ErrorFallback}
                >
                  <Col xs={24} sm={12}>
                    <WalletBalance title="Main Wallet" value={availableBalance} loading={loading} />
                  </Col>
                </ErrorBoundary>
                <ErrorBoundary
                  resetKeys={[error]}
                  onReset={() => setError(false)}
                  FallbackComponent={ErrorFallback}
                >
                  <Col xs={24} sm={12}>
                    <WalletBalance
                      title="Commission Wallet"
                      value={commissionWallet?.availableBalance}
                      loading={commissionWallet?.loading}
                    />
                  </Col>
                </ErrorBoundary>
              </Row>
            </Col>
            <Col xs={24} sm={10}>
              <ErrorBoundary
                resetKeys={[error]}
                onReset={() => setError(false)}
                FallbackComponent={ErrorFallback}
              >
                <AccountNumber {...bankAccountDetails} />
              </ErrorBoundary>
            </Col>
          </>
        )}
        <Col span={24}>
          <Anchor style={{ overflow: 'unset' }} showInkInFixed={false}>
            <DateRangeSelector
              anchor
              userId={userId}
              updateOtherParams={setBaseParams}
              otherParams={baseParams}
              handleSubmit={handleSubmit}
            />
          </Anchor>
        </Col>
        <Col xs={24} sm={24} md={isSavingsSDK ? 24 : 13}>
          <ErrorBoundary
            resetKeys={[error]}
            onReset={() => setError(false)}
            FallbackComponent={ErrorFallback}
          >
            <Suspense fallback={<Loading />}>
              <AjoLineChart
                xAxisDataKey="createdAt"
                title="Transaction Volume"
                dataKey="transactionAmount"
                nameKey="Transaction volume"
                loading={transactionsLoading}
                yAxisDataKey="transactionAmount"
                data={transactions?.items?.transactions}
              />
            </Suspense>
          </ErrorBoundary>
        </Col>
        <If condition={!isSavingsSDK}>
          <Col xs={24} sm={24} md={11}>
            <ErrorBoundary
              resetKeys={[error]}
              onReset={() => setError(false)}
              FallbackComponent={ErrorFallback}
            >
              <Suspense fallback={<Loading />}>
                <AjoPieChart
                  money
                  nameKey="product"
                  dataKey="value"
                  title="Product Categories"
                  loading={transactionsLoading}
                  data={transactions?.items?.transactions}
                />
              </Suspense>
            </ErrorBoundary>
          </Col>
        </If>
        <Col className="w-100">
          <ErrorBoundary
            resetKeys={[error]}
            onReset={() => setError(false)}
            FallbackComponent={ErrorFallback}
          >
            <Transactions
              {...transactions}
              userId={userId}
              otherParams={baseParams}
              updateParams={setBaseParams}
            />
          </ErrorBoundary>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (
  state: any,
  ownProps: { user: IUser; userId: string; profileView: boolean }
) => {
  const { loading, token, user } = state.auth;
  const { aggregatorAgents, userById } = state.user;

  return {
    user,
    token,
    loading,
    userById,
    ownProps,
    aggregatorAgents,
  };
};

const connector = connect(mapStateToProps);

export default React.memo(connector(HomeDashboard));
