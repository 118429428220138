/* eslint-disable */
import { Modal, notification } from 'antd';
import {
  ICreateAmlConfigurationPayload,
  IAmlPagination,
  ILimitDetails,
} from 'src/services/AmlService';
import { IBlacklistedBVNPayload, IUser } from 'src/services/UserService';
import { DefaultInitialState } from './types';
import * as actionTypes from '../constants';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

const initState: IAmlState = {
  amlAssignments: null,
  amlAssignmentById: null,
  amlConfigurations: null,
  amlConfigurationById: null,
  walletAssignments: null,
  walletAssignmentById: null,
  blacklist: {
    blacklistedBvnById: null,
    blacklistedBvnList: null,
  },
};

export type IAmlConfiguration = ICreateAmlConfigurationPayload &
  DefaultInitialState & { deletedLimits: ILimitDetails[] };

export type IAmlConfigurationPayload = IAmlConfiguration & {
  affectedUsers: number;
};

export type IAmlConfigurations = IAmlPagination &
  DefaultInitialState & {
    items: { [key: string]: IAmlConfigurationPayload };
  };

export interface IAmlAssignment extends DefaultInitialState {
  id: string;
  amlCode?: string;
  active: boolean;
  entityID: string;
  expireAt: string;
  tenantID: string;
  createdAt: string;
  updatedAt: string;
  entityType: string;
  hasExpiry: boolean;
  configurationID: string;
}

export interface IAmlAssignments extends DefaultInitialState {
  count: number;
  total: number;
  hasMore: boolean;
  items: IAmlAssignment[];
}

export interface IBlacklistedBvnList
  extends DefaultInitialState,
    IAmlPagination {
  data: IBlacklistedBVNPayload[];
}

interface blacklistHistory {
  adminId: string;
  active: boolean;
  comment: string;
  dateTime: string;
}

interface IBlacklistedBvnObj {
  id: string;
  blacklistType: string;
  value: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  blacklistHistory: blacklistHistory[];
}

export interface IBlacklistedBvnById
  extends DefaultInitialState,
    IUser,
    IBlacklistedBvnObj {}

export interface IAmlState {
  amlAssignments: null | IAmlAssignments;
  walletAssignments: null | IAmlAssignments;
  walletAssignmentById:
    | (Omit<DefaultInitialState, 'error'> & IAmlAssignment)
    | null;
  amlAssignmentById: null | IAmlAssignment;
  amlConfigurations: null | IAmlConfigurations;
  amlConfigurationById: null | IAmlConfiguration;
  blacklist: {
    blacklistedBvnById: null | IBlacklistedBvnById;
    blacklistedBvnList: null | IBlacklistedBvnList;
  };
}

type IAmlPayload = IAmlConfiguration &
  IAmlConfigurations &
  IAmlAssignment &
  IAmlAssignments &
  any;

const amlReducer = (
  state: IAmlState = initState,
  action: { type: string; payload: IAmlPayload }
) => {
  switch (action.type) {
    case actionTypes.GET_AML_CONFIGURATION_PENDING:
      return {
        ...state,
        amlConfigurationById: {
          ...state.amlConfigurationById,
          loading: true,
          error: null,
        },
      };
      break;
    case actionTypes.GET_AML_CONFIGURATION_SUCCESS:
      return {
        ...state,
        amlConfigurationById: {
          ...state.amlConfigurationById,
          ...action.payload,
          loading: false,
          error: null,
        },
      };
      break;
    case actionTypes.GET_AML_CONFIGURATION_FAILED:
      return {
        ...state,
        amlConfigurationById: {
          ...state.amlConfigurationById,
          error: action.payload,
          loading: false,
        },
      };
      break;
    case actionTypes.GET_AML_CONFIGURATIONS_PENDING:
      return {
        ...state,
        amlConfigurations: {
          ...state.amlConfigurations,
          loading: true,
          error: null,
        },
      };
    case actionTypes.GET_AML_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        amlConfigurations: {
          ...state.amlConfigurations,
          loading: false,
          error: null,
          items: reduce(
            action.payload.items,
            (
              configObjArr: { [key: string]: IAmlConfiguration },
              configObj: IAmlConfiguration
            ) => {
              configObjArr[configObj.id] = configObj;
              return configObjArr;
            },
            {}
          ),
        },
      };
    case actionTypes.GET_AML_CONFIGURATIONS_FAILED:
      notification.error({ message: action.payload.errors[0] });
      return {
        ...state,
        amlConfigurations: {
          ...state.amlConfigurations,
          error: action.payload,
          loading: false,
        },
      };
    case actionTypes.CREATE_AML_CONFIGURATION_PENDING:
      return {
        ...state,
        amlConfigurationById: {
          loading: true,
          error: null,
          done: false,
        },
      };
    case actionTypes.CREATE_AML_CONFIGURATION_SUCCESS:
      notification.success({
        message: 'AML configuration successfully created.',
      });
      Modal.destroyAll();
      return {
        ...state,
        amlConfigurationById: {
          ...action.payload,
          loading: false,
          error: null,
          done: true,
        },
        amlConfigurations: {
          ...state.amlConfigurations,
          items: {
            ...state.amlConfigurations?.items,
            [action.payload.id]: action.payload,
          },
        },
      };
    case actionTypes.CREATE_AML_CONFIGURATION_FAILED:
      notification.error({
        message: 'Unable to create AML configuration.',
      });
      return {
        ...state,
        amlConfigurationById: {
          ...state.amlConfigurationById,
          error: action.payload,
          loading: false,
          done: true,
        },
      };
    case actionTypes.UPDATE_AML_CONFIGURATION_PENDING:
      return {
        ...state,
        amlConfigurationById: {
          ...state.amlConfigurationById,
          loading: true,
          error: null,
          done: false,
        },
      };
    case actionTypes.UPDATE_AML_CONFIGURATION_SUCCESS:
      notification.success({
        message: 'AML configuration successfully updated.',
      });
      Modal.destroyAll();
      return {
        ...state,
        amlConfigurationById: {
          ...state.amlConfigurationById,
          loading: false,
          error: null,
          done: true,
        },
        amlConfigurations: {
          ...state.amlConfigurations,
          items: {
            ...state.amlConfigurations?.items,
            [action.payload.id]: action.payload,
          },
        },
      };
    case actionTypes.UPDATE_AML_CONFIGURATION_FAILED:
      return {
        ...state,
        amlConfigurationById: {
          done: true,
          loading: false,
          error: { ...action.payload },
        },
      };
    case actionTypes.GET_AML_ASSIGNMENTS_PENDING:
      return {
        ...state,
        amlAssignments: {
          ...state.amlAssignments,
          loading: true,
          error: null,
        },
      };
    case actionTypes.GET_AML_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        amlAssignments: {
          ...action.payload,
          loading: false,
          error: null,
        },
      };
    case actionTypes.GET_AML_ASSIGNMENTS_FAILED:
      notification.error({
        message: 'Unable to fetch AML assignments.',
      });
      return {
        ...state,
        amlAssignmentById: {
          ...state.amlAssignmentById,
          loading: false,
          error: action.payload,
        },
      };
    case actionTypes.GET_WALLET_AML_ASSIGNMENTS_PENDING:
      return {
        ...state,
        walletAssignments: {
          ...state.walletAssignments,
          loading: true,
          error: null,
        },
      };
    case actionTypes.GET_WALLET_AML_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        walletAssignments: {
          ...action.payload,
          loading: false,
          error: null,
        },
      };
    case actionTypes.GET_WALLET_AML_ASSIGNMENTS_FAILED:
      notification.error({
        message: 'Unable to fetch AML assignments.',
      });
      return {
        ...state,
        walletAssignments: {
          ...state.walletAssignments,
          loading: false,
          error: action.payload,
        },
      };
    case actionTypes.GET_AML_ASSIGNMENT_PENDING:
      return {
        ...state,
        amlAssignmentById: {
          ...state.amlAssignmentById,
          loading: true,
          error: null,
        },
      };
    case actionTypes.GET_AML_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        amlAssignmentById: {
          ...action.payload,
          loading: false,
          error: null,
        },
      };
    case actionTypes.GET_AML_ASSIGNMENT_FAILED:
      notification.error({
        message: 'Unable to fetch AML assignment.',
      });
      return {
        ...state,
        amlAssignmentById: {
          ...state.amlAssignmentById,
          loading: false,
          error: action.payload,
        },
      };
    case actionTypes.LOAD_AML_CONFIGURATION_BY_ID:
      return {
        ...state,
        amlConfigurationById: {
          ...state.amlConfigurations?.items[action.payload],
          deletedLimits: [],
        },
      };
    case actionTypes.UPDATE_AML_ASSIGNMENT_PENDING:
    case actionTypes.CREATE_AML_ASSIGNMENT_PENDING:
      return {
        ...state,
        walletAssignmentById: {
          ...state.walletAssignmentById,
          loading: true,
        },
      };
    case actionTypes.UPDATE_AML_ASSIGNMENT_SUCCESS:
      notification.success({
        message: 'Successful',
        description: 'AML assignment updated',
      });
      Modal.destroyAll();
      return {
        ...state,
        walletAssignmentById: {
          ...state.walletAssignmentById,
          loading: false,
        },
      };
    case actionTypes.CREATE_AML_ASSIGNMENT_SUCCESS:
      notification.success({
        message: 'Sucessful',
        description: 'AML assignment created for wallet',
      });
      Modal.destroyAll();
      return {
        ...state,
        walletAssignmentById: {
          ...state.walletAssignmentById,
          loading: false,
        },
      };
    case actionTypes.UPDATE_AML_ASSIGNMENT_FAILED:
    case actionTypes.CREATE_AML_ASSIGNMENT_FAILED:
      notification.error({
        message: 'AML Hold placement failed',
      });
      Modal.destroyAll();
      return {
        ...state,
        walletAssignmentById: {
          ...state.walletAssignmentById,
          loading: false,
        },
      };
    case actionTypes.UPDATE_AML_ASSIGNMENT_SUCCESS:
      notification.success({
        message: 'AML Hold sucessfully released',
      });
      Modal.destroyAll();
      return {
        ...state,
      };
    case 'RESET_DONE':
      return {
        ...state,
        amlConfigurationById: {
          ...state.amlConfigurationById,
          done: null,
        },
      };
    case actionTypes.GET_BLACKLISTED_BVN_LIST_PENDING:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          blacklistedBvnList: {
            ...state.blacklist.blacklistedBvnList,
            loading: true,
            error: null,
          },
        },
      };
    case actionTypes.GET_BLACKLISTED_BVN_LIST_SUCCESS:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          blacklistedBvnList: {
            ...action.payload,
            loading: false,
            error: null,
          },
        },
      };
    case actionTypes.GET_BLACKLISTED_BVN_LIST_FAILED:
      notification.error({
        message: 'Unable to fetch blacklisted BNVs.',
      });
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          blacklistedBvnList: {
            ...state.blacklist.blacklistedBvnList,
            loading: false,
            error: action.payload,
          },
        },
      };
    case actionTypes.GET_USER_DETAILS_BY_BVN_PENDING:
    case actionTypes.GET_BLACKLISTED_BVN_PENDING:
    case actionTypes.CREATE_BLACKLIST_PENDING:
    case actionTypes.LIFT_BLACKLIST_PENDING:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          blacklistedBvnById: {
            ...state.blacklist.blacklistedBvnById,
            loading: true,
            error: null,
          },
        },
      };

    case actionTypes.GET_BLACKLISTED_BVN_SUCCESS:
    case actionTypes.CREATE_BLACKLIST_SUCCESS:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          blacklistedBvnById: {
            ...action.payload,
            error: null,
            loading: true,
          },
        },
      };

    case actionTypes.LIFT_BLACKLIST_SUCCESS:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          blacklistedBvnById: {
            ...action.payload,
            error: null,
            loading: true,
          },
          blacklistedBvnList: {
            // Replace updated bvn in blacklisted bvn list

            data: map(
              state.blacklist.blacklistedBvnList?.data,
              (element: IBlacklistedBvnById) =>
                element.value === state.blacklist.blacklistedBvnById?.value
                  ? action.payload
                  : element
            ),
          },
        },
      };

    case actionTypes.GET_USER_DETAILS_BY_BVN_SUCCESS:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          blacklistedBvnById: {
            ...state.blacklist.blacklistedBvnById,
            ...action.payload,
            loading: false,
            error: null,
          },
        },
      };

    case actionTypes.CREATE_BLACKLIST_FAILED:
    case actionTypes.LIFT_BLACKLIST_FAILED:
      notification.error({
        message: 'Blacklist operation failed!',
      });

      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          blacklistedBvnById: {
            loading: true,
            error: action.payload,
          },
        },
      };

    case actionTypes.GET_BLACKLISTED_BVN_FAILED:
      notification.info({
        message: 'BVN not blacklisted.',
      });

      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          blacklistedBvnById: {
            loading: false,
            error: action.payload,
          },
        },
      };

    case actionTypes.GET_USER_DETAILS_BY_BVN_FAILED:
      notification.info({ message: 'User not found' });

      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          blacklistedBvnById: {
            loading: false,
            error: action.payload,
          },
        },
      };

    default:
      return state;
  }
};

export default amlReducer;
