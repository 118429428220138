/* eslint-disable */
import React, { ReactNode } from 'react';
import { Layout, Grid } from 'antd';
import Utils from 'src/utils';
import SideNav from '../../components/layout-components/SideNav';
import TopNav from '../../components/layout-components/TopNav';
import MobileNav from '../../components/layout-components/MobileNav';
import HeaderNav from '../../components/layout-components/HeaderNav';
import PageHeader from '../../components/layout-components/PageHeader';
import Footer from '../../components/layout-components/Footer';
import AppViews, { SnblViews } from '../../views/app-views';
import navigationConfig from '../../configs/NavigationConfig';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
} from '../../constants/ThemeConstant';
import utils from '../../utils';
import { ErrorBoundary } from 'react-error-boundary';
import { captureException } from '@sentry/react';
import ErrorFallback from 'src/components/util-components/ErrorFallback';
import Slack from 'src/utils/slack';
import { useNavigate } from 'react-router-dom';
import { clearLastUrl, getLastUrl } from 'src/utils/lastUrl';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, SNBL_PATH } from 'src/configs/AppConfig';
import { useAppSelector } from 'src/redux/store/hooks';
import { selectTheme } from 'src/redux/selectors/ThemeSelector';
import isValidLastUrl from 'src/utils/isValidLastUrl';
import useMediaQuery from 'src/hooks/useMediaQuery';
import useCurrentUser from 'src/hooks/useCurrentUser';
import isValid from 'src/utils/isValid';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export interface IRoute {
  key?: string;
  path: string;
  title?: string;
  users?: string;
  icon?: ReactNode;
  breadcrumb?: boolean;
  component: ReactNode;
}

export interface IRoutes extends IRoute {
  submenu?: Array<IRoute>;
}

export const AppLayout: React.FC<{ location: any; extraRoutes?: Array<IRoutes> }> = ({
  extraRoutes,
  location,
}) => {
  const { navCollapsed, navType } = useAppSelector((store) => selectTheme(store));
  const { user, routes } = useCurrentUser();
  const currentRouteInfo: any = React.useMemo(
    () => utils.getRouteInfo(navigationConfig, location?.pathname),
    [navigationConfig, location?.pathname]
  );
  const { isMobile } = useMediaQuery();
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const navigate = useNavigate();
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const handleError = (
    error: Error,
    info: {
      componentStack: string;
    }
  ) => {
    captureException(error);
  };

  React.useEffect(() => {
    window.addEventListener('error', (event) => {
      Slack.logError(event);
    });

    return () => {
      window.removeEventListener('error', () => {});
    };
  }, []);

  React.useEffect(() => {
    const lastUrl = getLastUrl();

    if (lastUrl?.includes(AUTH_PREFIX_PATH) || (isValid(lastUrl) && !routes?.includes(lastUrl))) {
      navigate(`${APP_PREFIX_PATH}/home`);
      clearLastUrl();
    }

    if (isValidLastUrl(lastUrl)) {
      navigate(lastUrl);
      return clearLastUrl();
    }
  }, []);

  const isSNBLRoute = window.location.pathname.includes(SNBL_PATH);
  const isUserKycSubmitted = React.useMemo(() => Utils.isUserKycSubmitted(user), [user]);
  return (
    <Layout>
      {isSNBLRoute ? (
        <SnblViews extraRoutes={extraRoutes} />
      ) : (
        <>
          {isMobile && <HeaderNav isMobile={isMobile} />}
          {user && isUserKycSubmitted && isNavTop && !isMobile ? (
            <TopNav user={user} routeInfo={currentRouteInfo} />
          ) : null}
          <Layout className="app-container">
            {user && isUserKycSubmitted && isNavSide && !isMobile ? (
              <SideNav user={user} routeInfo={currentRouteInfo} />
            ) : null}
            <Layout className="app-layout" style={{ paddingLeft: getLayoutGutter() }}>
              <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
                <PageHeader
                  display={currentRouteInfo?.breadcrumb}
                  title={currentRouteInfo?.title}
                />
                <Content>
                  <ErrorBoundary onError={handleError} fallback={<ErrorFallback />}>
                    <AppViews extraRoutes={extraRoutes} />
                  </ErrorBoundary>
                </Content>
              </div>
              <Footer />
            </Layout>
          </Layout>
          {isMobile && <MobileNav user={user} />}
        </>
      )}
    </Layout>
  );
};

export default React.memo(AppLayout);
