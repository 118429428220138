/* eslint-disable */
import React from 'react';
import { Container } from '@material-ui/core';
import useAppSearchParams from 'src/hooks/useAppSearchParams';
import useLastUrl from 'src/hooks/useLastUrl';
import useFetch from 'src/hooks/useFetch';
import { useNavigate } from 'react-router-dom';

interface FundWalletProps {
  target: string;
}

export const SNBLFundWallet: React.FC<FundWalletProps> = ({ target }) => {
  const navigate = useNavigate();
  const { searchParamsString } = useAppSearchParams();
  const currentUserData = JSON.parse(sessionStorage.getItem('current-user') || '');

  const { isLoading, response, reset, error } = useFetch({
    method: 'GET',
    skip: true,
    url: '/users/v1/walletFundingAccount?type=fundsTransfer',
  });

  useLastUrl({ noAuthFeature: true });

  return (
    <Container maxWidth="sm" style={{ marginTop: 80 }}>
      <h1>Balance</h1>
    </Container>
  );
};

export default SNBLFundWallet;
