/* eslint-disable */
const dev = {
  ENV: 'dev',
  API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL,
  INSURANCE_BASE_URL: process.env.REACT_APP_INSURANCE_BASE_URL,
  SENTRY_DNS: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_SAMPLE_RATE: 1.0,
  MESSAGING_API_URL: '/notifications-dev'
};

const staging = {
  ENV: 'staging',
  API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL,
  INSURANCE_BASE_URL: process.env.REACT_APP_INSURANCE_BASE_URL,
  SENTRY_DNS: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_SAMPLE_RATE: 1.0,
  MESSAGING_API_URL: '/notifications-staging'
};

const prod = {
  ENV: 'prod',
  API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL,
  INSURANCE_BASE_URL: process.env.REACT_APP_INSURANCE_BASE_URL,
  SENTRY_DNS: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_SAMPLE_RATE: 0.1,
  MESSAGING_API_URL: '/notifications'
};

const test = {
  ENV: 'test',
  API_ENDPOINT_URL: 'https://api.test.com',
  INSURANCE_BASE_URL: process.env.REACT_APP_INSURANCE_BASE_URL,
  SENTRY_DNS: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_SAMPLE_RATE: 1.0,
  MESSAGING_API_URL: '/notifications-staging'
};

function getEnv() {
  switch (process.env.REACT_APP_ENV || process.env.NODE_ENV) {
    case 'dev':
      return dev;
    case 'staging':
      return staging;
    case 'prod':
      return prod;
    case 'test':
      return test;
    default:
      return undefined;
  }
}

export const env = getEnv();
