import { createSelector } from 'reselect';
import { IStore } from '../store';

export const selectLoanManagementNode = (store: IStore) => store.loanManagement;

export const selectLoanManagementUserList = createSelector(selectLoanManagementNode, (loanManagement) => {
  return {
    ...loanManagement.users.userList,
    users: loanManagement?.users?.userList?.data?.data?.content,
    total: loanManagement?.users?.userList?.data?.data?.totalElements,
  };
});

export const selectLoanManagementCreateNewUser = createSelector(selectLoanManagementNode, (loanManagement) => loanManagement.users.newUser);

export const selectBVNConsentList = createSelector(selectLoanManagementNode, (loanManagement) => loanManagement.users.releaseAccount.data);

export const selectLoanManagementCreateAndDisburseLoanLoading = (store: IStore) => store.loanManagement.loanDisbursement.loading;
export const selectLoanRepaymentSchedule = (store: IStore) => store.loanManagement.repaymentSchedule;

export const selectLoanPortfolio = (store: IStore) => {
  return {
    ...store.loanManagement.loanPortfolio,
    loanPortfolio: store?.loanManagement?.loanPortfolio?.data?.data?.content,
    total: store?.loanManagement?.loanPortfolio?.data?.data?.totalElements,
  };
};

export const selectLoanAccountEnquiry = (store: IStore) => {
  return {
    loading: store.loanManagement.loanAccountEnquiry?.loading,
    data: store.loanManagement.loanAccountEnquiry.data?.data,
  };
};

export const selectFundTransfer = (store: IStore) => store.loanManagement.fundTransfer;

export const selectUserLog = (store: IStore) => store.loanManagement.users.userLog;
