export interface IDefaultDispatch<P = any> {
  type: string;
  payload?: P;
}

export enum IDefaultCommissionEventMap {
  'SAVINGS-CYCLE-CREATION' = 'New Saving Cycle',
  'LOAN-DISBURSEMENT' = 'New loan disbursement',
  'TRIGGER' = 'New transaction triggered',
}
