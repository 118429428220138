import { Grid } from 'antd';
import React from 'react';
import Utils from 'src/utils';

const useMediaQuery = () => {
  const { useBreakpoint } = Grid;

  const breakPoints = useBreakpoint();
  const screens = React.useMemo(() => Utils.getBreakPoint(breakPoints), [breakPoints]);

  const isMobile = !screens.includes('md');
  const isTablet = !screens.includes('lg');
  const isDesktop = screens.includes('lg');

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};

export default useMediaQuery;
