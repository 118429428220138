/* eslint-disable */
const LAST_URL = 'lastUrl';

export const setLastUrl = (lastUrl: string) => {
  sessionStorage.setItem(LAST_URL, JSON.stringify(lastUrl));
};

export const getLastUrl = () => {
  return JSON.parse(sessionStorage.getItem(LAST_URL) as string);
};

export const clearLastUrl = () => {
  sessionStorage.removeItem(LAST_URL);
};
