/* eslint-disable */
import React from 'react';
import { Card, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import LoginForm from '../../components/LoginForm';
import { LogoWhite } from 'src/assets/svg/logo-white';

const LoginOne = (props: any) => {
  return (
    <div className="auth-container">
      <div className="h-100">
        <div className="container d-flex flex-column justify-content-center h-100">
          <Row justify="center">
            <Col xs={20} sm={20} md={20} lg={8}>
              <Card>
                <div className="my-4">
                  <div className="text-center my-3">
                    <LogoWhite
                      style={{
                        width: '16rem',
                      }}
                    />
                  </div>
                  <Row justify="center">
                    <Col xs={24} sm={24} md={20} lg={20}>
                      <LoginForm {...props} />
                    </Col>
                  </Row>
                  <div className="text-center">
                    <p>
                      <Link to="/auth/forgot-password">Forgot password?</Link>
                    </p>
                    <p>
                      New to AjoCard? <Link to="/auth/register">Sign Up</Link>
                    </p>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default LoginOne;
