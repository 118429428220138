/* eslint-disable */
import { Dispatch } from 'redux';
import { SET_DOCUMENT_DATA } from '../constants';
import { IDocumentData } from '../reducers/Utils';

export interface IDocHeader {
  label: string;
  key: string;
}

export const setDocumentData =
  (args?: IDocumentData) => (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_DOCUMENT_DATA,
      payload: args,
    });
  };
