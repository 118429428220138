import React, { useEffect } from 'react';
import { Layout, Typography } from 'antd';
import LoanSidePane from './sidePane';
import LoanUsers from './loanUsers';
import { useDispatch } from 'react-redux';
import { getLoanToken } from 'src/redux/actions/loanManagement';
import isValid from 'src/utils/isValid';

const LoanManagementDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const VFD_TOKEN = localStorage.getItem('vfd_token');

  useEffect(() => {
    if (!isValid(VFD_TOKEN)) dispatch(getLoanToken());
  }, [VFD_TOKEN]);

  return (
    <Layout style={layoutStyle}>
      <Sider width="12%" theme="light" style={siderStyle}>
        <LoanSidePane />
      </Sider>
      <Layout>
        <Header style={headerStyle}>
          <Typography.Title>Overview</Typography.Title>
        </Header>
        <Content style={contentStyle}>
          <LoanUsers />
        </Content>
      </Layout>
    </Layout>
  );
};

export default LoanManagementDashboard;

const { Header, Sider, Content } = Layout;

const headerStyle: React.CSSProperties = {
  paddingTop: 10,
  background: 'transparent',
};

const contentStyle: React.CSSProperties = {
  paddingLeft: 50,
  paddingTop: 10,
};

const siderStyle: React.CSSProperties = {
  paddingTop: 30,
  paddingBottom: 30,
};

const layoutStyle: React.CSSProperties = {
  overflow: 'hidden',
  width: '100%',
  height: 'calc(100vh - 10.4rem)',

};
