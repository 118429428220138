/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Form, Input, Button, Divider, Typography, message } from 'antd';
import { validateOTP } from '../../../../redux/actions/Auth';
import authService from '../../../../services/AuthService';
import './index.scss';
import { IStore } from 'src/redux/store';
import { useSelector } from 'react-redux';

const ValidateOTP: React.FC = (props: any) => {
  const phoneNumber = useSelector((store: IStore) => store?.auth?.user?.phoneNumber);
  const { loading, validateOTP } = props;
  const [form] = Form.useForm();

  const onSend = () => {
    form
      .validateFields()
      .then((values) => {
        validateOTP({
          otp: values.confirmationCode,
          phoneNumber,
          registrationType: 'SELF',
        });
      })
      .catch((info) => {
        message.error('Error occured while processing your request, Please try again');
      });
  };

  return (
    <div className="auth-container">
      <div className="h-100">
        <div className="container d-flex flex-column justify-content-center h-100">
          <Row justify="center">
            <Col xs={20} sm={20} md={20} lg={8}>
              <Card>
                <div className="my-2">
                  <div className="text-center">
                    <h3 className="mt-3 font-weight-bold">Verify your phone number</h3>
                    <p className="mb-4 fs-3">Enter your OTP sent to your phone</p>
                  </div>
                  <Row justify="center">
                    <Col xs={24} sm={24} md={20} lg={20}>
                      <Form form={form} layout="vertical" name="forget-password" onFinish={onSend}>
                        <Form.Item
                          name="confirmationCode"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your OTP',
                            },
                          ]}
                        >
                          <Input placeholder="OTP" />
                        </Form.Item>
                        <Form.Item>
                          <Button loading={loading} type="primary" htmlType="submit" block>
                            {loading ? 'Verifying...' : 'Verify'}
                          </Button>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                  <Row className="d-flex align-items-center justify-content-around">
                    <Col>
                      <Button
                        className="border-0"
                        style={{ cursor: 'pointer' }}
                        onClick={() => authService.resendSignUpOTP()}
                        disabled={loading}
                        type="text"
                      >
                        Resend OTP code.
                      </Button>
                    </Col>
                    <Divider>Or</Divider>
                    <Col>
                      <Typography.Link href="tel:*347*256*111*00#">
                        DIAL *347*256*111*00#
                      </Typography.Link>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }: { auth: any }) => {
  const { loading, message, showMessage, token, redirect } = auth;
  return {
    loading,
    message,
    showMessage,
    token,
    redirect,
  };
};

const mapDispatchToProps = {
  validateOTP,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidateOTP);
