/* eslint-disable */
import { Action, ActionCreator, Dispatch } from 'redux';
import { CLEAR_QUEUE, ADD_TO_QUEUE } from '../constants/index';
import store from '../store';

// action creators
export const addToQueue: ActionCreator<Action> = (action) => ({
  type: ADD_TO_QUEUE,
  payload: action,
});

export const clearQueue = () => ({
  type: CLEAR_QUEUE,
});

// thunk creator
export const queueThunk =
  (actions: Action | Action[]) =>
  (dispatch: Dispatch, getState: typeof store.getState) => {
    const { actionQueue } = getState();

    const actionsToAdd = Array.isArray(actions) ? actions : [actions];

    actionsToAdd.forEach((action) => {
      if (actionQueue.queue.length === 0) {
        // If the queue is empty, immediately dispatch the action
        dispatch(action);
      } else {
        // Otherwise, add the action to the end of the queue
        dispatch(addToQueue(action));
      }
    });
  };
