/* eslint-disable */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MailOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import PasswordValidator from 'password-validator';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../../../redux/actions/Auth';

const formatPasswordValidateError = function (errors: Array<string>) {
  for (let i = 0; i < errors.length; i++) {
    if (errors[i] === 'min') {
      return 'password length should be a at least 8 characters';
      // eslint-disable-next-line
    } else if (errors[i] === 'lowercase') {
      return 'password should contain lowercase letters';
    } else if (errors[i] === 'uppercase') {
      return 'password should contain uppercase letters';
    } else if (errors[i] === 'digits') {
      return 'password should contain digits';
    } else if (errors[i] === 'symbols') {
      return 'password should contain symbols';
    }
    return '';
  }
  return '';
};

// create a password schema
const Schema = new PasswordValidator();

Schema.is().min(8).has().uppercase().has().lowercase().has().digits().has().symbols();

const rules = {
  phoneNumber: [
    {
      required: true,
      message: 'Please input your Phone Number',
    },
    ({ getFieldValue }: { getFieldValue: any }) => ({
      validator(_: any, value: any) {
        if (
          !value ||
          getFieldValue('phoneNumber').length === 11 ||
          (getFieldValue('phoneNumber').includes('+234') &&
            getFieldValue('phoneNumber').length === 14)
        ) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Not a valid number'));
      },
    }),
  ],
  firstName: [
    {
      required: true,
      message: 'Please input your First Name',
    },
  ],
  lastName: [
    {
      required: true,
      message: 'Please input your Last Name',
    },
  ],
  email: [
    {
      required: true,
      message: 'Please input your email address',
    },
    {
      type: 'email',
      message: 'Please enter a validate email!',
    },
  ],
  password: [
    {
      required: true,
      message: 'Please input your password',
    },
    ({ getFieldValue }: { getFieldValue: any }) => ({
      validator(rule: any, value: any) {
        const validationRulesErrors = Schema.validate(value || getFieldValue('password'), {
          list: true,
        });
        if (validationRulesErrors.length > 0) {
          return Promise.reject(formatPasswordValidateError(validationRulesErrors));
        }
        return Promise.resolve();
      },
    }),
  ],
  confirm: [
    {
      required: true,
      message: 'Please confirm your password!',
    },
    ({ getFieldValue }: { getFieldValue: any }) => ({
      validator(rule: any, value: any) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject('Passwords do not match!');
      },
    }),
  ],
};

export const RegisterForm = (props: any) => {
  const { token, loading, redirect, registerUser, allowRedirect } = props;
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onSignUp = () => {
    form
      .validateFields()
      .then((values) => {
        registerUser({
          ...values,
        });
      })
      .catch((info) => {});
  };

  useEffect(() => {
    if (token !== null && allowRedirect) {
      navigate(redirect);
    }
  });

  const title = 'Password Policy';

  const passwordPolicyContent = (
    <>
      <h4>Your password should contain: </h4>
      <ul>
        <li>Minimum length of 8 characters</li>
        <li>Numerical characters (0-9)</li>
        <li>Special characters</li>
        <li>Uppercase letter</li>
        <li>Lowercase letter</li>
      </ul>
    </>
  );

  return (
    <>
      <Form form={form} layout="vertical" name="register-form" onFinish={onSignUp}>
        <Form.Item name="firstName" label="First Name" rules={rules.firstName} hasFeedback>
          <Input />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name" rules={rules.lastName} hasFeedback>
          <Input />
        </Form.Item>
        {/* @ts-ignore */}
        <Form.Item name="phoneNumber" label="Phone Number" rules={rules.phoneNumber} hasFeedback>
          <Input placeholder="08012345678" maxLength={11} max={11} />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          // @ts-ignore
          rules={rules.email}
          hasFeedback
        >
          <Input prefix={<MailOutlined className="text-primary" />} />
        </Form.Item>
        <Form.Item>
          <Button
            data-testid="register-submit_button"
            type="primary"
            htmlType="submit"
            block
            loading={loading}
          >
            Sign Up
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const mapStateToProps = ({ auth }: { auth: any }) => {
  const { loading, message, showMessage, token, redirect } = auth;
  return {
    loading,
    message,
    showMessage,
    token,
    redirect,
  };
};

const mapDispatchToProps = {
  registerUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
