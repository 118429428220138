/* eslint-disable */
import { createSelector } from 'reselect';
import { IAuthState } from '../reducers/Auth';
import { IStore } from '../store';

export const selectAuth = (store: IStore) => store.auth;

export const selectChangePassword = createSelector(
  selectAuth,
  (authStore: IAuthState) => authStore.changePassword
);
