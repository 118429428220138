import React from 'react';
import { connect } from 'react-redux';
import { Grid } from 'antd';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_TOP,
} from '../../constants/ThemeConstant';
import { APP_NAME } from '../../configs/AppConfig';
import utils from '../../utils';

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props: any, isMobile: any) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP;
  if (isMobile && !props.mobileLogo) {
    return 0;
  }
  if (isNavTop) {
    return 'auto';
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  }
  return `${SIDE_NAV_WIDTH}px`;
};

const getLogo = (props: any) => {
  const { navCollapsed, logoType } = props;
  if (logoType === 'light') {
    if (navCollapsed) {
      return '/img/logo-sm-white.png';
    }
    return '/img/logo-white.png';
  }

  if (navCollapsed) {
    return '/img/logo-sm.png';
  }
  return '/img/logo.png';
};

const getLogoDisplay = (isMobile: any, mobileLogo: any) => {
  if (isMobile && !mobileLogo) {
    return 'd-none';
  }
  return 'logo';
};

export const Logo: React.FC<{ mobileLogo?: boolean; logoType?: any }> = (
  props: any,
) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  return (
    <div
      className={getLogoDisplay(isMobile, props.mobileLogo)}
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
    >
      <img src={getLogo(props)} alt={`${APP_NAME} logo`} />
    </div>
  );
};

const mapStateToProps = ({ theme }: { theme: any }) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType };
};

export default connect(mapStateToProps)(Logo);
