/* eslint-disable */
import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { LogoBlack } from 'src/assets/svg/logo';
import RegisterForm from '../../components/RegisterForm';

const RegisterTwo: React.FC = (props: any) => (
  <div className="auth-container">
    <div className="h-100">
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={20} sm={20} md={24} lg={16}>
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={24} sm={24} md={20} lg={12} xl={8}>
                <h1 className="text-white">Sign Up</h1>
                <p className="text-white">
                  Already have an account? <Link to="/auth/login">Sign In</Link>
                </p>
                <div className="mt-4">
                  <RegisterForm {...props} />
                  <style>
                    {`
                    label {
                      color: #dae9f7 !important;
                    }
                  `}
                  </style>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={0} sm={0} md={0} lg={8}>
          <div className="d-flex flex-column justify-content-between h-100 px-4">
            <Row justify="center" className="mt-10">
              <Col xs={0} sm={0} md={0} lg={20}>
                <LogoBlack style={{ height: 150, width: 300, marginLeft: -20 }} />
                <h1 className="text-white">Welcome to AjoCard Console</h1>
                <p>An all-in-one agency management platform.</p>
              </Col>
              <Col xs={0} sm={0} md={0} lg={20}>
                <div className="d-flex pb-4">
                  <div>
                    <a href="/#" onClick={(e) => e.preventDefault()}>
                      Term & Conditions
                    </a>
                    <span className="mx-2"> | </span>
                    <a href="/#" onClick={(e) => e.preventDefault()}>
                      Privacy & Policy
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default RegisterTwo;
