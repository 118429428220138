/* eslint-disable */
import { notification } from 'antd';
import { Reducer } from 'redux';
import { IGeneralTransaction } from 'src/services/MPosService';
import * as actionTypes from '../constants';
import { IAppAction } from './types';

interface MposState {
  loading: boolean;
  error: any;
  mposTransactionDetails: IGeneralTransaction | null;
  userMPOSTransactionList: {
    data: IGeneralTransaction[] | null;
    loading: boolean;
    error: any;
  };
  aggregatorAgentTransactions: {
    loading: boolean;
    data: null | IGeneralTransaction[];
    error: any;
  };
  agentTransactionsById: {
    loading: boolean;
    data: null | IGeneralTransaction[];
    error: any;
  };
  aggregatorTransactionsCount: {
    loading: boolean;
    data: null | number;
    error: any;
  };
}

const initState: MposState = {
  loading: false,
  error: null,
  mposTransactionDetails: null,
  userMPOSTransactionList: {
    data: [],
    error: null,
    loading: false,
  },
  aggregatorAgentTransactions: {
    loading: false,
    data: null,
    error: null,
  },
  agentTransactionsById: {
    loading: false,
    data: [],
    error: null,
  },
  aggregatorTransactionsCount: {
    loading: false,
    data: null,
    error: null,
  },
};

const key = actionTypes.KEY;

const mposReducer: Reducer<MposState, IAppAction> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case actionTypes.GET_AN_MPOS_TRANSACTIONS_PENDING:
      return {
        ...state,
        loading: true,
        mposTransactionDetails: null,
        error: null,
      };
    case actionTypes.GET_AN_MPOS_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        mposTransactionDetails: action.payload,
        error: null,
      };
    case actionTypes.GET_AN_MPOS_TRANSACTIONS_FAILED:
      notification.error({
        message: action?.payload && action?.payload.message,
        key,
      });
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_AGGREGATOR_MPOS_TRANSACTIONS_PENDING:
      return {
        ...state,
        loading: true,
        userMPOSTransactionList: {
          ...state.userMPOSTransactionList,
          loading: true,
        },
        error: null,
      };
    case actionTypes.GET_AGGREGATOR_MPOS_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        userMPOSTransactionList: { ...action.payload, loading: false },
        loading: false,
        error: null,
      };
    case actionTypes.GET_AGGREGATOR_MPOS_TRANSACTIONS_FAILED:
      notification.error({
        message: action?.payload && action?.payload.message,
        key,
      });
      return {
        ...state,
        userMPOSTransactionList: {
          ...state.userMPOSTransactionList,
          loading: false,
        },
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_AGENT_TRANSACTIONS_SUMMARY_PENDING:
      return {
        ...state,
        aggregatorAgentTransactions: {
          ...state.aggregatorAgentTransactions,
          loading: true,
          error: null,
        },
        error: null,
      };
    case actionTypes.GET_AGENT_TRANSACTIONS_SUMMARY_SUCCESS:
      return {
        ...state,
        aggregatorAgentTransactions: {
          data: action.payload,
          loading: false,
          error: null,
        },
        error: null,
      };
    case actionTypes.GET_AGENT_TRANSACTIONS_SUMMARY_FAILED:
      notification.error({
        message: action?.payload && action?.payload.message,
        key,
      });
      return {
        ...state,
        aggregatorAgentTransactions: {
          ...state.aggregatorAgentTransactions,
          loading: false,
          error: action?.payload && action?.payload.message,
        },
        error: action.payload,
      };
    //
    case actionTypes.GET_AGGREGATOR_TRANSACTION_COUNT_PENDING:
      return {
        ...state,
        aggregatorTransactionsCount: {
          ...state.aggregatorTransactionsCount,
          loading: true,
        },
        error: null,
      };
    case actionTypes.GET_AGGREGATOR_TRANSACTION_COUNT_SUCCESS:
      return {
        ...state,
        aggregatorTransactionsCount: {
          ...state.aggregatorTransactionsCount,
          data: action.payload,
          loading: false,
          error: null,
        },
        error: null,
      };
    case actionTypes.GET_AGGREGATOR_TRANSACTION_COUNT_FAILED:
      notification.error({
        message: action?.payload && action?.payload.message,
        key,
      });
      return {
        ...state,
        aggregatorTransactionsCount: {
          ...state.aggregatorTransactionsCount,
          loading: false,
        },
        error: action.payload,
      };
    //
    case actionTypes.GET_AGENT_MPOS_TRANSACTIONS_PENDING:
      return {
        ...state,
        agentTransactionsById: {
          ...state.agentTransactionsById,
          loading: true,
          error: null,
        },
        error: null,
      };
    case actionTypes.GET_AGENT_MPOS_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        agentTransactionsById: {
          ...state.agentTransactionsById,
          loading: false,
          error: null,
          data: action.payload,
        },
        error: null,
      };
    case actionTypes.GET_AGENT_MPOS_TRANSACTIONS_FAILED:
      notification.error({
        message: action?.payload && action?.payload.message,
        key,
      });
      return {
        ...state,
        agentTransactionsById: {
          ...state.agentTransactionsById,
          loading: false,
          error: action.payload,
        },
        error: action.payload,
      };
    default:
      return state;
  }
};

export default mposReducer;
