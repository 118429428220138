import { Action } from 'redux';
import * as actionTypes from '../constants';
import { DefaultInitialState } from './types';
import {
  IGetLoanUsersResponse,
  ILoanAccountEnquiryResponse,
  ILoanPortfolioResponse,
  ILoanRepaymentScheduleResponse,
  ILoanUserLog,
  IReleaseAccountResponse,
} from 'src/services/VFDService';

export interface ILoanManagementStore {
  users: {
    newUser: Partial<DefaultInitialState<{}>>;
    releaseAccount: Partial<DefaultInitialState<{ [key: string]: IReleaseAccountResponse }>>;
    userList: Partial<DefaultInitialState<IGetLoanUsersResponse>>;
    userLog: Partial<DefaultInitialState<ILoanUserLog>>;
  };
  loanDisbursement: Partial<DefaultInitialState<IGetLoanUsersResponse>>;
  loanPortfolio: Partial<DefaultInitialState<ILoanPortfolioResponse>>;
  repaymentSchedule: DefaultInitialState<ILoanRepaymentScheduleResponse['data']['repaymentSchedule']>;
  loanAccountEnquiry: Partial<DefaultInitialState<ILoanAccountEnquiryResponse>>;
  fundTransfer: Partial<DefaultInitialState<{}>>;
}

const initState: ILoanManagementStore = {
  users: {
    newUser: {},
    releaseAccount: {},
    userList: { data: {} as any },
    userLog: { data: {} as any },
  },
  loanDisbursement: {},
  repaymentSchedule: { data: { periods: [], currency: {} } } as any,
  loanPortfolio: { data: {} as any },
  loanAccountEnquiry: {},
  fundTransfer: {},
};

const loanManagementReducer = (state: ILoanManagementStore = initState, action: Action<string> & { payload: any }): ILoanManagementStore => {
  switch (action.type) {
    // Create New User
    case actionTypes.CREATE_NEW_LOAN_USER_PENDING:
      return {
        ...state,
        users: {
          ...state.users,
          newUser: {
            loading: true,
            error: null,
            data: undefined,
          },
        },
      };
    case actionTypes.CREATE_NEW_LOAN_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          newUser: {
            loading: false,
            error: null,
            data: action.payload,
          },
        },
      };
    case actionTypes.CREATE_NEW_LOAN_USER_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          newUser: {
            loading: false,
            error: action.payload,
            data: undefined,
          },
        },
      };

    // Get Loan Users List
    case actionTypes.GET_LOAN_USERS_LIST_PENDING:
      return {
        ...state,
        users: {
          ...state.users,
          userList: {
            ...state.users.userList,
            loading: true,
            error: null,
          },
        },
      };
    case actionTypes.GET_LOAN_USERS_LIST_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          userList: {
            loading: false,
            error: null,
            data: action?.payload,
          },
        },
      };
    case actionTypes.GET_LOAN_USERS_LIST_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          userList: {
            ...state.users.userList,
            loading: false,
            error: action.payload,
          },
        },
      };

    case actionTypes.GET_BVN_VFD_BVN_CONSENT_PENDING:
      return {
        ...state,
        users: {
          ...state.users,
          releaseAccount: {
            ...state.users.releaseAccount,
            data: {
              [action.payload.reference]: { loading: true } as any,
            },
          },
        },
      };

    case actionTypes.GET_BVN_VFD_BVN_CONSENT_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          releaseAccount: {
            ...state.users.releaseAccount,
            data: {
              [action.payload.reference]: action.payload,
            },
          },
        },
      };

    case actionTypes.GET_BVN_VFD_BVN_CONSENT_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          releaseAccount: {
            ...state.users.releaseAccount,
            data: {
              [action.payload.reference]: { loading: true, error: action.payload } as any,
            },
          },
        },
      };

    case actionTypes.CREATE_AND_DISBURSE_LOAN_PENDING:
      return {
        ...state,
        loanDisbursement: {
          ...state.loanDisbursement,
          loading: true,
          error: null,
        },
      };

    case actionTypes.CREATE_AND_DISBURSE_LOAN_SUCCESS:
      return {
        ...state,
        loanDisbursement: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };

    case actionTypes.CREATE_AND_DISBURSE_LOAN_ERROR:
      return {
        ...state,
        loanDisbursement: {
          ...state.loanDisbursement,
          loading: false,
          error: action.payload,
        },
      };

    case actionTypes.GET_LOAN_PORTFOLIO_PENDING:
      return {
        ...state,
        loanPortfolio: {
          ...state.loanPortfolio,
          loading: true,
          error: null,
        },
      };

    case actionTypes.GET_LOAN_PORTFOLIO_SUCCESS:
      return {
        ...state,
        loanPortfolio: {
          ...state.loanPortfolio,
          loading: false,
          error: null,
          data: action.payload,
        },
      };

    case actionTypes.GET_LOAN_PORTFOLIO_ERROR:
      return {
        ...state,
        loanPortfolio: {
          ...state.loanPortfolio,
          loading: false,
          error: action.payload,
        },
      };

    case actionTypes.GET_LOAN_REPAYMENT_SCHEDULE_PENDING:
      return {
        ...state,
        repaymentSchedule: {
          ...state.repaymentSchedule,
          loading: true,
          error: null,
        },
      };

    case actionTypes.GET_LOAN_REPAYMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        repaymentSchedule: {
          ...state.repaymentSchedule,
          loading: false,
          error: null,
          data: action.payload,
        },
      };

    case actionTypes.GET_LOAN_REPAYMENT_SCHEDULE_ERROR:
      return {
        ...state,
        repaymentSchedule: {
          ...state.repaymentSchedule,
          loading: false,
          error: action.payload,
        },
      };

    case actionTypes.GET_LOAN_ACCOUNT_ENQUIRY_PENDING:
      return {
        ...state,
        loanAccountEnquiry: {
          ...state.loanAccountEnquiry,
          loading: true,
          error: null,
        },
      };

    case actionTypes.GET_LOAN_ACCOUNT_ENQUIRY_SUCCESS:
      return {
        ...state,
        loanAccountEnquiry: {
          ...state.loanAccountEnquiry,
          loading: false,
          error: null,
          data: action.payload,
        },
      };

    case actionTypes.GET_LOAN_ACCOUNT_ENQUIRY_ERROR:
      return {
        ...state,
        loanAccountEnquiry: {
          ...state.loanAccountEnquiry,
          loading: false,
          error: action.payload,
        },
      };

    case actionTypes.VFD_FUND_TRANSFER_PENDING:
      return {
        ...state,
        fundTransfer: {
          ...state.fundTransfer,
          loading: true,
          error: null,
        },
      };

    case actionTypes.VFD_FUND_TRANSFER_SUCCESS:
      return {
        ...state,
        fundTransfer: {
          ...state.fundTransfer,
          loading: false,
          error: null,
          data: action.payload,
        },
      };

    case actionTypes.VFD_FUND_TRANSFER_ERROR:
      return {
        ...state,
        fundTransfer: {
          ...state.fundTransfer,
          loading: false,
          error: action.payload,
        },
      };

    case actionTypes.GET_LOAN_USER_LOG_PENDING:
      return {
        ...state,
        users: {
          ...state.users,
          userLog: {
            ...state.users.userLog,
            loading: true,
            error: null,
          },
        },
      };

    case actionTypes.GET_LOAN_USER_LOG_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          userLog: {
            ...state.users.userLog,
            data: action?.payload?.data,
            loading: false,
            error: null,
          },
        },
      };

    case actionTypes.GET_LOAN_USER_LOG_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          userLog: {
            ...state.users.userLog,
            loading: false,
            error: action.payload,
          },
        },
      };

    default:
      return state;
  }
};

export default loanManagementReducer;
