/* eslint-disable */
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import AppLayout, { IRoutes } from '../layouts/app-layout';
import AppLocale from '../lang';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, SNBL_PATH } from '../configs/AppConfig';
import ErrorOne from './auth-views/errors/error-page-1';
import SNBLIdentify from './app-views/snbl/identify';
import SNBLSavingsSetup from './app-views/snbl/savings-setup';
import SNBLFundWallet from './app-views/snbl/fund-wallet';
import { useAppSelector } from 'src/redux/store/hooks';
import { selectTheme } from 'src/redux/selectors/ThemeSelector';
import { selectAuth } from 'src/redux/selectors/AuthSelector';
import Login from './auth-views/authentication/login';
import RegisterTwo from './auth-views/authentication/register';
import Verify from './auth-views/authentication/Verify';
import ForgotPassword from './auth-views/authentication/forgot-password';
import ChangePassword from './app-views/change-password';
import SetPassword from './auth-views/authentication/set-password';

export function RouteInterceptor({
  children,
  isAuthenticated,
  ...rest
}: {
  children: any;
  isAuthenticated: any;
  path: any;
  exact?: any;
}) {
  return isAuthenticated ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: AUTH_PREFIX_PATH,
      }}
    />
  );
}

const SNBL_ROUTES: Array<IRoutes> = [
  { key: SNBL_PATH, path: SNBL_PATH, component: SNBLIdentify },
  { key: `${SNBL_PATH}/identify`, path: `${SNBL_PATH}/identify`, component: SNBLIdentify },
  {
    key: `${SNBL_PATH}/savings-setup`,
    path: `${SNBL_PATH}/savings-setup`,
    component: SNBLSavingsSetup,
  },
  { key: `${SNBL_PATH}/fund-wallet`, path: `${SNBL_PATH}/fund-wallet`, component: SNBLFundWallet },
  {
    key: `${SNBL_PATH}/account-creation-successful`,
    path: `${SNBL_PATH}/account-creation-successful`,
    component: SNBLFundWallet,
  },
];

export const Views: React.FC<any> = (props) => {
  const { token } = useAppSelector((store) => selectAuth(store));
  const { locale } = useAppSelector((store) => selectTheme(store));
  const location = props?.location;

  // @ts-ignore
  const currentAppLocale = AppLocale[locale];
  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd}>
        {token ? (
          <AppLayout location={location} extraRoutes={SNBL_ROUTES} />
        ) : (
          <Routes>
            <Route path="/" element={<Navigate to={AUTH_PREFIX_PATH} />} />
            <Route path={APP_PREFIX_PATH} element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />
            <Route path={AUTH_PREFIX_PATH}>
              <Route index Component={Login} />
              <Route index path="login" Component={Login} />
              <Route path="register" Component={RegisterTwo} />
              <Route path="verify" Component={Verify} />
              <Route path="forgot-password" Component={ForgotPassword} />
              <Route path="change-password" Component={ChangePassword} />
              <Route path="error-1" Component={ErrorOne} />
              <Route path="createPassword" Component={SetPassword} />
            </Route>
            <Route path={SNBL_PATH}>
              <Route index Component={SNBLIdentify} />
              <Route path="identify" Component={SNBLIdentify} />
              <Route path="savings-setup" Component={SNBLSavingsSetup as any} />
              <Route path="fund-wallet" Component={SNBLFundWallet as any} />
              <Route path="account-creation-successful" element={SNBLFundWallet} />
            </Route>
            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />
          </Routes>
        )}
      </ConfigProvider>
    </IntlProvider>
  );
};

export default Views;
