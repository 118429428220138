/* eslint-disable */

import { AxiosPromise } from 'axios';
import fetch from './FetchInterceptor';
import { IUser } from './UserService';
import { IPaginationParams } from 'src/redux/reducers/types';
import { LendingAgentStatus } from './Types';

export interface IGetLendingAgents extends IPaginationParams {
  status: LendingAgentStatus;
  agent?: string | object;
  review?: string;
  loanOfficer: string;
  active?: boolean;
  productId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  agentId?: string;
}

export interface IGetLoanRequest extends IPaginationParams {
  limit?: number;
  startingAfter?: string;
  endingBefore?: string;
  status?: LendingAgentStatus;
  agentId?: string;
  customerId?: string;
  loanOfficerId?: string;
  productId?: string;
  disburse?: boolean;
  phoneNumber?: string;
}

export interface IGetCustomerLoans extends IPaginationParams {
  limit: number;
  loanApplicationId?: string;
  startingAfter?: string;
  endingBefore?: string;
  status?: LendingAgentStatus;
  agentId?: string;
  customerId?: string;
  amountPaid?: string;
  disbursementDate?: string;
  version?: number;
}

export type IGetNonPerformingLoans = IGetCustomerLoans;

type LendingService = {
  createLoanApplication: (payload: ICreateLoanApplicationPayload) => AxiosPromise<any>;
  updateLoanApplication: (payload: IUpdateLoanApplicationPayload) => AxiosPromise<any>;
  completeLoanApplication: (payload: ICompleteLoanApplicationPayload) => AxiosPromise<any>;
  approveLoanApplication: (payload: IApproveLoanApplication) => AxiosPromise<any>;
  approveLendingAgent: (payload: IApproveLendingAgent) => AxiosPromise<any>;

  getLoanRequest: (payload: IGetLoanRequest, abortSignal: AbortSignal) => Promise<any>;
  getPersonalBankers: (payload: any, abortSignal: AbortSignal) => Promise<any>;
  updatePersonalBanker: (payload: any) => Promise<any>;
  getLoans: (payload: any, abortSignal: AbortSignal) => Promise<any>;
  getWallets: (payload: any) => Promise<any>;
  updateLoan: (payload: any) => Promise<any>;
  getRepayments: (payload: any) => Promise<any>;
  getLoanReports: (payload: any) => Promise<any>;
  getCommisionReports: (payload: any) => Promise<any>;
};

export interface ILendingCommision {
  amouint: number;
  createdAt: string;
}

export type IApproveLoanApplication = ICompleteLoanApplicationPayload;
export type IApproveLendingAgent = { status: 'APPROVED'; lendingAgentId: string };
export interface ICreateLoanApplicationPayload {
  amount: number;
  reasonForLoan: string;
  marketLocation: string;
  phoneNumber: string;
  customerId: string;
}

export interface ICompleteLoanApplicationPayload {
  id: string;
  personalDetails: Pick<IUser, 'firstName' | 'otherNames' | 'lastName' | 'phoneNumber' | 'dob' | 'address'>;
  isApplicationComplete: boolean;
  supportingDocumentUrls: string[];
  business: {
    type: string;
    yearsInBusiness: number;
    dailyUnitSales: number;
    weeklyMarketAttendance: number;
    belongsToMarketGroup: boolean;
  };
  guarantor: {
    firstName: string;
    lastName: string;
    address: { lga: string; state: string; address: string };
    dob: string;
    gender: string;
    religion: string;
    typeOfID: string;
    IDNumber: string;
    phoneNumber: string;
    business: {
      type: string;
      yearsInBusiness: number;
      monthlyIncome: string;
      address: string;
    };
  };
}

// No entirely sure of extending this payload from loan creation
export interface IUpdateLoanApplicationPayload extends ICreateLoanApplicationPayload {
  disburse: boolean;
  pin: string;
  cardId: string;
  id: string;
}

// eslint-disable-next-line
const LendingService = <LendingService>{};

LendingService.createLoanApplication = (data: ICreateLoanApplicationPayload) =>
  fetch({
    url: '/lending/loan-applications',
    method: 'POST',
    data,
  });

LendingService.updateLoanApplication = (data: IUpdateLoanApplicationPayload) =>
  fetch({
    url: `/lendingservice/loan-applications/${data?.id}`,
    method: 'PUT',
    data,
  });

LendingService.completeLoanApplication = (data: ICompleteLoanApplicationPayload) =>
  fetch({
    url: `/lending/loan-applications/${data?.id}`,
    method: 'PUT',
    data,
  });

LendingService.approveLoanApplication = (data: IApproveLoanApplication) =>
  fetch({ url: `/lending/loan-applications/${data?.id}`, method: 'PUT', data });

LendingService.approveLendingAgent = (data: IApproveLendingAgent) =>
  fetch({ url: `/lending-agents/${data?.lendingAgentId}`, method: 'PUT', data });

LendingService.getLoanRequest = (data: IGetLoanRequest, signal: AbortSignal) =>
  fetch({
    url: '/lending/loan-applications?&_load=customerstatus%2Cagentstatus%2Ccustomer%2Cagent',
    method: 'GET',
    params: data,
    signal,
  });
LendingService.getPersonalBankers = (data: any, signal: AbortSignal) =>
  fetch({
    url: 'lending/lending-agents',
    method: 'GET',
    params: {
      ...data,
    },
    signal,
  });
LendingService.updateLoan = (data: any) =>
  fetch({
    url: `/lending/loan-applications/${data.id}`,
    method: 'PUT',
    data,
  });
LendingService.updatePersonalBanker = (data: any) =>
  fetch({
    url: `/lending/lending-agents/${data.id}`,
    method: 'PUT',
    data,
  });

LendingService.getLoans = (data: any, signal) =>
  fetch({
    url: `lending/customer-loans${data.nonPerforming ? '/stats/npl-summary' : ''}`,
    method: 'GET',
    params: {
      ...data,
    },
    signal,
  });
LendingService.getWallets = (data: any) =>
  fetch({
    url: '/wallets/v2/wallets',
    method: 'GET',
    params: {
      ...data,
    },
  });
LendingService.getRepayments = (data: any) =>
  fetch({
    url: '/lending-reports/repayments',
    method: 'GET',
    params: {
      ...data,
    },
  });
LendingService.getLoanReports = (data: any) =>
  fetch({
    url: data.credit_reports
      ? 'lending/lending-agents'
      : data.loan_reports
        ? '/reports/mfb_reports'
        : data.repayments
          ? '/lending-reports/repayments'
          : '/reports/loan_reports',
    method: 'GET',
    params: {
      ...data,
    },
  });
LendingService.getCommisionReports = (data: any) =>
  fetch({
    url: '/reports/loan_reports',
    method: 'GET',
    params: {
      ...data,
    },
  });
// if (!data.start_date || !data.end_date) return Promise.resolve({ data: null });
// if (dayjs(data.start_date).isAfter(data.end_date, 'day')) return Promise.resolve({ data: null });

export default LendingService;
