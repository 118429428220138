/* eslint-disable */
import React from 'react';
import { AxiosRequestConfig, Method } from 'axios';
import { useState, useEffect } from 'react';
import fetch from '../services/FetchInterceptor';

interface IUseFetchProps<R = unknown> {
  method?: Method;
  url: string;
  data?: R;
  skip: boolean;
  params?: any;
  options?: AxiosRequestConfig;
}
export default function useFetch<R>({
  method,
  options,
  params = undefined,
  url,
  data,
  skip,
}: IUseFetchProps<R>) {
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    setResponse(null);

    try {
      fetch(url, { method, data, params, ...options })
        .then((res) => {
          setResponse(res);
        })
        .finally(() => {
          setIsLoading(false);
          setError(null);
        })
        .catch((err) => {
          setError(err?.response?.data?.errors[0]?.message);
        });
    } catch (err: any) {
      setIsLoading(false);
      setError(err);
    }
  };

  useEffect(() => {
    skip && fetchData();
  }, [skip]);

  return {
    response,
    error,
    isLoading,
    reset: () => {
      setError(null);
      setResponse(null);
    },
  };
}
