/* eslint-disable */
import React from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Card, CardProps, Typography } from 'antd';
import Utils from 'src/utils';

interface IWalletBalanceProps extends CardProps {
  value?: number | null;
  title: string;
}
const WalletBalance = (props: IWalletBalanceProps) => {
  const [hide, setHide] = React.useState(true);

  return (
    <Card
      styles={{
        header: {
          margin: 0,
          paddingBottom: 0,
          fontSize: '14px',
          fontWeight: 'lighter',
        },
      }}
      title={props.title}
      className="relative"
      loading={props.loading}
      bodyStyle={{ paddingTop: 0 }}
      style={{ height: '109.9px' }}
    >
      {hide ? (
        <EyeInvisibleOutlined
          className="position-absolute"
          onClick={() => setHide((prev) => !prev)}
          style={{ right: '1rem', top: '1rem', fontSize: '24px' }}
        />
      ) : (
        <EyeOutlined
          className="position-absolute"
          onClick={() => setHide((prev) => !prev)}
          style={{ right: '1rem', top: '1rem', fontSize: '24px' }}
        />
      )}
      <Typography.Title className="m-0">
        {hide ? '*** *** ***' : `${Utils.Money(!props.value ? 0 : props?.value)}`}
      </Typography.Title>
    </Card>
  );
};

export default React.memo(WalletBalance);
