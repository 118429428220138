/* eslint-disable */
import React from 'react';
import { setLastUrl } from 'src/utils/lastUrl';

interface IUseLastUrlProps {
  privateRoute?: boolean;
  noAuthFeature?: boolean;
}

const useLastUrl = (params?: IUseLastUrlProps) => {
  const fullPath = window.location.pathname + window.location.search;

  React.useEffect(() => {
    if (params?.noAuthFeature) {
      return setLastUrl(fullPath);
    }
  }, [window.location.href, params?.noAuthFeature]);
};

export default useLastUrl;
