/* eslint-disable */
import { message } from 'antd';
import { Reducer } from 'redux';
import * as actionTypes from '../constants/index';
import { IDefaultResponse } from './types';

const initialState = {
  ptspList: {},
  activePtsp: {},
  setPtst: {},
} as IInfrastructureState;

export type IPtsp = {
  healthMessage: string;
  healthy: boolean;
  provider: string;
};

export interface ISetPtspResponse {
  id: string;
  name: string;
  activeProvider: string;
  providers: string[];
  providerHealthURLs: [
    {
      provider: string;
      url: string;
    }
  ];
  updatedAt: string;
}
export interface IInfrastructureState {
  ptspList: IDefaultResponse<IPtsp[] | null>;
  activePtsp: IDefaultResponse<IPtsp | null>;
  setPtst: IDefaultResponse<ISetPtspResponse | null>;
}

const infrastructureReducer: Reducer<
  IInfrastructureState,
  { type: string; payload: any }
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PTSP_LIST_PENDING:
      return {
        ...state,
        ptspList: {
          ...state.ptspList,
          loading: true,
          error: false,
        },
      };
      break;

    case actionTypes.GET_PTSP_LIST_SUCCESS:
      return {
        ...state,
        ptspList: {
          loading: false,
          error: false,
          response: action.payload.response,
        },
      };
      break;

    case actionTypes.GET_PTSP_LIST_FAILED:
      return {
        ...state,
        ptspList: {
          loading: false,
          error: true,
          response: null,
        },
      };
      break;

    case actionTypes.GET_ACTIVE_PTSP_PENDING:
      return {
        ...state,
        activePtsp: {
          ...state.activePtsp,
          loading: true,
          error: false,
        },
      };
      break;

    case actionTypes.GET_ACTIVE_PTSP_SUCCESS:
      return {
        ...state,
        activePtsp: {
          ...state.activePtsp,
          loading: false,
          error: false,
          response: action.payload.response,
        },
      };
      break;

    case actionTypes.GET_ACTIVE_PTSP_FAILED:
      return {
        ...state,
        activePtsp: {
          loading: false,
          error: true,
          data: null,
        },
      };
      break;

    case actionTypes.SET_PTSP_PENDING:
      return {
        ...state,
        setPtst: {
          ...state.setPtst,
          loading: true,
          error: false,
        },
      };
      break;

    case actionTypes.SET_PTSP_SUCCESS:
      message.success('PTSP Change Successful');

      return {
        ...state,
        setPtst: {
          ...state.setPtst,
          loading: false,
          error: false,
          data: false,
        },
      };
      break;

    case actionTypes.SET_PTSP_FAILED:
      message.error('PTSP Change Failed');

      return {
        ...state,
        setPtst: {
          loading: false,
          error: true,
          data: null,
        },
      };
      break;

    default:
      return state;
      break;
  }
};

export default infrastructureReducer;
