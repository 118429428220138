import React, { memo } from 'react';
import { Modal, Button, Col, Form, Input, Row, Select, DatePicker, message } from 'antd';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { createNewLoanUser } from 'src/redux/actions/loanManagement';
import { ICreateNewLoanUser } from 'src/services/VFDService';
import { selectLoanManagementCreateNewUser } from 'src/redux/selectors/LoanManagement';
import { useAppSelector } from 'src/redux/store/hooks';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
};

interface ICreateUser {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateLoanUser: React.FC<ICreateUser> = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  const { loading } = useAppSelector((store) => selectLoanManagementCreateNewUser(store));

  const handleCancel = () => {
    setOpen(false);
  };

  const [form] = Form.useForm<ICreateNewLoanUser>();

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        form.submit();

        const payload = { ...values, dob: dayjs(values.dob).format('DD-MMM-YYYY') };

        dispatch(
          createNewLoanUser(
            payload,
            // Success callback
            () => {
              setOpen(false);

              form.resetFields([
                'firstname',
                'lastname',
                'middlename',
                'phone',
                'address',
                'bvn',
                'gender',
                'dob',
              ]);
            }
          )
        );
      })
      .catch((error) => {
        message.error(error);
      });
  };

  return (
    <>
      <Modal
        centered
        open={open}
        title="Create New User Account"
        onCancel={handleCancel}
        footer={
          <Form form={form}>
            <Form.Item>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                onClick={onSubmit}
                style={{ color: 'black' }}
              >
                {loading ? 'Creating Account...' : 'Create Account'}
              </Button>
            </Form.Item>
          </Form>
        }
        styles={{ body: { paddingTop: '20px' } }}
      >
        <Form {...formItemLayout} form={form} name="register" layout="vertical" scrollToFirstError>
          <Row gutter={[16, 16]} justify="space-between">
            <Col xs={24} md={12}>
              <Form.Item
                name="firstname"
                label="First name"
                rules={[{ required: true, message: 'Please input first name!', whitespace: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="lastname"
                label="Last name"
                rules={[{ required: true, message: 'Please input last name!', whitespace: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="middlename" label="Middle name">
            <Input />
          </Form.Item>

          <Form.Item
            name="address"
            label="Address"
            rules={[{ required: true, message: 'Please input address!', whitespace: true }]}
          >
            <Input />
          </Form.Item>

          <Row gutter={[16, 16]} justify="space-between">
            <Col xs={24} md={12}>
              <Form.Item
                name="bvn"
                label="BVN"
                rules={[
                  { required: true, message: 'Please input BVN!', whitespace: true },
                  { message: 'BVN must be at least 11 digits', whitespace: true, min: 11 },
                ]}
              >
                <Input type="number" min={0} minLength={10} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[{ required: true, message: 'Please input your phone number!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} justify="space-between">
            <Col xs={24} md={12}>
              <Form.Item
                name="gender"
                label="Gender"
                rules={[{ required: true, message: 'Please select gender!', whitespace: true }]}
              >
                <Select placeholder="Select gender">
                  <Select.Option value="Male">Male</Select.Option>
                  <Select.Option value="Female">Female</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="dob"
                label="Date of birth"
                rules={[
                  {
                    required: true,
                    type: 'date',
                    message: 'Please input date of birth!',
                    whitespace: true,
                  },
                ]}
              >
                <DatePicker
                  name="dob"
                  format={'DD-MMM-YYYY'}
                  maxDate={dayjs().subtract(18, 'years')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(CreateLoanUser);

CreateLoanUser.displayName = 'CreateLoanUser';
