import { Dispatch } from 'redux';
import { message, notification } from 'antd';
import VFDLoanService, {
  IBeneficiaryEnquiryPayload,
  IBeneficiaryEnquiryResponse,
  ICreateAndDisburseLoanPayload,
  ICreateNewLoanUser,
  IGetBVNConsentPayload,
  IGetLoanPortfolioPayload,
  IGetLoanUsersPayload,
  ILoanAccountEnquiryPayload,
  ILoanRepaymentSchedulePayload,
  ILoanUserLogPayoad,
  ILogNewLoanUser,
  IReleaseAccountPayload,
  IVFDFundTransferPayload,
} from 'src/services/VFDService';
import {
  BENEFICIARY_ENQUIRY_PENDING,
  CREATE_AND_DISBURSE_LOAN_ERROR,
  CREATE_AND_DISBURSE_LOAN_PENDING,
  CREATE_AND_DISBURSE_LOAN_SUCCESS,
  CREATE_NEW_LOAN_USER_ERROR,
  CREATE_NEW_LOAN_USER_PENDING,
  CREATE_NEW_LOAN_USER_SUCCESS,
  GET_BVN_VFD_BVN_CONSENT_ERROR,
  GET_BVN_VFD_BVN_CONSENT_PENDING,
  GET_BVN_VFD_BVN_CONSENT_SUCCESS,
  GET_LOAN_ACCOUNT_ENQUIRY_ERROR,
  GET_LOAN_ACCOUNT_ENQUIRY_PENDING,
  GET_LOAN_ACCOUNT_ENQUIRY_SUCCESS,
  GET_LOAN_PORTFOLIO_ERROR,
  GET_LOAN_PORTFOLIO_PENDING,
  GET_LOAN_PORTFOLIO_SUCCESS,
  GET_LOAN_REPAYMENT_SCHEDULE_ERROR,
  GET_LOAN_REPAYMENT_SCHEDULE_PENDING,
  GET_LOAN_REPAYMENT_SCHEDULE_SUCCESS,
  GET_LOAN_TOKEN_ERROR,
  GET_LOAN_TOKEN_PENDING,
  GET_LOAN_TOKEN_SUCCESS,
  GET_LOAN_USER_LOG_ERROR,
  GET_LOAN_USER_LOG_PENDING,
  GET_LOAN_USER_LOG_SUCCESS,
  GET_LOAN_USERS_LIST_ERROR,
  GET_LOAN_USERS_LIST_PENDING,
  GET_LOAN_USERS_LIST_SUCCESS,
  LOG_NEW_LOAN_USER_ERROR,
  LOG_NEW_LOAN_USER_PENDING,
  LOG_NEW_LOAN_USER_SUCCESS,
  RELEASE_LOAN_USERS_ACCOUNT_ERROR,
  RELEASE_LOAN_USERS_ACCOUNT_PENDING,
  RELEASE_LOAN_USERS_ACCOUNT_SUCCESS,
  VFD_FUND_TRANSFER_ERROR,
  VFD_FUND_TRANSFER_PENDING,
  VFD_FUND_TRANSFER_SUCCESS,
} from '../constants';
import { v1 as uuid } from 'uuid';
import Utils from 'src/utils';

export const createNewLoanUser = (data: ICreateNewLoanUser, successCallBack: () => any) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: CREATE_NEW_LOAN_USER_PENDING });

  try {
    const response = await VFDLoanService.createNewLoanUser(data);

    successCallBack();

    dispatch({ type: CREATE_NEW_LOAN_USER_SUCCESS, payload: response.data });

    dispatch(getLoansUsersList({ entity: 'individual', page: 0, size: 10 }));

    const accountNo = response?.data?.data?.accountNo;
    const ajoCardAccountNo = process.env.REACT_APP_AJOCARD_SETTLEMENT_ACCOUNT_NUMBER;
    const signature = `${accountNo}-${ajoCardAccountNo}-${uuid()}`;

    dispatch(logNewLoanUser({ ...data, accountNo, signature }));

    message.success(response?.data?.message);
  } catch (error: any) {
    dispatch({ type: CREATE_NEW_LOAN_USER_ERROR, payload: error });

    message.error(error?.response?.data?.message);
  }
};

export const logNewLoanUser = (data: ILogNewLoanUser) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: LOG_NEW_LOAN_USER_PENDING });

  try {
    const response = await VFDLoanService.logNewLoanUser(data);

    dispatch({ type: LOG_NEW_LOAN_USER_SUCCESS, payload: response.data });
  } catch (error: any) {
    dispatch({ type: LOG_NEW_LOAN_USER_ERROR, payload: error });

    message.error(error?.response?.error);
  }
};

export const getLoanToken = async () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_LOAN_TOKEN_PENDING });

  try {
    const response = await VFDLoanService.getLoanToken();

    localStorage.setItem('vfd_token', response.data.data.access_token);

    dispatch({ type: GET_LOAN_TOKEN_SUCCESS, payload: response.data });

    return response;
  } catch (error: any) {
    dispatch({ type: GET_LOAN_TOKEN_ERROR, payload: error });

    message.error(error?.response?.data?.message);
  }
};

export const getLoansUsersList = (params: IGetLoanUsersPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_LOAN_USERS_LIST_PENDING });

  try {
    const response = await VFDLoanService.getLoanUsersList(params);

    dispatch({ type: GET_LOAN_USERS_LIST_SUCCESS, payload: response.data });
  } catch (error: any) {
    dispatch({ type: GET_LOAN_USERS_LIST_ERROR, payload: error });

    message.error(error?.response?.data?.message);
  }
};

export const getBVNConsent = (params: IGetBVNConsentPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_BVN_VFD_BVN_CONSENT_PENDING, payload: params });

  notification.info({
    key: GET_BVN_VFD_BVN_CONSENT_PENDING,
    duration: 0,
    message: 'Loading...',
  });

  try {
    const response = await VFDLoanService.getBVNConsent(params);

    dispatch({
      type: GET_BVN_VFD_BVN_CONSENT_SUCCESS,
      payload: { reference: params.reference, ...response.data },
    });

    const consentUrl = response?.data?.data?.url;

    notification.success({
      key: GET_BVN_VFD_BVN_CONSENT_PENDING,
      message: 'Redirecting to BVN consent web page...',
    });

    if (consentUrl) {
      window.open(response?.data?.data?.url, '_blank');
    }
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message,
      key: GET_BVN_VFD_BVN_CONSENT_PENDING,
    });

    dispatch({
      type: GET_BVN_VFD_BVN_CONSENT_ERROR,
      payload: { ...error, reference: params.reference },
    });
  }
};

export const releaseLoanAccount = (params: IReleaseAccountPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: RELEASE_LOAN_USERS_ACCOUNT_PENDING });

  notification.info({
    key: RELEASE_LOAN_USERS_ACCOUNT_PENDING,
    duration: 0,
    message: 'Loading...',
  });

  try {
    const response = await VFDLoanService.releaseAccount(params);

    dispatch({ type: RELEASE_LOAN_USERS_ACCOUNT_SUCCESS, payload: response.data });

    notification.success({
      key: RELEASE_LOAN_USERS_ACCOUNT_PENDING,
      message: response?.data.message,
    });
  } catch (error: any) {
    dispatch({ type: RELEASE_LOAN_USERS_ACCOUNT_ERROR, payload: error });

    notification.error({
      message: error?.response?.data?.message,
      key: RELEASE_LOAN_USERS_ACCOUNT_PENDING,
    });
  }
};

export const getBeneficiaryDetails = async (
  data: IBeneficiaryEnquiryPayload,
  key: string,
  dispatch: Dispatch<any>
): Promise<IBeneficiaryEnquiryResponse> => {
  dispatch({ type: BENEFICIARY_ENQUIRY_PENDING });

  notification.info({
    key,
    duration: 0,
    message: 'Loading beneficiary details...',
  });

  try {
    return (await VFDLoanService.getBeneficiaryDetails(data)).data;
  } catch (error: any) {
    dispatch({ type: RELEASE_LOAN_USERS_ACCOUNT_ERROR, payload: error });

    notification.error({
      message: 'Unable to get beneficiary details',
      description: error?.response?.data?.message,
      key,
    });

    return error;
  }
};

export const createAndDisburseLoan =
  ({ accountNo, transfer_type, bank, amount }: ICreateAndDisburseLoanPayload & IBeneficiaryEnquiryPayload, successCallback?: () => any) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({ type: CREATE_AND_DISBURSE_LOAN_PENDING });

    notification.info({
      duration: 0,
      key: CREATE_AND_DISBURSE_LOAN_PENDING,
      message: 'Creating and disbursing loan...',
    });
    try {
      (await getBeneficiaryDetails({ accountNo, transfer_type, bank }, CREATE_AND_DISBURSE_LOAN_PENDING, dispatch))?.data;

      const isDevEnv = process.env.REACT_APP_ENV === 'dev';

      const loanResponse = await VFDLoanService.createAndDisburseLoan({
        amount,
        accountNo,
        duration: isDevEnv ? 3 : 1,
        durationType: isDevEnv ? '2' : '0',
        transactionId: `ajocard-loans-booking-REF${uuid()}`,
      });

      dispatch({ type: CREATE_AND_DISBURSE_LOAN_SUCCESS, payload: loanResponse.data });

      notification.success({
        message: 'Successful',
        description: loanResponse?.data?.message,
        key: CREATE_AND_DISBURSE_LOAN_PENDING,
      });

      successCallback && successCallback();
    } catch (error: any) {
      dispatch({ type: CREATE_AND_DISBURSE_LOAN_ERROR, payload: error });

      notification.error({
        message: 'Failed',
        description: error?.response?.data?.message,
        key: CREATE_AND_DISBURSE_LOAN_PENDING,
      });
    }
  };

export const getLoanPortfolio = (params: IGetLoanPortfolioPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_LOAN_PORTFOLIO_PENDING });

  try {
    const response = await VFDLoanService.getLoanPortfolio(params);

    dispatch({ type: GET_LOAN_PORTFOLIO_SUCCESS, payload: response.data });
  } catch (error: any) {
    dispatch({ type: GET_LOAN_PORTFOLIO_ERROR, payload: error });

    message.error(error?.response?.data?.message);
  }
};

export const getLoanRepaymentSchedule = (params: ILoanRepaymentSchedulePayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_LOAN_REPAYMENT_SCHEDULE_PENDING });

  try {
    const response = await VFDLoanService.getLoanRepaymentSchedule(params);

    dispatch({ type: GET_LOAN_REPAYMENT_SCHEDULE_SUCCESS, payload: response.data?.data?.repaymentSchedule });
  } catch (error: any) {
    dispatch({ type: GET_LOAN_REPAYMENT_SCHEDULE_ERROR, payload: error });

    message.error(error?.response?.data?.message);
  }
};

export const getLoanAccountEnquiry = (params: ILoanAccountEnquiryPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_LOAN_ACCOUNT_ENQUIRY_PENDING });

  try {
    const response = await VFDLoanService.getLoanAccountEquiry(params);

    dispatch({ type: GET_LOAN_ACCOUNT_ENQUIRY_SUCCESS, payload: response.data });
  } catch (error: any) {
    dispatch({ type: GET_LOAN_ACCOUNT_ENQUIRY_ERROR, payload: error });

    message.error(error?.response?.data?.message);
  }
};

export const vfdTransferFunds = (params: IVFDFundTransferPayload, successCallback?: () => any) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: VFD_FUND_TRANSFER_PENDING });

  try {
    const response = await VFDLoanService.transferFund(params);

    dispatch({ type: VFD_FUND_TRANSFER_SUCCESS, payload: response.data });

    notification.info({
      duration: 0,
      key: VFD_FUND_TRANSFER_PENDING,
      message: 'Successful',
      description: `${Utils.Money(params.amount)} was transfered from ${params.fromClient} to ${params.toClient}`,
    });

    successCallback && successCallback();
  } catch (error: any) {
    dispatch({ type: VFD_FUND_TRANSFER_ERROR, payload: error });

    notification.error({
      duration: 0,
      key: VFD_FUND_TRANSFER_PENDING,
      message: 'Trasfer failed',
      description: error?.response?.data?.message,
    });
  }
};

export const getLoanUserLog = (params: ILoanUserLogPayoad) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_LOAN_USER_LOG_PENDING });

  try {
    const response = await VFDLoanService.getLoanUserlog(params);

    dispatch({ type: GET_LOAN_USER_LOG_SUCCESS, payload: response.data });
  } catch (error: any) {
    dispatch({ type: GET_LOAN_USER_LOG_ERROR, payload: error });
  }
};
