/* eslint-disable */
import { Dispatch } from 'react';
import {
  CommissionsService,
  ICommissionPlansParamsList,
  ICreateCommissionsPayload,
  IGetAgentPerformanceParams,
  IGetTransactionsParams,
} from 'src/services/CommissionService';
import userService, { IUserListProjectionPayload } from 'src/services/UserService';
import Utils from 'src/utils';
import * as actionTypes from '../constants/index';
import { IGetAgentsPerformancePayload, IGetTransactionsPayload } from '../reducers/Commission';
import { IDefaultCommissionEventMap, IDefaultDispatch } from './types';
import { getAggregatorAgentCount } from './user';

export const getCommissionEventMap = () => (dispatch: Dispatch<any>) => {
  dispatch({ type: actionTypes.GET_COMMISSION_EVENT_MAP_PENDING });

  CommissionsService.getCommissionEventMap()
    .then((res) => {
      dispatch({
        type: actionTypes.GET_COMMISSION_EVENT_MAP_SUCCESS,
        payload: res,
      });

      try {
        const map = Utils.convertArrayOfObjectToObjectByKeys(res.items, 'name', 'description');

        localStorage.setItem(actionTypes.COMMISSION_EVENT_MAP, JSON.stringify(map));
      } catch (err) {
        localStorage.setItem(
          actionTypes.COMMISSION_EVENT_MAP,
          JSON.stringify(IDefaultCommissionEventMap)
        );
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_COMMISSION_EVENT_MAP_FAILED,
        payload: err,
      });

      localStorage.setItem(
        actionTypes.COMMISSION_EVENT_MAP,
        JSON.stringify(IDefaultCommissionEventMap)
      );
    });
};

export const getCommissionPlansList = (params: ICommissionPlansParamsList) => {
  (dispatch: Dispatch<IDefaultDispatch>) => {
    dispatch({ type: actionTypes.GET_COMMISSION_PLANS_LIST_PENDING });

    CommissionsService.getCommissionsPlansList(params)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_COMMISSION_PLANS_LIST_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_COMMISSION_PLANS_LIST_FAILED,
          payload: err,
        });
      });
  };
};

export const getCommissionPlanDetailsByName =
  (planName: string) => (dispatch: Dispatch<IDefaultDispatch>) => {
    dispatch({ type: actionTypes.GET_COMMISSION_PLAN_DETAILS_BY_NAME_PENDING });

    CommissionsService.getCommissionPlanDetailsByName(planName)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_COMMISSION_PLAN_DETAILS_BY_NAME_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_COMMISSION_PLAN_DETAILS_BY_NAME_FAILED,
          payload: err,
        });
      });
  };

export const createCommissionPlan =
  (payload: ICreateCommissionsPayload) => (dispatch: Dispatch<IDefaultDispatch>) => {
    dispatch({ type: actionTypes.CREATE_COMMISSION_PLAN_PENDING });

    CommissionsService.createCommissionPayload(payload)
      .then((res) => {
        dispatch({
          type: actionTypes.CREATE_COMMISSION_PLAN_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CREATE_COMMISSION_PLAN_FAILED,
          payload: err,
        });
      });
  };

export const getTransactions =
  (params: IGetTransactionsParams, abortSignal?: AbortSignal) =>
  async (dispatch: Dispatch<IDefaultDispatch>) => {
    dispatch({
      type: actionTypes.GET_TRANSACTIONS_PENDING,
    });

    CommissionsService.getTransactions(params, abortSignal)
      .then((data: IGetTransactionsPayload) => {
        // First, render results - User details come in after next call
        dispatch({
          type: actionTypes.GET_TRANSACTIONS_SUCCESS,
          payload: data,
        });

        const transactions = data.items?.transactions;

        if (!transactions) return;

        const injectDirectDownlinesTransaction = transactions?.map((elm) => {
          if (elm.directDownline === '' || !elm.directDownline)
            return { ...elm, directDownline: elm.transactionOriginator };
          return elm;
        });

        const users = injectDirectDownlinesTransaction?.map((elm) => elm.directDownline);

        const transactionOriginators = injectDirectDownlinesTransaction?.map(
          (elm) => elm.transactionOriginator
        );

        const uniqueUsers: string[] = [];
        const uniqueTransactionsOriginators: string[] = [];

        users?.forEach((elm) => {
          if (!uniqueUsers.includes(elm)) uniqueUsers.push(elm);
        });

        transactionOriginators?.forEach((elm) => {
          if (!uniqueTransactionsOriginators.includes(elm)) uniqueTransactionsOriginators.push(elm);
        });

        try {
          userService
            .getUserListByIds(
              {
                user_ids: uniqueUsers.join(','), // Convert array of userIds to comma separated string for endpoint requirments
                projection: 'firstName,lastName,phoneNumber,userId', // Specify field details to be returned
              },
              abortSignal
            )
            .then((users: IUserListProjectionPayload) => {
              // Array of user details returned
              dispatch({
                type: actionTypes.GET_LIST_OF_USER_DETAILS_BY_IDS_SUCCESS,
                payload: users,
              });
              const userDetailsList = users.items;

              // Merge array of user details objects and array of user performance object by their intersecting field (userId)
              const merged_1 = Utils.mergeObjectsWithIntersectingfields(
                userDetailsList,
                injectDirectDownlinesTransaction,
                'userId',
                'directDownline'
              );

              // Filter transactions excluded from merge
              const invalidTransactionOriginators = injectDirectDownlinesTransaction.filter(
                (elm) => !merged_1.map((e) => e.id).includes(elm.id)
              );

              const merged_2: IGetTransactionsPayload[] = [];

              userService
                .getUserListByIds(
                  {
                    user_ids: uniqueTransactionsOriginators.join(','),
                    projection: 'firstName,lastName,userId',
                  },
                  abortSignal
                )
                .then((res: IUserListProjectionPayload) => {
                  dispatch({
                    type: actionTypes.GET_LIST_OF_USER_DETAILS_BY_IDS_SUCCESS,
                    payload: res,
                  });

                  const combinedTransactions = [
                    ...merged_1,
                    ...invalidTransactionOriginators, // Add transactions from missing found transaction originators
                  ];

                  combinedTransactions.forEach((elm) => {
                    return res.items.forEach((a) => {
                      if (elm.transactionOriginator === a.userId) {
                        merged_2.push({
                          ...elm,
                          transactionOriginatorName: `${a.firstName} ${a.lastName}`,
                        });
                      }
                    });
                  });

                  // Dispatch success action and write merged array into store together with remaining transactions parameters
                  dispatch({
                    type: actionTypes.GET_TRANSACTIONS_SUCCESS,
                    payload: {
                      ...data,
                      items: {
                        ...data.items,
                        transactions: merged_2,
                      },
                    },
                  });
                });
            });
        } catch (err) {
          dispatch({
            type: actionTypes.GET_LIST_OF_USER_DETAILS_BY_IDS_FAILED,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_TRANSACTIONS_FAILED,
          payload: err,
        });
      });
  };

export const getAgentTransactionsById =
  (params: IGetTransactionsParams) => (dispatch: Dispatch<IDefaultDispatch>) => {
    dispatch({
      type: actionTypes.GET_AGENT_TRANSACTIONS_BY_ID_PENDING,
    });

    CommissionsService.getTransactions(params)
      .then((res: IGetTransactionsPayload) => {
        dispatch({
          type: actionTypes.GET_AGENT_TRANSACTIONS_BY_ID_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_AGENT_TRANSACTIONS_BY_ID_FAILED,
          payload: err,
        });
      });
  };

export const getAgentProfileTransactionsById =
  (params: IGetTransactionsParams) => (dispatch: Dispatch<IDefaultDispatch>) => {
    dispatch({
      type: actionTypes.GET_AGENT_PROFILE_TRANSACTIONS_BY_ID_PENDING,
    });

    CommissionsService.getTransactions(params)
      .then((res: IGetTransactionsPayload) => {
        dispatch({
          type: actionTypes.GET_AGENT_PROFILE_TRANSACTIONS_BY_ID_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_AGENT_PROFILE_TRANSACTIONS_BY_ID_FAILED,
          payload: err,
        });
      });
  };

export const getAgentsPerformance =
  (params: IGetAgentPerformanceParams, abortSignal?: AbortSignal) => (dispatch: Dispatch<any>) => {
    dispatch({
      type: actionTypes.GET_AGENT_PERFORMANCE_PENDING,
    });

    CommissionsService.getAgentPerformance(params, abortSignal)
      .then((performance: IGetAgentsPerformancePayload) => {
        // First, render results - User details come in after next call
        dispatch({
          type: actionTypes.GET_AGENT_PERFORMANCE_SUCCESS,
          payload: performance,
        });
        // Get Aggregator Agent total count for active comparison
        try {
          dispatch(
            getAggregatorAgentCount(
              JSON.parse(localStorage.getItem('ac_au') ?? '').user.aggregatorNumber
            )
          );
          // Query user service to get more details about the users
          userService
            .getUserListByIds(
              {
                user_ids: performance?.items?.directDownlines?.map((elm) => elm.id).join(','), // Convert array of userIds to comma separated string for endpoint requirments
                projection: 'firstName,lastName,phoneNumber,userId', // Specify field details to be returned
              },
              abortSignal
            )
            .then((users: IUserListProjectionPayload) => {
              // Array of user details returned
              const userDetailsList = users?.items;
              const userPerformanceList = performance?.items?.directDownlines;
              // Merge array of user details objects and array of user performance object by their intersecting field (userId)
              const merged = Utils.mergeObjectsWithIntersectingfields(
                userDetailsList,
                userPerformanceList,
                'userId',
                'id'
              );

              // Dispatch success action and write merged array into store together with remaining user performance parameters
              if (merged.length > 0) {
                dispatch({
                  type: actionTypes.GET_AGENT_PERFORMANCE_SUCCESS,
                  payload: {
                    ...performance,
                    items: { ...performance.items, directDownlines: merged },
                  },
                });
              }
            });
        } catch (err) {
          dispatch({
            type: actionTypes.GET_LIST_OF_USER_DETAILS_BY_IDS_FAILED,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_AGENT_PERFORMANCE_FAILED,
          payload: err,
        });
      });
  };
