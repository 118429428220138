/* eslint-disable */
import { message } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { categories } from './transactionCategories';
import prompt from 'antd-prompt';
import { COMMISSION_EVENT_MAP } from '../../src/redux/constants';
import fetch from '../services/FetchInterceptor';
import { API_BASE_URL } from '../../src/configs/AppConfig';
import { APP_PREFIX_PATH } from '../../src/configs/AppConfig';
import { PermissionsMap } from '../../src/constants/PermissionsMap';
const _ = require('lodash')

class Utils {
  /**
   * Get first character from first & last sentences of a username
   * @param {String} name - Username
   * @return {String} 2 characters string
   */
  static getNameInitial(name) {
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }
  static getTransactionCategory(categoryID) {
    return categories.find(category => category.categoryID === categoryID)?.description || '';
  }
  /**
   * Get accessible color contrast
   * @param {String} hex - Hex color code e.g '#3e82f7'
   * @return {String} 'dark' or 'light'
   */
  static getCache(key) {
    return localStorage.getItem(key) || false;
  }
  static async promptAction(props) {
    return await prompt({ ...props });
  }
  static getBankDetailsFromMetadata(meta, narration) {

    if (narration.includes("Funds transfer")) {
      let obj = undefined;
      const details = meta.length < 2 ? JSON.parse(meta[0]) : meta.split("\n");
      if (details.length < 2) { obj = JSON.parse(details[0]) }
      const bankDetails = obj ? [obj["Bank Name"], obj['Account Name'], obj["Account Number"]] : details[1].split(",")
      const metadata = {
        bank: bankDetails[0],
        accountNumber: bankDetails[1],
        accountName: bankDetails[2],
      }
      return `Funds transfer to ${metadata.bank}: ${metadata.accountName} | ${metadata.accountNumber}`
    }
    return narration;
  }
  static putCache(key, value) {
    return localStorage.setItem(key, value);
  }
  static sendSlackMessage(body, channel) {
    const data = {
      "type": "slack",
      "body": body,
      "channel": channel || "ajocard-tool"
    };
    fetch({
      url: '/users/v1/sendMessage',
      method: 'POST',
      data,
    })
  }
  static sendTierTrail(tier, agentname, agentphone, useremail) {
    Utils.sendSlackMessage(`Agent: ${agentname}: ${agentphone} have been added to tier ${tier} \n ADMIN EMAIL: ${useremail}`)
  }

  /**
   * 
   * @param {string} string 
   * @returns {boolean} true if match, false if no match
   */
  static validateEmptyString(string) {
    return (RegExp(/[\S\s]+[\S]+/).test(string))
  }
  /**
     * Dynamically return the path name with the first character capitalised
     * @param {String} path - Pathname
     * @returns {String} - Capitalized path name without route prefix and symbols
     */
  static documentTitle(path) {
    const lowCase = path
      .replaceAll('/app/', '')
      .replaceAll('-', ' ')
      .replaceAll('/auth/', '');

    switch (path) {
      case '/':
        return 'AjoCard console';
        break;
      case '/app':
        return 'Dashboard';
        break;
      default:
        return lowCase.charAt(0).toUpperCase() + lowCase.slice(1);
        break;
    }
  }

  /**
   * Returns the index of the current route as compared with it's corresponding key in the route config
   * @param route - String value of current route
   * @param routes - Array of dashboard routes
   * @returns {String} value of current route index
   */

  static routeConfigIndex(pathName, routes) {
    if (pathName == `${APP_PREFIX_PATH}`) return 'home';
    return pathName.split(`${APP_PREFIX_PATH}/`)[1]?.split('?')[0] // remove app prefix and exclude search params
  }

  /**
   * 
   * @param {dayjs} - input  
   * @param {*}
   */

  static formatStringsToDate = (dates) => dates.map((date) => dayjs(date));

  /**
   * 
   * @param {string} utcDate
   * @param {string} format
   * @returns {string}
   */
  static formatUTCDateToString = (utcDate, format = undefined) => dayjs(utcDate).format(format ? format : 'MMM D, YYYY h:mm A');

  static sendSlack(message, email, name) {
    Utils.sendSlackMessage(`\n ${message} \n Retrieved/Updated by user: ${name}(${email})  \n Dated: ${dayjs().format('YYYY-MM-DD HH:mm:ss')} \n -------`, 'ajocard-tool');
  }
  static getColorContrast(hex) {
    const threshold = 130;
    function cutHex(h) {
      return h.charAt(0) === '#' ? h.substring(1, 7) : h;
    }
    function hexToR(h) {
      return parseInt(cutHex(h).substring(0, 2), 16);
    }
    function hexToG(h) {
      return parseInt(cutHex(h).substring(2, 4), 16);
    }
    function hexToB(h) {
      return parseInt(cutHex(h).substring(4, 6), 16);
    }
    const hRed = hexToR(hex);
    const hGreen = hexToG(hex);
    const hBlue = hexToB(hex);
    const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
    if (cBrightness > threshold) {
      return 'dark';
    }
    return 'light';
  }

  /**
   * Merge 2 arrays of objects with intersecting field values
   * @param {Array of Objects}
   * @param {Array of Objects}
   * @return {Array of Objects}
   */

  static mergeObjectsWithIntersectingfields(arrObj1, arrObj2, intersect_1, intersect_2) {
    const merged = [];

    arrObj1.forEach(function (o) {
      arrObj2.forEach(function (c) {
        if (o[intersect_1] === c[intersect_2]) merged.push(Object.assign({}, o, c));
      })
    });

    return merged
  }

  /**
   * Get current path related object from Navigation Tree
   * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
   * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
   * @return {Object} object that contained the path string
   */
  static getRouteInfo(navTree, path) {
    if (navTree?.path === path) {
      return navTree;
    }
    let route;
    for (const p in navTree) {
      if (navTree.hasOwnProperty(p) && typeof navTree[p] === 'object') {
        route = this.getRouteInfo(navTree[p], path);
        if (route) {
          return route;
        }
      }
    }
    return route;
  }

  /**
   * 
   * @param {string} cname cookie name
   * @param {any} cvalue cookie value
   * @param {number} exdays expiry in days
   */

  static setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  /**
   * 
   * @param {{}[]} arrObj 
   * @param {string} key_1 
   * @param {string} key_2 
   * @returns {{}} object
   */

  static convertArrayOfObjectToObjectByKeys(arrObj, key_1, key_2) {
    return Object.assign({}, ...arrObj.map(obj => {
      return { [obj[key_1]]: obj[key_2] }
    }))
  }

  /**
   * 
   * @param {string} event 
   * @returns {string}
   */
  static getCommissionEvent(event) {
    const raw = localStorage.getItem(COMMISSION_EVENT_MAP);
    const parsed = JSON.parse(raw);
    return parsed && parsed[event] ? parsed[event] : event
  }

  /**
   * 
   * @param {string} cname cookie name
   * @returns {string} cookie key value pair
   */

  static getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  static userPermissions(roles = []) {
    return roles.map((elm) => {
      if (PermissionsMap[elm]) return PermissionsMap[elm];
    });
  };

  /**
   * Filter routes and dashboard nav tree based on user roles
   * @param {Array} userRoutes - Roles returned as concatenated strings from user object
   * @param {Object} routes - Dashboard/Navigation Tree from directory 'configs/NavigationConfig'
   */

  static filterRoutesNavtree(user, routes, menu = false) {
    const userRoles = user?.roles
      ?.join('')
      .split('*:')
      .filter((elm) => elm !== '');
    const userRoutes = this.userPermissions(userRoles).flat();
    return routes.filter(route => userRoutes.includes(route.key))
  }

  static findLongestString(strings) {
    return strings.reduce((longestString, currentString) =>
      currentString.length > longestString.length ? currentString : longestString, '');
  }


  /**
   * 
   * @param {{}[]} filteree 
   * @param {{}[]} base
   * @param {string} key
   * @return {{}[]}
   */
  static filterArrayOfObjectsByKey(filteree, base, key) {
    const filtereeKeys = filteree.map(a => a[key]) // Array of keys strings

    return base.filter(elm => !filtereeKeys.includes(elm[key]))
  }

  static buildQuery(data) {
    // If the data is already a string, return it as-is
    if (typeof data === "string") return data;

    // Create a query array to hold the key/value pairs
    var query = [];

    // Loop through the data object
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
          // Encode each key and value, concatenate them into a string, and push them to the array
          query.push(
            encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
          );
        }
      }
    }

    // Join each item in the array with a `&` and return the resulting string
    return query.join("&");
  }
  /**
   * Darken or lighten a hex color
   * @param {String} color - Hex color code e.g '#3e82f7'
   * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
   * @return {String} Darken or lighten color
   */
  static shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);
    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);
    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);
    return `#${RR}${GG}${BB}`;
  }

  /**
   * Returns either a positive or negative
   * @param {Number} number - number value
   * @param {any} positive - value that return when positive
   * @param {any} negative - value that return when negative
   * @return {any} positive or negative value based on param
   */
  static getSignNum(number, positive, negative) {
    if (number > 0) {
      return positive;
    }
    if (number < 0) {
      return negative;
    }
    return null;
  }

  /**
   * Returns either ascending or descending value
   * @param {Object} a - antd Table sorter param a
   * @param {Object} b - antd Table sorter param b
   * @param {String} key - object key for compare
   * @return {any} a value minus b value
   */
  static antdTableSorter(a, b, key) {
    if (typeof a[key] === 'number' && typeof b[key] === 'number') {
      return a[key] - b[key];
    }

    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
      a = a[key].toLowerCase();
      b = b[key].toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  }

  /**
   * Filter array of object
   * @param {Array} list - array of objects that need to filter
   * @param {String} key - object key target
   * @param {any} value  - value that excluded from filter
   * @return {Array} a value minus b value
   */
  static filterArray(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] === value);
    }
    return data;
  }

  /**
   * Remove object from array by value
   * @param {Array} list - array of objects
   * @param {String} key - object key target
   * @param {any} value  - target value
   * @return {Array} Array that removed target object
   */
  static deleteArrayRow(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] !== value);
    }
    return data;
  }

  /**
   * Wild card search on all property of the object
   * @param {Number | String} input - any value to search
   * @param {Array} list - array for search
   * @return {Array} array of object contained keyword
   */
  static wildCardSearch(list, input) {
    const searchText = (item) => {
      for (const key in item) {
        if (item[key] == null) {
          continue;
        }
        if (
          item[key]
            .toString()
            .toUpperCase()
            .indexOf(input.toString().toUpperCase()) !== -1
        ) {
          return true;
        }
      }
    };
    list = list.filter((value) => searchText(value));
    return list;
  }

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens) {
    const breakpoints = [];
    for (const key in screens) {
      if (screens.hasOwnProperty(key)) {
        const element = screens[key];
        if (element) {
          breakpoints.push(key);
        }
      }
    }
    return breakpoints;
  }

  /**
   * 
   * @param {Number} number - To be formatted
   * @returns string - separated by commas
   */
  static formatNumberToCommaSeperated(number) {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  /**
   * 
   * @param {*} number 
   * @param {*} countryCode 
   * @returns 
   */

  static convertPhoneToISO = (number, countryCode = '234') => {
    if (!number) return '';
    if (number.substring(0, 4) === `+${countryCode}`) {
      return number;
    } else if (number.substring(0, 3) === countryCode) {
      return `+${number}`;
    } else if (number.charAt(0) === "0") {
      return `+${countryCode}${number.slice(1)}`;
    } else {
      return null;
    }
  };
  static flattenObj = (ob) => {
    let result = {};
    for (const i in ob) {
      if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
        const temp = flattenObj(ob[i]);
        for (const j in temp) {
          result[j] = temp[j];
        }
      }
      else if (Array.isArray(ob[i])) {
        result[i] = JSON.stringify(ob[i]);
      }
      else {
        result[i] = ob[i];
      }
    }
    return result;
  };
  static snakeToText = (text) => {
    return text.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
  }
  static getUserName = (user) => {
    let text = "";
    let sub = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";

    for (let i = 0; i < 6; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    for (let i = 0; i < 6; i++) {
      sub += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return `AJOCARD-${text}-CONSOLE-USER-${sub}-${user}`;
  };

  static getReference = (type) => {
    let text = "";
    let sub = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";

    for (let i = 0; i < 6; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    for (let i = 0; i < 6; i++) {
      sub += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    if (type === 'csv') return `AJOCARD-${text}-CONSOLE-CSV-${sub}`;
    return `AJOCARD-${text}-CONSOLE-Image-${sub}`;
  };

  static uploadFile = (file) => {
    let re = '';
    const media = file;
    const filename = media.name;
    const filesize = media.size / 1024 / 1024;
    let last_dot = filename.lastIndexOf(".");
    let ext = filename.slice(last_dot + 1);
    if (filesize > 20) {
      message.error({ message: "Maximum image size should be 20MB" });
    } else if (
      ext !== "JPG" &&
      ext !== "jpg" &&
      ext !== "jpeg" &&
      ext !== "png"
    ) {
      message.error({ message: "File extension should only be jpg, jpeg, or png" });
    } else {
      let bucketKey = `${this.getReference()}.${ext}`;
      fetch(`${API_BASE_URL}/users/v1/upload/${bucketKey}?contentType=${ext}`)
        .then((response) => response.json())
        .then((result) => {
          var options = {
            headers: {
              "Content-Type": ext,
            },
          };
          axios
            .put(result.uri, file, options)
            .then((result) => {
              re = bucketKey;
            })
            .catch((err) => {
              if (err?.message) {
                message.error({ message: `${err?.response.errors[0]}` });
              }
            });
        })
        .catch((err) => {
          if (err?.message) {
            message.error({ message: `${err?.response.errors[0]}` });
          }
        });
    };
    return re;
  }

  static isUserKycSubmitted = (user = {}) => {
    if (user?.identity?.kycDocs?.length > 0)
      return true;

    return false;
  }
  static generateToolRefernce = (id, referenceType) => {
    return btoa(id + ':' + referenceType);
  }

  /**
   * 
   * @param {string} token 
   * @returns payload encoded within JWT token
   */
  static parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  /**
   * 
   * @param {string} string 
   * @returns {Array} - containing user roles
   */
  static structuredPermissions(string) {
    const otherRoles = string.scope.split(' *:').join('~').split('*:').join('~').split('~');

    const adminPermission = string.scope.includes('*:*:*');

    return [adminPermission && 'admin', ...otherRoles.filter(_ => _ !== '*:*').filter(_ => _ !== '').filter(_ => _ !== '*')];
  }

  static getSession(token) {
    if (!token) return false;
    const header = this.parseJwt(token);
    if (!header) return false;
    const now = Math.floor(Date.now() / 1000);
    if (header.exp <= now) return false;
    return header;
  }

  static isTokenExpired(token) {
    if (!this.getSession(token)) {
      localStorage.clear();
      return window.location.replace("/")
    }
  }

  static money(money, currency) {
    return currency !== ""
      ? money?.toLocaleString("en-NG", {
        currency: "NGN" || money.currency,
        style: "currency",
      })
      : money?.toLocaleString();
  }

  static Money = naira => {
    const a = naira && naira.toLocaleString('en-NG', { style: 'currency', currency: 'NGN' });
    return a;
  };

  static nairaValue = kobo => {
    const a = kobo / 100;
    return a;
  };

  static parseSalaryPaymentReport = (data) => {

    if (!data?.length) return {}

    let SuccessWallets = '',
      SuccessReference = '',
      SuccessNarrations = '',
      SuccessAmounts = '',
      FailedWallets = '',
      FailedReference = '',
      FailedNarrations = '',
      FailedAmounts = '';

    data?.forEach(item => {
      if (item.status === 'Successful') {
        SuccessWallets += `${item.destinationWallet}, <br/> `
        SuccessReference += `${item.reference}, <br/> `
        SuccessNarrations += `${item.narration}, <br/> `
        SuccessAmounts += `${item.amount}, <br/> `
      }
      if (item.status === 'Failed') {
        FailedWallets += `${item.destinationWallet}, <br/> `
        FailedReference += `${item.reference}, <br/> `
        FailedNarrations += `${item.narration}, <br/> `
        FailedAmounts += `${item.amount}, <br/> `
      }
    })

    return {
      SuccessWallets,
      SuccessReference,
      SuccessNarrations,
      SuccessAmounts,
      FailedWallets,
      FailedReference,
      FailedNarrations,
      FailedAmounts,
    }
  }

  /**
   * 
   * @param {string} statusCode 
   * @returns {string} capitalized 
   */

  static capitalizeFirstStringChar = (char, separator = ' ') => {

    if (typeof char !== 'string') return '';

    if (!separator) {
      const fullChar = char.split(/(?=[A-Z])/).join(' ');
      const firstChar = this.upperCaseFirstStringChar(fullChar);
      return `${firstChar}${fullChar.substring(1)}`;
    }

    const fullChar = char.split(separator).join(' ');

    const firstChar = this.upperCaseFirstStringChar(fullChar);

    return `${firstChar}${fullChar.substring(1)}`;
  }

  static upperCaseFirstStringChar = (char) => char.charAt(0).toUpperCase();

  static color = statusCode => {
    switch (statusCode) {
      case "00":
      case "10":
      case "11":
      case "16":
      case "32":
        return "#87d068";
      case "01":
      case "02":
      case "06":
      case "09":
      case "20":
      case "22":
      case "31":
      case "39":
      case "40":
      case "42":
      case "60":
      case "68":
      case "91":
      case "92":
      case "96":
        return "#f50";
      case "03":
      case "04":
      case "05":
      case "07":
      case "08":
      case "12":
      case "13":
      case "14":
      case "15":
      case "17":
      case "18":
      case "19":
      case "21":
      case "23":
      case "24":
      case "25":
      case "26":
      case "27":
      case "28":
      case "29":
      case "30":
      case "33":
      case "34":
      case "35":
      case "36":
      case "37":
      case "38":
      case "41":
      case "43":
      case "44":
      case "51":
      case "52":
      case "53":
      case "54":
      case "55":
      case "56":
      case "57":
      case "58":
      case "59":
      case "61":
      case "62":
      case "63":
      case "64":
      case "65":
      case "66":
      case "67":
      case "75":
      case "77":
      case "78":
      case "93":
      case "94":
      case "95":
      case "98":
      case "A3":
        return "#fcbd28";
      default:
        return "#2db7f5";
    }
  };

  static printKYCDocs = (title, documents) => {
    let mywindow = window.open(
      '',
      'PRINT',
      'height=650,width=900,top=100,left=150'
    );

    mywindow.document.write(`<html><head><title>${title}</title>`);
    mywindow.document.write(`
      <style>
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 20px;
        }
        .document-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        .document {
          width: calc(30% - 20px);
          margin-bottom: 20px;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
        }
        .document img {
          width: 100%;
          height: auto;
          border-radius: 5px;
        }
        .document p {
          margin: 5px 0;
        }
      </style>
    `);
    mywindow.document.write('</head><body>');

    mywindow.document.write(`<h1>${title}</h1>`);

    mywindow.document.write('<div class="document-container">');
    documents.forEach((document, index) => {
      mywindow.document.write(`
        <div class="document">
          <img src="${document.s3ObjectPath}" alt="${document.title}">
          <h3>${document.imageName}</h3>
        </div>
      `);
      if ((index + 1) % 3 === 0) {
        mywindow.document.write('</div><div class="document-container">');
      }
    });
    mywindow.document.write('</div>');

    mywindow.document.write('</body></html>');

    mywindow.document.close();
    mywindow.focus();
    mywindow.print();

    return true;
  };

  /**
   * 
   */
  static exportCSVData = (headers, items, fileTitle) => {
    const convertToCSV = (objArray) => {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';
      for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
          if (line != '') line += ','
          line += array[i][index];
        }
        str += line + '\r\n';
      }
      return str;
    };
    if (headers) {
      items.unshift(headers);
    }
    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);
    var csv = convertToCSV(jsonObject);
    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  static exportCSVFile = (headers, items, fileTitle) => {
    var csv = this.convertJSONToCSV(headers, items);
    var exportedFilename = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  /**
   * 
   * @param {string[]} headers 
   * @param {any[]} myData 
   * @returns csv file
   */
  static convertJSONToCSV(fields, myData) {
    const { parse } = require('json2csv');

    const opts = { fields };

    try {
      const csv = parse(myData, opts);
      return csv
    } catch (err) {
      message.error({ message: err });
    }
  }

  /**
   * 
   * @param {Array of objects} ob 
   * @returns stringified data for csv convertion input 
   */
  static flattenObj = (ob) => {
    let result = {};
    for (const i in ob) {
      if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
        const temp = this.flattenObj(ob[i]);
        for (const j in temp) {
          result[j] = temp[j];
        }
      }
      else if (Array.isArray(ob[i])) {
        result[i] = JSON.stringify(ob[i]);
      }
      else {
        result[i] = ob[i];
      }
    }
    return result;
  };

  static nibssStatusCodeMeaning = statusCode => {
    switch (statusCode) {
      case "00":
        return "Completed successfully";
      case "01":
        return "Refer to card issuer";
      case "02":
        return "Refer to card issuer, special condition";
      case "03":
        return "Invalid merchant";
      case "04":
        return "Pick-up card";
      case "05":
        return "Do not honor";
      case "06":
        return "Error";
      case "07":
        return "Pick-up card, special condition";
      case "08":
        return "Honor with identification";
      case "09":
        return "Request in progress";
      case "10":
        return "Approved, partial";
      case "11":
        return "Approved, VIP";
      case "12":
        return "Invalid transaction";
      case "13":
        return "Invalid amount";
      case "14":
        return "Invalid card number";
      case "15":
        return "No such issuer";
      case "16":
        return "Approved, update track 3";
      case "17":
        return "Customer cancellation";
      case "18":
        return "Customer dispute";
      case "19":
        return "Re-enter transaction";
      case "20":
        return "Invalid response";
      case "21":
        return "No action taken";
      case "22":
        return "Suspected malfunction";
      case "23":
        return "Unacceptable transaction fee";
      case "24":
        return "File update not supported";
      case "25":
        return "Unable to locate record";
      case "26":
        return "Duplicate record";
      case "27":
        return "File update edit pages";
      case "28":
        return "File update file locked";
      case "29":
        return "File update failed";
      case "30":
        return "Format pages";
      case "31":
        return "Bank not supported";
      case "32":
        return "Completed partially";
      case "33":
        return "Expired card, pick-up";
      case "34":
        return "Suspected fraud, pick-up";
      case "35":
        return "Contact acquirer, pick-up";
      case "36":
        return "Restricted card, pick-up";
      case "37":
        return "Call acquirer security, pick-up";
      case "38":
        return "PIN tries exceeded, pick-up";
      case "39":
        return "No credit account";
      case "40":
        return "Function not supported";
      case "41":
        return "Lost card";
      case "42":
        return "No universal account";
      case "43":
        return "Stolen card";
      case "44":
        return "No investment account";
      case "51":
        return "Not sufficient funds";
      case "52":
        return "No check account";
      case "53":
        return "No savings account";
      case "54":
        return "Expired card";
      case "55":
        return "Incorrect PIN";
      case "56":
        return "No card record";
      case "57":
        return "Transaction not permitted to cardholder";
      case "58":
        return "Transaction not permitted on terminal";
      case "59":
        return "Suspected fraud";
      case "60":
        return "Contact acquirer";
      case "61":
        return "Exceeds withdrawal limit";
      case "62":
        return "Restricted card";
      case "63":
        return "Security violation";
      case "64":
        return "Original amount incorrect";
      case "65":
        return "Exceeds withdrawal frequency";
      case "66":
        return "Call acquirer security";
      case "67":
        return "Hard capture";
      case "68":
        return "Response received too late";
      case "75":
        return "PIN tries exceeded";
      case "77":
        return "Intervene, bank approval required";
      case "78":
        return "Intervene, bank approval required for partial amount";
      case "90":
        return "Cut-off in progress";
      case "91":
        return "Issuer or switch inoperative";
      case "92":
        return "Routing pages";
      case "93":
        return "Violation of law";
      case "94":
        return "Duplicate transaction";
      case "95":
        return "Reconcile pages";
      case "96":
        return "System malfunction";
      case "98":
        return "Exceeds cash limit";
      default:
        return "unknown code";
    }
  };
}

export default Utils;
