import React from 'react';
import { connect } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import utils from '../../../utils';
import SearchInput from './SearchInput';

export const NavSearch: React.FC<{
  active: any;
  close: any;
  headerNavColor: any;
}> = (props: any) => {
  const { active, close, headerNavColor } = props;
  const mode = React.useMemo(() => utils.getColorContrast(headerNavColor), [headerNavColor]);

  return (
    <div
      className={`nav-search ${active ? 'nav-search-active' : ''} ${mode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className="d-flex align-items-center w-100">
        <SearchInput close={close} active={active} />
      </div>
      <div className="nav-close" onClick={close} role="button" tabIndex={0}>
        <CloseOutlined />
      </div>
    </div>
  );
};

const mapStateToProps = ({ theme }: { theme: any }) => {
  const { headerNavColor } = theme;
  return { headerNavColor };
};

export default React.memo(connect(mapStateToProps, {})(NavSearch));
