/* eslint-disable */
import {
  AuthUser,
  signUp,
  resetPassword,
  confirmResetPassword,
  signIn,
  confirmSignUp,
  getCurrentUser,
  updatePassword,
  UpdatePasswordInput,
  SignInOutput,
  SignUpInput,
} from 'aws-amplify/auth';
import Utils from 'src/utils';
import * as actionTypes from '../redux/constants';

export type AuthForgetPass = {
  email: string;
};

export type AuthForgetConfirmPass = {
  email: string;
  confirmationCode: string;
  password: string;
};

export type ConfirmAuth = {
  email: string;
  confirmationCode: string;
};

export type AuthLogIn = {
  email: string;
  password: string;
};

export type AuthChangePassword = {
  email: string;
  newPassword: string;
  oldPassword: string;
};

export type IConfirmNewPassword = {
  username: string;
  newPassword: string;
  confirmationCode: string;
};

export type IChangePasswordParams = Omit<AuthChangePassword, 'email'>;

export type AuthReg = {
  username: string;
  lastName: string;
  firstName: string;
  password: string;
  email: string;
  phoneNumber: number;
};

type AuthMethod = {
  createAccount: (payload: AuthReg) => Promise<any>;
  loginAccount: (payload: AuthLogIn) => Promise<SignInOutput>;
  confirmEmail: (payload: ConfirmAuth) => Promise<any>;
  resendSignUpOTP: () => Promise<any>;
  forgotPassword: (payload: AuthForgetPass) => Promise<any>;
  forgotPasswordSubmit: (payload: AuthForgetConfirmPass) => Promise<any>;
  changePassword: (payload: UpdatePasswordInput) => Promise<void>;
  confirmResetPassword: (payload: IConfirmNewPassword) => Promise<any>;
  currentUserPoolInfo: () => Promise<AuthUser>;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const authService = <AuthMethod>{};

authService.createAccount = function (payload: AuthReg) {
  const params: SignUpInput = {
    username: payload.username,
    password: payload.password,
    options: {
      userAttributes: {
        email: payload.email,
        family_name: payload.lastName,
        given_name: payload.firstName,
        phone_number: Utils.convertPhoneToISO(payload.phoneNumber),
      },
    },
  };
  return signUp(params);
};

authService.loginAccount = function (payload: AuthLogIn) {
  return signIn({
    password: payload.password,
    username: Utils.convertPhoneToISO(payload.email),
  });
};

authService.resendSignUpOTP = function () {
  const username = localStorage.getItem(actionTypes.AUTH_TOKEN_ID) || '';
  return confirmSignUp({ username, confirmationCode: 'AUTO_CONFIRM' });
};

authService.confirmEmail = function (payload: ConfirmAuth) {
  return confirmSignUp({ username: payload.email, confirmationCode: payload.confirmationCode });
};

authService.forgotPassword = function (payload: AuthForgetPass) {
  return resetPassword({ username: payload.email });
};

authService.forgotPasswordSubmit = function (payload: AuthForgetConfirmPass) {
  return confirmResetPassword({
    username: payload.email,
    newPassword: payload.password,
    confirmationCode: payload.confirmationCode,
  });
};

authService.changePassword = function (payload: UpdatePasswordInput) {
  return updatePassword({ oldPassword: payload.oldPassword, newPassword: payload.newPassword });
};

authService.confirmResetPassword = function (payload: IConfirmNewPassword) {
  return confirmResetPassword({
    username: payload.username,
    newPassword: payload.newPassword,
    confirmationCode: payload.confirmationCode,
  });
};

authService.currentUserPoolInfo = () => getCurrentUser();

export default authService;
