/* eslint-disable */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Auth from './Auth';
import Theme from './Theme';
import User from './user';
import mposReducer from './Mpos';
import walletReducer from './Wallet';
import lendingReducer from './Lending';
import toolsReducer from './tools';
import savingsReducer from './Savings';
import amlReducer from './Aml';
import commissionReducer from './Commission';
import insuranceReducer from './Insurance';
import infrastructureReducer from './Infrastructure';
import { IAppHistory } from '../store';
import utilsReducer from './Utils';
import queueReducer from './queue';
import loanManagementReducer from './LoanManagement';

const rootReducer = (history: IAppHistory) =>
  combineReducers({
    routers: connectRouter(history),
    theme: Theme,
    auth: Auth,
    user: User,
    mpos: mposReducer,
    wallet: walletReducer,
    lending: lendingReducer,
    tools: toolsReducer,
    savings: savingsReducer,
    aml: amlReducer,
    commission: commissionReducer,
    insurance: insuranceReducer,
    infrastructure: infrastructureReducer,
    utils: utilsReducer,
    actionQueue: queueReducer,
    loanManagement: loanManagementReducer,
    // your reducer here
  });

export default rootReducer;
