/* eslint-disable */
import { Dispatch, Middleware } from 'redux';
import { clearQueue, queueThunk } from '../actions/queue';

// middleware
const queueMiddleware: Middleware<Dispatch> =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (typeof action === 'function') {
      // If the action is a thunk, execute it with the dispatch and getState functions
      return action(dispatch, getState);
    } else {
      // If the action is a regular action, dispatch it
      next(action);
    }

    const { actionQueue } = getState();
    if (actionQueue.queue.length > 0) {
      // If the queue is not empty, dispatch the first action in the queue
      const [nextAction, ...rest] = actionQueue.queue;

      dispatch(nextAction);

      dispatch(clearQueue());

      rest.forEach((queuedAction: any) => {
        dispatch(queueThunk(queuedAction) as any);
      });
    }
  };

export default queueMiddleware;
