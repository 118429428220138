/* eslint-disable */
import { message } from 'antd';
import dayjs from 'dayjs';
import * as Redux from 'redux';
import { walletStatementHeaders } from 'src/constants/Docs';
import WalletService, {
  ICreateProviudusAccountPayload,
  IGetOrCreatWalletParams,
  IGetWalletsParams,
  IValidateWalletPinPayload,
  IWalletStatementParams,
} from 'src/services/WalletService';
import Utils from 'src/utils';
import * as actionTypes from '../constants';
import { setDocumentData } from './Utils';
import { IDefaultDispatch } from './types';

const GetUserWalletBalance =
  (data: { walletId: string }) => async (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.GET_USER_WALLET_BALANCE_PENDING,
    });
    await WalletService.getUserWalletBalance({
      ...data,
    })
      .then((response: any) => {
        dispatch({
          type: actionTypes.GET_USER_WALLET_BALANCE_SUCCESS,
          payload: response,
        });
      })
      .catch((err: any) => {
        dispatch({
          type: actionTypes.GET_USER_WALLET_BALANCE_FAILED,
          payload: err,
        });
      });
  };

export const getWalletStatement =
  (params: IWalletStatementParams) => (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.GET_WALLET_STATEMENT_PENDING,
    });

    params.setLoading(true);

    WalletService.getWalletStatement(params)
      .then((res) => {
        params.setLoading(false);
        dispatch({
          type: actionTypes.GET_WALLET_STATEMENT_SUCCESS,
          payload: res,
        });
        try {
          message.destroy();
          const transactions = res.items;
          const filteredTransactions = transactions.map((trx: any) => {
            const trxAmount = trx.amount;
            const balanceBefore = Utils.money(trx.balanceBefore, '');
            const balanceAfter = Utils.money(trx.balanceAfter, '');

            const narration =
              !!trx.metaData && !!trx.narration
                ? Utils.getBankDetailsFromMetadata(trx.metaData, trx.narration)
                : trx.narration;

            return {
              createdAt: dayjs(trx.createdAt).format('DD-MM-YYYY h:mm:ss A'),
              id: trx.id,
              direction: trx.direction,
              agent_name: `${params.agent?.lastName} ${params.agent?.firstName}`,
              amount: `${trxAmount}`,
              service: Utils.getTransactionCategory(trx.category),
              narration: narration,
              credits: trx.direction === 1 ? `${trxAmount}` : '0',
              debits: trx.direction !== 1 ? `${trxAmount}` : '0',
              balanceBefore: `${balanceBefore}`,
              balanceAfter: `${balanceAfter}`,
            };
          });

          dispatch(
            setDocumentData({
              format: params.format,
              data: filteredTransactions,
              headers: walletStatementHeaders,
              fileName: `${params.agent?.phoneNumber}-wallet-history`,
            })
          );
        } catch (err) {
          message.error(`An error occured: ${JSON.stringify(err)}`);
        }
      })
      .catch((err) => {
        params.setLoading(false);
        dispatch({
          type: actionTypes.GET_WALLET_STATEMENT_FAILED,
          payload: err,
        });
      })
      .finally(() => {
        params.setLoading(false);
      });
  };

export const getUserWalletBalance =
  (data: { walletId: string }) => async (dispatch: Redux.Dispatch<any>) => {
    dispatch(GetUserWalletBalance(data));
  };

const GetUserWalletHistory = (params: any) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.GET_USER_WALLET_HISTORY_PENDING,
  });
  await WalletService.getUserWalletHistory({
    ...params,
  })
    .then((response: any) => {
      dispatch({
        type: actionTypes.GET_USER_WALLET_HISTORY_SUCCESS,
        payload: response,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: actionTypes.GET_USER_WALLET_HISTORY_FAILED,
        payload: err,
      });
    });
};
const GetWalletTransactions =
  (walletId: string, data: any) => async (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.GET_USER_WALLET_HISTORY_PENDING,
    });
    await WalletService.getWalletTransactions(walletId, data)
      .then((response: any) => {
        dispatch({
          type: actionTypes.GET_USER_WALLET_HISTORY_SUCCESS,
          payload: response,
        });
      })
      .catch((err: any) => {
        dispatch({
          type: actionTypes.GET_USER_WALLET_HISTORY_FAILED,
          payload: err,
        });
      });
  };
export const getWalletTransactions =
  (walletId: string, data: any) => async (dispatch: Redux.Dispatch<any>) => {
    dispatch(GetWalletTransactions(walletId, data));
  };
export const getAggregatorCommissionsSummary =
  (data: any) => async (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.GET_AGGREGATOR_AIRTIME_COMMISSIONS_PENDING,
    });
    await WalletService.getAggregatorAirtimeCommissions()
      .then((response: any) => {
        dispatch({
          type: actionTypes.GET_AGGREGATOR_AIRTIME_COMMISSIONS_SUCCESS,
          payload: response,
        });
      })
      .catch((err: any) => {
        dispatch({
          type: actionTypes.GET_AGGREGATOR_AIRTIME_COMMISSIONS_FAILED,
          payload: err,
        });
      });
    dispatch({
      type: actionTypes.GET_AGGREGATOR_BANK_TRANSFERS_COMMISSIONS_PENDING,
    });
    await WalletService.getAggregatorBankTransfersCommissions()
      .then((response: any) => {
        dispatch({
          type: actionTypes.GET_AGGREGATOR_BANK_TRANSFERS_COMMISSIONS_SUCCESS,
          payload: response,
        });
        dispatch({
          type: actionTypes.GET_AGGREGATOR_COMMISSIONS_SUMMARY,
        });
      })
      .catch((err: any) => {
        dispatch({
          type: actionTypes.GET_AGGREGATOR_BANK_TRANSFERS_COMMISSIONS_FAILED,
          payload: err,
        });
      });
  };

export const getUserWalletHistory = (params: any) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch(GetUserWalletHistory(params));
};

export const getOrCreateWallet =
  (params: IGetOrCreatWalletParams) => (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.GET_OR_CREATE_WALLET_PENDING,
    });

    WalletService.getOrCreateWallet(params)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_OR_CREATE_WALLET_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_OR_CREATE_WALLET_FAILED,
          payload: err,
        });
      });
  };

export const createProvidusAccount =
  (data: ICreateProviudusAccountPayload) => (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.CREATE_PROVIDUS_ACCOUNT_PENDING,
    });

    WalletService.createProvidusAccount(data)
      .then((res) => {
        dispatch({
          type: actionTypes.CREATE_PROVIDUS_ACCOUNT_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({ type: actionTypes.CREATE_PROVIDUS_ACCOUNT_FAILED, payload: err });
      });
  };

export const getWalletsByParams =
  (params: IGetWalletsParams) => (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.GET_WALLETS_BY_PARAMS_PENDING,
    });

    WalletService.getWalletsByParams(params)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_WALLETS_BY_PARAMS_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_WALLETS_BY_PARAMS_FAILED,
          payload: err,
        });
      });
  };

export const validateWalletPin =
  (params: IValidateWalletPinPayload) => (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.VALIDATE_WALLET_PIN_PENDING,
    });

    WalletService.validateWalletPin(params)
      .then((res) => {
        dispatch({
          type: actionTypes.VALIDATE_WALLET_PIN_SUCCESS,
          payload: res,
        });

        dispatch<IDefaultDispatch>({
          type: actionTypes.SET_WALLET_PIN_VALIDATION,
          payload: true,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.VALIDATE_WALLET_PIN_FAILED,
          payload: err,
        });
      });
  };
