import uniqueId from 'lodash/uniqueId';

/* eslint-disable */
const isSnblIdentified = () => {
  return !!sessionStorage.getItem('snbl-token');
};

const setIsSnblIdentified = (data: any) => {
  sessionStorage.setItem('snbl-token', uniqueId());
  sessionStorage.setItem('current-user', JSON.stringify(data));
};

export { isSnblIdentified, setIsSnblIdentified };
