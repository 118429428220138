/* eslint-disable */
import React from 'react';
import { Drawer } from 'antd';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { NAV_TYPE_SIDE } from '../../constants/ThemeConstant';
import MenuContent from './MenuContent';
import { onMobileNavToggle as onMobileNavToggleRedux } from '../../redux/actions/Theme';
import Flex from '../shared-components/Flex';
import { LogoWhite } from 'src/assets/svg/logo-white';
import { Link } from 'react-router-dom';

export const MobileNav = ({
  sideNavTheme,
  mobileNav,
  onMobileNavToggle,
  routeInfo,
  hideGroupTitle,
  localization = true,
  user,
}: {
  sideNavTheme?: any;
  mobileNav?: any;
  onMobileNavToggle?: any;
  routeInfo?: any;
  hideGroupTitle?: any;
  localization?: boolean;
  user?: any;
}) => {
  const props = {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
  };

  const onClose: (e?: React.MouseEvent<HTMLElement>) => void = () => {
    onMobileNavToggle(false);
  };

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={() => onClose()}
      open={mobileNav}
      headerStyle={{ background: 'black' }}
      contentWrapperStyle={{ width: '250px' }}
      bodyStyle={{
        paddingTop: 10,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center" className="pb-2">
          <Link to="/app/home">
            <LogoWhite
              cursor="pointer"
              style={{
                width: '8rem',
                marginLeft: '1.6rem',
              }}
            />
          </Link>
          <div
            role="button"
            tabIndex={0}
            className="nav-close"
            onClick={(event: React.MouseEvent<HTMLElement>) => onClose()}
          >
            <ArrowLeftOutlined className="mr-3" />
          </div>
        </Flex>
        <div className="mobile-nav-menu">
          <Scrollbars autoHide>
            <MenuContent user={user} type={NAV_TYPE_SIDE} {...props} />
          </Scrollbars>
        </div>
      </Flex>
    </Drawer>
  );
};

const mapStateToProps = ({ theme }: { theme: any }) => {
  const { navCollapsed, sideNavTheme, mobileNav } = theme;
  return { navCollapsed, sideNavTheme, mobileNav };
};

export default connect(mapStateToProps, {
  onMobileNavToggle: onMobileNavToggleRedux,
})(MobileNav);
