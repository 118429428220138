import { useDispatch } from 'react-redux';
import { ILockScreenProps } from 'src/components/shared-components/LockScreen';
import { IDefaultDispatch } from 'src/redux/actions/types';
import { SET_LOCK_SCREEN } from 'src/redux/constants';
import { selectPinValidation } from 'src/redux/selectors/WalletSelector';
import { useAppSelector } from 'src/redux/store/hooks';

const useLockScreen = ({ onValidated, onCancel }: Omit<ILockScreenProps, 'visible'>) => {
  const dispatch = useDispatch();
  const { timeRemaining } = useAppSelector((store) => selectPinValidation(store));

  const setLockScreen = () => {
    if (typeof timeRemaining === 'number' && timeRemaining > 0) {
      onValidated();
    } else {
      dispatch<IDefaultDispatch<Pick<ILockScreenProps, 'visible' | 'onCancel' | 'onValidated'>>>({
        type: SET_LOCK_SCREEN,
        payload: {
          visible: true,
          onValidated,
          onCancel,
        },
      });
    }
  };

  return [setLockScreen];
};

export default useLockScreen;
