/* eslint-disable */
import fetch from './FetchInterceptor';
import { IDateFilterParams, IDateFilterParams_1 } from './Types';
import { IPaginationParams } from 'src/redux/reducers/types';
import { IUser } from './UserService';
import { DOWNLOAD_TYPE } from 'src/utils/enums';
import { AxiosPromise } from 'axios';

export interface IWalletStatementParams
  extends Partial<IDateFilterParams_1>,
    Partial<IDateFilterParams>,
    IPaginationParams {
  walletId: string;
  agent: IUser;
  $noLimit?: boolean;
  format?: DOWNLOAD_TYPE;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IGetOrCreatWalletParams {
  currency: number;
  walletOwner: string;
  walletClass: string;
}

export interface ICreateProviudusAccountPayload {
  customerName: string;
  customerEmail: string;
  ajocardWalletId: string;
  phone: string;
}

export interface ICreateProviudusAccountResponse {
  customerName: string;
  bankName: string;
  accountNumber: string;
}

export interface IWalletSummary {
  id: number;
  createdAt: string;
  updatedAt: string;
  walletID: string;
  walletClass: string;
  walletOwner: string;
  tenantID: string;
  domainID: string;
  trackedWalletID: string;
  currency: string;
  phoneNumber: string;
  availableBalance: number;
  spendLimit: number;
  totalSpend: number;
  active: boolean;
  isNew: boolean;
  tresholds: string;
  tresholdNotificationRecipients: string;
  statusMessage: string;
}

export interface IGetWalletsParams {
  $skip?: number;
  $limit?: number;
  addUserInfo?: boolean;
  walletClass?: 'customer' | 'agent';
}

export interface IGetWalletParams {
  walletId: string;
}

export interface IValidateWalletPinPayload {
  walletOwner: IUser['userId'];
  currency: '566';
  walletClass: 'agent' | 'customer';
  pin: string;
  attempts: number;
}

type WalletService = {
  getUserWalletBalance: (payload: any) => Promise<any>;
  getUserWalletHistory: (payload: any) => Promise<any>;
  getAggregatorAirtimeCommissions: () => Promise<any>;
  getAggregatorBankTransfersCommissions: () => Promise<any>;
  getLoanRequest: () => Promise<any>;
  placePnd: (payload: any) => Promise<any>;
  getWalletTransactions: (walletId: string, data?: any) => Promise<any>;
  getWalletStatement: (params: IWalletStatementParams) => Promise<any>;
  getWalletByWalletId: (walletId: string, abortSignal?: AbortSignal) => Promise<any>;
  getOrCreateWallet: (params: IGetOrCreatWalletParams) => Promise<any>;
  getWalletsByParams: (params: IGetWalletsParams) => Promise<any>;
  createProvidusAccount: (params: ICreateProviudusAccountPayload) => Promise<any>;
  validateWalletPin: (data: IValidateWalletPinPayload) => AxiosPromise<any>;
};

// eslint-disable-next-line
const WalletService = <WalletService>{};

WalletService.getUserWalletBalance = (data: { walletId: string }) =>
  fetch({
    url: `/wallets/wallet/${data.walletId}/balance`,
    method: 'GET',
  });
WalletService.getWalletByWalletId = (walletId: string, signal?: AbortSignal) =>
  fetch({
    url: `/wallets/v2/wallets`,
    method: 'GET',
    params: {
      walletID: walletId,
    },
    signal,
  });
WalletService.getWalletStatement = (params: IWalletStatementParams) =>
  fetch({
    url: '/wallets/v2/transactions',
    method: 'GET',
    params: {
      limit: params?.limit,
      $noLimit: params?.$noLimit,
      $sort: 'id:-1',
      endDate: params.endDate,
      walletID: params.walletId,
      startDate: params.startDate,
    },
  });

WalletService.getUserWalletHistory = (data: any) =>
  fetch({
    url: `/wallets/wallet/${data.walletId}/history`,
    method: 'GET',
    params: {
      page: data.page,
      ...data,
      limit: data.limit || 10,
    },
  });
WalletService.getLoanRequest = () =>
  fetch({
    url: '/lending/loan-applications',
    method: 'GET',
  });
WalletService.getWalletTransactions = (walletId: string, data?: any) =>
  fetch({
    url: '/wallets/v2/transactions',
    method: 'GET',
    params: {
      walletID: walletId,
      limit: 1000,
      sort: 'id',
      ...data,
    },
  });

WalletService.getAggregatorAirtimeCommissions = () =>
  fetch({
    url: '/wallets/v2/vas/aggregatorCommission',
    method: 'GET',
  });

WalletService.getAggregatorBankTransfersCommissions = () =>
  fetch({
    url: '/wallets/v2/banktransfers/aggregatorCommission',
    method: 'GET',
  });
WalletService.placePnd = (data: any) =>
  fetch({
    url: '/wallets/v2/wallets/pnd',
    method: 'PUT',
    data,
  });
WalletService.getOrCreateWallet = (params: IGetOrCreatWalletParams) =>
  fetch({
    url: 'wallets/v2/wallets/getOrCreate',
    method: 'GET',
    params,
  });

WalletService.getWalletsByParams = (params: IGetWalletsParams) =>
  fetch({
    url: `/wallets/v2/wallets`,
    method: 'GET',
    params,
  });

WalletService.createProvidusAccount = (data: ICreateProviudusAccountPayload) =>
  fetch({
    url: '/providus/virtualAccount/create',
    method: 'POST',
    data,
  });

WalletService.getWalletsByParams = (params: IGetWalletsParams) =>
  fetch({
    url: `/wallets/v2/wallets`,
    method: 'GET',
    params,
  });

WalletService.createProvidusAccount = (data: ICreateProviudusAccountPayload) =>
  fetch({
    url: '/providus/virtualAccount/create',
    method: 'POST',
    data,
  });

WalletService.validateWalletPin = (data: IValidateWalletPinPayload) =>
  fetch({
    url: '/wallets/v2/wallets/validatepin',
    method: 'POST',
    data,
  });

// if (!data.start_date || !data.end_date) return Promise.resolve({ data: null });
// if (dayjs(data.start_date).isAfter(data.end_date, 'day')) return Promise.resolve({ data: null });

export default WalletService;
