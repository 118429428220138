import React from 'react';

/**
 *
 * @param input string
 * @param minLength number
 * @returns
 */

const usePasswordValidator = (input: string, minLength: number) => {
  const [value, setValue] = React.useState<string>(input || '');
  const hasUpperCase = /[A-Z]/.test(value);
  const hasLowerCase = /[a-z]/.test(value);
  const hasNumber = /\d/.test(value);
  const minimumLength = value?.length >= minLength;
  const hasSpecialCharacter = /[^a-zA-Z0-9 ]/.test(value); // Regex for special characters

  const initialValues = [hasUpperCase, hasLowerCase, hasNumber, minimumLength, hasSpecialCharacter];

  const [isChecked, setIsChecked] = React.useState<boolean[]>(initialValues);
  let message = '';

  React.useEffect(() => {
    initialValues.forEach((item, index) => {
      setIsChecked(() => {
        const updatedChecked = [...initialValues];
        updatedChecked[index] = !!item;
        return updatedChecked;
      });

      if (!item) {
        message += ` ${
          [
            'an uppercase letter',
            'a lowercase letter',
            'a number',
            `a minimum of ${minLength} characters`,
            'a special character',
          ][index]
        }\n`;
      }
    });
  }, [value]);

  return {
    message,
    setValue,
    isChecked,
  };
};

export default usePasswordValidator;
