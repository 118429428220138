/* eslint-disable */
export class PermissionsMap {
  static agent = [
    'home',
    'wallet',
    'buy-mpos',
    'settings',
    'user-profile',
    'change-password',
    'agent-onboarding',
  ];

  static aggregator = [...this.agent, 'agents-performance', 'agent-onboarding'];

  static headofcompliance = [
    ...this.agent,
    'aml',
    'aml-setup',
    'bvn-blacklist',
    'statement',
    'kyc-reporting',
    'user-review',
    'set-wallet-status',
  ];

  static walletstatusupdater = ['aml', 'set-wallet-status'];

  static kycapprover = ['aml', 'kyc-reporting', 'user-review'];

  static kycreviewer = ['aml', 'kyc-reporting', 'user-review'];

  static engineering = ['admin-tools'];

  static support = [
    ...this.agent,
    ...this.kycapprover,
    this.kycreviewer,
    'statement',
    'users',
    'admin-tools',
    'agent-profile',
    'partners',
  ];

  static insuranceops = ['reports', 'insurance-reports', 'products', 'insurance'];

  static savingsops = ['reports', 'savings-reports', 'products'];

  static lendingops = [
    'reports',
    'lending-reports',
    'products',
    'loanrequests',
    'pbs',
    'pbrequests',
    'npl',
  ];

  static operations = [
    ...this.agent,
    'reports',
    'savings-reports',
    'lending-reports',
    'insurance-reports',
    'products',
    'savings',
    'lending',
    'loanrequests',
    'pbs',
    'pbrequests',
    'npl',
    'users',
    'partners',
    'agent-profile',
    'insurance',
    'admin-tools',
  ];

  static savingssdk = ['home', 'wallet', 'user-profile', 'change-password'];

  static humanresources = ['admin-tools'];

  static lendinglead = ['loan-management-dashboard'];

  static admin: (string | undefined)[] = [
    'mandate-card',
    ...this.lendinglead,
    ...this.humanresources,
    ...this.agent,
    ...this.aggregator,
    ...this.headofcompliance,
    ...this.operations,
  ];

  static removeDuplicates(arr: string[]) {
    return arr.filter((elm) => arr.indexOf(elm) === arr.lastIndexOf(elm));
  }
}

export type IPermissionsMapKeys =
  | 'agent'
  | 'aggregator'
  | 'headofcompliance'
  | 'kycapprover'
  | 'kycreviewer'
  | 'insuranceops'
  | 'savingsops'
  | 'lendingops'
  | 'operations'
  | 'support'
  | 'admin'
  | 'engineering'
  | 'walletstatusupdater'
  | 'humanresources'
  | 'lendinglead';
