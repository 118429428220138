import React, { useEffect, useState } from 'react';
import { Flex, Input, Typography, Modal, message } from 'antd';
import type { OTPProps } from 'antd/es/input/OTP';
import type { GetProp } from 'antd';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/redux/store/hooks';
import { selectWallet } from 'src/redux/selectors/WalletSelector';
import { SET_LOCK_SCREEN, SET_WALLET_PIN_VALIDATION_TIME_REMAINING } from 'src/redux/constants';
import { validateWalletPin } from 'src/redux/actions/Wallet';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { IDefaultDispatch } from 'src/redux/actions/types';
import Loading from '../Loading';

const { Title } = Typography;
const PIN_LENGTH = 4;

export interface ILockScreenProps {
  visible: boolean;
  onCancel?: () => void;
  onValidated: (arg?: any) => any;
}

const LockScreen: React.FC = () => {
  const dispatch = useDispatch();
  const selectPinValidation = useAppSelector((store) => selectWallet(store))?.pinValidation;
  const { userId } = useCurrentUser();
  const [pin, setPin] = useState('');

  const { visible, onCancel, onValidated } = selectPinValidation?.lockScreen;

  useEffect(() => {
    if (typeof selectPinValidation?.timeRemaining === 'number') {
      const timer = setInterval(() => {
        if ((selectPinValidation?.timeRemaining as number) > 0) {
          dispatch<{ payload: number; type: string }>({
            type: SET_WALLET_PIN_VALIDATION_TIME_REMAINING,
            payload: (selectPinValidation?.timeRemaining as number) - 1,
          });
        } else {
          clearInterval(timer);
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [selectPinValidation?.isValid, selectPinValidation?.timeRemaining]);

  const onChange: GetProp<typeof Input.OTP, 'onChange'> = (text) => {
    setPin(text);

    if (text.length === PIN_LENGTH) {
      dispatch(
        validateWalletPin({
          pin: text,
          attempts: 0,
          currency: '566',
          walletOwner: userId,
          walletClass: 'agent',
        })
      );
    }
  };

  const handleCancel = () => {
    dispatch<IDefaultDispatch<Pick<ILockScreenProps, 'visible'>>>({
      type: SET_LOCK_SCREEN,
      payload: {
        visible: false,
      },
    });

    setPin('');

    onCancel && onCancel();
  };

  useEffect(() => {
    if (selectPinValidation?.isSuccess) {
      setPin('');
      message.success('Access granted');

      onValidated();
      dispatch<IDefaultDispatch<Pick<ILockScreenProps, 'visible'>>>({
        type: SET_LOCK_SCREEN,
        payload: {
          visible: false,
        },
      });
    }
  }, [selectPinValidation?.isSuccess]);

  const sharedProps: OTPProps = {
    onChange,
  };

  return (
    <Modal
      centered
      open={visible}
      onCancel={handleCancel}
      footer={
        <div style={{ paddingBottom: '30px' }}>
          {selectPinValidation?.loading && <Loading className="position-relative" />}
        </div>
      }
    >
      <Flex gap="middle" align="center" vertical>
        <Title level={5}>Enter your Wallet PIN to proceed</Title>
        <Input.OTP
          value={pin}
          mask={'🔒'}
          style={{ width: '200px' }}
          length={PIN_LENGTH}
          itemType="number"
          {...sharedProps}
        />
      </Flex>
    </Modal>
  );
};

export default LockScreen;
