/* eslint-disable */
import React from 'react';
import { DatePicker, Row, Col, Segmented, message } from 'antd';
import { IDateFilterParams } from 'src/services/Types';
import { IGetTransactionsParams } from 'src/services/CommissionService';
import useAppSearchParams from 'src/hooks/useAppSearchParams';
import dayjs, { Dayjs } from 'dayjs';
const { RangePicker } = DatePicker;

export enum IDateRanges {
  MOST_RECENT = 'Most Recent',
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  LAST_7_DAYS = 'Last 7 Days',
}

const DateRangeSelector = (props: {
  anchor?: boolean;
  userId: string;
  className?: string;
  otherParams: IGetTransactionsParams;
  updateOtherParams: React.Dispatch<React.SetStateAction<IGetTransactionsParams>>;
  handleSubmit: (params: Partial<IDateFilterParams>, abortSignal?: AbortSignal) => void;
}) => {
  const { addSearchParam, getSearchParam, removeQueryKey } = useAppSearchParams();
  const startDate = getSearchParam('startDate') || '';
  const endDate = getSearchParam('endDate') || '';

  const abortController = new AbortController();

  const { userId, handleSubmit, otherParams, updateOtherParams } = props;
  const [segmented, setSegmented] = React.useState(false);

  const isQueryToday = React.useMemo(
    () => dayjs().format('YYYY-MM-DD') === startDate && dayjs().format('YYYY-MM-DD') === endDate,
    [startDate, endDate]
  );

  const isQueryYesterday = React.useMemo(
    () =>
      dayjs().subtract(1, 'days').format('YYYY-MM-DD') === startDate &&
      dayjs().subtract(1, 'days').format('YYYY-MM-DD') === endDate,
    [startDate, endDate]
  );

  const isQueryLast7Days = React.useMemo(
    () =>
      dayjs().subtract(6, 'days').format('YYYY-MM-DD') === startDate &&
      dayjs().format('YYYY-MM-DD') === endDate,
    [startDate, endDate]
  );

  const handleChange = (values: any, formatString: [string, string], segmentChange?: boolean) => {
    const isDateRangeNotEmpty = formatString[0] !== '' && formatString[1] !== '';
    const isDateRangeEmpty = formatString[0] === '' && formatString[1] === '';

    addSearchParam('startDate', formatString[0]);
    addSearchParam('endDate', formatString[1]);

    if (isDateRangeEmpty) {
      removeQueryKey('endDate');
      removeQueryKey('startDate');
      setSegmented(false);

      delete otherParams.endDate;
      delete otherParams.startDate;

      return handleSubmit(
        {
          userId,
          ...otherParams,
        },
        abortController.signal
      );
    }

    if (isDateRangeNotEmpty && !segmentChange) {
      setSegmented(true);
    } else setSegmented(false);

    if (isDateRangeNotEmpty) {
      try {
        updateOtherParams((prev) => {
          return {
            ...prev,
            startDate: formatString[0],
            endDate: formatString[1],
          };
        });
        handleSubmit(
          {
            userId,
            ...otherParams,
            startDate: formatString[0],
            endDate: formatString[1],
          },
          abortController.signal
        );
      } catch (err: any) {
        message.error(err);
      }
    }
  };

  const handleClick = () => {
    if (segmented) message.info('Clear date range to proceed');
  };

  const formatDate = (dayjs: Dayjs[]) => {
    handleChange('', [dayjs[0].format('YYYY-MM-DD'), dayjs[1].format('YYYY-MM-DD')], true);
  };

  const handleSegmentChange = (value: string | number) => {
    switch (value) {
      case IDateRanges.MOST_RECENT:
        handleSubmit(
          {
            userId,
            ...otherParams,
            startDate: '',
            endDate: '',
          },
          abortController.signal
        );
        removeQueryKey('startDate');
        removeQueryKey('endDate');
        break;
      case IDateRanges.TODAY:
        formatDate([dayjs(), dayjs()]);
        break;
      case IDateRanges.YESTERDAY:
        formatDate([dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')]);
        break;
      case IDateRanges.LAST_7_DAYS:
        formatDate([dayjs().subtract(6, 'days'), dayjs()]);
        break;

      default:
        break;
    }
  };

  const defaultSegmentValue = React.useMemo(() => {
    if (isQueryToday) {
      return IDateRanges.TODAY;
    }
    if (isQueryYesterday) {
      return IDateRanges.YESTERDAY;
    }
    if (isQueryLast7Days) {
      return IDateRanges.LAST_7_DAYS;
    }

    return 'Most Recent';
  }, [isQueryToday, isQueryYesterday, isQueryLast7Days]);

  const defaultDatePickerValue = React.useMemo<[Dayjs, Dayjs] | undefined>(() => {
    if (startDate && endDate && !isQueryToday && !isQueryYesterday && !isQueryLast7Days) {
      return [dayjs(startDate), dayjs(endDate)];
    }
  }, [startDate, endDate, isQueryToday, isQueryYesterday, isQueryLast7Days]);

  React.useEffect(() => {
    handleSubmit(
      {
        userId,
        ...otherParams,
        startDate,
        endDate,
      },
      abortController.signal
    );

    return () => {
      abortController.abort();
    };
  }, []);

  const options = React.useMemo(() => Object.values(IDateRanges), []);

  return (
    <Row gutter={[16, 16]} className="mb-3">
      <Col xs={24} sm={12} lg={12}>
        <Segmented
          block
          size="large"
          onClick={handleClick}
          onChange={handleSegmentChange}
          defaultValue={defaultSegmentValue}
          disabled={segmented}
          className="shadow-new"
          style={{ background: '#f0f0f1' }}
          options={options}
        />
      </Col>
      <Col xs={24} sm={12} lg={12}>
        <RangePicker
          className="shadow-new"
          onChange={handleChange}
          defaultValue={defaultDatePickerValue}
          style={{ background: 'white', width: '100%' }}
        />
      </Col>
    </Row>
  );
};

export default DateRangeSelector;
