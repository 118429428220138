/* eslint-disable */
import { notification } from 'antd';
import { IBankAccountDetails, IDesignations, IUser } from 'src/services/UserService';
import * as actionTypes from '../constants';
import { DefaultInitialState, IPaginationParams } from './types';

export interface IUserStore {
  loading: boolean;
  error: any;
  users: any;
  userById: IUser & DefaultInitialState;
  userProfileById: IUser & DefaultInitialState;
  updatedUser: boolean;
  newUser: any;
  userList: {
    data: any[];
    loading: boolean;
  };
  allUserList: {
    page: number;
    count: number;
    limit: number;
    totalCount: number;
    data: any[];
    loading: boolean;
  };
  allUserListForAssign: {
    page: number;
    count: number;
    limit: number;
    totalCount: number;
    data: any[];
    loading: boolean;
  };
  allAggregators: {
    data: any[];
    loading: boolean;
    error: any;
    limit: number;
    assignToAgent: {
      loading: boolean;
      error: any;
      done: any;
    };
    unassignAgent: {
      loading: boolean;
      error: any;
      data: any;
    };
  };
  verifyKyc: any;
  bankAccountDetails: IBankAccountDetailsPayload;
  makeAggregator: IUpdatedUserDesignation;
  updatedUserDesignation: IUpdatedUserDesignation;
  updateUserAddress: any;
  updateUserNextOfKin: any;
  updateUserIdentity: any;
  updateUserProduct: any;
  aggregatorAgents: IAggrgatorAgents;
  aggregatorAgentsCount: IAggrgatorAgents;
  designations: IDesginationStore;
  onboarding: {
    loading: boolean;
    error: any;
    data: {
      successful: any;
      failure: any;
    };
    downloadCSVTemplate: {
      loading: boolean;
      error: any;
      data: any[];
    };
    uploadCSVFile: {
      loading: boolean;
      error: any;
      data: any[];
    };
    generateURI: {
      loading: boolean;
      error: any;
      data: any;
    };
    bulkProcess: {
      loading: boolean;
      error: any;
      data: any;
    };
  };
  userGroups: {
    loading: boolean;
    error: any;
    page: number;
    count: number;
    limit: number;
    totalCount: number;
    data: Array<IUserGroup>;
  };
}

export interface IUserGroup {
  id: string;
  groupName: string;
  users: Array<Partial<IUser>>;
  isTier: boolean;
  createdAt: string;
  updatedAt: string;
}

export type IBankAccountDetailsPayload = IBankAccountDetails<string> & DefaultInitialState;

export interface IAggrgatorAgents extends IPaginationParams {
  data: IUser[];
  count: number;
  loading: boolean;
}

export type IDesginationStore = DefaultInitialState & IPaginationParams & IDesignations[];

export type IUpdatedUserDesignation = Pick<
  IUser,
  | 'userId'
  | 'firstName'
  | 'otherNames'
  | 'lastName'
  | 'email'
  | 'phoneNumber'
  | 'displayName'
  | 'dob'
  | 'description'
  | 'designation'
> &
  DefaultInitialState;

export interface IUserListResponse extends Partial<IPaginationParams> {
  data: IUser[];
}

const initState = {
  loading: false,
  error: null,
  users: null,
  userById: {} as IUser & DefaultInitialState,
  userProfileById: {} as IUser & DefaultInitialState,
  updatedUser: false,
  newUser: null,
  userList: {
    data: [],
    loading: false,
  },
  allUserList: {
    data: [],
    loading: false,
  },
  allUserListForAssign: {
    data: [],
    loading: false,
  },
  allAggregators: {
    data: [],
    loading: false,
    error: null,
    limit: null,
    assignToAgent: {
      loading: false,
      error: null,
      done: null,
    },
    unassignAgent: {
      loading: false,
      error: null,
      data: null,
    },
  },
  verifyKyc: {
    loading: false,
    error: null,
  },
  bankAccountDetails: {},
  makeAggregator: {},
  updatedUserDesignation: {},
  updateUserAddress: null,
  updateUserNextOfKin: null,
  updateUserIdentity: null,
  updateUserProduct: null,
  designations: {},
  aggregatorAgents: {
    data: [],
    loading: false,
  },
  aggregatorAgentsCount: {
    data: [],
    loading: false,
  },
  onboarding: {
    loading: false,
    error: null,
    data: {
      successful: null,
      failure: null,
    },
    downloadCSVTemplate: {
      loading: false,
      error: null,
      data: [],
    },
    uploadCSVFile: {
      loading: false,
      error: null,
      data: [],
    },
    generateURI: {
      loading: false,
      error: null,
      data: null,
    },
    bulkProcess: {
      loading: false,
      error: null,
      data: null,
    },
  },
  userGroups: {
    loading: false,
    error: null,
    data: null,
  },
};

const key = actionTypes.KEY;

const userReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_BANK_ACCOUNT_DETAILS_PENDING:
      return {
        ...state,
        bankAccountDetails: {
          ...state.bankAccountDetails,
          loading: true,
          error: null,
        },
      };

    case actionTypes.GET_BANK_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        bankAccountDetails: {
          ...action.payload,
          loading: false,
          error: null,
        },
      };

    case actionTypes.GET_BANK_ACCOUNT_DETAILS_FAILED:
      return {
        ...state,
        bankAccountDetails: {
          ...state.bankAccountDetails,
          error: action.payload,
          loading: false,
        },
      };
    case actionTypes.GET_ALL_USERS_PENDING:
      return {
        ...state,
        allUserList: {
          ...state.allUserList,
          loading: true,
        },
        loading: true,
        error: null,
      };
    case actionTypes.GET_ALL_USERS_FOR_ASSIGN_PENDING:
      return {
        ...state,
        allUserListForAssign: {
          ...state.allUserListForAssign,
          loading: true,
        },
        loading: true,
        error: null,
      };
    case actionTypes.GET_AGGREGATOR_USERS_PENDING:
      return {
        ...state,
        userList: {
          ...state.userList,
          loading: true,
        },
        loading: true,
        error: null,
      };
    case actionTypes.GET_AGGREGATOR_USERS_SUCCESS:
      return {
        ...state,
        userList: {
          ...state.userList,
          loading: false,
          data: action.payload.data,
        },
        loading: false,
        error: null,
      };
    case actionTypes.GET_ALL_AGGREGATORS_PENDING:
      return {
        ...state,
        allAggregators: {
          ...state.allAggregators,
          loading: true,
        },
        error: null,
      };
    case actionTypes.GET_ALL_AGGREGATORS_SUCCESS:
      return {
        ...state,
        allAggregators: {
          ...state.allAggregators,
          loading: false,
          data: action.payload.data,
          limit: action.payload.limit,
          totalCount: action.payload.totalCount,
          error: null,
        },
      };
    case actionTypes.GET_ALL_AGGREGATORS_FAILED:
      return {
        ...state,
        allAggregators: {
          ...state.allAggregators,
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case actionTypes.UPDATE_USER_IDENTITY_PENDING:
      return {
        ...state,
        updateUserIdentity: null,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_USER_NEXT_OF_KIN_PENDING:
      return {
        ...state,
        updateUserNextOfKin: null,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_USER_PRODUCT_PENDING:
      return {
        ...state,
        updateUserProduct: null,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_USER_ADDRESS_PENDING:
      return {
        ...state,
        updateUserAddress: null,
        loading: true,
        error: null,
      };
    case actionTypes.CREATE_NEW_USER_PENDING:
      return {
        ...state,
        newUser: null,
        loading: true,
        error: null,
      };
    case actionTypes.GET_USER_DETAILS_BY_ID_PENDING:
      return {
        ...state,
        userById: { ...state.userById, loading: true, error: null },
        loading: true,
        error: null,
      };
    case actionTypes.GET_USER_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        userById: { ...action.payload, loading: false, error: null },
        loading: false,
        error: null,
      };
    case actionTypes.GET_USER_DETAILS_BY_ID_FAILED:
      notification.error({
        message: 'User not found',
        key,
      });
      return {
        ...state,
        userById: { loading: false, error: action.payload },
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_USER_PROFILE_DETAILS_BY_ID_PENDING:
      return {
        ...state,
        userProfileById: {
          ...state.userProfileById,
          loading: true,
          error: null,
        },
        loading: true,
        error: null,
      };
    case actionTypes.GET_USER_PROFILE_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        userProfileById: { ...action.payload, loading: false, error: null },
        loading: false,
        error: null,
      };
    case actionTypes.GET_USER_PROFILE_DETAILS_BY_ID_FAILED:
      notification.destroy();
      notification.error({
        message: 'User not found',
        key,
      });
      return {
        ...state,
        userProfileById: { loading: false, error: action.payload },
        loading: false,
        error: action.payload,
      };
    case actionTypes.CREATE_NEW_USER_SUCCESS:
      return {
        ...state,
        newUser: action.payload,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        updateUserAddress: action.payload,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_USER_IDENTITY_SUCCESS:
      return {
        ...state,
        updateUserIdentity: action.payload,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_USER_NEXT_OF_KIN_SUCCESS:
      return {
        ...state,
        updateUserNextOfKin: action.payload,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_USER_PRODUCT_SUCCESS:
      notification.success({
        message: 'Successful',
        key,
      });
      return {
        ...state,
        updateUserProduct: action.payload,
        loading: false,
        error: null,
      };
    case actionTypes.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        allUserList: { ...action.payload, loading: false },
        loading: false,
        error: null,
      };
    case actionTypes.GET_ALL_USERS_FOR_ASSIGN_SUCCESS:
      return {
        ...state,
        allUserListForAssign: { ...action.payload, loading: false },
        loading: false,
        error: null,
      };
    case actionTypes.ASSIGN_AGENT_TO_AGGREGATOR_PENDING:
      return {
        ...state,
        allAggregators: {
          ...state.allAggregators,
          assignToAgent: {
            ...state.allAggregators.assignToAgent,
            loading: true,
            error: null,
            done: false,
          },
        },
      };
    case actionTypes.ASSIGN_AGENT_TO_AGGREGATOR_SUCCESS:
      if (action.payload.failure) {
        const key = Object.keys(action.payload.failure[0]);
        const message = action.payload.failure[0][key[0]];
        notification.warning({
          message,
        });
      } else notification.success({ message: 'Agent successfully assigned.' });
      return {
        ...state,
        allAggregators: {
          ...state.allAggregators,
          assignToAgent: {
            ...state.allAggregators.assignToAgent,
            loading: false,
            message: action.payload,
            error: null,
            done: true,
          },
        },
      };
    case actionTypes.ASSIGN_AGENT_TO_AGGREGATOR_FAILED:
      notification.error({ message: 'Oops, Something went wrong!' });
      return {
        ...state,
        allAggregators: {
          ...state.allAggregators,
          assignToAgent: {
            ...state.allAggregators.assignToAgent,
            loading: false,
            error: action.payload,
            done: true,
          },
        },
      };
    case actionTypes.UNASSIGN_AGENT_TO_AGGREGATOR_PENDING:
      return {
        ...state,
        allAggregators: {
          ...state.allAggregators,
          unassignAgent: {
            ...state.allAggregators.unassignAgent,
            data: null,
            loading: true,
            error: null,
          },
        },
      };
    case actionTypes.UNASSIGN_AGENT_TO_AGGREGATOR_SUCCESS:
      if (action.payload.failure) {
        const key = Object.keys(action.payload.failure[0]);
        const message = action.payload.failure[0][key[0]];
        notification.warning({
          message,
        });
      } else
        notification.success({
          message: `Successfully unassigned agent: ${action.payload.successful[0]}.`,
        });
      return {
        ...state,
        allAggregators: {
          ...state.allAggregators,
          unassignAgent: {
            ...state.allAggregators.unassignAgent,
            loading: false,
            data: action.payload,
            error: null,
          },
        },
      };
    case actionTypes.UNASSIGN_AGENT_TO_AGGREGATOR_FAILED:
      notification.error({ message: 'Oops, Something went wrong!' });
      return {
        ...state,
        allAggregators: {
          ...state.allAggregators,
          unassignAgent: {
            ...state.allAggregators.unassignAgent,
            loading: false,
            data: null,
            error: action.payload,
          },
        },
      };
    //  Aggregator Portal Dashboard
    case actionTypes.GET_AGGREGATOR_AGENTS_SUCCESS:
      return {
        ...state,
        aggregatorAgents: { ...action.payload, loading: false },
        error: null,
      };
    case actionTypes.GET_AGGREGATOR_AGENTS_PENDING:
      return {
        ...state,
        aggregatorAgents: { ...state.aggregatorAgents, loading: true },
        error: null,
      };
    case actionTypes.GET_AGGREGATOR_AGENTS_FAILED:
      notification.error({
        message: 'Failed',
        key,
      });
      return {
        ...state,
        aggregatorAgents: { ...state.aggregatorAgents, loading: false },
        error: true,
      };
    //  Aggregator Agents Count
    case actionTypes.GET_AGGREGATOR_AGENTS_COUNT_SUCCESS:
      return {
        ...state,
        aggregatorAgentsCount: { ...action.payload, loading: false },
        error: null,
      };
    case actionTypes.GET_AGGREGATOR_AGENTS_COUNT_PENDING:
      return {
        ...state,
        aggregatorAgentsCount: {
          ...state.aggregatorAgentsCount,
          loading: true,
        },
        error: null,
      };
    case actionTypes.GET_AGGREGATOR_AGENTS_COUNT_FAILED:
      notification.error({
        message: 'Failed',
        key,
      });
      return {
        ...state,
        aggregatorAgentsCount: {
          ...state.aggregatorAgentsCount,
          loading: false,
        },
        error: true,
      };
    case actionTypes.GET_ALL_USERS_FAILED:
      return {
        ...state,
        allUserList: { ...state.allUserList, loading: false },
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_ALL_USERS_FOR_ASSIGN_FAILED:
      return {
        ...state,
        allUserListForAssign: { ...state.allUserListForAssign, loading: false },
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_AGGREGATOR_USERS_FAILED:
      return {
        ...state,
        userList: { ...state.userList, loading: false },
        loading: false,
        error: action.payload,
      };
    case actionTypes.GENERATE_UPLOAD_URI_PENDING:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          loading: true,
          error: null,
          data: {
            successful: null,
            failure: null,
          },
        },
      };
    case actionTypes.GENERATE_UPLOAD_URI_SUCCESS:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          error: null,
        },
      };
    case actionTypes.GENERATE_UPLOAD_URI_FAILED:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          loading: false,
          error: action.payload,
        },
      };
    case actionTypes.UPLOAD_BULK_AGENTS_PENDING:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          uploadCSVFile: {
            ...state.onboarding.uploadCSVFile,
            loading: true,
            error: null,
          },
        },
      };
    case actionTypes.UPLOAD_BULK_AGENTS_SUCCESS:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          data: action.payload,
          error: null,
        },
      };
    case actionTypes.UPLOAD_BULK_AGENTS_FAILED:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          loading: false,
          error: action.payload,
          data: null,
        },
      };
    case actionTypes.BULK_PROCESS_AGGREGATOR_UPLOAD_PENDING:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          loading: true,
          error: null,
        },
      };
    case actionTypes.BULK_PROCESS_AGGREGATOR_UPLOAD_SUCCESS:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          loading: false,
          data: action.payload,
        },
      };
    case actionTypes.BULK_PROCESS_AGGREGATOR_UPLOAD_ERROR:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          loading: false,
          error: action.payload,
        },
      };
    case actionTypes.VALIDATE_OTP_PENDING:
      notification.info({
        message: 'Loading...',
        duration: 0,
        key,
      });
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.VALIDATE_OTP_SUCCESS:
      notification.success({
        message: 'OTP Validation Successful.',
        key,
      });
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.VALIDATE_OTP_FAILED:
      notification.error({
        message: action?.payload && action?.payload.message,
        key,
      });
      return {
        ...state,
        loading: false,
        error: action?.payload && action?.payload.message,
      };
    case actionTypes.UPDATE_USER_PRODUCT_FAILED:
    case actionTypes.UPDATE_USER_NEXT_OF_KIN_FAILED:
    case actionTypes.UPDATE_USER_IDENTITY_FAILED:
    case actionTypes.UPDATE_USER_ADDRESS_FAILED:
      notification.error({ message: 'Failed' });
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CREATE_NEW_USER_FAILED:
      notification.error({
        message: action.payload.response.data.errors[0].message,
      });
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.errors[0],
      };

    case actionTypes.UPDATE_USER_DESIGNATION_PENDING:
      notification.info({
        message: 'Updating user designation...',
        duration: 0,
      });
      return {
        ...state,
        updatedUserDesignation: {
          ...state.updatedUserDesignation,
          loading: true,
          error: null,
        },
      };

    case actionTypes.UPDATE_USER_DESIGNATION_SUCCESS:
      notification.destroy();
      notification.success({
        message: 'Successful',
        description: `User's designation updated`,
      });
      return {
        ...state,
        updatedUserDesignation: {
          ...action.payload,
          loading: false,
          error: null,
        },
      };

    case actionTypes.UPDATE_USER_DESIGNATION_FAILED:
      notification.destroy();
      notification.error({ message: 'Update failed' });
      return {
        ...state,
        updatedUserDesignation: {
          ...state.updatedUserDesignation,
          error: action.payload,
          loading: false,
        },
      };
    case actionTypes.MAKE_AGGREGATOR_PENDING:
      notification.info({
        message: `${
          action.payload.action ? 'Upgrading to aggregator...' : 'Downgrading aggregator...'
        }`,
        duration: 0,
      });
      return {
        ...state,
        makeAggregator: {
          ...state.makeAggregator,
          loading: true,
          error: null,
        },
      };

    case actionTypes.MAKE_AGGREGATOR_SUCCESS:
      notification.destroy();
      notification.success({
        message: 'Successful',
        description: 'Aggregator approved',
      });
      return {
        ...state,
        makeAggregator: {
          ...action.payload,
          loading: false,
          error: null,
        },
      };

    case actionTypes.MAKE_AGGREGATOR_FAILED:
      notification.destroy();
      notification.error({
        message: action.payload.action ? 'Upgrade failed' : 'Downgrade failed',
      });
      return {
        ...state,
        makeAggregator: {
          ...state.makeAggregator,
          error: action.payload,
          loading: false,
        },
      };
    case actionTypes.VERIFY_KYC_PENDING:
      notification.info({
        message: 'Verifying kyc....',
      });
      return {
        ...state,
        verifyKyc: {
          loading: true,
          error: false,
        },
      };
    case actionTypes.VERIFY_KYC_SUCCESS:
      notification.info({
        message: 'User KYC Updated successfully!',
      });
      return {
        ...state,
        verifyKyc: {
          ...action.payload,
          loading: false,
          error: false,
        },
      };
    case actionTypes.VERIFY_KYC_FAILED:
      notification.info({
        message: 'Request failed',
      });
      return {
        ...state,
        verifyKyc: {
          error: true,
          loading: false,
        },
      };
    case actionTypes.GET_DESIGNATIONS_PENDING:
      return {
        ...state,
        designations: {
          error: null,
          loading: true,
          data: [],
        },
      };
    case actionTypes.GET_DESIGNATIONS_SUCCESS:
      return {
        ...state,
        designations: {
          error: null,
          loading: false,
          ...action.payload,
        },
      };
    case actionTypes.GET_DESIGNATIONS_FAILED:
      return {
        ...state,
        designations: {
          error: action.payload,
          loading: false,
          data: action.paload,
        },
      };
    case actionTypes.GET_USER_GROUPS_SUCCESS:
      return {
        ...state,
        userGroups: {
          ...state.userGroups,
          ...action.payload,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
