/* eslint-disable */
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_API_PARAMS } from 'src/constants/ApiConstant';
import { getSearchCriteriaKeys } from 'src/utils/getSearchCriteriaKeys';

const useAppSearchParams = (fieldKeys: Array<any> = []) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsString = searchParams.toString();
  const currentSearchParams = new URLSearchParams(searchParamsString);
  const queryParamsObject = React.useMemo(
    () => Object.fromEntries(currentSearchParams),
    [currentSearchParams]
  );
  const searchCriteriaKeys = React.useMemo(() => getSearchCriteriaKeys(fieldKeys), [fieldKeys]);
  const apiQueryParamsObject = React.useMemo(
    () =>
      Object.fromEntries(
        Object.entries(queryParamsObject).filter((param) =>
          [...DEFAULT_API_PARAMS, ...searchCriteriaKeys].includes(param[0])
        )
      ),
    [queryParamsObject, DEFAULT_API_PARAMS, searchCriteriaKeys]
  );

  const addSearchParam = (paramName: string, paramValue: any) => {
    // Get the current search parameters

    // Add the new parameter and its value
    currentSearchParams.set(paramName, paramValue);

    // Update the search parameters in the URL without replacing the existing ones
    setSearchParams(currentSearchParams);
  };

  const getSearchParam = (key: string) => searchParams.get(key);

  const removeQueryKey = (keyToRemove: string) => {
    // Remove the specified key from the URLSearchParams
    currentSearchParams.delete(keyToRemove);

    // Update the URL with the modified search parameters
    setSearchParams(currentSearchParams);
  };

  return {
    getSearchParam,
    addSearchParam,
    setSearchParams,
    removeQueryKey,
    queryParamsObject,
    searchParamsString,
    apiQueryParamsObject,
  };
};

export default useAppSearchParams;
