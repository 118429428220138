// Auth -done - confirm if it has either toaster or modal notification indicator in the reducers
/* eslint-disable */

export const REGISTER_PENDING = 'REGISTER_PENDING';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT_PENDING = 'LOGOUT_PENDING';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const VERIFY_EMAIL_OTP_PENDING = 'VERIFY_EMAIL_OTP_PENDING';
export const VERIFY_EMAIL_OTP_SUCCESS = 'VERIFY_EMAIL_OTP_SUCCESS';
export const VERIFY_EMAIL_OTP_FAILED = 'VERIFY_EMAIL_OTP_FAILED';

export const RESET_USER_PASSWORD_PENDING = 'RESET_USER_PASSWORD_PENDING';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_FAILED = 'RESET_USER_PASSWORD_FAILED';

export const COMPLETE_RESET_USER_PASSWORD_PENDING = 'COMPLETE_RESET_USER_PASSWORD_PENDING';
export const COMPLETE_RESET_USER_PASSWORD_SUCCESS = 'COMPLETE_RESET_USER_PASSWORD_SUCCESS';
export const COMPLETE_RESET_USER_PASSWORD_FAILED = 'COMPLETE_RESET_USER_PASSWORD_FAILED';

export const EXTRACT_TOKEN_PAYLOAD_PENDING = 'EXTRACT_TOKEN_PAYLOAD_PENDING';
export const EXTRACT_TOKEN_PAYLOAD_SUCCESS = 'EXTRACT_TOKEN_PAYLOAD_SUCCESS';
export const EXTRACT_TOKEN_PAYLOAD_FAILED = 'EXTRACT_TOKEN_PAYLOAD_FAILED';

// user

export const GET_ALL_USERS_PENDING = 'GET_ALL_USERS_PENDING';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILED = 'GET_ALL_USERS_FAILED';

export const GET_ALL_USERS_FOR_ASSIGN_PENDING = 'GET_ALL_USERS_FOR_ASSIGN_PENDING';
export const GET_ALL_USERS_FOR_ASSIGN_SUCCESS = 'GET_ALL_USERS_FOR_ASSIGN_SUCCESS';
export const GET_ALL_USERS_FOR_ASSIGN_FAILED = 'GET_ALL_USERS_FOR_ASSIGN_FAILED';

export const GET_AGGREGATOR_USERS_PENDING = 'GET_AGGREGATOR_USERS_PENDING';
export const GET_AGGREGATOR_USERS_SUCCESS = 'GET_AGGREGATOR_USERS_SUCCESS';
export const GET_AGGREGATOR_USERS_FAILED = 'GET_AGGREGATOR_USERS_FAILED';

export const GET_AGGREGATOR_AGENTS_PENDING = 'GET_AGGREGATOR_AGENTS_PENDING';
export const GET_AGGREGATOR_AGENTS_SUCCESS = 'GET_AGGREGATOR_AGENTS_SUCCESS';
export const GET_AGGREGATOR_AGENTS_FAILED = 'GET_AGGREGATOR_AGENTS_FAILED';

export const GET_AGGREGATOR_AGENTS_COUNT_PENDING = 'GET_AGGREGATOR_AGENTS_COUNT_PENDING';
export const GET_AGGREGATOR_AGENTS_COUNT_SUCCESS = 'GET_AGGREGATOR_AGENTS_COUNT_SUCCESS';
export const GET_AGGREGATOR_AGENTS_COUNT_FAILED = 'GET_AGGREGATOR_AGENTS_COUNT_FAILED';

export const GET_AGGREGATOR_ACTIVE_USERS_PENDING = 'GET_AGGREGATOR_ACTIVE_USERS_PENDING';
export const GET_AGGREGATOR_ACTIVE_USERS_SUCCESS = 'GET_AGGREGATOR_ACTIVE_USERS_SUCCESS';
export const GET_AGGREGATOR_ACTIVE_USERS_FAILED = 'GET_AGGREGATOR_ACTIVE_USERS_FAILED';

export const GET_USER_CURRENT_BY_ID_PENDING = 'GET_USER_CURRENT_BY_ID_PENDING';
export const GET_USER_CURRENT_BY_ID_SUCCESS = 'GET_USER_CURRENT_BY_ID_SUCCESS';
export const GET_USER_CURRENT_BY_ID_FAILED = 'GET_USER_CURRENT_BY_ID_FAILED';

export const GET_USER_DETAILS_BY_ID_PENDING = 'GET_USER_DETAILS_BY_ID_PENDING';
export const GET_USER_DETAILS_BY_ID_SUCCESS = 'GET_USER_DETAILS_BY_ID_SUCCESS';
export const GET_USER_DETAILS_BY_ID_FAILED = 'GET_USER_DETAILS_BY_ID_FAILED';

export const GET_USER_PROFILE_DETAILS_BY_ID_PENDING = 'GET_USER_PROFILE_DETAILS_BY_ID_PENDING';
export const GET_USER_PROFILE_DETAILS_BY_ID_SUCCESS = 'GET_USER_PROFILE_DETAILS_BY_ID_SUCCESS';
export const GET_USER_PROFILE_DETAILS_BY_ID_FAILED = 'GET_USER_PROFILE_DETAILS_BY_ID_FAILED';

export const GET_USER_DETAILS_BY_BVN_PENDING = 'GET_USER_DETAILS_BY_BVN_PENDING';
export const GET_USER_DETAILS_BY_BVN_SUCCESS = 'GET_USER_DETAILS_BY_BVN_SUCCESS';
export const GET_USER_DETAILS_BY_BVN_FAILED = 'GET_USER_DETAILS_BY_BVN_FAILED';

export const UPDATE_USER_DETAILS_PENDING = 'UPDATE_USER_DETAILS_PENDING';
export const UPDATE_USER_DETAILS_SUCCESS = 'UPDATE_USER_DETAILS_SUCCESS';
export const UPDATE_USER_DETAILS_FAILED = 'UPDATE_USER_DETAILS_FAILED';

export const UPDATE_USER_PHONE_NUMBER_PENDING = 'UPDATE_USER_PHONE_NUMBER_PENDING';
export const UPDATE_USER_PHONE_NUMBER_SUCCESS = 'UPDATE_USER_PHONE_NUMBER_SUCCESS';
export const UPDATE_USER_PHONE_NUMBER_FAILED = 'UPDATE_USER_PHONE_NUMBER_FAILED';

export const CREATE_NEW_USER_PENDING = 'CREATE_NEW_USER_PENDING';
export const CREATE_NEW_USER_SUCCESS = 'CREATE_NEW_USER_SUCCESS';
export const CREATE_NEW_USER_FAILED = 'CREATE_NEW_USER_FAILED';

export const UPDATE_USER_ADDRESS_PENDING = 'UPDATE_USER_ADDRESS_PENDING';
export const UPDATE_USER_ADDRESS_SUCCESS = 'UPDATE_USER_ADDRESS_SUCCESS';
export const UPDATE_USER_ADDRESS_FAILED = 'UPDATE_USER_ADDRESS_FAILED';

export const UPDATE_USER_IDENTITY_PENDING = 'UPDATE_USER_IDENTITY_PENDING';
export const UPDATE_USER_IDENTITY_SUCCESS = 'UPDATE_USER_IDENTITY_SUCCESS';
export const UPDATE_USER_IDENTITY_FAILED = 'UPDATE_USER_IDENTITY_FAILED';

export const UPDATE_USER_NEXT_OF_KIN_PENDING = 'UPDATE_USER_NEXT_OF_KIN_PENDING';
export const UPDATE_USER_NEXT_OF_KIN_SUCCESS = 'UPDATE_USER_NEXT_OF_KIN_SUCCESS';
export const UPDATE_USER_NEXT_OF_KIN_FAILED = 'UPDATE_USER_NEXT_OF_KIN_FAILED';

export const UPDATE_USER_PRODUCT_PENDING = 'UPDATE_USER_PRODUCT_PENDING';
export const UPDATE_USER_PRODUCT_SUCCESS = 'UPDATE_USER_PRODUCT_SUCCESS';
export const UPDATE_USER_PRODUCT_FAILED = 'UPDATE_USER_PRODUCT_FAILED';

export const GET_ALL_AGGREGATORS_PENDING = 'GET_ALL_AGGREGATORS_PENDING';
export const GET_ALL_AGGREGATORS_SUCCESS = 'GET_ALL_AGGREGATORS_SUCCESS';
export const GET_ALL_AGGREGATORS_FAILED = 'GET_ALL_AGGREGATORS_FAILED';

export const ASSIGN_AGENT_TO_AGGREGATOR_PENDING = 'ASSIGN_AGENT_TO_AGGREGATOR_PENDING';
export const ASSIGN_AGENT_TO_AGGREGATOR_SUCCESS = 'ASSIGN_AGENT_TO_AGGREGATOR_SUCCESS';
export const ASSIGN_AGENT_TO_AGGREGATOR_FAILED = 'ASSIGN_AGENT_TO_AGGREGATOR_FAILED';

export const UNASSIGN_AGENT_TO_AGGREGATOR_PENDING = 'UNASSIGN_AGENT_TO_AGGREGATOR_PENDING';
export const UNASSIGN_AGENT_TO_AGGREGATOR_SUCCESS = 'UNASSIGN_AGENT_TO_AGGREGATOR_SUCCESS';
export const UNASSIGN_AGENT_TO_AGGREGATOR_FAILED = 'UNASSIGN_AGENT_TO_AGGREGATOR_FAILED';

export const GENERATE_UPLOAD_URI_PENDING = 'GENERATE_UPLOAD_URI_PENDING';
export const GENERATE_UPLOAD_URI_SUCCESS = 'GENERATE_UPLOAD_URI_SUCCESS';
export const GENERATE_UPLOAD_URI_FAILED = 'GENERATE_UPLOAD_URI_FAILED';

export const UPLOAD_BULK_AGENTS_PENDING = 'UPLOAD_BULK_AGENTS_PENDING';
export const UPLOAD_BULK_AGENTS_SUCCESS = 'UPLOAD_BULK_AGENTS_SUCCESS';
export const UPLOAD_BULK_AGENTS_FAILED = 'UPLOAD_BULK_AGENTS_FAILED';

export const BULK_PROCESS_AGGREGATOR_UPLOAD_PENDING = 'BULK_PROCESS_AGGREGATOR_UPLOAD_PENDING';
export const BULK_PROCESS_AGGREGATOR_UPLOAD_SUCCESS = 'BULK_PROCESS_AGGREGATOR_UPLOAD_SUCCESS';
export const BULK_PROCESS_AGGREGATOR_UPLOAD_ERROR = 'BULK_PROCESS_AGGREGATOR_UPLOAD_ERROR';

export const DOWNLOAD_CSV_TEMPLATE_PENDING = 'DOWNLOAD_CSV_TEMPLATE_PENDING';
export const DOWNLOAD_CSV_TEMPLATE_SUCCESS = 'DOWNLOAD_CSV_TEMPLATE_SUCCESS';
export const DOWNLOAD_CSV_TEMPLATE_FAILED = 'DOWNLOAD_CSV_TEMPLATE_FAILED';

export const VALIDATE_OTP_PENDING = 'VALIDATE_OTP_PENDING';
export const VALIDATE_OTP_SUCCESS = 'VALIDATE_OTP_SUCCESS';
export const VALIDATE_OTP_FAILED = 'VALIDATE_OTP_FAILED';

export const GET_LIST_OF_USER_DETAILS_BY_IDS_PENDING = 'GET_LIST_OF_USER_DETAILS_BY_IDS_PENDING';
export const GET_LIST_OF_USER_DETAILS_BY_IDS_SUCCESS = 'GET_LIST_OF_USER_DETAILS_BY_IDS_SUCCESS';
export const GET_LIST_OF_USER_DETAILS_BY_IDS_FAILED = 'GET_LIST_OF_USER_DETAILS_BY_IDS_FAILED';

export const MAKE_AGGREGATOR_PENDING = 'MAKE_AGGREGATOR_PENDING';
export const MAKE_AGGREGATOR_SUCCESS = 'MAKE_AGGREGATOR_SUCCESS';
export const MAKE_AGGREGATOR_FAILED = 'MAKEE_AGGREGATOR_FAILED';

export const UPDATE_USER_DESIGNATION_PENDING = 'UPDATE_USER_DESIGNATION_PENDING';
export const UPDATE_USER_DESIGNATION_SUCCESS = 'UPDATE_USER_DESIGNATION_SUCCESS';
export const UPDATE_USER_DESIGNATION_FAILED = 'UPDATE_USER_DESIGNATION_FAILED';

export const GET_BANK_ACCOUNT_DETAILS_PENDING = 'GET_BANK_ACCOUNT_DETAILS_PENDING';
export const GET_BANK_ACCOUNT_DETAILS_SUCCESS = 'GET_BANK_ACCOUNT_DETAILS_SUCCESS';
export const GET_BANK_ACCOUNT_DETAILS_FAILED = 'GET_BANK_ACCOUNT_DETAILS_FAILED';

export const GET_BANK_ACCOUNT_DETAILS_BY_ID_PENDING = 'GET_BANK_ACCOUNT_DETAILS_PENDING';
export const GET_BANK_ACCOUNT_DETAILS_BY_ID_SUCCESS = 'GET_BANK_ACCOUNT_DETAILS_SUCCESS';
export const GET_BANK_ACCOUNT_DETAILS_BY_ID_FAILED = 'GET_BANK_ACCOUNT_DETAILS_FAILED';

export const GET_DESIGNATIONS_PENDING = 'GET_DESIGNATIONS_PENDING';
export const GET_DESIGNATIONS_SUCCESS = 'GET_DESIGNATIONS_SUCCESS';
export const GET_DESIGNATIONS_FAILED = 'GET_DESIGNATIONS_FAILED';

export const GET_USER_GROUPS_PENDING = 'GET_USER_GROUPS_PENDING';
export const GET_USER_GROUPS_SUCCESS = 'GET_USER_GROUPS_SUCCESS';
export const GET_USER_GROUPS_FAILED = 'GET_USER_GROUPS_FAILED';

// mpos

export const GET_AGGREGATOR_MPOS_TRANSACTIONS_PENDING = 'GET_AGGREGATOR_MPOS_TRANSACTIONS_PENDING';
export const GET_AGGREGATOR_MPOS_TRANSACTIONS_SUCCESS = 'GET_AGGREGATOR_MPOS_TRANSACTIONS_SUCCESS';
export const GET_AGGREGATOR_MPOS_TRANSACTIONS_FAILED = 'GET_AGGREGATOR_MPOS_TRANSACTIONS_FAILED';

export const GET_AGENT_MPOS_TRANSACTIONS_PENDING = 'GET_AGENT_MPOS_TRANSACTIONS_PENDING';
export const GET_AGENT_MPOS_TRANSACTIONS_SUCCESS = 'GET_AGENT_MPOS_TRANSACTIONS_SUCCESS';
export const GET_AGENT_MPOS_TRANSACTIONS_FAILED = 'GET_AGENT_MPOS_TRANSACTIONS_FAILED';

export const GET_AN_MPOS_TRANSACTIONS_PENDING = 'GET_AN_MPOS_TRANSACTIONS_PENDING';
export const GET_AN_MPOS_TRANSACTIONS_SUCCESS = 'GET_AN_MPOS_TRANSACTIONS_SUCCESS';
export const GET_AN_MPOS_TRANSACTIONS_FAILED = 'GET_AN_MPOS_TRANSACTIONS_FAILED';

export const GET_AGENT_TRANSACTIONS_SUMMARY_PENDING = 'GET_AGENT_TRANSACTIONS_SUMMARY_PENDING';
export const GET_AGENT_TRANSACTIONS_SUMMARY_SUCCESS = 'GET_AGENT_TRANSACTIONS_SUMMARY_SUCCESS';
export const GET_AGENT_TRANSACTIONS_SUMMARY_FAILED = 'GET_AGENT_TRANSACTIONS_SUMMARY_FAILED';

export const GET_AGGREGATOR_TRANSACTION_COUNT_PENDING = 'GET_AGGREGATOR_TRANSACTION_COUNT_PENDING';
export const GET_AGGREGATOR_TRANSACTION_COUNT_SUCCESS = 'GET_AGGREGATOR_TRANSACTION_COUNT_SUCCESS';
export const GET_AGGREGATOR_TRANSACTION_COUNT_FAILED = 'GET_AGGREGATOR_TRANSACTION_COUNT_FAILED';

// wallet

export const GET_USER_WALLET_BALANCE_PENDING = 'GET_USER_WALLET_BALANCE_PENDING';
export const GET_USER_WALLET_BALANCE_SUCCESS = 'GET_USER_WALLET_BALANCE_SUCCESS';
export const GET_USER_WALLET_BALANCE_FAILED = 'GET_USER_WALLET_BALANCE_FAILED';

export const GET_USER_WALLET_HISTORY_PENDING = 'GET_USER_WALLET_HISTORY_PENDING';
export const GET_USER_WALLET_HISTORY_SUCCESS = 'GET_USER_WALLET_HISTORY_SUCCESS';
export const GET_USER_WALLET_HISTORY_FAILED = 'GET_USER_WALLET_HISTORY_FAILED';

export const GET_AGGREGATOR_AIRTIME_COMMISSIONS_PENDING = 'GET_AGGREGATOR_AIRTIME_COMMISSIONS_PENDING';
export const GET_AGGREGATOR_AIRTIME_COMMISSIONS_SUCCESS = 'GET_AGGREGATOR_AIRTIME_COMMISSIONS_SUCCESS';
export const GET_AGGREGATOR_AIRTIME_COMMISSIONS_FAILED = 'GET_AGGREGATOR_AIRTIME_COMMISSIONS_FAILED';

export const GET_AGGREGATOR_BANK_TRANSFERS_COMMISSIONS_PENDING = 'GET_AGGREGATOR_BANK_TRANSFERS_COMMISSIONS_PENDING';
export const GET_AGGREGATOR_BANK_TRANSFERS_COMMISSIONS_SUCCESS = 'GET_AGGREGATOR_BANK_TRANSFERS_COMMISSIONS_SUCCESS';
export const GET_AGGREGATOR_BANK_TRANSFERS_COMMISSIONS_FAILED = 'GET_AGGREGATOR_BANK_TRANSFERS_COMMISSIONS_FAILED';

export const GET_AGGREGATOR_COMMISSIONS_SUMMARY = 'GET_AGGREGATOR_COMMISSIONS_SUMMARY';

export const GET_WALLET_STATEMENT_PENDING = 'GET_WALLET_STATEMENT_PENDING';
export const GET_WALLET_STATEMENT_SUCCESS = 'GET_WALLET_STATEMENT_SUCCESS';
export const GET_WALLET_STATEMENT_FAILED = 'GET_WALLET_STATEMENT_FAILED';

export const GET_OR_CREATE_WALLET_PENDING = 'GET_OR_CREATE_WALLET_PENDING';
export const GET_OR_CREATE_WALLET_SUCCESS = 'GET_OR_CREATE_WALLET_SUCCESS';
export const GET_OR_CREATE_WALLET_FAILED = 'GET_OR_CREATE_WALLET_FAILED';

export const GET_WALLETS_BY_PARAMS_PENDING = 'GET_WALLETS_BY_PARAMS_PENDING';
export const GET_WALLETS_BY_PARAMS_SUCCESS = 'GET_WALLETS_BY_PARAMS_SUCCESS';
export const GET_WALLETS_BY_PARAMS_FAILED = 'GET_WALLETS_BY_PARAMS_FAILED';

export const CREATE_PROVIDUS_ACCOUNT_PENDING = 'CREATE_PROVIDUS_ACCOUNT_PENDING'
export const CREATE_PROVIDUS_ACCOUNT_SUCCESS = 'CREATE_PROVIDUS_ACCOUNT_SUCCESS'
export const CREATE_PROVIDUS_ACCOUNT_FAILED = 'CREATE_PROVIDUS_ACCOUNT_FAILED'

export const VALIDATE_WALLET_PIN_PENDING = 'VALIDATE_WALLET_PIN_PENDING';
export const VALIDATE_WALLET_PIN_SUCCESS = 'VALIDATE_WALLET_PIN_SUCCESS';
export const VALIDATE_WALLET_PIN_FAILED = 'VALIDATE_WALLET_PIN_FAILED';

export const SET_WALLET_PIN_VALIDATION = 'SET_WALLET_PIN_VALIDATION';
export const SET_WALLET_PIN_VALIDATION_TIME_REMAINING = 'SET_WALLET_PIN_VALIDATION_TIME_REMAINING';

export const SET_LOCK_SCREEN = 'SET_LOCK_SCREEN';

//  lending

export const LENDING_REQUEST_PENDING = 'LENDING_REQUEST_PENDING';
export const LENDING_REQUEST_SUCCESS = 'LENDING_REQUEST_SUCCESS';
export const LENDING_REQUEST_ERROR = 'LENDING_REQUEST_ERROR';

export const PB_REQUEST_PENDING = 'PB_REQUEST_PENDING';
export const PB_REQUEST_SUCCESS = 'PB_REQUEST_SUCCESS';
export const PB_REQUEST_ERROR = 'PB_REQUEST_ERROR';

export const PB_REQUEST_ACTION_PENDING = 'PB_REQUEST_ACTION_PENDING';
export const PB_REQUEST_ACTION_SUCCESS = 'PB_REQUEST_ACTION_SUCCESS';
export const PB_REQUEST_ACTION_ERROR = 'PB_REQUEST_ACTION_ERROR';

export const LOAN_REQUEST_PENDING = 'LOAN_REQUEST_PENDING';
export const LOAN_REQUEST_SUCCESS = 'LOAN_REQUEST_SUCCESS';
export const LOAN_REQUEST_ERROR = 'LOAN_REQUEST_ERROR';

export const REPORTS_PENDING = 'REPORTS_PENDING';
export const REPORTS_SUCCESS = 'REPORTS_SUCCESS';
export const REPORTS_ERROR = 'REPORTS_ERROR';

export const CREATE_NEW_LOAN_USER_PENDING = 'CREATE_NEW_LOAN_USER_PENDING';
export const CREATE_NEW_LOAN_USER_SUCCESS = 'CREATE_NEW_LOAN_USER_SUCCESS';
export const CREATE_NEW_LOAN_USER_ERROR = 'CREATE_NEW_LOAN_USER_ERROR';

export const GET_LOAN_TOKEN_PENDING = 'GET_LOAN_TOKEN_PENDING';
export const GET_LOAN_TOKEN_SUCCESS = 'GET_LOAN_TOKEN_SUCCESS';
export const GET_LOAN_TOKEN_ERROR = 'GET_LOAN_TOKEN_ERROR';

export const GET_LOAN_USERS_LIST_PENDING = 'GET_LOAN_USERS_LIST_PENDING';
export const GET_LOAN_USERS_LIST_SUCCESS = 'GET_LOAN_USERS_LIST_SUCCESS';
export const GET_LOAN_USERS_LIST_ERROR = 'GET_LOAN_USERS_LIST_ERROR';

export const GET_BVN_VFD_BVN_CONSENT_PENDING = 'GET_BVN_VFD_BVN_CONSENT_PENDING';
export const GET_BVN_VFD_BVN_CONSENT_SUCCESS = 'GET_BVN_VFD_BVN_CONSENT_SUCCESS';
export const GET_BVN_VFD_BVN_CONSENT_ERROR = 'GET_BVN_VFD_BVN_CONSENT_ERROR';

export const RELEASE_LOAN_USERS_ACCOUNT_PENDING = 'RELEASE_LOAN_USERS_ACCOUNT_PENDING';
export const RELEASE_LOAN_USERS_ACCOUNT_SUCCESS = 'RELEASE_LOAN_USERS_ACCOUNT_SUCCESS';
export const RELEASE_LOAN_USERS_ACCOUNT_ERROR = 'RELEASE_LOAN_USERS_ACCOUNT_ERROR';

export const BENEFICIARY_ENQUIRY_PENDING = 'BENEFICIARY_ENQUIRY_PENDING';
export const BENEFICIARY_ENQUIRY_SUCCESS = 'BENEFICIARY_ENQUIRY_SUCCESS';
export const BENEFICIARY_ENQUIRY_ERROR = 'BENEFICIARY_ENQUIRY_ERROR';

export const CREATE_AND_DISBURSE_LOAN_PENDING = 'CREATE_AND_DISBURSE_LOAN_PENDING';
export const CREATE_AND_DISBURSE_LOAN_SUCCESS = 'CREATE_AND_DISBURSE_LOAN_SUCCESS';
export const CREATE_AND_DISBURSE_LOAN_ERROR = 'CREATE_AND_DISBURSE_LOAN_ERROR';

export const GET_LOAN_PORTFOLIO_PENDING = 'GET_LOAN_PORTFOLIO_PENDING';
export const GET_LOAN_PORTFOLIO_SUCCESS = 'GET_LOAN_PORTFOLIO_SUCCESS';
export const GET_LOAN_PORTFOLIO_ERROR = 'GET_LOAN_PORTFOLIO_ERROR';

export const GET_LOAN_REPAYMENT_SCHEDULE_PENDING = 'GET_LOAN_REPAYMENT_SCHEDULE_PENDING';
export const GET_LOAN_REPAYMENT_SCHEDULE_SUCCESS = 'GET_LOAN_REPAYMENT_SCHEDULE_SUCCESS';
export const GET_LOAN_REPAYMENT_SCHEDULE_ERROR = 'GET_LOAN_REPAYMENT_SCHEDULE_ERROR';

export const GET_LOAN_ACCOUNT_ENQUIRY_PENDING = 'GET_LOAN_ACCOUNT_ENQUIRY_PENDING';
export const GET_LOAN_ACCOUNT_ENQUIRY_SUCCESS = 'GET_LOAN_ACCOUNT_ENQUIRY_SUCCESS';
export const GET_LOAN_ACCOUNT_ENQUIRY_ERROR = 'GET_LOAN_ACCOUNT_ENQUIRY_ERROR';

export const LOG_NEW_LOAN_USER_PENDING = 'LOG_NEW_LOAN_USER_PENDING';
export const LOG_NEW_LOAN_USER_SUCCESS = 'LOG_NEW_LOAN_USER_SUCCESS';
export const LOG_NEW_LOAN_USER_ERROR = 'LOG_NEW_LOAN_USER_ERROR';

export const GET_LOAN_USER_LOG_PENDING = 'GET_LOAN_USER_LOG_PENDING';
export const GET_LOAN_USER_LOG_SUCCESS = 'GET_LOAN_USER_LOG_SUCCESS';
export const GET_LOAN_USER_LOG_ERROR = 'GET_LOAN_USER_LOG_ERROR';

export const LOG_LOAN_DISBURSE_PENDING = 'LOG_LOAN_DISBURSE_PENDING';
export const LOG_LOAN_DISBURSE_SUCCESS = 'LOG_LOAN_DISBURSE_SUCCESS';
export const LOG_LOAN_DISBURSE_ERROR = 'LOG_LOAN_DISBURSE_ERROR';

export const VFD_FUND_TRANSFER_PENDING = 'VFD_FUND_TRANSFER_PENDING'
export const VFD_FUND_TRANSFER_SUCCESS = 'VFD_FUND_TRANSFER_SUCCESS'
export const VFD_FUND_TRANSFER_ERROR = 'VFD_FUND_TRANSFER_ERROR'

// tools
export const SEARCH_AGENT_PENDING = 'SEARCH_AGENT_PENDING';
export const SEARCH_AGENT_SUCCESS = 'SEARCH_AGENT_SUCCESS';
export const SEARCH_AGENT_ERROR = 'SEARCH_AGENT_ERROR';

export const PLACE_PND_PENDING = 'PLACE_PND_PENDING';
export const PLACE_PND_SUCCESS = 'PLACE_PND_SUCCESS';
export const PLACE_PND_ERROR = 'PLACE_PND_ERROR';

// savings

export const GET_SAVINGS_TRANSACTIONS_PENDING = 'GET_SAVINGS_TRANSACTIONS_PENDING';
export const GET_SAVINGS_TRANSACTIONS_SUCCESS = 'GET_SAVINGS_TRANSACTIONS_SUCCESS';
export const GET_SAVINGS_TRANSACTIONS_FAILED = 'GET_SAVINGS_TRANSACTIONS_FAILED';

export const GET_SAVINGS_TRANSACTIONS_SUMMARY_PENDING = 'GET_SAVINGS_TRANSACTIONS_SUMMARY_PENDING';
export const GET_SAVINGS_TRANSACTIONS_SUMMARY_SUCCESS = 'GET_SAVINGS_TRANSACTIONS_SUMMARY_SUCCESS';
export const GET_SAVINGS_TRANSACTIONS_SUMMARY_FAILED = 'GET_SAVINGS_TRANSACTIONS_SUMMARY_FAILED';

export const GET_ACTIVE_AGENTS_AND_CUSTOMER_COUNT_PENDING = 'GET_ACTIVE_AGENTS_AND_CUSTOMER_COUNT_PENDING';
export const GET_ACTIVE_AGENTS_AND_CUSTOMER_COUNT_SUCCESS = 'GET_ACTIVE_AGENTS_AND_CUSTOMER_COUNT_SUCCESS';
export const GET_ACTIVE_AGENTS_AND_CUSTOMER_COUNT_FAILED = 'GET_ACTIVE_AGENTS_AND_CUSTOMER_COUNT_FAILED';

export const GET_CYCLES_CREATED_PENDING = 'GET_CYCLES_CREATED_PENDING';
export const GET_CYCLES_CREATED_SUCCESS = 'GET_CYCLES_CREATED_SUCCESS';
export const GET_CYCLES_CREATED_FAILED = 'GET_CYCLES_CREATED_FAILED';

export const GET_PB_CUMMULATVE_RECORDS_PENDING = 'GET_PB_CUMMULATVE_RECORDS_PENDING';
export const GET_PB_CUMMULATVE_RECORDS_SUCCESS = 'GET_PB_CUMMULATVE_RECORDS_SUCCESS';
export const GET_PB_CUMMULATVE_RECORDS_FAILED = 'GET_PB_CUMMULATVE_RECORDS_FAILED';

export const GET_CUSTOMER_CUMMULATIVE_RECORDS_PENDING = 'GET_CUSTOMER_CUMMULATIVE_RECORDS_PENDING';
export const GET_CUSTOMER_CUMMULATIVE_RECORDS_SUCCESS = 'GET_CUSTOMER_CUMMULATIVE_RECORDS_SUCCESS';
export const GET_CUSTOMER_CUMMULATIVE_RECORDS_FAILED = 'GET_CUSTOMER_CUMMULATIVE_RECORDS_FAILED';

export const GET_RUNNERS_LIST_PENDING = 'GET_RUNNERS_LIST_PENDING';
export const GET_RUNNERS_LIST_SUCCESS = 'GET_RUNNERS_LIST_SUCCESS';
export const GET_RUNNERS_LIST_FAILED = 'GET_RUNNERS_LIST_FAILED';

export const GET_RUNNERS_CUMMULATIVE_RECORDS_PENDING = 'GET_RUNNERS_CUMMULATIVE_RECORDS_PENDING';
export const GET_RUNNERS_CUMMULATIVE_RECORDS_SUCCESS = 'GET_RUNNERS_CUMMULATIVE_RECORDS_SUCCESS';
export const GET_RUNNERS_CUMMULATIVE_RECORDS_FAILED = 'GET_RUNNERS_CUMMULATIVE_RECORDS_FAILED';

export const GET_SAVINGS_REPORT_PENDING = 'GET_SAVINGS_REPORT_PENDING';
export const GET_SAVINGS_REPORT_SUCCESS = 'GET_SAVINGS_REPORT_SUCCESS';
export const GET_SAVINGS_REPORT_FAILED = 'GET_SAVINGS_REPORT_FAILED';

export const PAY_SALARIES_PENDING = 'PAY_SALARIES_PENDING';
export const PAY_SALARIES_SUCCESS = 'PAY_SALARIES_SUCCESS';
export const PAY_SALARIES_FAILED = 'PAY_SALARIES_FAILED';

export const SEND_SALARY_PAYMENT_REPORT_PENDING = 'SEND_SALARY_PAYMENT_REPORT_PENDING';
export const SEND_SALARY_PAYMENT_REPORT_SUCCESS = 'SEND_SALARY_PAYMENT_REPORT_SUCCESS';
export const SEND_SALARY_PAYMENT_REPORT_FAILED = 'SEND_SALARY_PAYMENT_REPORT_FAILED';

export const UPDATE_SALARY_PAYMENT_STATUS = 'UPDATE_SALARY_PAYMENT_STATUS';
export const RESET_SALARY_PAYMENT_STATUS = 'RESET_SALARY_PAYMENT_STATUS';

// AML

export const GET_AML_CONFIGURATIONS_PENDING = 'GET_AML_CONFIGURATIONS_PENDING';
export const GET_AML_CONFIGURATIONS_SUCCESS = 'GET_AML_CONFIGURATIONS_SUCCESS';
export const GET_AML_CONFIGURATIONS_FAILED = 'GET_AML_CONFIGURATIONS_FAILED';

export const GET_AML_CONFIGURATION_PENDING = 'GET_AML_CONFIGURATION_PENDING';
export const GET_AML_CONFIGURATION_SUCCESS = 'GET_AML_CONFIGURATION_SUCCESS';
export const GET_AML_CONFIGURATION_FAILED = 'GET_AML_CONFIGURATION_FAILED';

export const CREATE_AML_CONFIGURATION_PENDING = 'CREATE_AML_CONFIGURATION_PENDING';
export const CREATE_AML_CONFIGURATION_SUCCESS = 'CREATE_AML_CONFIGURATION_SUCCESS';
export const CREATE_AML_CONFIGURATION_FAILED = 'CREATE_AML_CONFIGURATION_FAILED';

export const UPDATE_AML_CONFIGURATION_PENDING = 'UPDATE_AML_CONFIGURATION_PENDING';
export const UPDATE_AML_CONFIGURATION_SUCCESS = 'UPDATE_AML_CONFIGURATION_SUCCESS';
export const UPDATE_AML_CONFIGURATION_FAILED = 'UPDATE_AML_CONFIGURATION_FAILED';

export const GET_AML_ASSIGNMENTS_PENDING = 'GET_AML_ASSIGNMENTS_PENDING';
export const GET_AML_ASSIGNMENTS_SUCCESS = 'GET_AML_ASSIGNMENTS_SUCCESS';
export const GET_AML_ASSIGNMENTS_FAILED = 'GET_AML_ASSIGNMENTS_FAILED';

export const GET_WALLET_AML_ASSIGNMENTS_PENDING = 'GET_WALLET_AML_ASSIGNMENTS_PENDING';
export const GET_WALLET_AML_ASSIGNMENTS_SUCCESS = 'GET_WALLET_AML_ASSIGNMENTS_SUCCESS';
export const GET_WALLET_AML_ASSIGNMENTS_FAILED = 'GET_WALLET_AML_ASSIGNMENTS_FAILED';

export const GET_AML_ASSIGNMENT_PENDING = 'GET_AML_ASSIGNMENT_PENDING';
export const GET_AML_ASSIGNMENT_SUCCESS = 'GET_AML_ASSIGNMENT_SUCCESS';
export const GET_AML_ASSIGNMENT_FAILED = 'GET_AML_ASSIGNMENT_FAILED';

export const UPDATE_AML_ASSIGNMENT_SUCCESS = 'UPDATE_AML_ASSIGNMENT_SUCCESS';
export const UPDATE_AML_ASSIGNMENT_PENDING = 'UPDATE_AML_ASSIGNMENT_PENDING';
export const UPDATE_AML_ASSIGNMENT_FAILED = 'UPDATE_AML_ASSIGNMENT_FAILED';

export const CREATE_AML_ASSIGNMENT_SUCCESS = 'CREATE_AML_ASSIGNMENT_SUCCESS';
export const CREATE_AML_ASSIGNMENT_PENDING = 'CREATE_AML_ASSIGNMENT_PENDING';
export const CREATE_AML_ASSIGNMENT_FAILED = 'CREATE_AML_ASSIGNMENT_FAILED';

export const GET_BLACKLISTED_BVN_LIST_PENDING = 'GET_BLACKLISTED_BVN_LIST_PENDING';
export const GET_BLACKLISTED_BVN_LIST_SUCCESS = 'GET_BLACKLISTED_BVN_LIST_SUCCESS';
export const GET_BLACKLISTED_BVN_LIST_FAILED = 'GET_BLACKLISTED_BVN_LIST_FAILED';

export const GET_BLACKLISTED_BVN_PENDING = 'GET_BLACKLISTED_BVN_PENDING';
export const GET_BLACKLISTED_BVN_SUCCESS = 'GET_BLACKLISTED_BVN_SUCCESS';
export const GET_BLACKLISTED_BVN_FAILED = 'GET_BLACKLISTED_BVN_FAILED';

export const CREATE_BLACKLIST_PENDING = 'CREATE_BLACKLIST_PENDING';
export const CREATE_BLACKLIST_SUCCESS = 'CREATE_BLACKLIST_SUCCESS';
export const CREATE_BLACKLIST_FAILED = 'CREATE_BLACKLIST_FAILED';

export const LIFT_BLACKLIST_PENDING = 'LIFT_BLACKLIST_PENDING';
export const LIFT_BLACKLIST_SUCCESS = 'LIFT_BLACKLIST_SUCCESS';
export const LIFT_BLACKLIST_FAILED = 'LIFT_BLACKLIST_FAILED';

export const LOAD_AML_CONFIGURATION_BY_ID = 'LOAD_AML_CONFIGURATION_BY_ID';

export const REMOVE_AML_CONFIGURATION_LIMIT_BY_ID = 'REMOVE_AML_CONFIGURATION_LIMIT_BY_ID';

export const UPDATE_LOADED_AML = 'UPDATE_LOADED_AML';

export const DELETE_LOADED_AML_LIMIT = 'DELETE_LOADED_AML_LIMIT';

// Commission Engine

export const GET_COMMISSION_PLANS_LIST_PENDING = 'GET_COMMISSION_PLANS_LIST_PENDING';
export const GET_COMMISSION_PLANS_LIST_SUCCESS = 'GET_COMMISSION_PLANS_LIST_SUCCESS';
export const GET_COMMISSION_PLANS_LIST_FAILED = 'GET_COMMISSION_PLANS_LIST_FAILED';

export const GET_COMMISSION_PLAN_DETAILS_BY_NAME_PENDING = 'GET_COMMISSION_PLANS_LIST_PENDING';
export const GET_COMMISSION_PLAN_DETAILS_BY_NAME_SUCCESS = 'GET_COMMISSION_PLANS_LIST_SUCCESS';
export const GET_COMMISSION_PLAN_DETAILS_BY_NAME_FAILED = 'GET_COMMISSION_PLANS_LIST_FAILED';

export const CREATE_COMMISSION_PLAN_PENDING = 'GET_COMMISSION_PLANS_LIST_PENDING';
export const CREATE_COMMISSION_PLAN_SUCCESS = 'GET_COMMISSION_PLANS_LIST_SUCCESS';
export const CREATE_COMMISSION_PLAN_FAILED = 'GET_COMMISSION_PLANS_LIST_FAILED';

export const GET_TRANSACTIONS_PENDING = 'GET_TRANSACTIONS_PENDING';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAILED = 'GET_TRANSACTIONS_FAILED';

export const GET_AGENT_TRANSACTIONS_BY_ID_PENDING = 'GET_AGENT_TRANSACTIONS_BY_ID_PENDING';
export const GET_AGENT_TRANSACTIONS_BY_ID_SUCCESS = 'GET_AGENT_TRANSACTIONS_BY_ID_SUCCESS';
export const GET_AGENT_TRANSACTIONS_BY_ID_FAILED = 'GET_AGENT_TRANSACTIONS_BY_ID_FAILED';

export const GET_AGENT_PROFILE_TRANSACTIONS_BY_ID_PENDING = 'GET_AGENT_PROFILE_TRANSACTIONS_BY_ID_PENDING';
export const GET_AGENT_PROFILE_TRANSACTIONS_BY_ID_SUCCESS = 'GET_AGENT_PROFILE_TRANSACTIONS_BY_ID_SUCCESS';
export const GET_AGENT_PROFILE_TRANSACTIONS_BY_ID_FAILED = 'GET_AGENT_PROFILE_TRANSACTIONS_BY_ID_FAILED';

export const GET_AGENT_PERFORMANCE_PENDING = 'GET_AGENT_PERFORMANCE_PENDING';
export const GET_AGENT_PERFORMANCE_SUCCESS = 'GET_AGENT_PERFORMANCE_SUCCESS';
export const GET_AGENT_PERFORMANCE_FAILED = 'GET_AGENT_PERFORMANCE_FAILED';

export const GET_COMMISSION_EVENT_MAP_PENDING = 'GET_COMMISSION_EVENT_MAP_PENDING';
export const GET_COMMISSION_EVENT_MAP_SUCCESS = 'GET_COMMISSION_EVENT_MAP_SUCCESS';
export const GET_COMMISSION_EVENT_MAP_FAILED = 'GET_COMMISSION_EVENT_MAP_FAILED';

export const VERIFY_KYC_PENDING = 'VERIFY_KYC_PENDING';
export const VERIFY_KYC_SUCCESS = 'VERIFY_KYC_SUCCESS';
export const VERIFY_KYC_FAILED = 'VERIFY_KYC_FAILED';

// utils

export const AUTH_USER = 'ac_au';
export const AUTH_TOKEN = 'ac_au_token';
export const AUTH_TOKEN_AGG_ID = 'ac_au_agg_id';
export const AUTH_TOKEN_ID = 'ac_au_token_id';
export const KEY = 'update_key';
export const SHOW_LOADING = 'SHOW_LOADING';
export const USER_ID = 'userId';
export const COMMISSION_EVENT_MAP = 'commission_event_map';

export const PENDING_USERS = 'PENDING';
export const REVIEWED_USERS = 'REVIEWED';
export const FLAGGED_USERS = 'FLAGGED';
export const APPROVED_USERS = 'APPROVED';
export const DECLINED_USERS = 'DECLINED';
export const RESUBMITTED = 'RESUBMITTED';
// insurance

export const GET_INSURANCE_POLICY_PENDING = 'GET_INSURANCE_POLICY_PENDING';
export const GET_INSURANCE_POLICY_SUCCESS = 'GET_INSURANCE_POLICY_SUCCESS';
export const GET_INSURANCE_POLICY_FAILED = 'GET_INSURANCE_POLICY_FAILED';

// infrastruture

export const GET_ACTIVE_PTSP_PENDING = 'GET_ACTIVE_PTSP_PENDING';
export const GET_ACTIVE_PTSP_SUCCESS = 'GET_ACTIVE_PTSP_SUCCESS';
export const GET_ACTIVE_PTSP_FAILED = 'GET_ACTIVE_PTSP_FAILED';

export const GET_PTSP_LIST_PENDING = 'GET_PTSP_LIST_PENDING';
export const GET_PTSP_LIST_SUCCESS = 'GET_PTSP_LIST_SUCCESS';
export const GET_PTSP_LIST_FAILED = 'GET_PTSP_LIST_FAILED';

export const SET_PTSP_PENDING = 'SET_PTSP_PENDING';
export const SET_PTSP_SUCCESS = 'SET_PTSP_SUCCESS';
export const SET_PTSP_FAILED = 'SET_PTSP_FAILED';

export const SET_DOCUMENT_DATA = 'SET_DOCUMENT_DATA';

// action queue
export const ADD_TO_QUEUE = "ADD_TO_QUEUE";
export const CLEAR_QUEUE = "CLEAR_QUEUE";