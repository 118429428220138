/* eslint-disable */
import React, { useState } from 'react';
import { Card, Row, Col, Form, Input, Button, message, Popover, Typography } from 'antd';
import PasswordValidator from 'password-validator';
import { LockOutlined } from '@ant-design/icons';
import userService from 'src/services/UserService';
import * as actionTypes from 'src/redux/constants';
import { history } from 'src/redux/store';
import usePasswordValidator from 'src/hooks/usePasswordValidator';
import { passwordCriteria } from 'src/utils/validatePassword';
import PasswordValidationChecklist from 'src/components/util-components/PasswordValidationChecklist';

const formatPasswordValidateError = function (errors: Array<string>) {
  for (let i = 0; i < errors.length; i++) {
    if (errors[i] === 'min') {
      return 'password length should be a at least 8 characters';
      // eslint-disable-next-line
    } else if (errors[i] === 'lowercase') {
      return 'password should contain lowercase letters';
    } else if (errors[i] === 'uppercase') {
      return 'password should contain uppercase letters';
    } else if (errors[i] === 'digits') {
      return 'password should contain digits';
    } else if (errors[i] === 'symbols') {
      return 'password should contain symbols';
    }
    return '';
  }
  return '';
};

const rules = {
  password: [
    {
      required: true,
      message: 'Please input your password',
    },
    ({ getFieldValue }: { getFieldValue: any }) => ({
      validator(rule: any, value: any) {
        const validationRulesErrors = Schema.validate(value || getFieldValue('password'), {
          list: true,
        });
        if (validationRulesErrors.length > 0) {
          return Promise.reject(formatPasswordValidateError(validationRulesErrors));
        }
        return Promise.resolve();
      },
    }),
  ],
  confirm: [
    {
      required: true,
      message: 'Please confirm your password!',
    },
    ({ getFieldValue }: { getFieldValue: any }) => ({
      validator(rule: any, value: any) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject('Passwords do not match!');
      },
    }),
  ],
};

// create a password schema
const Schema = new PasswordValidator();

Schema.is().min(8).has().uppercase().has().lowercase().has().digits().has().symbols();

const SetPassword: React.FC = (props: any) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onSetPassword = () => {
    setLoading(true);
    const userId = sessionStorage.getItem(actionTypes.USER_ID);
    form.validateFields().then((values: any) => {
      userService
        .createPassword({ userId, password: values.password })
        .then(async (res: any) => {
          setLoading(false);
          message.success('Password created, you can now log in.');
          history.push('/auth/login');
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message);
        });
    });
  };

  const title = 'Password Policy';

  const passwordPolicyContent = (
    <>
      <h4>Your password should contain: </h4>
      <ul>
        <li>Minimum length of 8 characters</li>
        <li>Numerical characters (0-9)</li>
        <li>Special characters</li>
        <li>Uppercase letter</li>
        <li>Lowercase letter</li>
      </ul>
    </>
  );

  const { isChecked, setValue } = usePasswordValidator(
    form.getFieldValue('password'),
    passwordCriteria.minLength
  );

  const handlePasswordChange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <div className="auth-container">
      <div className="h-100">
        <div className="container d-flex flex-column justify-content-center h-100">
          <Row justify="center">
            <Col xs={20} sm={20} md={20} lg={8}>
              <Card>
                <div className="my-2">
                  <div className="text-center">
                    <Typography.Title level={3} className="mb-4  font-weight-bold">
                      Create password
                    </Typography.Title>
                  </div>
                  <Row justify="center">
                    <Col xs={24} sm={24} md={20} lg={20}>
                      <Form form={form} layout="vertical" name="password" onFinish={onSetPassword}>
                        <Popover
                          placement="topLeft"
                          title={title}
                          content={passwordPolicyContent}
                          trigger="focus"
                        >
                          <Form.Item
                            name="password"
                            label="Create Password"
                            rules={rules.password}
                            hasFeedback
                          >
                            <Input.Password
                              onChange={handlePasswordChange}
                              prefix={<LockOutlined className="text-primary" />}
                            />
                          </Form.Item>
                          <div className="mb-4">
                            <PasswordValidationChecklist isChecked={isChecked} />
                          </div>
                        </Popover>
                        <Form.Item
                          name="confirm"
                          label="Confirm Password"
                          rules={rules.confirm}
                          hasFeedback
                        >
                          <Input.Password prefix={<LockOutlined className="text-primary" />} />
                        </Form.Item>
                        <Form.Item>
                          <Button loading={loading} type="primary" htmlType="submit" block>
                            {loading ? 'Submitting' : 'Submit'}
                          </Button>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
