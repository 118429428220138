/* eslint-disable */
import { createSelector } from 'reselect';
import { IWallet } from '../reducers/Wallet';
import { IStore } from '../store';

export const selectWallet = (store: IStore) => store.wallet;
export const selectCommissionWallet = (wallet: IWallet) => wallet.commissionWallet;
export const selectProvidus = (wallet: IWallet) => wallet.providus;

export const selectPinValidation = createSelector(
  selectWallet,
  (walletStore) => walletStore.pinValidation
);
