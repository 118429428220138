/* eslint-disable */
import { message, notification } from 'antd';
import * as actionTypes from '../constants';
import { ICreateProviudusAccountResponse } from 'src/services/WalletService';
import { ILockScreenProps } from 'src/components/shared-components/LockScreen';

export interface IWallet {
  loading: boolean;
  error: any;
  userWalletBalance: {
    active: boolean;
    agentId: string;
    availableBalance: number;
    currency: string;
    pendingBalance: number;
    walletId: string;
  };
  userWalletHistoryList: {
    data: any[];
    loading: boolean;
  };
  aggregatorAirtimeCommissions: number | null;
  aggregatorBankTransfersCommissions: number | null;
  aggregatorCommissionsSummary: {
    totalCommission: number | null;
    totalCount: number | null;
    totalVolume: number | null;
  };
  commissionWallet: {
    loading: boolean;
    error: any;
    walletId: string;
    agentId: string;
    currency: string;
    availableBalance: number;
    pendingBalance: number;
    active: boolean;
  };
  userWallets: {
    loading: boolean;
    error: any;
    data: any;
  } | null;
  providus: {
    accountCreation: {
      loading: boolean;
      error: any;
      data: ICreateProviudusAccountResponse | null;
    };
  };
  pinValidation: {
    lockScreen: ILockScreenProps;
    loading: boolean;
    error: any | null;
    isValid: boolean | null;
    isSuccess: boolean;
    timeRemaining: number | null;
  };
}

const initState = {
  loading: false,
  error: null,
  userWalletBalance: {},
  userWalletHistoryList: {
    data: [],
    loading: false,
  },
  aggregatorAirtimeCommissions: null,
  aggregatorBankTransfersCommissions: null,
  aggregatorCommissionsSummary: {
    totalCommission: null,
    totalCount: null,
    totalVolume: null,
  },
  commissionWallet: {},
  userWallets: { data: { items: [] } },
  providus: {},
  pinValidation: {
    loading: false,
    error: null,
    isValid: null,
    isSuccess: false,
    timeRemaining: null,
    lockScreen: {},
  },
};

const key = actionTypes.KEY;

const walletReducer = (state: any = initState, action: any): IWallet => {
  switch (action.type) {
    case actionTypes.GET_USER_WALLET_BALANCE_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_USER_WALLET_HISTORY_PENDING:
      return {
        ...state,
        loading: true,
        userWalletHistoryList: {
          ...state.userWalletHistoryList,
          loading: true,
        },
        error: null,
      };
    case actionTypes.GET_USER_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        userWalletBalance: action.payload,
        loading: false,
        error: null,
      };
    case actionTypes.GET_USER_WALLET_HISTORY_SUCCESS:
      return {
        ...state,
        userWalletHistoryList: { ...action.payload, loading: false },
        loading: false,
        error: null,
      };
    case actionTypes.GET_USER_WALLET_BALANCE_FAILED:
    case actionTypes.GET_USER_WALLET_HISTORY_FAILED:
      notification.error({
        message: action?.payload && action?.payload.message,
        key,
      });
      return {
        ...state,
        userWalletHistoryList: {
          ...state.userWalletHistoryList,
          loading: false,
        },
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_AGGREGATOR_AIRTIME_COMMISSIONS_PENDING:
      return {
        ...state,
        aggregatorAirtimeCommissions: {
          ...state.aggregatorAirtimeCommissions,
          loading: true,
        },
      };
    case actionTypes.GET_AGGREGATOR_AIRTIME_COMMISSIONS_SUCCESS:
      return {
        ...state,
        aggregatorAirtimeCommissions: {
          ...action.payload,
          loading: false,
        },
      };
    case actionTypes.GET_AGGREGATOR_AIRTIME_COMMISSIONS_FAILED:
      notification.error({
        message: action?.payload?.message,
        key,
      });
      return {
        ...state,
        aggregatorAirtimeCommissions: {
          ...action?.payload,
          loading: false,
        },
      };
    case actionTypes.GET_AGGREGATOR_BANK_TRANSFERS_COMMISSIONS_PENDING:
      return {
        ...state,
        aggregatorBankTransfersCommissions: {
          ...state.aggregatorBankTransfersCommissions,
          loading: true,
        },
      };
    case actionTypes.GET_AGGREGATOR_BANK_TRANSFERS_COMMISSIONS_SUCCESS:
      return {
        ...state,
        aggregatorBankTransfersCommissions: {
          ...action.payload,
          loading: false,
        },
      };
    case actionTypes.GET_AGGREGATOR_BANK_TRANSFERS_COMMISSIONS_FAILED:
      notification.info({
        message: action?.payload?.message,
        key,
      });
      return {
        ...state,
        aggregatorBankTransfersCommissions: {
          ...action?.payload,
          loading: false,
        },
      };
    case actionTypes.GET_AGGREGATOR_COMMISSIONS_SUMMARY:
      return {
        ...state,
        aggregatorCommissionsSummary: {
          totalCommission: `N ${
            state.aggregatorAirtimeCommissions?.totalCommission +
            state.aggregatorBankTransfersCommissions?.totalCommission
          }`,
          totalCount:
            state.aggregatorAirtimeCommissions?.totalCount +
            state.aggregatorBankTransfersCommissions?.totalCount,
          totalVolume: `N ${
            state.aggregatorAirtimeCommissions?.totalVolume +
            state.aggregatorBankTransfersCommissions?.totalVolume
          }`,
        },
      };
    case actionTypes.GET_OR_CREATE_WALLET_PENDING:
      return {
        ...state,
        commissionWallet: {
          ...state.commissionWallet,
          loading: true,
          error: null,
        },
      };
    case actionTypes.GET_OR_CREATE_WALLET_SUCCESS:
      return {
        ...state,
        commissionWallet: {
          ...action.payload,
          loading: false,
          error: null,
        },
      };
    case actionTypes.GET_OR_CREATE_WALLET_FAILED:
      return {
        ...state,
        commissionWallet: {
          loading: false,
          error: action.payload,
        },
      };

    case actionTypes.GET_WALLETS_BY_PARAMS_PENDING:
      return {
        ...state,
        userWallets: {
          ...state.userWallets,
          loading: true,
          error: null,
        },
      };

    case actionTypes.GET_WALLETS_BY_PARAMS_SUCCESS:
      return {
        ...state,
        userWallets: {
          ...state.userWallets,
          loading: false,
          error: null,
          data: {
            ...state?.userWallets?.data,
            ...action.payload,
            items: [...state.userWallets?.data?.items, ...action.payload.items],
          },
        },
      };

    case actionTypes.GET_WALLETS_BY_PARAMS_FAILED:
      return {
        ...state,
        userWallets: {
          ...state.userWallets,
          loading: false,
          error: action.payload,
        },
      };

    case actionTypes.CREATE_PROVIDUS_ACCOUNT_PENDING:
      return {
        ...state,
        providus: {
          ...state.providus,
          accountCreation: {
            loading: true,
            error: null,
          },
        },
      };
    case actionTypes.CREATE_PROVIDUS_ACCOUNT_SUCCESS:
      return {
        ...state,
        providus: {
          ...state.providus,
          accountCreation: {
            loading: false,
            error: null,
            data: action.payload,
          },
        },
      };
    case actionTypes.CREATE_PROVIDUS_ACCOUNT_FAILED:
      return {
        ...state,
        providus: {
          ...state.providus,
          accountCreation: {
            loading: false,
            error: action.payload,
            data: null,
          },
        },
      };

    case actionTypes.GET_WALLETS_BY_PARAMS_PENDING:
      return {
        ...state,
        userWallets: {
          ...state.userWallets,
          loading: true,
          error: null,
        },
      };

    case actionTypes.GET_WALLETS_BY_PARAMS_SUCCESS:
      return {
        ...state,
        userWallets: {
          ...state.userWallets,
          loading: false,
          error: null,
          data: {
            ...state?.userWallets?.data,
            ...action.payload,
            items: [...state.userWallets?.data?.items, ...action.payload.items],
          },
        },
      };

    case actionTypes.GET_WALLETS_BY_PARAMS_FAILED:
      return {
        ...state,
        userWallets: {
          ...state.userWallets,
          loading: false,
          error: action.payload,
        },
      };

    case actionTypes.CREATE_PROVIDUS_ACCOUNT_PENDING:
      return {
        ...state,
        providus: {
          ...state.providus,
          accountCreation: {
            loading: true,
            error: null,
          },
        },
      };
    case actionTypes.CREATE_PROVIDUS_ACCOUNT_SUCCESS:
      return {
        ...state,
        providus: {
          ...state.providus,
          accountCreation: {
            loading: false,
            error: null,
            data: action.payload,
          },
        },
      };
    case actionTypes.CREATE_PROVIDUS_ACCOUNT_FAILED:
      return {
        ...state,
        providus: {
          ...state.providus,
          accountCreation: {
            loading: false,
            error: action.payload,
            data: null,
          },
        },
      };
    case actionTypes.VALIDATE_WALLET_PIN_PENDING:
      return {
        ...state,
        pinValidation: {
          ...state.pinValidation,
          loading: true,
          error: null,
          isSuccess: false,
          isValid: false,
        },
      };
    case actionTypes.VALIDATE_WALLET_PIN_SUCCESS:
      return {
        ...state,
        pinValidation: {
          ...state.pinValidation,
          loading: false,
          error: null,
          isValid: true,
          isSuccess: true,
          timeRemaining: 300,
        },
      };
    case actionTypes.VALIDATE_WALLET_PIN_FAILED:
      const error = action?.payload?.response?.data?.errors[0];

      message.error(error?.message);

      return {
        ...state,
        pinValidation: {
          ...state.pinValidation,
          loading: false,
          error,
          isValid: false,
          isSuccess: false,
          timeRemaining: 0,
        },
      };
    case actionTypes.SET_WALLET_PIN_VALIDATION_TIME_REMAINING:
      return {
        ...state,
        pinValidation: {
          ...state.pinValidation,
          timeRemaining: action.payload,
        },
      };
    case actionTypes.SET_WALLET_PIN_VALIDATION:
      return {
        ...state,
        pinValidation: {
          ...state.pinValidation,
          isValid: action.payload,
        },
      };
    case actionTypes.SET_LOCK_SCREEN:
      return {
        ...state,
        pinValidation: {
          ...state.pinValidation,
          lockScreen: {
            ...state.pinValidation.lockScreen,
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export default walletReducer;
