import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';

export interface IDefaultVFDResponse<D> {
  data: D;
  message: string;
  status: string;
}

export type ILoanTokenResponse = IDefaultVFDResponse<{
  access_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
}>;

export interface ILoanUser {
  lastName: string;
  phone: string;
  firstName: string;
  createdDate: string;
  clientId: string;
  bvn: string;
  accountNo: string;
}

export type IGetLoanUsersResponse = IDefaultVFDResponse<{
  content: Array<ILoanUser>;
  totalElements: number;
  totalPages: number;
}>;

export interface IGetLoanUsersPayload {
  entity: 'virtual' | 'individual' | 'corporate';
  page: number;
  size: number;
}

export interface IReleaseAccountPayload {
  accountNo: string;
}

export interface IGetBVNConsentPayload {
  type: '02';
  bvn: string;
  reference: string;
}

export type IGetBVNConsentResponse = IDefaultVFDResponse<{
  statusCode: 'false' | 'true';
  url: string;
  reference: string;
}>;

export type IReleaseAccountResponse = IDefaultVFDResponse<Pick<IGetLoanUsersResponse, 'message' | 'status'>>;

export interface IBeneficiaryEnquiryPayload {
  accountNo: string;
  bank: string;
  transfer_type: 'intra';
}

export type IBeneficiaryEnquiryResponse = IDefaultVFDResponse<{
  name: string;
  clientId: string;
  bvn: string;
  account: {
    number: string;
    id: string;
  };
  status: string;
  currency: string;
  bank: string;
}>;

export interface ICreateAndDisburseLoanPayload {
  transactionId: string;
  accountNo: string;
  amount: number;
  duration: number;
  durationType?: string;
}

export type ICreateAndDisburseLoanResponse = IDefaultVFDResponse<{
  loanAccountNumber: string;
}>;

export interface IGetLoanPortfolioPayload {
  endDate: string;
  startDate: string;
  size?: number;
  page?: number;
  filterBy?: 'amount' | 'transactionId' | 'status' | 'accountNo';
  search?: string;
}

export interface ILoanTransaction {
  duration: string;
  disburseDate: string;
  loanAccountNo: string;
  clientName: string;
  walletName: string;
  transactionId: string;
  status: string;
  amount: string;
  accountNo: string;
  walletId: string;
  statusDescription: string;
  interestFrequency: string;
  interest: string;
  durationType: string;
}

export type ILoanPortfolioResponse = IDefaultVFDResponse<{
  content: Array<ILoanTransaction>;
  totalElements: number;
  totalPages: number;
}>;

export interface ILoanRepaymentSchedulePayload {
  identification: string;
}

interface ILoanCurrency {
  code: string;
  name: string;
  decimalPlaces: number;
  inMultiplesOf: number;
  nameCode: string;
  displayLabel: string;
}

export interface ILoanPeriod {
  period?: number;
  fromDate?: number[];
  dueDate: number[];
  complete: boolean;
  daysInPeriod?: number;
  principalOriginalDue?: number;
  principalDue?: number;
  principalPaid?: number;
  principalWrittenOff?: number;
  principalOutstanding?: number;
  principalLoanBalanceOutstanding: number;
  interestOriginalDue?: number;
  interestDue?: number;
  interestPaid?: number;
  interestWaived?: number;
  interestWrittenOff?: number;
  interestOutstanding?: number;
  feeChargesDue?: number;
  feeChargesPaid?: number;
  feeChargesWaived?: number;
  feeChargesWrittenOff?: number;
  feeChargesOutstanding?: number;
  penaltyChargesDue?: number;
  penaltyChargesPaid?: number;
  penaltyChargesWaived?: number;
  penaltyChargesWrittenOff?: number;
  penaltyChargesOutstanding?: number;
  overdueInterest?: number;
  totalOriginalDueForPeriod: number;
  totalDueForPeriod: number;
  totalPaidForPeriod: number;
  totalPaidInAdvanceForPeriod?: number;
  totalPaidLateForPeriod?: number;
  totalWaivedForPeriod?: number;
  totalWrittenOffForPeriod?: number;
  totalOutstandingForPeriod: number;
  totalOverdue?: number;
  totalActualCostOfLoanForPeriod: number;
  totalInstallmentAmountForPeriod: number;
}

export interface IRepaymentSchedule {
  currency: ILoanCurrency;
  loanTermInDays: number;
  totalPrincipalDisbursed: number;
  totalPrincipalExpected: number;
  totalPrincipalPaid: number;
  totalInterestCharged: number;
  totalFeeChargesCharged: number;
  totalPenaltyChargesCharged: number;
  totalWaived: number;
  totalWrittenOff: number;
  totalRepaymentExpected: number;
  totalRepayment: number;
  totalPaidInAdvance: number;
  totalPaidLate: number;
  totalOutstanding: number;
  totalOverdueInterest: number;
  periods: ILoanPeriod[];
}

export type ILoanRepaymentScheduleResponse = IDefaultVFDResponse<{
  repaymentSchedule: IRepaymentSchedule;
}>;

export interface ILoanAccountEnquiryPayload {
  accountNumber: string;
}

export type ILoanAccountEnquiryResponse = IDefaultVFDResponse<{
  accountNo: string;
  accountBalance: string;
  accountId: string;
  client: string;
  clientId: string;
  savingsProductName: string;
}>;

export type IVFDFundTransferResponse = IDefaultVFDResponse<{
  txnId: string;
  sessionId: string;
  reference: string;
}>;

export interface ILoanUserLogPayoad {
  accountNo: string;
}

export interface ILoanUserLog extends ILoanUser {
  signature: string;
}
export interface ILoanUserLogResponse {
  data: ILoanUserLog;
}

type VFDLoanService = {
  createNewLoanUser: (payload: ICreateNewLoanUser) => AxiosPromise<any>;
  getLoanToken: () => AxiosPromise<ILoanTokenResponse>;
  getLoanUsersList: (payload: IGetLoanUsersPayload) => AxiosPromise<IGetLoanUsersResponse>;
  releaseAccount: (payload: IReleaseAccountPayload) => AxiosPromise<IReleaseAccountResponse>;
  getBVNConsent: (payload: IGetBVNConsentPayload) => AxiosPromise<IGetBVNConsentResponse>;
  getBeneficiaryDetails: (payload: IBeneficiaryEnquiryPayload) => AxiosPromise<IBeneficiaryEnquiryResponse>;
  createAndDisburseLoan: (payload: ICreateAndDisburseLoanPayload) => AxiosPromise<ICreateAndDisburseLoanResponse>;
  getLoanPortfolio: (payload: IGetLoanPortfolioPayload) => AxiosPromise<ILoanPortfolioResponse>;
  getLoanRepaymentSchedule: (payload: ILoanRepaymentSchedulePayload) => AxiosPromise<ILoanRepaymentScheduleResponse>;
  getLoanAccountEquiry: (payload: ILoanAccountEnquiryPayload) => AxiosPromise<ILoanAccountEnquiryResponse>;
  logNewLoanUser: (payload: ILogNewLoanUser) => AxiosPromise<any>;
  transferFund: (payload: IVFDFundTransferPayload) => AxiosPromise<IVFDFundTransferResponse>;
  getLoanUserlog: (payload: ILoanUserLogPayoad) => AxiosPromise<ILoanUserLogResponse>;
};

export interface ICreateNewLoanUser {
  firstname: string;
  lastname: string;
  middlename: string;
  phone: string;
  address: string;
  bvn: string;
  gender: string;
  dob: string;
}

export interface ILogNewLoanUser extends ICreateNewLoanUser {
  accountNo: string;
  signature: string;
}

export interface IVFDFundTransferPayload {
  fromAccount: string;
  uniqueSenderAccountId: string;
  fromClientId: string;
  fromClient: string;
  fromSavingsId: string;
  fromBvn: string;
  toClientId: string;
  toClient: string;
  toSavingsId: string;
  toSession: string;
  toBvn: string;
  toAccount: string;
  toBank: string;
  signature: string;
  amount: string;
  remark: string;
  transferType: string;
  reference: string;
}

// eslint-disable-next-line
const VFDLoanService = <VFDLoanService>{};

const VFD_TOKEN = localStorage.getItem('vfd_token');

const vfdFetch = (args: AxiosRequestConfig): ReturnType<typeof axios> =>
  axios({
    baseURL: process.env.REACT_APP_VFD_WALLET_BASE_URL,
    headers: {
      AccessToken: `${VFD_TOKEN}`,
      'Content-Type': 'application/json',
    },
    ...args,
  });

VFDLoanService.createNewLoanUser = (data: ICreateNewLoanUser) =>
  vfdFetch({
    url: '/client/individual',
    method: 'POST',
    data,
  });

VFDLoanService.getLoanToken = () =>
  vfdFetch({
    baseURL: process.env.REACT_APP_VFD_TOKEN_BASE_URL,
    url: '/baasauth/token',
    method: 'POST',
    data: {
      validityTime: '-1',
      consumerKey: process.env.REACT_APP_VFD_CONSUMER_KEY,
      consumerSecret: process.env.REACT_APP_VFD_CONSUMER_SECRET,
    },
  });

VFDLoanService.getLoanUsersList = (params: IGetLoanUsersPayload) =>
  vfdFetch({
    url: '/sub-accounts',
    method: 'GET',
    params,
  });

VFDLoanService.getBVNConsent = (params: IGetBVNConsentPayload) =>
  vfdFetch({
    url: '/bvn-consent',
    method: 'GET',
    params,
  });

VFDLoanService.releaseAccount = (data: IReleaseAccountPayload) =>
  vfdFetch({
    url: '/client/release',
    method: 'POST',
    data,
  });

VFDLoanService.getBeneficiaryDetails = (params: IBeneficiaryEnquiryPayload) =>
  vfdFetch({
    url: '/transfer/recipient',
    method: 'GET',
    params,
  });

VFDLoanService.createAndDisburseLoan = (data: ICreateAndDisburseLoanPayload) =>
  vfdFetch({
    baseURL: process.env.REACT_APP_VFD_LOAN_BASE_URL,
    url: '/loan/create-and-disburse',
    method: 'POST',
    data,
  });

VFDLoanService.getLoanPortfolio = (params: IGetLoanPortfolioPayload) =>
  vfdFetch({
    baseURL: process.env.REACT_APP_VFD_LOAN_BASE_URL,
    url: '/loan/portfolio',
    method: 'GET',
    params,
  });

VFDLoanService.getLoanRepaymentSchedule = (params: ILoanRepaymentSchedulePayload) =>
  vfdFetch({
    baseURL: process.env.REACT_APP_VFD_LOAN_BASE_URL,
    url: '/loan/repayment-schedule',
    method: 'GET',
    params,
  });

VFDLoanService.getLoanAccountEquiry = (params: ILoanAccountEnquiryPayload) =>
  vfdFetch({
    url: '/account/enquiry',
    method: 'GET',
    params,
  });

VFDLoanService.logNewLoanUser = (data: ILogNewLoanUser) =>
  vfdFetch({
    baseURL: process.env.REACT_APP_VFD_LOAN_LOGS,
    url: '/users/create-user',
    method: 'POST',
    data,
  });

VFDLoanService.transferFund = (data: IVFDFundTransferPayload) =>
  vfdFetch({
    url: '/transfer',
    method: 'POST',
    data,
  });

VFDLoanService.getLoanUserlog = (params: ILoanUserLogPayoad) =>
  vfdFetch({
    baseURL: process.env.REACT_APP_VFD_LOAN_LOGS,
    url: `/users/account-no-search/${params.accountNo}`,
    method: 'GET',
  });

export default VFDLoanService;
