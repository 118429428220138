/* eslint-disable */
import { IGetUsersParams, IUser } from './../../services/UserService';
import * as Redux from 'redux';
import userService, {
  IBulkAgentOnboading,
  IBulkProcessAggregatorUpload,
  IUpdateUserDesignationPayload,
} from 'src/services/UserService';
import { message } from 'antd';
import Utils from 'src/utils';
import * as actionTypes from '../constants';
import { IDefaultDispatch } from './types';
import Slack from 'src/utils/slack';
import dayjs from 'dayjs';
import { searchAgent } from './tools';
import { ERR_CANCELED } from 'src/constants/ErrorCodes';

export type IUnassignAgentToAggregator = ReturnType<typeof unassignAgentToAggregator>;

export interface IFilter {
  phoneNumber?: string;
  firstName?: string;
}
export interface IAggregatorFilter {
  userId: string;
}
export interface IMakeAggregator {
  id?: string;
  agentId?: string;
  action?: boolean;
  approval?: boolean;
  designation?: 'super agent' | 'aggregator';
}

export interface IPagination {
  total?: number;
  current?: number;
  limit?: number;
  count?: number;
}

export const getBankAccountDetails = () => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.GET_BANK_ACCOUNT_DETAILS_PENDING,
  });
  try {
    const response = await userService.getBankAccountDetails();

    dispatch({
      type: actionTypes.GET_BANK_ACCOUNT_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_BANK_ACCOUNT_DETAILS_FAILED,
      payload: error,
    });
  }
};

export const getBankAccountDetailsById = () => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.GET_BANK_ACCOUNT_DETAILS_BY_ID_PENDING,
  });
  try {
    const response = await userService.getBankAccountDetails();

    dispatch({
      type: actionTypes.GET_BANK_ACCOUNT_DETAILS_BY_ID_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_BANK_ACCOUNT_DETAILS_BY_ID_FAILED,
      payload: error,
    });
  }
};

const GetUserDetailsById = (userId: string) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.GET_USER_DETAILS_BY_ID_PENDING,
  });
  await userService
    .getUserById({ userId })
    .then((response) => {
      dispatch({
        type: actionTypes.GET_USER_DETAILS_BY_ID_SUCCESS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_USER_DETAILS_BY_ID_FAILED,
        payload: err,
      });
    });
};

export const getUserProfileDetailsById =
  (userId: string) => async (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.GET_USER_PROFILE_DETAILS_BY_ID_PENDING,
    });
    await userService
      .getUserById({ userId })
      .then((response) => {
        dispatch({
          type: actionTypes.GET_USER_PROFILE_DETAILS_BY_ID_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_USER_PROFILE_DETAILS_BY_ID_FAILED,
          payload: err,
        });
      });
  };

export const getUserDetailsByPhoneNumber = (phone: string) => (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.GET_USER_DETAILS_BY_ID_PENDING,
  });
  userService
    .getUserByPhoneNumber({ phoneNumber: Utils.convertPhoneToISO(phone) })
    .then((user: IUser) => {
      dispatch({
        type: actionTypes.GET_USER_DETAILS_BY_ID_SUCCESS,
        payload: user,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_USER_DETAILS_BY_ID_FAILED,
        payload: err,
      });
    });
};

export const getUserListByIds =
  (user_ids: string[]) => async (dispatch: Redux.Dispatch<IDefaultDispatch>) => {
    dispatch({ type: actionTypes.GET_LIST_OF_USER_DETAILS_BY_IDS_PENDING });

    return await userService
      .getUserListByIds({
        user_ids: user_ids.join(','),
        projection: 'firstName,lastName,phoneNumber',
      })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_LIST_OF_USER_DETAILS_BY_IDS_SUCCESS,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_LIST_OF_USER_DETAILS_BY_IDS_FAILED,
          payload: err,
        });
      });
  };

export const getUserDetailsByBvn = (bvn: string) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.GET_USER_DETAILS_BY_BVN_PENDING,
  });
  await userService
    .getUserByBvn(bvn)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_USER_DETAILS_BY_BVN_SUCCESS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_USER_DETAILS_BY_BVN_FAILED,
        payload: err,
      });
    });
};

export const getUserDetailsById = (userId: string) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch(GetUserDetailsById(userId));
};
export const GetAggregatorByParentId = (data: any) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.SEARCH_AGENT_PENDING,
  });
  await userService
    .findUserByParentId(data)
    .then((response) => {
      // eslint-disable-next-line  @typescript-eslint
      dispatch({
        type: actionTypes.SEARCH_AGENT_SUCCESS,
        payload: response.data[0],
      });
    })
    .catch((err: any) => {
      dispatch({
        type: actionTypes.SEARCH_AGENT_ERROR,
        payload: err,
      });
    });
};
export const getAggregatorByParentId = (data: any) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch(GetAggregatorByParentId(data));
};
export const createANewUser =
  (user: any, otherVals?: any) => async (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.CREATE_NEW_USER_PENDING,
    });
    await userService
      .createANewUser(user)
      .then((response) => {
        dispatch({
          type: actionTypes.CREATE_NEW_USER_SUCCESS,
          payload: response,
        });
        dispatch(continueRegistration(otherVals, response.userId));
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CREATE_NEW_USER_FAILED,
          payload: err,
        });
      });
  };

const continueRegistration = (val: any, id: string) => (dispatch: Redux.Dispatch<any>) => {
  if (val && id) {
    Promise.all([
      dispatch(
        updateUserAddress({
          addressLine1: val?.homeAddress,
          city: val?.city,
          id,
          lga: val?.lga,
          state: val?.state,
        })
      ),
      dispatch(
        updateUserIdentity({
          id,
          lgaOfOrigin: val?.lgaOrigin,
          stateOfOrigin: val?.stateOrigin,
          dob: dayjs(val?.dob).format('YYYY-MM-DD'),
          dateOfBirth: dayjs(val?.dob).format('YYYY-MM-DD'),
          gender: val?.gender,
          homeAddress: {
            addressLine1: val?.homeAddress,
            city: val?.city,
            lga: val?.lga,
            state: val?.state,
          },
          idType: val?.idType,
          idNumber: val?.idNumber,
          kycDocs: [
            {
              ImageName: 'ID Card',
              ImageString: val?.personalID.fileList[0].originFileObj.bucketKey,
            },
            {
              ImageName: 'UTILITY_BILL',
              ImageString: val?.utilityBill.fileList[0].originFileObj.bucketKey,
            },
            {
              ImageName: 'SIGNATURE',
              ImageString: val?.personalSignature.fileList[0].originFileObj.bucketKey,
            },
          ],
        })
      ),
      dispatch(
        updateUserNextOfKin({
          firstName: val?.nokFirstName,
          id,
          lastName: val?.nokLastName,
          phoneNumber: Utils.convertPhoneToISO(val?.nokPhoneNumber),
        })
      ),
    ]);
  } else message.error({ message: 'User not created.' });
};

const UpdateUserAddress = (address: any) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.UPDATE_USER_ADDRESS_PENDING,
  });
  await userService
    .updateUserAddress(address)
    .then((response) => {
      dispatch({
        type: actionTypes.UPDATE_USER_ADDRESS_SUCCESS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.UPDATE_USER_ADDRESS_FAILED,
        payload: err,
      });
    });
};

export const updateUserAddress = (address: any) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch(UpdateUserAddress(address));
};

const UpdateUserIdentity =
  (identity: any, withLoader?: boolean) => async (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.UPDATE_USER_IDENTITY_PENDING,
    });
    await userService
      .updateUserIdentity(identity)
      .then((response) => {
        dispatch({
          type: actionTypes.UPDATE_USER_IDENTITY_SUCCESS,
          payload: response,
        });
        dispatch(
          searchAgent(
            {
              userId: Utils.convertPhoneToISO(identity.phoneNumber),
              searchType: 'phoneNumber',
            },
            withLoader
          )
        );
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPDATE_USER_IDENTITY_FAILED,
          payload: err,
        });
      });
  };

export const updateUserIdentity =
  (identity: any, withLoader?: boolean) => async (dispatch: Redux.Dispatch<any>) => {
    dispatch(UpdateUserIdentity(identity, withLoader));
  };

const UpdateUserNextOfKin = (nextOfKin: any) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.UPDATE_USER_NEXT_OF_KIN_PENDING,
  });
  await userService
    .updateUserNextOfKin(nextOfKin)
    .then((response) => {
      dispatch({
        type: actionTypes.UPDATE_USER_NEXT_OF_KIN_SUCCESS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.UPDATE_USER_NEXT_OF_KIN_FAILED,
        payload: err,
      });
    });
};

export const updateUserNextOfKin = (nextOfKin: any) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch(UpdateUserNextOfKin(nextOfKin));
};

const UpdateUserProduct = (product: any) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.UPDATE_USER_PRODUCT_PENDING,
  });
  await userService
    .updateUserProduct(product)
    .then((response) => {
      dispatch({
        type: actionTypes.UPDATE_USER_PRODUCT_SUCCESS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.UPDATE_USER_PRODUCT_FAILED,
        payload: err,
      });
    });
};

export const updateUserProduct = (product: any) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch(UpdateUserProduct(product));
};

const GetCurrentUser = (userId: any) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.GET_USER_CURRENT_BY_ID_PENDING,
  });

  const extractPermissions = (token: string) => Utils.parseJwt(token);

  await userService
    .getUserById({ userId })
    .then((response) => {
      const jwtToken = localStorage.getItem(actionTypes.AUTH_TOKEN);

      dispatch({
        type: actionTypes.GET_USER_CURRENT_BY_ID_SUCCESS,
        payload: response,
      });
      const data = JSON.parse(localStorage.getItem(actionTypes.AUTH_USER) || '');
      localStorage.setItem(actionTypes.AUTH_USER, JSON.stringify({ ...data, user: response }));
      try {
        dispatch({
          type: actionTypes.EXTRACT_TOKEN_PAYLOAD_PENDING,
        });
        if (jwtToken) {
          dispatch({
            type: actionTypes.EXTRACT_TOKEN_PAYLOAD_SUCCESS,
            payload: Utils.structuredPermissions(extractPermissions(jwtToken)),
          });
        }
      } catch (err) {
        dispatch({
          type: actionTypes.EXTRACT_TOKEN_PAYLOAD_FAILED,
          payload: err,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_USER_CURRENT_BY_ID_FAILED,
        payload: err,
      });
    });
}; // done

export const getCurrentUser = (userId: any) => (dispatch: Redux.Dispatch<any>) => {
  dispatch(GetCurrentUser(userId));
}; // done

const GetAggregatorUsers = (data: any) => async (dispatch: Redux.Dispatch<any>) => {
  const aggId = localStorage.getItem(actionTypes.AUTH_TOKEN_AGG_ID) || '';
  dispatch({
    type: actionTypes.GET_AGGREGATOR_USERS_PENDING,
  });
  await userService
    .getUsers({
      ...data,
      relationshipType: 'AGGREGATOR',
      userId: data?.aggId ? data?.aggId : aggId,
    })
    .then((response) => {
      dispatch({
        type: actionTypes.GET_AGGREGATOR_USERS_SUCCESS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_AGGREGATOR_USERS_FAILED,
        payload: err,
      });
    });
}; // done

// Aggregator Agents Portal Dashboard
export const getAggregatorAgents =
  (userId: string, pagination?: IPagination, filter?: IGetUsersParams, abortSignal?: AbortSignal) =>
  async (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.GET_AGGREGATOR_AGENTS_PENDING,
    });
    await userService
      .getAggregatorAgents({ aggregatorId: userId }, pagination, filter, abortSignal)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_AGGREGATOR_AGENTS_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_AGGREGATOR_AGENTS_FAILED,
          payload: err,
        });
      });
  };

export const getAggregatorAgentCount =
  (userId: string, pagination?: IPagination, filter?: IFilter) =>
  async (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.GET_AGGREGATOR_AGENTS_COUNT_PENDING,
    });
    await userService
      .getAggregatorAgents({ aggregatorId: userId }, pagination, filter)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_AGGREGATOR_AGENTS_COUNT_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_AGGREGATOR_AGENTS_COUNT_FAILED,
          payload: err,
        });
      });
  };

export const getAggregatorAgentsForCommission =
  (userId: string, pagination?: IPagination, filter?: IFilter) =>
  async (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.GET_AGGREGATOR_AGENTS_PENDING,
    });
    await userService
      .getAggregatorAgents({ aggregatorId: userId }, pagination, filter)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_AGGREGATOR_AGENTS_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_AGGREGATOR_AGENTS_FAILED,
          payload: err,
        });
      });
  };
export const makeAggregator = (data: IMakeAggregator) => (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.MAKE_AGGREGATOR_PENDING,
    payload: data,
  });
  userService
    .makeAggregator({
      id: data.agentId,
      approval: data.action,
      designation: data.designation,
    })
    .then((res) => {
      dispatch({
        type: actionTypes.MAKE_AGGREGATOR_SUCCESS,
        payload: res,
      });
      dispatch(updateUserByIdSilently(data.agentId as string));
    })
    .catch((err: any) => {
      dispatch({
        type: actionTypes.MAKE_AGGREGATOR_FAILED,
        payload: data,
      });
    });
};

export const updateUserByIdSilently =
  (userId: string, successCallback?: () => void) => (dispatch: Redux.Dispatch<any>) => {
    userService
      .getUserById({ userId })
      .then((res) => {
        successCallback && successCallback();
        dispatch({
          type: actionTypes.GET_USER_DETAILS_BY_ID_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {});
  };
export const getAggregatorUsers = (data: any) => (dispatch: Redux.Dispatch<any>) => {
  dispatch(GetAggregatorUsers(data));
}; // done

export const getUsersByProduct =
  (params: IGetUsersParams) => async (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.GET_ALL_USERS_PENDING,
    });

    await userService
      .getUsersByProduct(params)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ALL_USERS_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_ALL_USERS_FAILED,
          payload: err,
        });
      });
  };

const GetAllUsers =
  (data: IGetUsersParams, abortSignal?: AbortSignal) => async (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.GET_ALL_USERS_PENDING,
    });

    await userService
      .adminGetUsers(data, abortSignal)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ALL_USERS_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        message.error('Oops, something went wrong');
        if (err?.code === ERR_CANCELED) {
        } else {
          dispatch({
            type: actionTypes.GET_ALL_USERS_FAILED,
            payload: err,
          });
        }
      });
  }; // done

export const getAllUsersForAssign =
  (data: IGetUsersParams, abortSignal?: AbortSignal) => async (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.GET_ALL_USERS_FOR_ASSIGN_PENDING,
    });

    await userService
      .adminGetUsers(data, abortSignal)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ALL_USERS_FOR_ASSIGN_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        message.error('Oops, something went wrong');
        if (err?.code === ERR_CANCELED) {
        } else {
          dispatch({
            type: actionTypes.GET_ALL_USERS_FOR_ASSIGN_FAILED,
            payload: err,
          });
        }
      });
  };

export const getAllUsers =
  (data: IGetUsersParams, abortSignal?: AbortSignal) => (dispatch: Redux.Dispatch<any>) => {
    dispatch(GetAllUsers(data, abortSignal));
  }; // done

const VerifyUserKyc = (data: any) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.VERIFY_KYC_PENDING,
  });

  const payload = data;

  await userService
    .verifyKyc({ ...payload })
    .then((response) => {
      Slack.logAction(
        {
          firstName: data.userData.firstName,
          lastName: data.userData.lastName,
          agentId: data.userData.userId,
          kycStatus: data.kycStatus,
        },
        Slack.actionTypes.userReview
      );
      dispatch({
        type: actionTypes.VERIFY_KYC_SUCCESS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.VERIFY_KYC_FAILED,
        payload: err,
      });
    });
}; // done

export const verifyUserKyc = (data: any) => (dispatch: Redux.Dispatch<any>) => {
  dispatch(VerifyUserKyc(data));
}; // done

export const getAllAggregators = (data?: any) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.GET_ALL_AGGREGATORS_PENDING,
  });

  await userService
    .getAllAggregators(data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_ALL_AGGREGATORS_SUCCESS,
        payload: data?.search ? { data: [response] } : response,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_ALL_AGGREGATORS_FAILED,
        payload: err,
      });
    });
};
export const assignAgentToAggregator =
  (data: { aggregator: IUser; users: IUser | Array<string>; successCallback?: VoidFunction }) =>
  async (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.ASSIGN_AGENT_TO_AGGREGATOR_PENDING,
      payload: data,
    });

    const isArrayOfUsers = Array.isArray(data?.users);
    const aggregatorDetails = (await userService.getUserByPhoneNumber({
      phoneNumber: data.aggregator.phoneNumber,
    })) as IUser;

    await userService
      .assignAgentToAggregator({
        aggregator: data.aggregator.phoneNumber,
        users: (isArrayOfUsers ? data?.users : [(data.users as any).phoneNumber]) as any,
      })
      .then(async (response) => {
        data.successCallback && data.successCallback();

        try {
          // Automatically assign agent to his parent's user group
          await userService.updateUserGroup({
            users: [
              isArrayOfUsers ? (data.users as Array<string>)[0] : (data.users as IUser).userId,
            ],
            userGroupId: aggregatorDetails.userGroupId,
          });

          message.success('Successfully assigned to parent user group');
        } catch (error) {
          message.error('Assignmment to parent user group failed');
        }

        Slack.logAction(
          {
            firstName: isArrayOfUsers ? '' : (data.users as any).firstName,
            lastName: isArrayOfUsers ? '' : (data.users as any).lastName,
            agentId: isArrayOfUsers ? '' : (data.users as any).userId,
            phoneNumber: isArrayOfUsers ? '' : (data.users as any).phoneNumber,
            phoneNumbers: data?.users as any,
            parent: data.aggregator,
          },
          Slack.actionTypes.usersAssignToParent
        );
        dispatch({
          type: actionTypes.ASSIGN_AGENT_TO_AGGREGATOR_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ASSIGN_AGENT_TO_AGGREGATOR_FAILED,
          payload: err,
        });
      });
  };

export const unassignAgentToAggregator = (user: IUser) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.UNASSIGN_AGENT_TO_AGGREGATOR_PENDING,
    payload: user.phoneNumber,
  });

  await userService
    .unassignAgentToAggregator(user.phoneNumber)
    .then((response) => {
      Slack.logAction(
        {
          firstName: user.firstName,
          lastName: user.lastName,
          agentId: user.userId,
          phoneNumber: user.phoneNumber,
          parentId: user.parentId,
        },
        Slack.actionTypes.userUnassignFromParent
      );
      dispatch({
        type: actionTypes.UNASSIGN_AGENT_TO_AGGREGATOR_SUCCESS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.UNASSIGN_AGENT_TO_AGGREGATOR_FAILED,
        payload: err,
      });
    });
};

export const generateURI = (data: IBulkAgentOnboading) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.GENERATE_UPLOAD_URI_PENDING,
  });

  const res = await userService
    .bulkAgentOnboading(data)
    .then((response) => {
      dispatch({
        type: actionTypes.GENERATE_UPLOAD_URI_SUCCESS,
        payload: response,
      });
      return response;
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GENERATE_UPLOAD_URI_FAILED,
        payload: err,
      });
      return err;
    });

  return res;
};

export const bulkProcessAggregatorUpload =
  (data: IBulkProcessAggregatorUpload) => async (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.BULK_PROCESS_AGGREGATOR_UPLOAD_PENDING,
    });

    await userService
      .bulkProcessAggregatorUpload(data)
      .then((response) => {
        message.success('Bulk processing started successfully.');
        dispatch({
          type: actionTypes.BULK_PROCESS_AGGREGATOR_UPLOAD_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        message.error('Error starting bulk processing.');
        dispatch({
          type: actionTypes.BULK_PROCESS_AGGREGATOR_UPLOAD_ERROR,
          payload: err,
        });
      });
  };

export const downloadCSVTemplate = (data: any) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.DOWNLOAD_CSV_TEMPLATE_PENDING,
  });

  await userService
    .downloadCSVTemplate()
    .then((response) => {
      dispatch({
        type: actionTypes.DOWNLOAD_CSV_TEMPLATE_SUCCESS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.DOWNLOAD_CSV_TEMPLATE_FAILED,
        payload: err,
      });
    });
};

export const updateUserDesignation =
  (data: IUpdateUserDesignationPayload) => async (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: actionTypes.UPDATE_USER_DESIGNATION_PENDING,
      payload: data,
    });

    try {
      const response = await userService.updateUserDesignation(data);

      dispatch({
        type: actionTypes.UPDATE_USER_DESIGNATION_SUCCESS,
        payload: response,
      });

      dispatch(updateUserByIdSilently(data.id));
    } catch (error) {
      dispatch({
        type: actionTypes.UPDATE_USER_DESIGNATION_FAILED,
        payload: error,
      });
    }
  };

export const getDesignations = () => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.GET_DESIGNATIONS_PENDING,
  });

  try {
    const response = await userService.getDesignations();

    dispatch({
      type: actionTypes.GET_DESIGNATIONS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_DESIGNATIONS_FAILED,
      payload: err,
    });
  }
};

export const getUserGroups = () => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.GET_USER_GROUPS_PENDING,
  });

  try {
    const response = await userService.getUserGroups();

    dispatch({
      type: actionTypes.GET_USER_GROUPS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_USER_GROUPS_FAILED,
      payload: err,
    });
  }
};
