/* eslint-disable */
import { notification } from 'antd';
import * as actionTypes from '../constants';

export interface InsuranceType {
  loading: boolean;
  error: any;
  policy: any;
}

const initState = {
  loading: false,
  error: null,
  policy: {}
};

const key = actionTypes.KEY;

const insuranceReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_INSURANCE_POLICY_PENDING:
      notification.info({
        message: 'Loading.....',
        duration: 3,
        key,
      });
      return {
        ...state,
        loading: true,
        policy: {},
        error: null,
      };
    case actionTypes.GET_INSURANCE_POLICY_FAILED:
      notification.info({
        message: 'Insurance policy does not exist',
      });
      return {
        ...state,
        loading: false,
        policy: {},
        error: null,
      };
    case actionTypes.GET_INSURANCE_POLICY_SUCCESS:
      return {
        ...state,
        policy: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default insuranceReducer;
