/* eslint-disable */

import { Rule } from 'antd/lib/form';

type RuleObject = {
  [key: string]: Rule[];
};

class Validation {
  /**
   *
   * @param _
   * @param value
   * @returns boolean validation
   */

  static noEmptySpace = (_: any, value: any) =>
    value !== ' ' ? Promise.resolve() : Promise.reject(new Error('No empty spaces allowed'));

  static antFormRules: RuleObject = {
    firstName: [
      {
        required: true,
        message: 'Please input your First Name',
      },
    ],
    lastName: [
      {
        required: true,
        message: 'Please input your Last Name',
      },
    ],
    displayName: [
      {
        required: true,
        message: 'Please input your Display Name',
      },
    ],
    phoneNumber: [
      {
        required: true,
        message: 'Please input your Phone Number',
      },
    ],
    gender: [
      {
        required: true,
        message: 'Please select your Gender',
      },
    ],
    registrationType: [
      {
        required: true,
        message: 'Please select registration type',
      },
    ],
  };
}

export default Validation;
