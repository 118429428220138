/* eslint-disable */
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Utils from '../utils';

interface IUsePathName {
  docTitleChange?: boolean;
}

const usePathName = (props: IUsePathName) => {
  const { docTitleChange } = props;
  const location = useLocation().pathname;
  const pathKey = useMemo(() => Utils.documentTitle(location), [location]);

  useEffect(() => {
    if (docTitleChange) document.title = pathKey;
  }, [location]);

  return { pathKey, pathName: location };
};

export { usePathName };
