import React, { memo, useContext } from 'react';
import { Modal, Button, Col, Form, Input, Row, message, Descriptions, DescriptionsProps } from 'antd';
import { useDispatch } from 'react-redux';
import { createAndDisburseLoan } from 'src/redux/actions/loanManagement';
import { selectLoanManagementCreateAndDisburseLoanLoading } from 'src/redux/selectors/LoanManagement';
import { useAppSelector } from 'src/redux/store/hooks';
import { ICreateAndDisburseLoanPayload, ILoanUser } from 'src/services/VFDService';
import { LoanDashboardContext } from './loanUsers';
import dayjs from 'dayjs';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
};

interface ICreateAndDisburseLoanProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateAndDisburseLoan: React.FC<ICreateAndDisburseLoanProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { disburseArgs } = useContext(LoanDashboardContext);

  const loading = useAppSelector((store) => selectLoanManagementCreateAndDisburseLoanLoading(store));

  const handleCancel = () => {
    setOpen(false);
    form.resetFields(['amount']);
  };

  const [form] = Form.useForm<ICreateAndDisburseLoanPayload>();

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        form.submit();

        dispatch(
          createAndDisburseLoan(
            {
              ...disburseArgs,
              ...values,
              amount: +values.amount,
              transfer_type: 'intra',
              bank: '999999',
              duration: 1,
              transactionId: '',
            },
            // Success callback
            handleCancel
          )
        );
      })
      .catch((error) => {
        message.error(error);
      });
  };

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'First name',
      children: (disburseArgs as unknown as ILoanUser)?.firstName,
    },
    {
      key: '2',
      label: 'Last name',
      children: (disburseArgs as unknown as ILoanUser)?.lastName,
    },
    {
      key: '3',
      label: 'Phone number',
      children: (disburseArgs as unknown as ILoanUser)?.phone,
    },
    {
      key: '4',
      label: 'Account number',
      span: 2,
      children: (disburseArgs as unknown as ILoanUser)?.accountNo,
    },
    {
      key: '5',
      label: 'BVN',
      children: (disburseArgs as unknown as ILoanUser)?.bvn,
    },
    {
      key: '6',
      label: 'Client ID',
      children: (disburseArgs as unknown as ILoanUser)?.clientId,
    },
    {
      key: '7',
      label: 'Date created',
      children: dayjs((disburseArgs as unknown as ILoanUser)?.createdDate).format('DD-MM-YYYY'),
    },
  ];

  return (
    <>
      <Modal
        centered
        open={open}
        title="Create and Disburse Loan"
        onCancel={handleCancel}
        footer={
          <Form form={form}>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit" onClick={onSubmit} style={{ color: 'black' }}>
                {loading ? 'Loading...' : 'Create and Disburse Loan'}
              </Button>
            </Form.Item>
          </Form>
        }
        styles={{ body: { paddingTop: '20px' } }}
      >
        <Descriptions bordered title="User Info" layout="vertical" items={items} className="mb-4" size="small" />
        <Form {...formItemLayout} form={form} name="register" layout="vertical" scrollToFirstError>
          <Row gutter={[16, 16]} justify="space-between">
            <Col xs={24}>
              <Form.Item
                name="amount"
                label="Amount"
                rules={[{ required: true, message: 'Please input amount!', whitespace: true }]}
              >
                <Input type="number" autoFocus />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(CreateAndDisburseLoan);

CreateAndDisburseLoan.displayName = 'CreateAndDisburseLoan';
