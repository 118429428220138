/* eslint-disable */
import * as Redux from 'redux';
import { AuthUser, SignInOutput, fetchAuthSession } from 'aws-amplify/auth';
import { message } from 'antd';
import userService, { IUserValidateOTP } from 'src/services/UserService';
import Utils from 'src/utils';
import * as actionTypes from '../constants';
import authService, { AuthLogIn, AuthReg, IChangePasswordParams, IConfirmNewPassword } from '../../services/AuthService';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from '../../configs/AppConfig';
import { history } from '../store/index';
import VFDLoanService from 'src/services/VFDService';

const SignOutUser = () => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.LOGOUT_PENDING,
  });

  await signOut();
  await localStorage.clear();
  await sessionStorage.clear();
  window.location.href = '/';

  dispatch({
    type: actionTypes.LOGOUT_SUCCESS,
  });
};

export const signOut = () => (dispatch: Redux.Dispatch<any>) => {
  dispatch(SignOutUser());
};

const RegisterUser = (data: AuthReg) => async (dispatch: Redux.Dispatch<any>) => {
  localStorage.removeItem('aton');
  dispatch({
    type: actionTypes.REGISTER_PENDING,
  });

  await userService
    .createANewUser({
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: Utils.convertPhoneToISO(data.phoneNumber),
      registrationType: 'SELF',
    })
    .then((res) => {
      dispatch({
        type: actionTypes.REGISTER_SUCCESS,
        payload: res,
      });
      history.push('/auth/verify');
      window.location.reload();
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.REGISTER_FAILED,
        payload: err,
      });
    });
};

export const registerUser = (data: AuthReg) => (dispatch: Redux.Dispatch<any>) => {
  dispatch(RegisterUser(data));
};

const LoginUser = (data: AuthLogIn) => async (dispatch: Redux.Dispatch<any>) => {
  localStorage.removeItem('aton');
  dispatch({
    type: actionTypes.LOGIN_PENDING,
  });

  await authService
    .loginAccount(data)
    .then(async (cognitoUser: SignInOutput) => {
      const currentSession = await fetchAuthSession();
      const jwtToken = `${currentSession?.tokens?.idToken?.toString()}`;

      try {
        // Get and set VFD loan token
        const response = await VFDLoanService.getLoanToken();
        localStorage.setItem('vfd_token', response.data.data.access_token);
        dispatch({ type: actionTypes.GET_LOAN_TOKEN_SUCCESS, payload: response.data });
      } catch (error) {
      } finally {
        // Proceed with login even if VFD loan token fetch fails
        // TODO: Actual velue of nextStep nested property to determine "NEW_PASSWORD_REQUIRED" flow
        if (cognitoUser.nextStep === ('NEW_PASSWORD_REQUIRED' as any)) {
          localStorage.setItem(actionTypes.AUTH_USER, JSON.stringify({ cognitoUser }));
          history.push(`${AUTH_PREFIX_PATH}/change-password`);
          window.location.reload();
        } else {
          localStorage.setItem(actionTypes.AUTH_TOKEN, jwtToken);

          const user = await userService.findUserByPhoneNumber({
            userId: Utils.convertPhoneToISO(data.email),
          });

          dispatch({
            type: actionTypes.LOGIN_SUCCESS,
            payload: user,
          });

          localStorage.setItem(actionTypes.AUTH_TOKEN_AGG_ID, user.userId);
          localStorage.setItem(actionTypes.AUTH_USER, JSON.stringify({ user, session: currentSession, cognitoUser }));
          window.location.href = Utils.isUserKycSubmitted(user) ? `${APP_PREFIX_PATH}/home` : `${APP_PREFIX_PATH}/onboarding`;
        }
      }
    })
    .catch(async (err: any) => {
      dispatch({
        type: actionTypes.LOGIN_FAILED,
        payload: err,
      });

      localStorage.clear();
      sessionStorage.clear();
    });
};

export const loginUser = (data: AuthLogIn) => (dispatch: Redux.Dispatch<any>) => {
  dispatch(LoginUser(data));
};

const VerifyPhoneOTP = (data: IUserValidateOTP) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.VALIDATE_OTP_PENDING,
  });

  await userService
    .userValidateOTP(data)
    .then((response: any) => {
      sessionStorage.setItem(actionTypes.USER_ID, response.userId);
      dispatch({
        type: actionTypes.VALIDATE_OTP_SUCCESS,
        payload: response,
      });
      localStorage.removeItem('aton');
      message.success('Account successfully created. Check your email for instructions!');
      history.push('/auth/createPassword');
      window.location.reload();
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.VALIDATE_OTP_FAILED,
        payload: err,
      });
    });
};

export const validateOTP = (data: IUserValidateOTP) => (dispatch: Redux.Dispatch<any>) => {
  dispatch(VerifyPhoneOTP(data));
};

export const confirmUpdatePassword = (data: IConfirmNewPassword) => (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.RESET_USER_PASSWORD_PENDING,
  });

  const { newPassword, confirmationCode, username } = data;

  authService
    .confirmResetPassword({ username, newPassword, confirmationCode })
    .then(() => {
      dispatch({
        type: actionTypes.RESET_USER_PASSWORD_SUCCESS,
      });
      message.success('Password Changed, you can now log in with your new password.');
      history.push('/auth/login');
      window.location.reload();
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.RESET_USER_PASSWORD_FAILED,
      });
      message.error(err.message);
    });
};

export const changePassword = (data: IChangePasswordParams) => (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.RESET_USER_PASSWORD_PENDING,
  });

  const { newPassword, oldPassword } = data;

  authService
    .currentUserPoolInfo()
    .then((cognitoUser) => {
      authService
        .changePassword({ newPassword, oldPassword })
        .then(() => {
          dispatch({
            type: actionTypes.RESET_USER_PASSWORD_SUCCESS,
          });
          message.success('Password Changed, you can now log in with your new password.');
          dispatch(signOut());
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.RESET_USER_PASSWORD_FAILED,
          });
          message.error(err.message);
        });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.RESET_USER_PASSWORD_FAILED,
      });
      message.error(err.message);
    });
};
