import React, { createContext, useContext, useEffect, useState } from 'react';
import { Button, TablePaginationConfig, Tabs, DatePicker, Flex } from 'antd';
import { EyeFilled, SendOutlined, UserAddOutlined } from '@ant-design/icons';
import { TabsProps } from 'antd/lib';
import AjoTable from 'src/components/shared-components/AjoTable';
import useLockScreen from 'src/hooks/useLockScreen';
import CreateLoanUser from './createLoanUser';
import { useDispatch } from 'react-redux';
import { getBVNConsent, getLoanPortfolio, getLoansUsersList, releaseLoanAccount } from 'src/redux/actions/loanManagement';
import { useAppSelector } from 'src/redux/store/hooks';
import { selectLoanManagementUserList, selectLoanPortfolio } from 'src/redux/selectors/LoanManagement';
import { ColumnsType } from 'antd/es/table';
import { IBeneficiaryEnquiryPayload, ICreateAndDisburseLoanPayload, ILoanTransaction, ILoanUser } from 'src/services/VFDService';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import CreateAndDisburseLoan from './createAndDisburseLoan';
import ViewLoanDetails from './viewLoanDetails';
import FundTransfer from './fundTransfer';
const { RangePicker } = DatePicker;

const NewUsers: React.FC = () => {
  const dispatch = useDispatch();
  const { loading, users, total } = useAppSelector((store) => selectLoanManagementUserList(store));
  const [releaseArgs, setReleaseArgs] = useState<ILoanUser>();
  const { setDisburseArgs, setDisburseOpen, setOpenTransfer } = useContext(LoanDashboardContext);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
  });

  useEffect(() => {
    dispatch(
      getLoansUsersList({
        entity: 'individual',
        page: (pagination.current as number) - 1, // VFD page starts from 0
        size: pagination.pageSize as number,
      })
    );
  }, []);

  const handlePagination = (arg: TablePaginationConfig) => {
    setPagination((prev) => {
      return {
        ...prev,
        ...arg,
      };
    });

    dispatch(
      getLoansUsersList({
        entity: 'individual',
        page: (arg.current as number) - 1, // VFD page starts from 0
        size: arg.pageSize as number,
      })
    );
  };

  const handleGetConsentClick =
    ({ bvn }: ILoanUser, reference: string) =>
    () => {
      if (bvn) {
        dispatch(getBVNConsent({ bvn, type: '02', reference }));
      }
    };

  const handleReleaseClick = () => {
    const accountNo = releaseArgs?.accountNo;

    if (accountNo) {
      dispatch(releaseLoanAccount({ accountNo }));
    }
  };

  const handleDisburseClick = () => {
    setDisburseOpen(true);
  };

  const handleTransferClick = () => {
    setOpenTransfer(true);
  };

  const [validateRelease] = useLockScreen({
    onValidated: handleReleaseClick,
  });

  const [validateDisburse] = useLockScreen({
    onValidated: handleDisburseClick,
  });

  const [validateTransfer] = useLockScreen({
    onValidated: handleTransferClick,
  });

  const columns: ColumnsType<ILoanUser> = [
    {
      key: 'name',
      title: 'Name',
      render(_, elm) {
        return `${elm.firstName} ${elm.lastName}`;
      },
    },
    {
      key: 'phone',
      title: 'Phone number',
      dataIndex: 'phone',
    },
    {
      key: 'bvn',
      title: 'BVN',
      dataIndex: 'bvn',
    },
    {
      key: 'accountNo',
      title: 'Account number',
      dataIndex: 'accountNo',
    },
    {
      key: 'createdDate',
      title: 'Date created',
      render: (_, elm) => `${dayjs(elm.createdDate).format('DD-MM-YYYY')}`,
    },
    {
      key: 'action',
      title: 'Action',
      render: (_, elm) => {
        const reference = `${elm.phone}-${elm.bvn}-${uuid()}`;

        return (
          <div className="d-flex">
            <Button className="mr-2" type="text" size="small" onClick={handleGetConsentClick(elm, reference)}>
              Get BVN Consent
            </Button>
            <Button
              type="text"
              size="small"
              className="mr-2"
              onClick={validateRelease}
              onMouseEnter={() => {
                setReleaseArgs(elm);
              }}
            >
              Release
            </Button>
            <Button
              size="small"
              type="primary"
              className="mr-2"
              onClick={validateDisburse}
              onMouseEnter={() => {
                setDisburseArgs(elm as any);
              }}
            >
              Disburse Loan
            </Button>
            <Button
              size="small"
              type="text"
              onClick={validateTransfer}
              onMouseEnter={() => {
                setDisburseArgs(elm as any);
              }}
            >
              <SendOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <AjoTable
      scroll={{
        y: 'calc(100vh - 28rem)',
        x: '100%',
      }}
      tableLayout="auto"
      columns={columns}
      loading={loading}
      dataSource={users}
      onChange={handlePagination}
      pagination={{ ...pagination, total }}
    />
  );
};

const LoanPortfolio: React.FC = () => {
  const dispatch = useDispatch();
  const { loading, loanPortfolio, total } = useAppSelector((store) => selectLoanPortfolio(store));
  const [open, setOpen] = useState<boolean>();
  const [loanData, setLoanData] = useState<ILoanTransaction>();
  const [dateRange, setDateRange] = useState<Array<string>>(['2018-01-01 00:00:00', dayjs().format('YYYY-MM-DD HH:mm:ss')]);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
  });

  useEffect(() => {
    dispatch(
      getLoanPortfolio({
        startDate: dateRange[0],
        endDate: dateRange[1],
        size: pagination.pageSize,
        page: (pagination.current as number) - 1,
        filterBy: 'status',
        search: '00',
      })
    );
  }, []);

  const handlePagination = (arg: TablePaginationConfig) => {
    setPagination((prev) => {
      return {
        ...prev,
        ...arg,
      };
    });

    dispatch(
      getLoanPortfolio({
        page: (arg.current as number) - 1, // VFD page starts from 0
        size: arg.pageSize as number,
        startDate: dateRange[0],
        endDate: dateRange[1],
        filterBy: 'status',
        search: '00',
      })
    );
  };

  const handleSetViewArgs = (loanData: ILoanTransaction) => () => {
    if (loanData) {
      setLoanData(loanData);
    }
  };

  const handleViewLoan = () => {
    setOpen(true);
  };

  const columns: ColumnsType<ILoanTransaction> = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'clientName',
    },
    {
      key: 'accountNo',
      title: 'Account number',
      dataIndex: 'accountNo',
    },
    {
      key: 'disburseDate',
      title: 'Disburse Date',
      dataIndex: 'disburseDate',
    },
    {
      key: 'statusDescription',
      title: 'Status Description',
      dataIndex: 'statusDescription',
    },
    {
      key: 'interestFrequency',
      title: 'Interest Frequency',
      dataIndex: 'interestFrequency',
    },
    {
      key: 'view',
      title: 'View',
      render: (_, elm) => {
        return (
          <Button className="mr-2" type="primary" size="small" onMouseEnter={handleSetViewArgs(elm)} onClick={handleViewLoan}>
            <EyeFilled />
          </Button>
        );
      },
    },
  ];

  const handleDateRange = (dates: unknown, dateStrings: [string, string]) => {
    setDateRange([`${dateStrings[0]} 00:00:00`, `${dateStrings[1]} 23:59:59`]);

    dispatch(
      getLoanPortfolio({
        startDate: `${dateStrings[0] || '2018-01-01'} 00:00:00`, // Fallback if date is cleared
        endDate: `${dateStrings[1] || dayjs().format('YYYY-MM-DD')} 23:59:59`, // Fallback if date is cleared
        size: pagination.pageSize,
        page: (pagination.current as number) - 1,
        filterBy: 'status',
        search: '00',
      })
    );
  };

  return (
    <>
      <Flex justify="flex-end">
        <RangePicker onChange={handleDateRange} />
      </Flex>
      <AjoTable
        scroll={{
          y: 'calc(100vh - 32rem)',
          x: '100%',
        }}
        tableLayout="auto"
        columns={columns}
        loading={loading}
        dataSource={loanPortfolio}
        onChange={handlePagination}
        pagination={{ ...pagination, total }}
      />
      <ViewLoanDetails
        accountNumber={loanData?.accountNo as string}
        identification={loanData?.transactionId as string}
        setOpen={setOpen as any}
        open={open as boolean}
      />
    </>
  );
};

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Users',
    children: <NewUsers />,
  },
  {
    key: '2',
    label: 'Loan Portfolio',
    children: <LoanPortfolio />,
  },
];

export const LoanDashboardContext = createContext<{
  disburseOpen: boolean;
  openTransfer: boolean;
  setOpenTransfer: React.Dispatch<React.SetStateAction<boolean>>;
  setDisburseOpen: React.Dispatch<React.SetStateAction<boolean>>;
  disburseArgs: ICreateAndDisburseLoanPayload & IBeneficiaryEnquiryPayload;
  setDisburseArgs: React.Dispatch<React.SetStateAction<ICreateAndDisburseLoanPayload & IBeneficiaryEnquiryPayload>>;
}>({} as any);

const LoanUsers: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [openTransfer, setOpenTransfer] = useState<boolean>(false);
  const [disburseOpen, setDisburseOpen] = useState<boolean>(false);
  const [disburseArgs, setDisburseArgs] = useState<ICreateAndDisburseLoanPayload & IBeneficiaryEnquiryPayload>({} as any);

  const onValidated = () => {
    handleOpenModal();
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const [validateWithLockScreen] = useLockScreen({ onValidated });

  return (
    <LoanDashboardContext.Provider value={{ disburseArgs, setDisburseArgs, disburseOpen, setDisburseOpen, openTransfer, setOpenTransfer }}>
      <Tabs
        tabBarExtraContent={
          <Button onClick={validateWithLockScreen} icon={<UserAddOutlined />}>
            Create New User
          </Button>
        }
        items={items}
      />
      <CreateLoanUser open={open} setOpen={setOpen} />
      <CreateAndDisburseLoan open={disburseOpen} setOpen={setDisburseOpen} />
      <FundTransfer open={openTransfer} setOpen={setOpenTransfer} />
    </LoanDashboardContext.Provider>
  );
};

export default LoanUsers;
