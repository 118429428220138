/* eslint-disable */
import React from 'react';
import { Form, Button, Card, Input, Row, Col } from 'antd';
import { IChangePasswordParams } from 'src/services/AuthService';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from 'src/redux/actions';
import { selectChangePassword } from 'src/redux/selectors/AuthSelector';
import { IStore } from 'src/redux/store';
import useCurrentUser from 'src/hooks/useCurrentUser';
import useLastUrl from 'src/hooks/useLastUrl';
import validatePassword, { passwordCriteria } from 'src/utils/validatePassword';
import PasswordChecklist from 'src/components/util-components/PasswordValidationChecklist';
import usePasswordValidator from 'src/hooks/usePasswordValidator';

const ChangePassword: React.FC = (props: any) => {
  const [form] = Form.useForm<IChangePasswordParams>();

  const dispatch = useDispatch();

  const changePasswordStore = useSelector((store: IStore) => selectChangePassword(store));

  const { loading } = changePasswordStore;

  const {
    user: { phoneNumber },
  } = useCurrentUser();

  const onFinish = () => {
    form.validateFields().then((values: Omit<IChangePasswordParams, 'phoneNumber'>) => {
      dispatch(
        changePassword({
          newPassword: values.newPassword,
          oldPassword: values.oldPassword,
        })
      );
    });
  };

  useLastUrl({ privateRoute: true });

  const { isChecked, setValue } = usePasswordValidator(
    form.getFieldValue('password'),
    passwordCriteria.minLength
  );

  const handlePasswordChange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <Row gutter={12} className="mt-4">
      <Col xs={24} md={12}>
        <Card title="Change Password">
          <Form
            form={form}
            layout="vertical"
            name="change-password"
            onFinish={onFinish}
            scrollToFirstError
            autoComplete="off"
          >
            <Form.Item
              hasFeedback
              name="oldPassword"
              label="Old Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password autoComplete="off" />
            </Form.Item>
            <Form.Item
              hasFeedback
              name="password"
              label="New Password"
              rules={[{ required: true }, validatePassword()]}
            >
              <Input.Password onChange={handlePasswordChange} autoComplete="off" />
            </Form.Item>
            <div className="mb-4">
              <PasswordChecklist isChecked={isChecked} />
            </div>
            <Form.Item
              name="newPassword"
              label="Confirm New Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error('The two passwords that you entered do not match!')
                    );
                  },
                }),
              ]}
            >
              <Input.Password autoComplete="off" />
            </Form.Item>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default ChangePassword;
