/* eslint-disable */
import { Button, Checkbox, Descriptions, Modal, ModalProps, Radio, message } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SNBL_PATH } from 'src/configs/AppConfig';
import useAppSearchParams from 'src/hooks/useAppSearchParams';
import useFetch from 'src/hooks/useFetch';
import { createProvidusAccount } from 'src/redux/actions/Wallet';
import { selectProvidus } from 'src/redux/selectors/WalletSelector';
import { useAppSelector } from 'src/redux/store/hooks';
import { IEnhancedKYCSyncResponse } from 'src/services/Types';
import { CreateUser } from 'src/services/UserService';
import { setIsSnblIdentified } from 'src/utils/isSnblIdentified';
import TermsAndConditionsModal from './terms-and-conditions';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface VerifyIdentityModalProps {
  userData: IEnhancedKYCSyncResponse;
  modalProps: ModalProps;
}

const VerifyIdentityModal: React.FC<VerifyIdentityModalProps> = ({ userData, modalProps }) => {
  const data = userData?.FullData;
  const navigate = useNavigate();
  const { searchParamsString, getSearchParam, addSearchParam } = useAppSearchParams();
  const target = getSearchParam('target');
  const [createUserTrigger, setCreateUserTrigger] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const accountCreation = useAppSelector((store) => selectProvidus(store.wallet)).accountCreation;

  React.useEffect(() => {}, [modalProps?.visible]);

  const {
    isLoading: createUserLoading,
    response: createUserResponse,
    reset: createUserReset,
    error,
  } = useFetch<CreateUser>({
    method: 'POST',
    data: {
      registrationType: 'SNBL',
      firstName: data?.FIRST_NAME,
      lastName: data?.LAST_NAME,
      aggregatorId: '',
      description: '',
      displayName: data?.FIRST_NAME,
      dob: data?.DATE_OF_BIRTH,
      otherNames: '',
      phoneNumber: data?.MSISDN,
    },
    skip: createUserTrigger,
    url: '/users/v1/user',
  });

  const handleOk = () => {
    setIsSnblIdentified(userData);
    setCreateUserTrigger(true);
  };

  React.useEffect(() => {
    if (!!error) {
      setCreateUserTrigger(false);
      message.error(error);
    }
  }, [error]);

  React.useEffect(() => {
    if (createUserResponse && !error) {
      message.success('Account successfully created');

      // Create Providus Account on successful AjoCard account creation
      dispatch(
        createProvidusAccount({
          customerName: `${data.FIRST_NAME} ${data.LAST_NAME}`,
          customerEmail: createUserResponse?.email,
          ajocardWalletId: createUserResponse?.WalletId,
          phone: data.MSISDN,
        })
      );
    }

    return () => {
      createUserReset();
    };
  }, [createUserResponse, error, target]);

  React.useEffect(() => {
    if (!!accountCreation?.data) {
      const { customerName, accountNumber, bankName } = accountCreation?.data;

      addSearchParam('accountName', accountNumber);
      addSearchParam('customerName', customerName);
      addSearchParam('bankName', bankName);

      navigate(`${SNBL_PATH}/account-creation-successful?${searchParamsString}`);
    }
  }, [accountCreation?.data]);

  const [visibleTAndC, setVisibleTAndC] = React.useState<boolean>(false);
  const [proceed, setProceed] = React.useState<boolean>(false);

  const handleTAndCsClose = () => {
    setVisibleTAndC(false);
  };
  const handleTAndCsVisible = () => {
    setVisibleTAndC(true);
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setProceed(e.target.checked);
  };

  return (
    <Modal
      {...modalProps}
      cancelText="No"
      onOk={handleOk}
      destroyOnClose
      footer={
        !proceed
          ? null
          : [
              <Button key="no" type="default" onClick={modalProps.onCancel}>
                No
              </Button>,
              <Button key="yes" type="primary" onClick={handleOk}>
                Yes
              </Button>,
            ]
      }
      confirmLoading={createUserLoading}
      title="Are these details correct?"
      okText={createUserLoading ? 'Creating account' : 'Yes'}
    >
      <Descriptions size="small">
        <Descriptions.Item label="First name">{data?.FIRST_NAME}</Descriptions.Item>
        <Descriptions.Item label="Last name">{data?.LAST_NAME}</Descriptions.Item>
        <Descriptions.Item label="Phone">{data?.MSISDN}</Descriptions.Item>
        <Descriptions.Item label="DOB">{data?.DATE_OF_BIRTH}</Descriptions.Item>
        <Descriptions.Item label="Gender">{data?.GENDER} </Descriptions.Item>
      </Descriptions>
      <div className="d-flex align-items-center">
        <Checkbox onChange={onChange} style={{ marginTop: 10 }} />
        <span style={{ marginRight: 4 }}>Agree to</span>
        <a onClick={handleTAndCsVisible}> terms and conditions</a>
      </div>
      <TermsAndConditionsModal visible={visibleTAndC} onClose={handleTAndCsClose} />
    </Modal>
  );
};

export default VerifyIdentityModal;
