/* eslint-disable */
import {
  InsuranceOutlined,
  SaveOutlined,
  ApartmentOutlined,
  FundProjectionScreenOutlined,
  WalletOutlined,
  AppstoreOutlined,
  BoxPlotOutlined,
  PartitionOutlined,
  SolutionOutlined,
  ContainerOutlined,
  UsergroupAddOutlined,
  ToolOutlined,
  FileProtectOutlined,
  AreaChartOutlined,
  ClusterOutlined,
} from '@ant-design/icons';
import { lazy } from 'react';
import home from '../views/app-views/home';
import { APP_PREFIX_PATH } from './AppConfig';
import LoanManagementDashboard from '../views/app-views/products/lending/loan-management-dashboard';

const dashBoardNavTree = [{
  key: 'home',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'Home',
  component: home,
  icon: AppstoreOutlined,
  breadcrumb: false,
  submenu: [],
  users: 'all,:agent,:admin,:aggregator',
},
{
  key: 'loan-management-dashboard',
  path: `${APP_PREFIX_PATH}/loan-management-dashboard`,
  title: 'Loan Management Dashboard',
  component: LoanManagementDashboard,
  icon: ApartmentOutlined,
  breadcrumb: false,
  submenu: [],
  users: ':admin,:lendinglead',
},
{
  key: 'partners',
  path: `${APP_PREFIX_PATH}/partners`,
  component: lazy(() => import('../views/app-views/partners')),
  title: 'Partners',
  icon: PartitionOutlined,
  breadcrumb: false,
  submenu: [],
  users: ':admin',
},
{
  key: 'users',
  path: `${APP_PREFIX_PATH}/users`,
  component: lazy(() => import('../views/app-views/users')),
  title: 'Users',
  icon: SolutionOutlined,
  breadcrumb: false,
  submenu: [],
  users: ':admin',
},
{
  key: 'agents-performance',
  path: `${APP_PREFIX_PATH}/agents-performance`,
  component: lazy(() => import('../views/app-views/agents/AgentsPerformance')),
  title: 'Agents Performance',
  icon: AreaChartOutlined,
  breadcrumb: false,
  submenu: [],
  users: ':aggregator,:admin',
}, {
  key: 'wallet',
  path: `${APP_PREFIX_PATH}/wallet`,
  component: lazy(() => import('../views/app-views/wallet')),
  title: 'My Wallet',
  icon: WalletOutlined,
  breadcrumb: false,
  submenu: [],
  users: 'all,:agent,:admin,:aggregator',
}, {
  key: 'admin-tools',
  path: `${APP_PREFIX_PATH}/admin-tools`,
  component: lazy(() => import('../views/app-views/admin-tools')),
  title: 'Admin Tools',
  icon: ClusterOutlined,
  breadcrumb: false,
  submenu: [],
  users: ':admin,',
}, {
  key: 'products',
  path: null,
  component: undefined,
  title: 'Products',
  icon: BoxPlotOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'savings',
      path: `${APP_PREFIX_PATH}/products/savings`,
      component: lazy(() => import('../views/app-views/products/Savings')),
      title: 'Savings',
      icon: SaveOutlined,
      breadcrumb: false,
      users: ':admin,:savingsops',
    },
    {
      key: 'lending',
      path: `${APP_PREFIX_PATH}/products/lending`,
      component: lazy(() => import('../views/app-views/products/lending/')),
      title: 'Lending',
      icon: ApartmentOutlined,
      breadcrumb: false,
      submenu: [],
      users: ':admin,:lendingops',
    },
    {
      key: 'insurance',
      path: `${APP_PREFIX_PATH}/products/insurance`,
      component: lazy(() => import('../views/app-views/products/Insurance')),
      title: 'Insurance',
      icon: InsuranceOutlined,
      breadcrumb: false,
      users: ':admin,:insuranceops',
    },
  ],
  users: ':admin,:savingsops,:lendingops,:insuranceops',
},
{
  key: 'reports',
  path: null,
  component: undefined,
  title: 'Reports',
  icon: FundProjectionScreenOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'savings-reports',
      path: `${APP_PREFIX_PATH}/reports/savings-reports`,
      component: lazy(() => import('../views/app-views/reports/SavingsSummary')),
      title: 'Savings',
      icon: SaveOutlined,
      breadcrumb: false,
      submenu: [],
      users: ':admin,:savingsops',
    },
    {
      key: 'lending-reports',
      path: `${APP_PREFIX_PATH}/reports/lending-reports`,
      component: lazy(() => import('../views/app-views/products/Lending')),
      title: 'Lending',
      icon: ApartmentOutlined,
      breadcrumb: false,
      users: ':admin,:lendingops',
    },
    {
      key: 'insurance-reports',
      path: `${APP_PREFIX_PATH}/reports/insurance-reports`,
      component: lazy(() => import('../views/app-views/products/Insurance')),
      title: 'Insurance',
      icon: InsuranceOutlined,
      breadcrumb: false,
      users: ':admin,:insuranceops',
    },
  ],
  users: ':admin,:savingsops,:lendingops,:insuranceops',
}, {
  key: 'buy-mpos',
  path: `${APP_PREFIX_PATH}/buy-mpos`,
  component: lazy(() => import('../views/app-views/buy-mpos')),
  title: 'Buy Mpos',
  icon: ContainerOutlined,
  breadcrumb: false,
  submenu: [],
  users: ':agent,:aggregator',
}, {
  key: 'agent-onboarding',
  path: `${APP_PREFIX_PATH}/agent-onboarding`,
  component: lazy(() => import('../views/app-views/onboarding/AggregatorAgentOnboarding')),
  title: 'Agent Onboarding',
  icon: UsergroupAddOutlined,
  breadcrumb: false,
  submenu: [],
  users: ':aggregator',
},
{
  key: 'mandate-card',
  path: `${APP_PREFIX_PATH}/mandate-card`,
  component: lazy(() => import('../views/app-views/MandateCard')),
  title: 'Mandate Card',
  icon: UsergroupAddOutlined,
  breadcrumb: false,
  submenu: [],
  users: ':agent',
},
{
  key: 'aml',
  path: null,
  path: `${APP_PREFIX_PATH}/tools`,
  component: undefined,
  title: 'AML',
  icon: ToolOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'aml-setup',
      path: `${APP_PREFIX_PATH}/aml/setup`,
      component: lazy(() => import('../views/app-views/aml')),
      title: 'AML Setup',
      icon: FileProtectOutlined,
      breadcrumb: false,
      users: ':admin',
    },
    {
      key: 'bvn-blacklist',
      path: `${APP_PREFIX_PATH}/aml/blacklist`,
      component: lazy(() => import('../views/app-views/aml/blacklist')),
      title: 'BVN Blacklist',
      icon: FileProtectOutlined,
      breadcrumb: false,
      users: ':admin',
    },
    {
      key: 'set-wallet-status',
      path: `${APP_PREFIX_PATH}/aml/set-wallet-status`,
      component: lazy(() => import('../views/app-views/aml/SetWalletStatus')),
      title: 'Set Wallet Status',
      icon: FileProtectOutlined,
      breadcrumb: false,
      users: ':admin',
    },
    {
      key: 'statement',
      title: 'Wallet Statement',
      path: `${APP_PREFIX_PATH}/aml/wallet-statement`,
      component: lazy(() => import('../views/app-views/aml/WalletStatement')),
      exact: true,
      icon: WalletOutlined,
      users: ':admin',
      isUserKycSubmitted: true,
    },
    {
      key: 'kyc-reporting',
      title: 'KYC Reporting',
      path: `${APP_PREFIX_PATH}/aml/kyc-reporting`,
      component: lazy(() => import('../views/app-views/aml/KycReporting')),
      exact: true,
      icon: FundProjectionScreenOutlined,
      users: ':admin',
      isUserKycSubmitted: true,
    },
    {
      key: 'user-review',
      path: `${APP_PREFIX_PATH}/kyc/user-review`,
      component: lazy(() => import('../views/app-views/user-review')),
      title: 'User KYC Review',
      icon: PartitionOutlined,
      breadcrumb: false,
      submenu: [],
      users: ':admin, :kycreviewer, :kycapprover',
    }
  ],
  users: ':admin',
}
];

const Routes = [{
  key: 'home',
  path: `${APP_PREFIX_PATH}/home`,
  component: lazy(() => import('../views/app-views/home')),
  exact: true,
  users: 'all,:agent,:admin,:aggregator',
  isUserKycSubmitted: true,
},
{
  key: 'loan-management-dashboard',
  path: `${APP_PREFIX_PATH}/loan-management-dashboard`,
  component: lazy(() => import('../views/app-views/products/lending/loan-management-dashboard')),
  exact: true,
  users: ':admin,:lendinglead',
  isUserKycSubmitted: true,
},
{
  key: 'partners',
  path: `${APP_PREFIX_PATH}/partners`,
  component: lazy(() => import('../views/app-views/partners')),
  exact: true,
  users: ':admin',
  isUserKycSubmitted: true,
},
{
  key: 'user-review',
  path: `${APP_PREFIX_PATH}/kyc/user-review`,
  component: lazy(() => import('../views/app-views/user-review')),
  isUserKycSubmitted: true,
  exact: true,
  users: ':admin, :kycreviewer, :kycapprover',
},
{
  key: 'agent-profile',
  path: `${APP_PREFIX_PATH}/users/:id`,
  component: lazy(() => import('../views/app-views/agents/profile')),
  exact: true,
  users: ':agent,:admin,:aggregator',
  isUserKycSubmitted: true,
}, {
  key: 'users',
  path: `${APP_PREFIX_PATH}/users`,
  component: lazy(() => import('../views/app-views/users')),
  exact: true,
  users: ':admin',
  isUserKycSubmitted: true,
},

{
  key: 'mandate-card',
  path: `${APP_PREFIX_PATH}/mandate-card`,
  component: lazy(() => import('../views/app-views/MandateCard')),
  exact: true,
  users: ':agent',
  isUserKycSubmitted: true,
},

{
  key: 'agents-performance',
  path: `${APP_PREFIX_PATH}/agents-performance`,
  component: lazy(() => import('../views/app-views/agents/AgentsPerformance')),
  exact: true,
  users: ':admin,:aggregator',
  isUserKycSubmitted: true,
}, {
  key: 'admin-tools',
  path: `${APP_PREFIX_PATH}/admin-tools`,
  component: lazy(() => import('../views/app-views/admin-tools')),
  exact: false,
  users: ':admin,',
  isUserKycSubmitted: true,
}, {
  key: 'wallet',
  path: `${APP_PREFIX_PATH}/wallet`,
  component: lazy(() => import('../views/app-views/wallet')),
  exact: true,
  users: 'all,:agent,:admin,:aggregator',
  isUserKycSubmitted: true,
}, {
  key: 'buy-mpos',
  path: `${APP_PREFIX_PATH}/buy-mpos`,
  component: lazy(() => import('../views/app-views/buy-mpos')),
  exact: true,
  users: '',
  isUserKycSubmitted: true,
}, {
  key: 'agent-onboarding',
  path: `${APP_PREFIX_PATH}/onboarding`,
  component: lazy(() => import('../views/app-views/onboarding')),
  exact: true,
  users: ':all,:admin,:agent,:aggregator',
  isUserKycSubmitted: false,
}, {
  key: 'agent-home',
  path: `${APP_PREFIX_PATH}/home`,
  component: lazy(() => import('../views/app-views/home')),
  exact: true,
  users: ':agent,:admin,:aggregator',
  isUserKycSubmitted: true,
}, {
  key: 'user-profile',
  path: `${APP_PREFIX_PATH}/profile`,
  component: lazy(() => import('../views/app-views/profile')),
  exact: true,
  users: ':agent,:admin,:aggregator',
  isUserKycSubmitted: true,
},
{
  key: 'change-password',
  path: `${APP_PREFIX_PATH}/change-password`,
  component: lazy(() => import('../views/app-views/change-password')),
  exact: true,
  users: ':agent,:admin,:aggregator',
  isUserKycSubmitted: true,
},
{
  key: 'agent-onboarding',
  path: `${APP_PREFIX_PATH}/agent-onboarding`,
  component: lazy(() => import('../views/app-views/onboarding/AggregatorAgentOnboarding')),
  exact: true,
  users: ':aggregator',
  isUserKycSubmitted: true,
}, {
  key: 'agent-transaction-details',
  path: `${APP_PREFIX_PATH}/agent-transaction-details`,
  component: lazy(() => import('../views/app-views/agents/transaction')),
  exact: true,
  users: ':aggregator',
  isUserKycSubmitted: true,
},
{
  key: 'savings',
  path: `${APP_PREFIX_PATH}/products/savings`,
  component: lazy(() => import('../views/app-views/products/Savings')),
  exact: true,
  users: ':admin,:savingsops',
  isUserKycSubmitted: true,
},
{
  key: 'lending',
  path: `${APP_PREFIX_PATH}/products/lending`,
  component: lazy(() => import('../views/app-views/products/lending/')),
  exact: true,
  users: ':admin,:lendingops',
  isUserKycSubmitted: true,
},
{
  key: 'insurance',
  path: `${APP_PREFIX_PATH}/products/insurance`,
  component: lazy(() => import('../views/app-views/products/Insurance')),
  exact: true,
  users: ':admin,:insuranceops',
  isUserKycSubmitted: true,
},
{
  key: 'savings-reports',
  path: `${APP_PREFIX_PATH}/reports/savings-reports`,
  component: lazy(() => import('../views/app-views/reports/SavingsSummary')),
  exact: true,
  users: ':admin,:savingsops',
  isUserKycSubmitted: true,
},
{
  key: 'lending-reports',
  path: `${APP_PREFIX_PATH}/reports/lending-reports`,
  component: lazy(() => import('../views/app-views/reports/LendingReports')),
  exact: true,
  users: ':admin,:lendingops',
  isUserKycSubmitted: true,
},
{
  key: 'loanrequests',
  path: `${APP_PREFIX_PATH}/products/lending/loan-requests`,
  component: lazy(() => import('../views/app-views/products/lending/loanRequests')),
  exact: true,
  isUserKycSubmitted: true,
  users: ':admin,:lendingops',
},
{
  key: 'pbrequests',
  path: `${APP_PREFIX_PATH}/products/lending/pb-requests`,
  component: lazy(() => import('../views/app-views/products/lending/pbRequests')),
  users: ':admin,:lendingops,',
  exact: true,
  isUserKycSubmitted: true,
},
{
  key: 'npl',
  path: `${APP_PREFIX_PATH}/products/lending/npl`,
  component: lazy(() => import('../views/app-views/products/lending/npl')),
  users: ':admin,:lendingops,',
  exact: true,
  isUserKycSubmitted: true,
},
{
  key: 'loanhistory',
  path: `${APP_PREFIX_PATH}/products/lending/loan-history/:id`,
  component: lazy(() => import('../views/app-views/products/lending/loanHistory')),
  users: ':admin,:lendingops',
  exact: true,
  isUserKycSubmitted: true,
},
{
  key: 'customerprofile',
  path: `${APP_PREFIX_PATH}/products/lending/customer/:id`,
  component: lazy(() => import('../views/app-views/products/lending/customerProfile')),
  users: ':admin,:lendingops',
  exact: true,
  isUserKycSubmitted: true,
},
{
  key: 'insurance-reports',
  path: `${APP_PREFIX_PATH}/reports/insurance-reports`,
  component: lazy(() => import('../views/app-views/reports/InsuranceReports')),
  exact: true,
  users: ':admin,:insuranceops',
  isUserKycSubmitted: true,
},
{
  key: 'savings-summary',
  path: `${APP_PREFIX_PATH}/reports/savings-reports/savings-summary`,
  component: lazy(() => import('../views/app-views/reports/SavingsSummary')),
  exact: true,
  users: ':admin,:savingsops',
  isUserKycSubmitted: true,
},
{
  key: 'statement',
  path: `${APP_PREFIX_PATH}/aml/wallet-statement`,
  component: lazy(() => import('../views/app-views/aml/WalletStatement')),
  exact: true,
  users: ':admin',
  isUserKycSubmitted: true,
},
{
  key: 'kyc-reporting',
  title: 'KYC Reporting',
  path: `${APP_PREFIX_PATH}/aml/kyc-reporting`,
  component: lazy(() => import('../views/app-views/aml/KycReporting')),
  exact: true,
  icon: ToolOutlined,
  users: ':admin',
  isUserKycSubmitted: true,
},
{
  key: 'mine-kyc',
  path: `${APP_PREFIX_PATH}/kyc-mining`,
  component: lazy(() => import('../views/app-views/tools/kycMine')),
  exact: true,
  users: ':admin',
  isUserKycSubmitted: true,
},
{
  key: 'aml',
  path: `${APP_PREFIX_PATH}/aml/setup`,
  component: lazy(() => import('../views/app-views/aml')),
  exact: true,
  users: ':admin',
  isUserKycSubmitted: true,
},
{
  key: 'set-wallet-status',
  path: `${APP_PREFIX_PATH}/aml/set-wallet-status`,
  component: lazy(() => import('../views/app-views/aml/SetWalletStatus')),
  exact: true,
  users: ':admin',
  isUserKycSubmitted: true,
},
{
  key: 'bvn-blacklist',
  path: `${APP_PREFIX_PATH}/aml/blacklist`,
  component: lazy(() => import('../views/app-views/aml/blacklist')),
  title: 'BVN Blacklist',
  icon: FileProtectOutlined,
  breadcrumb: false,
  users: ':admin',
  exact: true,
  isUserKycSubmitted: true,
},
];

export {
  Routes,
};

const navigationConfig = [
  ...dashBoardNavTree,
];

export default navigationConfig;
