/* eslint-disable */
import React from 'react';
import { Button, Form, Input } from 'antd';
import { Container } from '@material-ui/core';
// TODO: Redundant for now
// import history from 'src/redux/history';
// import Validation from 'src/utils/validation';
import { SNBL_PATH } from 'src/configs/AppConfig';
import useAppSearchParams from 'src/hooks/useAppSearchParams';
import useLastUrl from 'src/hooks/useLastUrl';
import { useNavigate } from 'react-router-dom';

interface SavingsSetupProps {
  target: string;
}

export const SNBLSavingsSetup: React.FC<SavingsSetupProps> = ({ target }) => {
  const [form] = Form.useForm<any>();
  const navigate = useNavigate();
  const { getSearchParam, searchParamsString } = useAppSearchParams();

  const savingsTarget = getSearchParam('target');
  const currentUserData = JSON.parse(sessionStorage.getItem('current-user') || '');

  React.useEffect(() => {
    if (!!target) {
      navigate(target);
    }
  }, [target]);

  const onSubmit = () => {
    navigate(`${SNBL_PATH}?${searchParamsString}`);
  };

  useLastUrl({ noAuthFeature: true });

  return (
    <Container maxWidth="sm" style={{ marginTop: 80 }}>
      <Form form={form} layout="vertical" name="snpl-form" onFinish={onSubmit}>
        <Form.Item
          hasFeedback
          name="targetAmount"
          label="Target Amount"
          // rules={Validation.antFormRules.phoneNumber}
        >
          <Input placeholder="Enter Target Amount" defaultValue={savingsTarget || ''} />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="startingAmount"
          label="Starting Amount"
          // rules={Validation.antFormRules.phoneNumber}
        >
          <Input placeholder="Enter Starting Amount" />
        </Form.Item>
        <Button htmlType="submit">Submit</Button>
      </Form>
    </Container>
  );
};

export default SNBLSavingsSetup;
