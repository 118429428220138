import React from 'react';
import { Spin, SpinProps } from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';

const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />;

interface ILoadingProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  align?: string;
  cover?: string;
  iconProps?: SpinProps;
}

const Loading: React.FC<ILoadingProps> = (props) => {
  const { align = 'center', cover = 'inline', iconProps } = props;
  return (
    <div
      className={`loading text-${align} cover-${cover} ${
        !cover && 'position-absolute'
      } w-100 h-100 z-1000`}
      {...props}
    >
      {/* @ts-ignore */}
      <Spin indicator={Icon} className="absolute-centered" {...iconProps} />
    </div>
  );
};

export default Loading;
