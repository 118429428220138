/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LogoBlack } from 'src/assets/svg/logo';
import { NAV_TYPE_TOP } from '../../constants/ThemeConstant';
import utils from '../../utils';
import MenuContent from './MenuContent';
import NavPanel from './NavPanel';

export const TopNav: React.FC<{
  user?: any;
  topNavColor: any;
  localization?: boolean;
  routeInfo?: any;
}> = ({ topNavColor, localization = true, user }) => {
  const props = { topNavColor, localization };

  return (
    <div
      className={`top-nav ${utils.getColorContrast(
        topNavColor
      )} w-100 align-center d-flex px-2`}
      style={{ backgroundColor: topNavColor }}
    >
      <div className="top-nav-wrapper d-flex justify-content-between align-center w-100">
        <div className="d-flex w-100 align-center">
          <Link to="/app/home">
            <LogoBlack cursor="pointer" />
          </Link>
          <div style={{ width: '90%' }}>
            <MenuContent user={user} type={NAV_TYPE_TOP} {...props} />
          </div>
        </div>
        <NavPanel />
      </div>
    </div>
  );
};

const mapStateToProps = ({ theme }: { theme: any }) => {
  const { topNavColor } = theme;
  return { topNavColor };
};

export default React.memo(connect(mapStateToProps)(TopNav));
