import React from 'react';
import { Checkbox } from 'antd';

const PasswordChecklist = ({ isChecked }: { isChecked: Array<boolean> }) => {
  return (
    <>
      <Checkbox checked={isChecked[0]}>Uppercase Letter</Checkbox>
      <Checkbox checked={isChecked[1]}>Lowercase Letter</Checkbox>
      <Checkbox checked={isChecked[2]}>Number</Checkbox>
      <Checkbox checked={isChecked[3]}>Minimum 8 Characters</Checkbox>
      <Checkbox checked={isChecked[4]}>Special Character</Checkbox>
    </>
  );
};

export default React.memo(PasswordChecklist);
