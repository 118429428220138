/* eslint-disable */
import React from 'react';
import { CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Col, message, Row, Tag, Typography } from 'antd';
import { IBankAccountDetailsPayload } from 'src/redux/reducers/user';
import { THEME_CONFIG } from '../../../configs/AppConfig';

const AccountNumber = (props: IBankAccountDetailsPayload) => {
  const { accountName, accountNumber, bankName, loading } = props;

  const handleCopy = () => {
    try {
      if (accountNumber && accountNumber !== '') {
        navigator.clipboard.writeText(accountNumber);
        message.success('Account number copied to clipboard!');
      }
    } catch (err) {
      message.error('Failed to copy');
    }
  };

  return (
    <Card
      headStyle={{
        fontSize: '14px',
        fontWeight: 'lighter',
        margin: 0,
        paddingBottom: 0,
      }}
      loading={loading}
      title="Account Details"
      className="relative"
      bodyStyle={{ paddingTop: 0 }}
      style={{ height: '109.9px' }}
    >
      <CopyOutlined
        onClick={handleCopy}
        className="position-absolute"
        style={{ right: '1rem', top: '1rem', fontSize: '24px' }}
      />
      <div
        className="position-absolute"
        style={{
          right: '2.3rem',
          top: '1rem',
          fontSize: '12px',
          width: 'max-content',
        }}
      >
        {accountName && (
          <Row gutter={[12, 12]}>
            <Col xs={24} className="d-flex justify-content-end">
              <Tag className="align-self-end" color="black">
                {accountName}
              </Tag>
            </Col>
            <Col xs={24} className={`d-flex justify-content-end`}>
              <Tag className="align-self-end" color={THEME_CONFIG.primary}>
                {bankName}
              </Tag>
            </Col>
          </Row>
        )}
      </div>
      {!accountNumber ? (
        <Button
          type="primary"
          size="small"
          className="w-100"
          icon={<DownloadOutlined />}
          onClick={() =>
            window.open(
              'https://play.google.com/store/apps/details?id=com.ajocard.app.prod',
              '_blank'
            )
          }
        >
          Download mobile app to create a wallet
        </Button>
      ) : (
        accountNumber !== '' && <Typography.Title className="m-0">{accountNumber}</Typography.Title>
      )}
    </Card>
  );
};

export default React.memo(AccountNumber);
