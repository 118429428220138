import React, { memo, useContext, useEffect } from 'react';
import { Modal, Button, Col, Form, Input, Row, message, Descriptions, DescriptionsProps } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import { getLoanUserLog, vfdTransferFunds } from 'src/redux/actions/loanManagement';
import { selectFundTransfer, selectUserLog } from 'src/redux/selectors/LoanManagement';
import { useAppSelector } from 'src/redux/store/hooks';
import { ILoanUser, IVFDFundTransferPayload } from 'src/services/VFDService';
import { LoanDashboardContext } from './loanUsers';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
};

interface IFundTransferProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FundTransfer: React.FC<IFundTransferProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { disburseArgs } = useContext(LoanDashboardContext);
  const userArgs = disburseArgs as unknown as ILoanUser;

  const { loading } = useAppSelector((store) => selectFundTransfer(store));
  const { data: userLog } = useAppSelector((store) => selectUserLog(store));

  const handleCancel = () => {
    setOpen(false);
    form.resetFields(['amount']);
  };

  useEffect(() => {
    if (userArgs.accountNo) {
      dispatch(getLoanUserLog({ accountNo: userArgs.accountNo }));
    }
  }, [userArgs.accountNo]);

  const [form] = Form.useForm<IVFDFundTransferPayload>();

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        form.submit();

        dispatch(
          vfdTransferFunds(
            {
              ...values,
              fromAccount: userArgs.accountNo,
              fromClientId: userArgs.clientId,
              fromClient: `${userArgs.firstName} ${userArgs.lastName}`,
              fromSavingsId: '2943328',
              fromBvn: userArgs.bvn,
              toClientId: '',
              toClient: 'AJOCARD LIMITED SETTLEMENT',
              toSavingsId: '2943328', // TODO: for test. To be replaced in prod
              toSession: '',
              toBvn: process.env.REACT_APP_AJOCARD_SETTLEMENT_BVN as string,
              toAccount: process.env.REACT_APP_AJOCARD_SETTLEMENT_ACCOUNT_NUMBER as string,
              toBank: '999999',
              transferType: 'inter',
              reference: `ajocard-loans-spend-console-${uuid()}`,
              uniqueSenderAccountId: '',
              signature: userLog?.signature as string,
            },
            handleCancel
          )
        );
      })
      .catch((error) => {
        message.error(error);
      });
  };

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'First name',
      children: (disburseArgs as unknown as ILoanUser)?.firstName,
    },
    {
      key: '2',
      label: 'Last name',
      children: (disburseArgs as unknown as ILoanUser)?.lastName,
    },
    {
      key: '3',
      label: 'Phone number',
      children: (disburseArgs as unknown as ILoanUser)?.phone,
    },
    {
      key: '4',
      label: 'Account number',
      span: 2,
      children: (disburseArgs as unknown as ILoanUser)?.accountNo,
    },
    {
      key: '5',
      label: 'BVN',
      children: (disburseArgs as unknown as ILoanUser)?.bvn,
    },
    {
      key: '6',
      label: 'Client ID',
      children: (disburseArgs as unknown as ILoanUser)?.clientId,
    },
    {
      key: '7',
      label: 'Date created',
      children: dayjs((disburseArgs as unknown as ILoanUser)?.createdDate).format('DD-MM-YYYY'),
    },
  ];

  return (
    <>
      <Modal
        centered
        open={open}
        title="Fund Transfer to AjoCard"
        onCancel={handleCancel}
        footer={
          <Form form={form}>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit" onClick={onSubmit} style={{ color: 'black' }}>
                {loading ? 'Loading...' : 'Transfer fund'}
              </Button>
            </Form.Item>
          </Form>
        }
        styles={{ body: { paddingTop: '20px' } }}
      >
        <Descriptions bordered title="User Info" layout="vertical" items={items} className="mb-4" size="small" />
        <Form {...formItemLayout} form={form} name="register" layout="vertical" scrollToFirstError>
          <Row gutter={[16, 16]} justify="space-between">
            <Col xs={24}>
              <Form.Item name="amount" label="Amount" rules={[{ required: true, message: 'Please input amount!', whitespace: true }]}>
                <Input type="number" autoFocus />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item name="remark" label="Remark">
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(FundTransfer);

FundTransfer.displayName = 'FundTransfer';
