/* eslint-disable */
import React, { Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Navigate, Route } from 'react-router-dom';
import Utils from 'src/utils';
import { getDesignations, getCurrentUser } from 'src/redux/actions/user';
import Loading from '../../components/shared-components/Loading';
import { APP_PREFIX_PATH } from '../../configs/AppConfig';
import { Routes as NavigationRoutes } from '../../configs/NavigationConfig';
import ErrorOne from '../auth-views/errors/error-page-2';
import { pdfjs } from 'react-pdf';
import { useAppSelector } from 'src/redux/store/hooks';
import { selectUser } from 'src/redux/selectors/UserSelectors';
import { IRoutes } from 'src/layouts/app-layout';
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

const AppViews: React.FC<{ extraRoutes?: Array<IRoutes> }> = ({ extraRoutes }) => {
  const dispatch = useDispatch();
  const user = useAppSelector((store) => selectUser(store));

  React.useEffect(() => {
    dispatch(getCurrentUser(user?.userId));
    dispatch(getDesignations());
  }, []);

  return user?.roles ? (
    <Suspense fallback={<Loading cover="page" />}>
      <Routes>
        <Route path="/" element={<Navigate to={`${APP_PREFIX_PATH}/home`} />} />
        <Route path={APP_PREFIX_PATH} element={<Navigate to={`${APP_PREFIX_PATH}/home`} />} />
        <Route element={Utils.isUserKycSubmitted(user) && <Navigate to={`${APP_PREFIX_PATH}/onboarding`} />} />
        {Utils.filterRoutesNavtree(user, NavigationRoutes).map((item: IRoutes) => {
          return <Route key={item.key} path={item.path} Component={item.component as any} />;
        })}
        <Route path="*" element={<ErrorOne />} />
      </Routes>
    </Suspense>
  ) : (
    <Loading cover="page" />
  );
};

export const SnblViews: React.FC<{ extraRoutes?: Array<IRoutes> }> = ({ extraRoutes }) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Routes>
        {(extraRoutes as any).map((item: IRoutes) => {
          return <Route key={item.key} path={item.path} Component={item.component as any} />;
        })}
        <Route path="*" element={<ErrorOne />} />
      </Routes>
    </Suspense>
  );
};

export default AppViews;
