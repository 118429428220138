/* eslint-disable */
import { message } from 'antd';
import { IUser } from 'src/services/UserService';
import * as actionTypes from '../constants';
import { IKYCActivitylog } from './types';

export interface IToolsState {
  searchingAgent: boolean;
  agent: IUser & { logs: Array<IKYCActivitylog>; agents: IUser & { totalCount: number } };
  agentError: boolean;
  placingPnd?: boolean;
  pndPlaced?: boolean;
  pndError?: boolean;
  error?: any;
}

const initState: IToolsState = {
  searchingAgent: false,
  agent: {} as any,
  agentError: false,
};

const key = actionTypes.KEY;

const toolsReducer = (state: IToolsState = initState, action: any): IToolsState => {
  switch (action.type) {
    case actionTypes.SEARCH_AGENT_PENDING:
      return {
        ...state,
        searchingAgent: true,
        agent: {} as any,
      };
    case actionTypes.SEARCH_AGENT_SUCCESS:
      return {
        ...state,
        agentError: false,
        searchingAgent: false,
        agent: action.payload,
      };
    case actionTypes.SEARCH_AGENT_ERROR:
      message.error(action?.payload?.response?.data?.errors[0]?.message || 'Something went wrong');
      return {
        ...state,
        searchingAgent: false,
        agentError: true,
      };
    case actionTypes.PLACE_PND_PENDING:
      return {
        ...state,
        placingPnd: true,
        pndPlaced: false,
        pndError: false,
      };
    case actionTypes.PLACE_PND_SUCCESS:
      return {
        ...state,
        placingPnd: false,
        pndPlaced: true,
      };
    case actionTypes.PLACE_PND_ERROR:
      return {
        ...state,
        placingPnd: false,
        pndPlaced: false,
        pndError: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default toolsReducer;
