/* eslint-disable */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Menu, Layout } from 'antd';
import { MenuFoldOutlined } from '@ant-design/icons';
import { THEME_CONFIG } from 'src/configs/AppConfig';
import Logo from './Logo';
import NavPanel from './NavPanel';
import NavSearch from './NavSearch';
import {
  toggleCollapsedNav as toggleCollapsedNavRedux,
  onMobileNavToggle as onMobileNavToggleRedux,
} from '../../redux/actions/Theme';
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from '../../constants/ThemeConstant';
import utils from '../../utils';

const { Header } = Layout;

const MenuBurgerIcon = React.memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="none" viewBox="0 0 70 70">
      <path
        fill={THEME_CONFIG.primary}
        fillRule="evenodd"
        d="M27 28h10.213v1.754H27V28zm0 6.623h16v1.754H27v-1.754zm0 6.623h10.213V43H27v-1.754z"
        clipRule="evenodd"
      />
    </svg>
  );
});

export const HeaderNav = (props: any) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
  } = props;
  const [searchActive, setSearchActive] = useState(false);

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = React.useCallback(() => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  }, [isMobile, navCollapsed, mobileNav]);

  const isNavTop = navType === NAV_TYPE_TOP;
  const mode = React.useMemo(() => utils.getColorContrast(headerNavColor), [headerNavColor]);
  const navWidth = React.useMemo(() => {
    if (isNavTop || isMobile) {
      return '0px';
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    }
    return `${SIDE_NAV_WIDTH}px`;
  }, [isNavTop, isMobile, navCollapsed]);

  return (
    <Header className={`app-header ${mode}`} style={{ backgroundColor: 'black' }}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={mode} />
        <div className="nav" style={{ width: `calc(100% - ${navWidth})` }}>
          <div>
            <Menu mode="horizontal">
              {isNavTop && !isMobile ? null : (
                <Menu.Item key="0" onClick={onToggle}>
                  {navCollapsed || isMobile ? (
                    <MenuBurgerIcon />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </Menu.Item>
              )}
            </Menu>
          </div>
          <div className="mr-3 d-flex align-items-center">
            <NavPanel />
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme }: { theme: any }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav } = theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
  };
};

export default React.memo(
  connect(mapStateToProps, {
    toggleCollapsedNav: toggleCollapsedNavRedux,
    onMobileNavToggle: onMobileNavToggleRedux,
  })(HeaderNav)
);
