/* eslint-disable */
import { applyMiddleware, createStore, compose } from 'redux';
import { logger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router/immutable';
import rootReducer from '../reducers';
import * as initHistory from 'history';
import queueMiddleware from '../middleware/queue';

const createHistory: <S = unknown>(
  options?: initHistory.BrowserHistoryBuildOptions | undefined
) => initHistory.History<S> = initHistory?.createBrowserHistory;

export const history = createHistory();

// Build the middleware for intercepting and dispatching navigation actions
const historyMiddleware = routerMiddleware(history);

// Middlewares
const middlewares = [thunkMiddleware, historyMiddleware, queueMiddleware];

// Redux logger
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

// @ts-ignore Redux Devtools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer =
  process.env.NODE_ENV === 'development'
    ? composeEnhancers(applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares);

const store = createStore(rootReducer(history), enhancer);

export default store;
export type IStoreGetter = typeof store.getState;
export type IStore = ReturnType<typeof store.getState>;
export type IAppDispatch = typeof store.dispatch;
export type IAppHistory = typeof history;
