/* eslint-disable */
import { createSelector } from 'reselect';
import { IAuthState } from '../reducers/Auth';
import { IUserStore } from '../reducers/user';
import { IStore } from '../store';

export const selectUserStore = (store: IStore) => store.user;

export const selectCurrentUser = (store: IStore) => store.auth;

export const selectUser = createSelector(
  (store: IStore) => store.auth,
  (authStore: IAuthState) => authStore.user
);

export const selectBankAccountDetails = createSelector(
  (store: IStore) => store.user,
  (userStore: IUserStore) => userStore.bankAccountDetails
);

export const selectUsers = createSelector(
  (store: IStore) => store.user,
  (userList: IUserStore) => userList
);

export const selectUpdatedUserDesignation = createSelector(
  (store: IStore) => store.user,
  (userStore: IUserStore) => userStore.updatedUserDesignation
);

export const selectMakeAggregator = createSelector(
  (store: IStore) => store.user,
  (userStore: IUserStore) => userStore.makeAggregator
);

export const selectUserProfileDetails = createSelector(
  (store: IStore) => store.user,
  (userStore: IUserStore) => userStore.userProfileById
);

export const selectVerifyKyc = createSelector(
  (store: IStore) => store.user,
  (userStore: IUserStore) => userStore.verifyKyc
);

export const selectDesignations = createSelector(
  selectUserStore,
  (users: IUserStore) => users.designations.data
);
