import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
// TODO: Suspend Sentry for now. This will be reverted once optimal performance is restored.
// import { init, BrowserTracing, Replay } from '@sentry/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { env } from './configs/EnvironmentConfig';

// init({
//   dsn: env?.SENTRY_DNS,
//   integrations: [new BrowserTracing(), new Replay()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: env?.SENTRY_SAMPLE_RATE,
//   replaysSessionSampleRate: env?.SENTRY_SAMPLE_RATE,
//   replaysOnErrorSampleRate: env?.SENTRY_SAMPLE_RATE,
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(env?.ENV === 'dev' ? console.log : undefined);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  /* TODO: Suspend `StrictMode` until fully compatible with all dependencies */
  // <StrictMode>
  <App />
  // </StrictMode>
);
