/* eslint-disable */
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Grid } from 'antd';
import { connect } from 'react-redux';
// TODO: Causing performance issues
// import IntlMessage from '../util-components/IntlMessage';
import Icon from '../util-components/Icon';
import navigationConfig from '../../configs/NavigationConfig';
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from '../../constants/ThemeConstant';
import utils from '../../utils';
import { onMobileNavToggle as onMobileNavToggleRedux } from '../../redux/actions/Theme';
import { usePathName } from 'src/hooks/usePathName';
import Utils from '../../utils';
import { ItemType } from 'antd/es/menu/interface';

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setDefaultOpen = (key: any) => {
  const keyList = [];
  let keyString = '';
  if (key) {
    const arr = key.split('-');
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

type IMenuRoute = (typeof navigationConfig)[0];

const SideNavContent: React.FC = React.memo((props: any) => {
  const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle, user } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  const menuRoutes: IMenuRoute[] =
    React.useMemo(
      () => user.roles && Utils.filterRoutesNavtree(user, navigationConfig, true),
      [user.roles]
    ) || [];

  const setLocale = useCallback(
    (isLocaleOn: any, localeKey: any) => {
      return localeKey.toString();
    },
    [localization]
  );

  // Recursive Component to be continued...
  const RenderMenu = React.memo(({ routes }: { routes: IMenuRoute[] }) => {
    return (
      <>
        {routes.map((elm, index) => {
          return elm.submenu?.length ? (
            <Menu.ItemGroup key={elm.key} title={setLocale(localization, elm.title)}>
              <SubMenu
                icon={elm.icon ? <Icon type={elm?.icon} /> : null}
                key={elm.key}
                title={setLocale(localization, elm.title)}
              >
                <Menu.Item key={elm.key}>
                  <Icon type={elm.icon} />
                  <span>{setLocale(localization, elm.title)}</span>
                  <Link onClick={closeMobileNav} to={elm.path as string} />
                  <RenderMenu routes={elm.submenu as any} />
                </Menu.Item>
              </SubMenu>
            </Menu.ItemGroup>
          ) : (
            <Menu.Item key={elm.key}>
              <Icon type={elm?.icon} />
              <span>{setLocale(localization, elm?.title)}</span>
              {elm.path ? <Link onClick={closeMobileNav} to={elm.path} /> : null}
            </Menu.Item>
          );
        })}
      </>
    );
  });

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? 'hide-group-title' : ''}
    >
      {menuRoutes.map((menu: any) =>
        menu?.submenu?.length > 0 ? (
          <Menu.ItemGroup key={menu.key} title={setLocale(localization, menu.title)}>
            {menu?.submenu?.map((subMenuFirst: any) =>
              subMenuFirst?.submenu?.length > 0 ? (
                <SubMenu
                  icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  key={subMenuFirst.key}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst?.submenu?.map((subMenuSecond: any) => (
                    <Menu.Item key={subMenuSecond.key}>
                      {subMenuSecond.icon ? <Icon type={subMenuSecond?.icon} /> : null}
                      <span>{setLocale(localization, subMenuSecond.title)}</span>
                      <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
});

const TopNavContent: React.FC = React.memo((props: any) => {
  const { localization, user } = props;

  const route = usePathName({ docTitleChange: true });

  const selectedRouteIndex = React.useMemo(
    () => Utils.routeConfigIndex(window.location.pathname, navigationConfig),
    [window.location.pathname]
  );

  const setLocale = useCallback(
    (isLocaleOn: any, localeKey: any) => {
      return localeKey.toString();
    },
    [localization]
  );

  const menuRoutes =
    React.useMemo<Array<IMenuRoute>>(
      () => user.roles && Utils.filterRoutesNavtree(user, navigationConfig, true),
      [user.roles]
    )?.map<ItemType>((route) => {
      return {
        key: route.submenu?.length > 0 ? null : route.key,
        title: route.title,
        children: route.submenu?.map((subRoute) => {
          return {
            key: (subRoute?.submenu?.length as any) > 0 ? null : route.path,
            title: subRoute.title,
            label: (
              <Link to={subRoute.path}>
                <subRoute.icon />
                <span> {setLocale(localization, subRoute.title.toLowerCase())}</span>
              </Link>
            ),
            type: 'group',
          };
        }),
        label: (
          <Link to={route.path as string}>
            <route.icon />
            <span> {setLocale(localization, route.title)}</span>
          </Link>
        ),
      } as ItemType;
    }) || [];

  return <Menu mode="horizontal" items={menuRoutes} defaultSelectedKeys={[selectedRouteIndex]} />;
});

const MenuContent: React.FC<{ type: any; user: any }> = (props: any) =>
  props.type === NAV_TYPE_SIDE ? <SideNavContent {...props} /> : <TopNavContent {...props} />;

const mapStateToProps = ({ theme }: { theme: any }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default React.memo(
  connect(mapStateToProps, {
    onMobileNavToggle: onMobileNavToggleRedux,
  })(MenuContent)
);
