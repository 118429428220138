/* eslint-disable */
import { IPaginationParams } from 'src/redux/reducers/types';
import fetch from './FetchInterceptor';
import { IDateFilterParams, IProducts, ITransactionSort } from './Types';

export type ICommissionPlansParamsList = IPaginationParams & {
  planActive: boolean;
  commissionRateType: 'FLAT';
};

export interface ICreateCommissionsPayload {
  tenantID: string;
  userType: 'AGENT';
  event: 'REPAYMENT';
  ledgerWalletID: string;
  walletCategory: number;
  commissionRate: number;
  planDescription: string;
  productIndex: 'EVERYTIME';
  deductionType: 'COMMISSION';
  commissionRateType: 'PERCENTAGE';
  commissionVariable: 'TOTAL_AMOUNT';
  product: 'SAVINGS' | 'LENDING' | 'MPOS';
}

export interface IGetTransactionsParams
  extends Omit<Partial<IPaginationParams>, 'totalCount, limit'>,
    Partial<IDateFilterParams> {
  count?: number;
  userId?: string;
  product?: IProducts;
  directDownline?: string;
  $sort?: string;
  sortBy?: ITransactionSort;
  transactionOriginator?: string;
}

export type IGetAgentPerformanceParams = IGetTransactionsParams;

type ICommissionsService = {
  getCommissionEventMap: () => Promise<any>;
  getCommissionPlanDetailsByName: (planName: string) => Promise<any>;
  getCommissionsPlansList: (params: ICommissionPlansParamsList) => Promise<any>;
  createCommissionPayload: (payload: ICreateCommissionsPayload) => Promise<any>;
  getTransactions: (params: IGetTransactionsParams, signal?: AbortSignal) => Promise<any>;
  getAgentPerformance: (
    params: IGetAgentPerformanceParams,
    abortSignal?: AbortSignal
  ) => Promise<any>;
};

export const CommissionsService = <ICommissionsService>{};

CommissionsService.getCommissionEventMap = () =>
  fetch({
    url: '/commission/v1/events',
    method: 'GET',
  });

CommissionsService.getCommissionsPlansList = (params: ICommissionPlansParamsList) =>
  fetch({
    url: '/commission/v1/plans',
    method: 'GET',
    params,
  });

CommissionsService.getCommissionPlanDetailsByName = (planName: string) =>
  fetch({
    url: `/commission/v1/plans/byName/${planName}`,
    method: 'GET',
  });

CommissionsService.createCommissionPayload = (data: ICreateCommissionsPayload) =>
  fetch({
    url: '/commission/v1/plans',
    method: 'POST',
    data,
  });

CommissionsService.getTransactions = (params: IGetTransactionsParams, signal?: AbortSignal) =>
  fetch({
    url: '/commission/v1/commissions',
    method: 'GET',
    params,
    signal,
  });

CommissionsService.getAgentPerformance = (
  params: IGetAgentPerformanceParams,
  signal?: AbortSignal
) =>
  fetch({
    url: '/commission/v1/agent-performance',
    method: 'GET',
    params,
    signal,
  });
