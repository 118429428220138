import { IDocHeader } from 'src/redux/actions/Utils';

export const walletStatementHeaders: IDocHeader[] = [
  {
    key: 'createdAt',
    label: 'Trans. Date',
  },
  {
    key: 'id',
    label: 'Trans. ID',
  },
  {
    key: 'agent_name',
    label: 'Agent Name',
  },
  {
    key: 'amount',
    label: 'Trans. Amount (NGN)',
  },
  {
    key: 'service',
    label: 'Trans. Type',
  },
  {
    key: 'narration',
    label: 'Narration',
  },
  {
    key: 'credits',
    label: 'Credits (NGN)',
  },
  {
    key: 'debits',
    label: 'Debits (NGN)',
  },
  {
    key: 'balanceBefore',
    label: 'Balance Before (NGN)',
  },
  {
    key: 'balanceAfter',
    label: 'Balance After (NGN)',
  },
];
