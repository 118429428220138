import * as actionTypes from '../constants';

const initState = {
  loading: false,
  loanError: true,
  lendingRequests: {},
  pbRequests: {},
  loans: {},
  updating: false,
  updated: false,
  reports: {
    loading: false,
  },
};

type ILoanSection =
  | {
      count: number;
      data: Array<any>;
      has_more: boolean;
      limit: number;
      page: number | string;
      total_count: number;
    }
  | {}
  | null;

interface ILendingStore {
  loading: boolean;
  loanError: boolean;
  lendingRequests: ILoanSection;
  pbRequests: ILoanSection;
  loans: ILoanSection;
  updating: boolean;
  updated: boolean;
  reports: {
    loading: boolean;
  };
}

const key = actionTypes.KEY;

const lendingReducer = (
  state: ILendingStore = initState as ILendingStore,
  action: any
): ILendingStore => {
  switch (action.type) {
    case actionTypes.LENDING_REQUEST_PENDING:
      return {
        ...state,
        lendingRequests: { ...state.lendingRequests },
        loading: true,
        loanError: false,
      };
    case actionTypes.LENDING_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        lendingRequests: action.payload,
        loanError: false,
      };
    case actionTypes.LENDING_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        lendingRequests: {},
        loanError: true,
      };
    case actionTypes.PB_REQUEST_PENDING:
      return {
        ...state,
        loading: true,
        pbRequests: {},
        pbError: false,
      } as ILendingStore;
    case actionTypes.PB_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        pbRequests: action.payload,
        pbError: false,
      } as ILendingStore;
    case actionTypes.PB_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        pbRequests: {},
        pbError: true,
      } as ILendingStore;
    case actionTypes.PB_REQUEST_ACTION_PENDING:
      return {
        ...state,
        updating: true,
        updated: false,
        updateError: false,
      } as ILendingStore;
    case actionTypes.PB_REQUEST_ACTION_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: true,
        updateError: false,
      } as ILendingStore;
    case actionTypes.PB_REQUEST_ACTION_ERROR:
      return {
        ...state,
        updating: false,
        updated: false,
        updateError: true,
      } as ILendingStore;
    case actionTypes.LOAN_REQUEST_PENDING:
      return {
        ...state,
        loading: true,
        loans: {},
        nplError: false,
      } as ILendingStore;
    case actionTypes.LOAN_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        loans: action.payload,
        nplError: false,
      } as ILendingStore;
    case actionTypes.LOAN_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        loans: {},
        nplError: true,
      } as ILendingStore;
    case actionTypes.REPORTS_PENDING:
      return {
        ...state,
        reports: {
          ...state.reports,
          data: [],
          loading: true,
        },
      } as ILendingStore;
    case actionTypes.REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          data: action.payload,
          error: false,
          loading: false,
        },
      } as ILendingStore;
    case actionTypes.REPORTS_ERROR:
      return {
        ...state,
        reports: {
          ...state.reports,
          data: [],
          loading: false,
          error: true,
        },
      } as ILendingStore;
    default:
      return state;
  }
};

export default lendingReducer;
