/* eslint-disable */
import { Reducer } from 'redux';
import { CLEAR_QUEUE, ADD_TO_QUEUE } from '../constants/index';

interface IQueueReducerState {
  queue: any[];
}

const initialState: IQueueReducerState = {
  queue: [],
};

const queueReducer: Reducer<
  IQueueReducerState,
  { type: string; payload: any }
> = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_QUEUE:
      return {
        ...state,
        queue: [...state.queue, action.payload],
      };
    case CLEAR_QUEUE:
      return {
        ...state,
        queue: [],
      };
    default:
      return state;
  }
};

export default queueReducer;
