/* eslint-disable */
import dayjs from 'dayjs';
import { IUser } from 'src/services/UserService';
import Utils from '.';

const { user } = JSON.parse(localStorage.getItem('ac_au') || '{}');
const appEnv = () => {
  const host = window.location.hostname;
  const env = process.env.NODE_ENV;
  const local = host === 'localhost';
  return { host, env, local };
};

export interface ISlackParams {
  firstName: string;
  lastName: string;
  email?: string;
  agentId?: string;
  oldTier?: string;
  currentTier?: string;
  searchType?: string;
  searchParam?: string;
  parent?: IUser;
  parentId?: string;
  phoneNumber?: string;
  phoneNumbers?: Array<string>;
  kycReason?: string;
  kycStatus?: boolean;
  initialValue?: any;
  updatedValue?: any;
  label?: string;
  walletId?: string;
  changeNotes?: string;
  previousWalletStatus?: string;
  newWalletStatus?: string;
}

export enum ISlackActiontypes {
  kycDocsAdded = 'kyc_docs_added',
  walletStatement = 'wallet_statement_fetch',
  updateWalletStatus = 'wallet_status_updated',
  tierChange = 'tier_change',
  kycReport = 'kyc_report',
  userUnassignFromParent = 'user_unassign_to_parent',
  userAssignToParent = 'user_assign_to_parent',
  usersAssignToParent = 'users_assign_to_parent',
  userReview = 'user-review',
  updateEmailSuccess = 'update_email',
  updatePhoneSuccess = 'update_phone',
  updateEmailFailed = 'update_email_failed',
  updatePhoneFailed = 'update_phone_failed',
  savingsCardUpdated = 'savings_card_updated',
}

export type ISlackActions =
  | ISlackActiontypes.kycDocsAdded
  | ISlackActiontypes.walletStatement
  | ISlackActiontypes.updateWalletStatus
  | ISlackActiontypes.tierChange
  | ISlackActiontypes.kycReport
  | ISlackActiontypes.userUnassignFromParent
  | ISlackActiontypes.userAssignToParent
  | ISlackActiontypes.usersAssignToParent
  | ISlackActiontypes.userReview
  | ISlackActiontypes.updateEmailSuccess
  | ISlackActiontypes.updatePhoneSuccess
  | ISlackActiontypes.updateEmailFailed
  | ISlackActiontypes.updatePhoneFailed
  | ISlackActiontypes.savingsCardUpdated;

export type ISlackActionStatus = 'failed' | 'success';

const Slack = {
  channels: {
    ajocardConsole: 'ajocard-console-logs',
    tools: 'ajocard-tool',
  },
  actionTypes: ISlackActiontypes,
  getSlackMessage(actionType: ISlackActions, params: ISlackParams, status?: ISlackActionStatus) {
    let message = '';
    switch (actionType) {
      case ISlackActiontypes.kycDocsAdded:
        message += `*KYC DOC Added* for user with phone number ${params.searchParam}`;
        message += `\nAgent Name: *${params?.firstName} ${params?.lastName}*  \n Agent Email: *${params.email}*`;
        break;
      case ISlackActiontypes.walletStatement:
        message += `*Wallet Statement retrieved* for user with ${params.searchType} ${params.searchParam}`;
        message += `\nAgent Name: *${params?.firstName} ${params?.lastName}*  \n Agent ID: *${params.agentId}*`;
        break;
      case ISlackActiontypes.updateWalletStatus:
        message += `*Wallet Status updated* \n The wallet (${params.walletId}) for user with phone number ${params.phoneNumber} was changed from *${params.previousWalletStatus}* to *${params.newWalletStatus}*.`;
        message += `\nChange Notes: ${params.changeNotes}`;
        message += `\nAgent Name: *${params?.firstName} ${params?.lastName}*  \n Agent Email: *${params.email}*`;
        break;
      case ISlackActiontypes.tierChange:
        message += `*Tier update* for user with ${params.searchType} ${params.searchParam}`;
        message += `\nAgent Name: *${params?.firstName} ${params?.lastName}*  \n Agent ID: *${params.agentId}*`;
        message += `\n Old Tier: ${params?.oldTier || 'None'} \n New Tier:${params.currentTier}`;
        break;
      case ISlackActiontypes.kycReport:
        message += `*KYC report retrieved* for user with ${params.searchType} ${params.searchParam}`;
        message += `\nAgent Name: *${params?.firstName} ${params?.lastName}*  \n Agent ID: *${params.agentId}*`;
        break;
      case ISlackActiontypes.userAssignToParent:
        message += `*User assigned to parent:* \n`;
        message += `*${params.firstName}* *${params.lastName}* with phone number ${params.phoneNumber} was assigned to parent *${params.parent?.firstName}* *${params.parent?.lastName}* with phone number *${params.parent?.phoneNumber}*`;
        break;
      case ISlackActiontypes.usersAssignToParent:
        message += `*Users assigned to parent:* \n`;
        message += `*The Users* with the following phone numbers: *${params.phoneNumbers?.join(', ')}* were assigned to parent *${params.parent?.firstName}* *${params.parent?.lastName}* with phone number *${params.parent?.phoneNumber}*`;
        break;
      case ISlackActiontypes.userUnassignFromParent:
        message += `*User unassigned from parent:* \n`;
        message += `*${params.firstName}* *${params.lastName}* with phone number ${params.phoneNumber} was unassigned from parentId *${params.parentId}*`;
        break;
      case ISlackActiontypes.userReview:
        message += `*User KYC ${params.kycStatus ? 'Approved' : 'Rejected'}*\n`;
        message += `*${params.firstName}* *${params.lastName}* with userid ${params.agentId} was ${
          params.kycStatus ? 'approved' : `rejected`
        } `;
        break;
      case ISlackActiontypes.updateEmailSuccess:
        message += `\n *${params.label} Updated* `;
        message += `\n From: ${params.initialValue} to: ${params.updatedValue}`;
        message += `\nAgent Name: *${params?.firstName} ${params?.lastName}*  \n Agent ID: *${params.agentId}*`;
        break;

      case ISlackActiontypes.updatePhoneSuccess:
        message += `\n *${params.label} Updated* `;
        message += `\n From: ${params.initialValue} to: ${params.updatedValue}`;
        message += `\nAgent Name: *${params?.firstName} ${params?.lastName}*  \n Agent ID: *${params.agentId}*`;
        break;

      case ISlackActiontypes.updateEmailFailed:
        message += `\n *${params.label} change Attempted and Failed* `;
        message += `\n From: ${params.initialValue} to: ${params.updatedValue}`;
        break;

      case ISlackActiontypes.updatePhoneFailed:
        message += `\n *${params.label} change Attempted and Failed* `;
        message += `\n From: ${params.initialValue} to: ${params.updatedValue}`;
        message += `\nAgent Name: *${params?.firstName} ${params?.lastName}*  \n Agent ID: *${params.agentId}*`;
        break;

      case ISlackActiontypes.savingsCardUpdated:
        message += `*Savings Card updated* for user with phone number ${params.phoneNumber}`;
        message += `\nAgent Name: *${params?.firstName} ${params?.lastName}*  \n Agent Email: *${params.email}*`;
        break;
      default:

      // code block
    }
    message += `\n ${status === 'failed' ? 'Attempted by' : 'Retrieved/Updated by user:'} ${
      user?.firstName
    } ${user?.lastName}(${user.email}) \n Dated: ${dayjs().format(
      'YYYY-MM-DD HH:mm:ss'
    )}  \nApp Stage: ${appEnv().host} \n_______\n\n\n`;
    return message;
  },
  log(message: string, forceSlack?: boolean) {
    if (appEnv().local && !forceSlack) return;
    Utils.sendSlackMessage(message, this.channels.ajocardConsole);
  },
  logAction(
    params: ISlackParams,
    actionType: ISlackActions,
    status?: ISlackActionStatus,
    forceSlack?: boolean
  ) {
    //if (appEnv().local && !forceSlack) return;
    const message = this.getSlackMessage(actionType, params, status);
    return Utils.sendSlackMessage(message, this.channels.tools);
  },
  logError(error: any, errorInfo?: any) {
    return this.log(
      ` ${appEnv().host} ERROR: \n \n ${error.message} on line ${error.lineno} \n User: ${
        user?.firstName
      } ${user?.lastName}(${user.email}) \n App Stage: ${appEnv().host} 
      \n ErrorDetails: ${JSON.stringify(error)}
      \n ErrorInfoDetails: ${JSON.stringify(errorInfo)}
        \n Url: ${window.location.href}
      \n_______\n\n\n`
    );
  },
  test() {
    return this.logAction(
      {
        searchType: 'phoneNumber',
        searchParam: '08107034667',
        firstName: 'firstName',
        lastName: 'last Name',
        agentId: '001',
        oldTier: 'Old Tier test',
        currentTier: 'New Tier test',
      },
      ISlackActiontypes.kycReport
    );
  },
};
export default Slack;
