/* eslint-disable */
import { Reducer } from 'redux';
import { notification } from 'antd';
import * as actionTypes from '../constants/index';
import { DefaultInitialState, IDefaultResponse, IPaginationParams } from './types';

const initialState: ISavingsState = {
  loading: false,
  error: null,
  runnersList: null,
  savingsReport: null,
  savingsTransactions: null,
  savingsTransactionSummary: null,
  pbCummulativeRecords: null,
  runnersCummulativeRecords: null,
  customerCummulativeRecords: null,
  paySalary: { logs: [] },
  sendSalaryEmailReport: null,
};

export type ISavingsTransactionsDataUnit = {
  agentBalance: number;
  agentId: string;
  agentWallet: string;
  amount: number;
  createdAt: string;
  customerBalance: number;
  customerId: string;
  customerName: string;
  customerWallet: string;
  fees: number;
  narration: string;
  reference: string;
  total: number;
  transactionType: string;
  updatedAt: string;
  cycleDateCode: string;
  cycleId: string;
  runnerName: string;
  runnerPhoneNumber: string;
  id: string;
};

export type IPbCummulativeRecord = {
  totalSaved: number;
  totalWithdrawn: number;
  retainedBalance: number;
  agentId: string;
  saversCount: number;
  agentPhoneNumber: string;
  agentName: string;
  agentGender: string;
  agentEmail: string;
  agentWalletID: string;
};

export interface ISavingsTransactionData extends IPaginationParams {
  transactions: ISavingsTransactionsDataUnit[];
  loading: boolean;
  data: any;
}

export interface ISavingsSummaryData {
  totalAmount: number;
  totalCount: number;
  transactionType: string;
}

export interface IPbCummulativeRecords extends IPaginationParams {
  records: IPbCummulativeRecord[];
  loading: boolean;
}

export interface IRunnersCummulativeRecord {
  _id: string;
  customerId: string;
  lastTxnAmount: number;
  lastTxnDate: string;
  totalDeposit: number;
  customerPhoneNumber: string;
  customerName: string;
  customerGender: string;
  customerEmail: string;
  customerWalletID: string;
}

export interface IRunnersCummulativeRecords extends IPaginationParams {
  records: IRunnersCummulativeRecord[];
  loading: boolean;
}

export type ISavingsState = {
  loading: boolean;
  error: any;
  savingsReport: null | ISavingsReports;
  runnersList: null | IRunnersList;
  savingsTransactions: null | ISavingsTransactionData;
  savingsTransactionSummary: null | ISavingsSummaryData;
  pbCummulativeRecords: null | IPbCummulativeRecords;
  runnersCummulativeRecords: null | IRunnersCummulativeRecords;
  customerCummulativeRecords: null | ICustomerCummulativeRecords;
  paySalary: Partial<PaySalariesState> | null;
  sendSalaryEmailReport: IDefaultResponse<any> | null;
};

export type ICustomerCummulativeRecord = {
  _id: string;
  customerId: string;
  lastTxnAmount: number;
  lastTxnDate: string;
  retainedBalance: number;
  totalWithdrawn: number;
  totalDeposit: number;
  customerPhoneNumber: string;
  customerName: string;
  customerGender: string;
  customerEmail: string;
  customerWalletID: string;
};

export interface ICustomerCummulativeRecords extends IPaginationParams {
  records: ICustomerCummulativeRecord[];
  loading: boolean;
}

export interface IRunner {
  amountCollected: number;
  currentState: 'ACTIVATED' | 'DEACTIVATED' | 'DELETED';
  userId: string;
  agentId: string;
  maximumAmountCollectable: number;
  amountCollectedHistory: any[];
  statusHistory: [
    {
      status: 'ACTIVATED' | 'DEACTIVATED' | 'DELETED';
      date: string;
    }
  ];
  displayName: string;
  phoneNumber: string;
  createdAt: string;
  updatedAt: string;
  id: string;
}

export interface IRunnersList {
  data: IRunner[];
}

export interface ISavingsReport {
  reference: string;
  narration: string;
  agentId: string;
  agentWallet: string;
  customerWallet: string;
  customerId: string;
  customerName: string;
  amount: number;
  fees: number;
  total: number;
  customerBalance: number;
  agentBalance: number;
  transactionType: string;
  cycleId: string;
  cycleDateCode: string;
  runnerPhoneNumber: string;
  createdAt: string;
  updatedAt: string;
  runnerLength: number;
  runnerName: string;
  id: string;
}

export interface ISavingsReports {
  data: {
    key: string;
    fields: string[];
    report: any[];
  };
  loading: boolean;
}

type ISavingsPayloads =
  | IRunnersList
  | ISavingsReports
  | ISavingsTransactionData
  | ISavingsSummaryData
  | IPbCummulativeRecords
  | IRunnersCummulativeRecords
  | ICustomerCummulativeRecord;

export interface ISalarySheet {
  name: string;
  amount: number;
  status: string;
  remark: string;
  narration: string;
  reference: string;
  destinationWallet: string;
}

export type PaySalariesState = DefaultInitialState<{
  message: string;
  data: {
    amount: number;
    fees: number;
    creditedAmount: number;
    transactionId: string;
  };
}> & { logs: ISalarySheet[] };

type ISavingsReducer = Reducer<ISavingsState | any, { type: string; payload: ISavingsPayloads }>;

const savingsReducer: ISavingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SAVINGS_TRANSACTIONS_PENDING:
      notification.destroy();

      return {
        ...state,
        savingsTransactions: {
          ...state.savingsTransactions,
          loading: true,
          error: null,
        },
      };
      break;
    case actionTypes.GET_SAVINGS_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        savingsTransactions: {
          ...state.savingsTransactions,
          loading: false,
          error: null,
          ...action.payload,
        },
      };
      break;

    case actionTypes.GET_SAVINGS_TRANSACTIONS_FAILED:
      notification.error({
        message: action.payload,
      });

      return {
        ...state,
        savingsTransactions: {
          ...state.savingsTransactions,
          loading: false,
          error: action.payload,
        },
      };
      break;
    case actionTypes.GET_SAVINGS_TRANSACTIONS_SUMMARY_PENDING:
      return {
        ...state,
        savingsTransactionSummary: {
          ...state.savingsTransactionSummary,
          loading: true,
          error: null,
        },
      };
      break;
    case actionTypes.GET_SAVINGS_TRANSACTIONS_SUMMARY_SUCCESS:
      return {
        ...state,
        savingsTransactionSummary: {
          ...state.savingsTransactionSummary,
          loading: false,
          error: null,
          ...action.payload,
        },
      };
      break;

    case actionTypes.GET_SAVINGS_TRANSACTIONS_SUMMARY_FAILED:
      notification.destroy();

      notification.error({
        message: action.payload,
      });

      return {
        ...state,
        savingsTransactionSummary: {
          ...state.savingsTransactionSummary,
          loading: false,
          error: action.payload,
        },
      };
      break;

    case actionTypes.GET_PB_CUMMULATVE_RECORDS_PENDING:
      return {
        ...state,
        pbCummulativeRecords: {
          ...state.pbCummulativeRecords,
          loading: true,
          error: null,
        },
      };
      break;
    case actionTypes.GET_PB_CUMMULATVE_RECORDS_SUCCESS:
      notification.destroy();

      return {
        ...state,
        pbCummulativeRecords: {
          ...state.pbCummulativeRecords,
          loading: false,
          error: null,
          ...action.payload,
        },
      };
      break;

    case actionTypes.GET_PB_CUMMULATVE_RECORDS_FAILED:
      notification.destroy();

      notification.error({
        message: action.payload,
      });

      return {
        ...state,
        pbCummulativeRecords: {
          ...state.pbCummulativeRecords,
          loading: false,
          error: action.payload,
        },
      };
      break;

    case actionTypes.GET_CUSTOMER_CUMMULATIVE_RECORDS_PENDING:
      notification.destroy();

      return {
        ...state,
        customerCummulativeRecords: {
          ...state.customerCummulativeRecords,
          loading: true,
          error: null,
        },
      };
      break;
    case actionTypes.GET_CUSTOMER_CUMMULATIVE_RECORDS_SUCCESS:
      return {
        ...state,
        customerCummulativeRecords: {
          ...state.customerCummulativeRecords,
          loading: false,
          error: null,
          ...action.payload,
        },
      };
      break;

    case actionTypes.GET_CUSTOMER_CUMMULATIVE_RECORDS_FAILED:
      notification.destroy();

      notification.error({
        message: action.payload,
      });

      return {
        ...state,
        customerCummulativeRecords: {
          ...state.customerCummulativeRecords,
          loading: false,
          error: action.payload,
        },
      };
      break;

    case actionTypes.GET_RUNNERS_LIST_PENDING:
      notification.destroy();

      return {
        ...state,
        runnersList: {
          ...state.runnersList,
          loading: true,
          error: null,
        },
      };
      break;
    case actionTypes.GET_RUNNERS_LIST_SUCCESS:
      return {
        ...state,
        runnersList: {
          ...state.runnersList,
          loading: false,
          error: null,
          ...action.payload,
        },
      };
    case actionTypes.GET_RUNNERS_LIST_FAILED:
      notification.destroy();

      notification.error({
        message: action.payload,
      });

      return {
        ...state,
        runnersList: {
          ...state.runnersList,
          loading: false,
          error: action.payload,
        },
      };
      break;

    case actionTypes.GET_RUNNERS_CUMMULATIVE_RECORDS_PENDING:
      notification.destroy();

      return {
        ...state,
        runnersCummulativeRecords: {
          ...state.runnersCummulativeRecords,
          loading: true,
          error: null,
        },
      };
      break;
    case actionTypes.GET_RUNNERS_CUMMULATIVE_RECORDS_SUCCESS:
      return {
        ...state,
        runnersCummulativeRecords: {
          ...state.runnersCummulativeRecords,
          loading: false,
          error: null,
          ...action.payload,
        },
      };
      break;

    case actionTypes.GET_RUNNERS_CUMMULATIVE_RECORDS_FAILED:
      notification.destroy();

      notification.error({
        message: action.payload,
      });

      return {
        ...state,
        runnersCummulativeRecords: {
          ...state.runnersCummulativeRecords,
          loading: false,
          error: action.payload,
        },
      };
      break;

    case actionTypes.GET_SAVINGS_REPORT_PENDING:
      notification.info({
        message: 'Fetching report...',
        duration: 0,
      });
      return {
        ...state,
        savingsReport: {
          ...state.savingsReport,
          loading: true,
          error: null,
        },
      };
      break;
    case actionTypes.GET_SAVINGS_REPORT_SUCCESS:
      return {
        ...state,
        savingsReport: {
          ...state.savingsReport,
          data: action.payload,
          loading: false,
          error: null,
        },
      };
      break;

    case actionTypes.GET_SAVINGS_REPORT_FAILED:
      notification.destroy();

      notification.error({
        message: 'Somewthing went wrong.',
      });

      return {
        ...state,
        savingsReport: {
          ...state.savingsReport,
          loading: false,
          error: action.payload,
        },
      };
      break;

    case actionTypes.PAY_SALARIES_PENDING:
      return {
        ...state,
        paySalary: {
          ...state.paySalary,
          loading: true,
          error: null,
        },
      };

      break;

    case actionTypes.PAY_SALARIES_SUCCESS:
      return {
        ...state,
        paySalary: {
          ...state.paySalary,
          loading: false,
          error: null,
          data: action.payload,
        },
      };
      break;

    case actionTypes.PAY_SALARIES_FAILED:
      return {
        ...state,
        paySalary: {
          ...state.paySalary,
          loading: false,
          error: action.payload,
        },
      };

      break;

    case actionTypes.UPDATE_SALARY_PAYMENT_STATUS:
      return {
        ...state,
        paySalary: {
          ...state.paySalary,
          logs: [...state.paySalary.logs, action.payload],
        },
      };

    case actionTypes.RESET_SALARY_PAYMENT_STATUS:
      return {
        ...state,
        paySalary: {
          ...state.paySalary,
          logs: [],
        },
      };

    case actionTypes.SEND_SALARY_PAYMENT_REPORT_PENDING:
      return {
        ...state,
        sendSalaryEmailReport: {
          ...state.sendSalaryEmailReport,
          loading: true,
          error: null,
        },
      };

    case actionTypes.SEND_SALARY_PAYMENT_REPORT_SUCCESS:
      return {
        ...state,
        sendSalaryEmailReport: {
          ...state.sendSalaryEmailReport,
          loading: false,
          error: null,
          data: action.payload,
        },
      };

    case actionTypes.SEND_SALARY_PAYMENT_REPORT_FAILED:
      return {
        ...state,
        sendSalaryEmailReport: {
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default savingsReducer;
