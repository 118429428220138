import React from 'react';
import { Button, Result } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Utils from 'src/utils';
import { getCurrentUser } from 'src/redux/actions/user';

const ErrorTwo: React.FC<{ user: any; getCurrentUser: any }> = ({ user }) => {
  const navigate = useNavigate();
  const [render, setRender] = React.useState<boolean>(false);

  React.useEffect(() => {
    let run = true;

    if (Utils.isUserKycSubmitted(user)) {
      navigate('/app/home');
    } else {
      navigate('/app/onboarding');
    }
    setRender(true);

    return () => {
      run = false;
    };
  }, []);

  return render ? (
    <div className="mt-6">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, you might not have permissions to view this page."
        extra={
          <Link to="/app/home">
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    </div>
  ) : null;
};

const mapStateToProps = ({ auth }: { theme: any; auth: any }) => {
  const { token, user, loading } = auth;
  return { token, user, loading };
};

const mapDispatchToProps = {
  getCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorTwo);
