/* eslint-disable */
import dayjs from 'dayjs';
import { IFilter, IMakeAggregator, IPagination } from 'src/redux/actions/user';
import { IPaginationParams } from 'src/redux/reducers/types';
import fetch from './FetchInterceptor';
import { IProducts, IQueryParams, IVerificationStatus } from './Types';
import { AxiosPromise } from 'axios';

export type IkycDoc = {
  image: string;
  s3ObjectPath: string;
  imageName: string;
  s3Bucket: string;
};

export interface IVerifyUserBySmileId {
  phoneNumber: string;
}

export interface IRegisterUserPayload {
  firstName: string;
  lastName: string;
  otherNames?: string;
  displayName: string;
  phoneNumber: string;
  registrationType: string;
  gender: string;
}

export type IAddress = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  lga: string;
  state: string;
};

export type IWalletFunding = {
  accountName: string;
  accountNumber: string;
  ajocardWalletId: string;
  bankName: string;
  bankCode: string;
  id: string;
  type: string;
  walletId: string;
  provider: string;
};

export type IUser = {
  id: string;
  currentTier?: string;
  wallets?: { items: any[]; loading: boolean };
  roles: string[];
  userId: string;
  firstName: string;
  otherNames: string;
  lastName: string;
  email: string;
  userGroupId: string;
  phoneNumber: string;
  displayName: string;
  dob: string;
  description: string;
  designation: IUserDesignation;
  aggregatorNumber: string;
  isAggregatorApproved: boolean;
  walletId: string;
  createdAt: string;
  currentStatus: string;
  statusHistory: {
    Status: string;
    Timestamp: number;
  }[];
  identity: IIdentity;
  address: IAddress;
  nextOfKin?: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
  };
  kycStatuses?: {
    idcard: KYC_STATUS;
    passport: KYC_STATUS;
    selfie: KYC_STATUS;
    signature: KYC_STATUS;
    utility: KYC_STATUS;
  };
  parentId: string;
  products?: string[];
  uploadedAggregatorRecords?: any[];
  walletsFunding?: IWalletFunding[];
  walletFundingAccount: IWalletFunding[];
};

export enum KYC_STATUS {
  MISSING = 'MISSING',
  FLAGGED = 'FLAGGED',
  APPROVED = 'APPROVED',
}

export interface IIdentity {
  isIdentityVerified: string;
  identityType: string;
  identityNumber: string;
  dob: string;
  gender: string;
  kycDocs: IkycDoc[];
  homeAddress: IAddress;
  stateOfOrigin: string;
  lgaOfOrigin: string;
  bvn: string;
  nin: string;
  cac: string;
  type: string;
  kycDocsVerificationStatus: IkycDocsVerificationStatus;
  kycDocsVerificationMessage: string;
  flaggedDate: string;
  flagOverdueDate: string;
  flagOverdue: boolean;
  flagOverdueDaysLeft: number;
  resubmittedKyc: boolean;
}

export type IkycDocsVerificationStatus = 'FLAGGED' | 'APPROVED' | 'DECLINED';

export interface IGetUsersParams extends Partial<IPaginationParams> {
  relationshipType?: 'AGGREGATOR';
  aggregatorApproved?: boolean;
  kycSubmitted?: boolean;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  kycStatus?: string;
  $noLimit?: boolean;
  noLimit?: boolean;
  start_date?: string;
  end_date?: string;
  product?: IProducts;
  products?: IProducts;
  userId?: string;
  email?: string;
  skip?: number;
}

export type IUserListProjectionPayload = {
  items: {
    userId: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  }[];
  hasMore: boolean;
  count: number;
  total: number;
};

export interface IGetUserListParams {
  user_ids?: string;
  projection: string;
}

export type CreateUser = {
  registrationType?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  aggregatorId?: string;
  description?: string;
  displayName?: string;
  dob?: string;
  otherNames?: string;
  phoneNumber?: string;
};

export type UserCreatePassword = {
  password: string;
  userId: string | null;
};

export type IUserValidateOTP = {
  phoneNumber: string;
  otp: string;
  registrationType: string;
};

export type UserById = {
  userId: string;
  searchType?: boolean;
};

export type UpdateUserAddress = {
  addressLine1: string;
  city: string;
  id: string;
  lga: string;
  state: string;
  addressLine2: string;
};

export type UpdateUserNextOfKin = {
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
};

export type UpdateProduct = {
  productType: string;
  userId: string;
  userRole: string;
};

export type IUpdatePhoneNumberPayload = Pick<IUser, 'userId' | 'phoneNumber'>;

export type AggregatorById = {
  aggregatorId: string;
};
export type AggregatorByParentId = {
  parentId: string;
  aggregatorApproved: boolean;
};
export type IGetAllAggregatorsParams = {
  page: number;
  limit: number;
  search?: string;
};

export type IAssignAgentToAggregator = {
  aggregator: string;
  users: string[];
};

export type IBulkAgentOnboading = {
  bucketKey: string;
  ext: string;
};

export type IBulkProcessAggregatorUpload = {
  uploadKey: string;
  aggregatorId: string;
};

export type IGetBlacklistedBVNsListParams = Partial<IPaginationParams>;
export type IActivityLogParams = {
  principalUser?: string;
  affectedUser?: string;
  action?: string;
};

export type IGetBlacklistedBVNParams = {};

export type IBlacklistedBVNPayload = {
  _id: string;
  active: boolean;
  value: string;
  createdAt: string;
  updatedAt: string;
  blacklistType: string;
  blacklistHistory: IBlacklistedBVNHistoryObj[];
};

export type IBlackListBvnOps = {
  blacklistType?: 'bvn';
  value: string;
  active?: boolean;
  comment?: string;
};

export type IBlacklistedBVNHistoryObj = {
  active: boolean;
  adminId: string;
  comment: string;
  agentId: string;
  dateTime: string;
};

export type IUserDesignation = 'super agent' | 'aggregator' | 'agent' | 'customer';

export interface IUpdateUserDesignationPayload extends Pick<IUser, 'designation'> {
  id: string;
}

export interface IBankAccountDetails<T> {
  accountName: T;
  accountNumber: T;
  ajocardWalletId: T;
  bankName: T;
  bankCode: T;
  id: T;
  type: T;
  provider: T;
  surcharge: T;
}

export interface IDesignations {
  id: string;
  name: string;
  level: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  description: string;
  createdBy: IUser['userId'];
}

export interface IVerifyKyc {
  verificationStatus: IVerificationStatus;
  verificationMessage: string;
  flaggedDate: string;
  kycDocs: IkycDoc &
    {
      approvalMessage: string;
      approvalStatus: IVerificationStatus;
    }[];
  userId: IUser['userId'];
}

export interface ISendSalaryPaymentReportPayload {
  type: 'email';
  handler: 'POST';
  email: string;
  Name: string;
  template: '8OyWKi5N7636hXaloZeKPKCw';
  vars: {
    SuccessWallets: string;
    SuccessReference: string;
    SuccessNarrations: string;
    SuccessAmounts: string;
    FailedWallets: string;
    FailedReference: string;
    FailedNarrations: string;
    FailedAmounts: string;
  };
}

export interface IGetUserGroupsParams {
  isTier: boolean;
}

export interface IUpdateUserGroupPayload {
  users: Array<string>;
  userGroupId: string;
}

type UserServiceMethod = {
  getBankAccountDetails: () => Promise<any>;
  getBankAccountDetailsById: (id: string) => Promise<any>;
  getUserById: (payload: UserById, abortSignal?: AbortSignal) => AxiosPromise<IUser>;
  getUserListByIds: (params: IGetUserListParams, abortSignal?: AbortSignal) => Promise<any>;
  getUserByBvn: (bvn: string) => Promise<any>;
  createANewUser: (payload: CreateUser) => Promise<any>;
  userValidateOTP: (payload: IUserValidateOTP) => Promise<any>;
  createPassword: (payload: UserCreatePassword) => Promise<any>;
  updateUserAddress: (payload: UpdateUserAddress) => Promise<any>;
  updateUserIdentity: (payload: any) => AxiosPromise<IUser>;
  updateUserPhoneNumber: (payload: IUpdatePhoneNumberPayload) => Promise<any>;
  updateUserNextOfKin: (payload: UpdateUserNextOfKin) => Promise<any>;
  updateUserProduct: (payload: UpdateProduct) => Promise<any>;
  findUserByPhoneNumber: (payload: UserById, abortSignal?: AbortSignal) => Promise<any>;
  getUserByPhoneNumber: (payload: Pick<IUser, 'phoneNumber'>) => Promise<any>;
  findUserByEmail: (payload: UserById) => Promise<any>;
  getUsers: (payload: UserById) => Promise<any>;
  adminGetUsers: (payload: IGetUsersParams, signal?: AbortSignal) => Promise<any>;
  getUsersByProduct: (payload: IGetUsersParams) => Promise<any>;
  makeAggregator: (payload: IMakeAggregator) => Promise<any>;
  findUserByParentId: (payload: AggregatorByParentId) => Promise<any>;
  getAggregatorAgents: (payload: AggregatorById, pagination?: IPagination, filter?: IFilter | undefined, abortSignal?: AbortSignal) => Promise<any>;
  getAllAggregators: (payload?: IGetAllAggregatorsParams) => Promise<any>;
  assignAgentToAggregator: (payload: IAssignAgentToAggregator) => Promise<any>;
  unassignAgentToAggregator: (payload: string) => Promise<any>;
  bulkAgentOnboading: (payload: IBulkAgentOnboading) => Promise<any>;
  downloadCSVTemplate: () => Promise<any>;
  bulkProcessAggregatorUpload: (payload: IBulkProcessAggregatorUpload) => Promise<any>;
  getBlacklistedBVNsList: (params: IGetBlacklistedBVNsListParams) => Promise<any>;
  getBlacklistedBVN: (bvn: string) => Promise<any>;
  createBvnBlackList: (payload: IBlackListBvnOps) => Promise<any>;
  liftBvnBlackList: (payload: IBlackListBvnOps) => Promise<any>;
  updateUserDesignation: (payload: IUpdateUserDesignationPayload) => Promise<any>;
  getActivityLogs: (payload: IActivityLogParams) => Promise<any>;
  verifyKyc: (payload: IVerifyKyc) => Promise<any>;
  getDesignations: () => Promise<unknown>;
  sendSalaryPaymentReport: (payload: ISendSalaryPaymentReportPayload) => Promise<any>;
  getUserGroups: () => Promise<any>;
  updateUserGroup: (payload: IUpdateUserGroupPayload) => AxiosPromise<any>;
};

// eslint-disable-next-line
export const userService = <UserServiceMethod>{};

userService.getBankAccountDetails = () =>
  fetch({
    url: '/users/v1/walletFundingAccount',
    method: 'GET',
    params: {
      type: 'fundsTransfer',
    },
  });

userService.getBankAccountDetailsById = (id: string) =>
  fetch({
    url: '/users/v1/walletFundingAccount',
    method: 'GET',
    params: {
      type: 'fundsTransfer',
      id,
    },
  });

userService.getUsersByProduct = (params: IGetUsersParams) =>
  fetch({
    url: '/users/v1/user/listByProducts',
    method: 'GET',
    params,
  });

userService.getUserById = (params: UserById, signal?: AbortSignal) =>
  fetch({
    url: `/users/v1/user/${params.userId}`,
    method: 'GET',
    signal,
  });

userService.getUserListByIds = (params: IGetUserListParams, signal?: AbortSignal) =>
  fetch({
    url: '/users/v1/user/listByUserIDs',
    method: 'GET',
    params,
    signal,
  });

userService.getUserByBvn = (bvn: string) =>
  fetch({
    url: `/users/v1/user/${bvn}/bvn`,
    method: 'GET',
  });

userService.createANewUser = (data: CreateUser) =>
  fetch({
    url: data.registrationType === 'SELF' ? '/users/v1/self/user' : '/users/v1/user',
    method: 'post',
    data,
    headers:
      data.registrationType === 'SELF'
        ? {
            'public-request': 'true',
          }
        : {},
  });

userService.createPassword = (data: UserCreatePassword) =>
  fetch({
    url: '/users/v1/self/createPassword',
    method: 'post',
    data,
    headers: {
      'public-request': 'true',
    },
  });

userService.userValidateOTP = (data: IUserValidateOTP) =>
  fetch({
    url: '/users/v1/self/validateUserOtp',
    method: 'post',
    data,
    headers: {
      'public-request': 'true',
    },
  });

userService.updateUserAddress = (data: UpdateUserAddress) =>
  fetch({
    url: '/users/v1/user/address',
    method: 'put',
    data,
  });

userService.updateUserPhoneNumber = (payload: IUpdatePhoneNumberPayload) =>
  fetch({
    url: '/users/v1/user/update/phoneNumber',
    method: 'POST',
    data: {
      id: payload.userId,
      phoneNumber: payload.phoneNumber,
    },
  });

userService.updateUserIdentity = (data: IUser) =>
  fetch({
    url: '/users/v1/user/identity',
    method: 'put',
    data,
  });

userService.updateUserNextOfKin = (data: UpdateUserNextOfKin) =>
  fetch({
    url: '/users/v1/user/nextofkin',
    method: 'put',
    data,
  });

userService.updateUserProduct = (data: UpdateProduct) =>
  fetch({
    url: '/users/v1/user/product',
    method: 'put',
    data,
  });
userService.findUserByParentId = (data: AggregatorByParentId) =>
  fetch({
    url: `/users/v1/user`,
    method: 'get',
    params: {
      ...data,
    },
  });
userService.findUserByPhoneNumber = (data: UserById, signal?: AbortSignal) =>
  fetch({
    url: `/users/v1/user/${data.userId}/${data.hasOwnProperty('searchType') ? data.searchType : 'phoneNumber'}`,
    method: 'get',
    params: {
      searchType: data.searchType,
    },
    signal,
  });

userService.getUserByPhoneNumber = (params: Pick<IUser, 'phoneNumber'>) =>
  fetch({
    url: `/users/v1/user/${params.phoneNumber}/phoneNumber`,
    method: 'GET',
  });

userService.findUserByEmail = (data: UserById) =>
  fetch({
    url: `/users/v1/user/${data.userId}/emailAddress`,
    method: 'get',
  });

userService.getUsers = (data: IQueryParams) => {
  if ((!data.start_date && data.end_date) || (data.start_date && !data.end_date)) return Promise.resolve({ data: null });
  if (dayjs(data.start_date).isAfter(data.end_date, 'day')) return Promise.resolve({ data: null });
  return fetch({
    url: `/users/v1/user/${data.userId}/relationship`,
    method: 'get',
    params: {
      relationshipType: data.relationshipType,
      product: 'POS',
      page: data.page || 1,
      ...data,
      limit: data.limit || 10,
    },
  });
};

userService.getAggregatorAgents = (data: any, pagination?: IPagination, filter?: IFilter, signal?: AbortSignal) =>
  fetch({
    url: '/users/v1/user',
    method: 'get',
    params: {
      ...filter,
      parentId: data.aggregatorId,
      phoneNumber: filter?.phoneNumber?.replace('+', ''),
      page: pagination?.current,
      limit: pagination?.limit,
    },
    signal,
  });

userService.adminGetUsers = (data: IGetUsersParams, signal?: AbortSignal) => {
  return fetch({
    url: '/users/v1/user',
    method: 'get',
    params: {
      ...data,
      page: data?.page || 1,
      limit: data?.limit || 10,
    },
    signal,
  });
};

userService.makeAggregator = (data: IMakeAggregator) =>
  fetch({
    url: '/users/v1/user/aggregatorApproval',
    method: 'put',
    data,
  });

userService.getAllAggregators = (data?: IGetAllAggregatorsParams) =>
  fetch({
    url: data?.search ? `/users/v1/user/${data.search}/phoneNumber` : '/users/v1/user',
    method: 'get',
    params: {
      aggregatorApproved: true,
      ...data,
    },
  });

userService.assignAgentToAggregator = (data: IAssignAgentToAggregator) =>
  fetch({
    url: '/users/v1/user/attachUsersToAggregator',
    method: 'post',
    data,
  });

userService.unassignAgentToAggregator = (data: string) =>
  fetch({
    url: '/users/v1/user/removeUsersFromAggregator',
    method: 'post',
    data: { users: [data] },
  });

userService.bulkAgentOnboading = (data: IBulkAgentOnboading) =>
  fetch({
    url: `/users/v1/upload/${data.bucketKey}?contentType=${data.ext}`,
    method: 'get',
  });

userService.bulkProcessAggregatorUpload = (data: IBulkProcessAggregatorUpload) =>
  fetch({
    url: '/users/v1/user/bulkProcessAggregatorUpload',
    method: 'post',
    data,
  });

userService.downloadCSVTemplate = () =>
  fetch({
    url: '/users/v1/user/onboarding/csvTemplate',
    method: 'get',
  });

userService.getBlacklistedBVNsList = (params: IGetBlacklistedBVNsListParams) => {
  return fetch({
    url: 'users/v1/user/blacklist',
    method: 'GET',
    params,
  });
};

userService.getBlacklistedBVN = (bvn: string) => {
  return fetch({
    url: `/users/v1/user/blacklist/bvn/${bvn}`,
    method: 'GET',
  });
};

userService.createBvnBlackList = (data: IBlackListBvnOps) => {
  return fetch({
    url: 'users/v1/user/blacklist',
    method: 'POST',
    data: {
      blacklistType: 'bvn',
      value: data.value,
      active: true,
      comment: data.comment,
    },
  });
};

userService.liftBvnBlackList = (data: IBlackListBvnOps) => {
  return fetch({
    url: 'users/v1/user/blacklist',
    method: 'POST',
    data: {
      blacklistType: 'bvn',
      value: data.value,
      active: false,
      comment: data.comment,
    },
  });
};

userService.updateUserDesignation = (data: IUpdateUserDesignationPayload) => {
  return fetch({
    url: 'users/v1/user/designation',
    method: 'PUT',
    data,
  });
};

userService.getActivityLogs = (params: IActivityLogParams) => {
  return fetch({
    url: 'users/v1/user/activitylogs',
    method: 'GET',
    params,
  });
};

userService.verifyKyc = (params: IVerifyKyc) => {
  return fetch({
    url: `users/v1/user/${params.userId}/kycverification`,
    method: 'PUT',
    data: params,
  });
};

userService.getDesignations = () =>
  fetch({
    url: 'users/v1/user/designations',
    method: 'GET',
  });

userService.sendSalaryPaymentReport = (data: ISendSalaryPaymentReportPayload) => {
  return fetch({
    url: `users/v1/sendMessage`,
    method: 'POST',
    data,
  });
};

userService.getUserGroups = () => {
  return fetch({
    method: 'GET',
    url: 'users/v1/user/userGroups',
    params: { isTier: true },
  });
};

userService.updateUserGroup = (data: IUpdateUserGroupPayload) => {
  return fetch({
    url: `users/v1/user/userGroups/add/${data.userGroupId}`,
    method: 'PUT',
    data: {
      users: data.users,
    },
  });
};

export default userService;
