import { Rule } from 'antd/es/form';

export type IPasswordCriteria = {
  uppercase: boolean;
  lowercase: boolean;
  number: boolean;
  minLength: number; // You can add more criteria here
  specialCharacter: boolean;
};

export const passwordCriteria: IPasswordCriteria = {
  uppercase: true,
  lowercase: true,
  number: true,
  minLength: 8,
  specialCharacter: true,
};

/**
 *
 * @param criteria
 * @returns
 */

const validatePassword = (criteria: IPasswordCriteria = passwordCriteria): Rule => ({
  validator: async (rule, value) => {
    if (!value) {
      return Promise.reject('Please enter a password');
    }

    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /\d/.test(value);
    const minimumLength = value.length >= criteria.minLength;
    const hasSpecialCharacter = /[^a-zA-Z0-9 ]/.test(value); // Regex for special characters

    const isChecked = [hasUpperCase, hasLowerCase, hasNumber, minimumLength, hasSpecialCharacter];
    let message = '';

    isChecked.forEach((item, index) => {
      if (!item) {
        message += ` ${
          [
            'an uppercase letter',
            'a lowercase letter',
            'a number',
            `a minimum of ${criteria.minLength} characters`,
            'a special character',
          ][index]
        }\n`;
      }
    });

    if (message) {
      return Promise.reject(`Password must contain:\n${message}`);
    }
    return Promise.resolve();
  },
});

export default validatePassword;
