/* eslint-disable */
import { Button, Form, Input, Modal, ModalProps, message } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SNBL_PATH } from 'src/configs/AppConfig';
import useAppSearchParams from 'src/hooks/useAppSearchParams';
import useFetch from 'src/hooks/useFetch';
import { IUserValidateOTP } from 'src/services/UserService';
import { setIsSnblIdentified } from 'src/utils/isSnblIdentified';
import Validation from 'src/utils/validation';

interface ValidateOTPModalProps {
  phoneNumber: string;
  modalProps: ModalProps;
  loading?: boolean;
  successCallback: () => void;
}

const ValidateOTPModal: React.FC<ValidateOTPModalProps> = ({
  phoneNumber,
  modalProps,
  loading,
  successCallback,
}) => {
  const [form] = Form.useForm<Omit<IUserValidateOTP, 'registrationType'>>();
  const navigate = useNavigate();
  const { searchParamsString, getSearchParam } = useAppSearchParams();
  const [validateOTPtrigger, setValidateOTPtrigger] = React.useState<boolean>();
  const [userDetails, setUserDetails] =
    React.useState<Omit<IUserValidateOTP, 'registrationType'>>();
  const target = getSearchParam('target');

  const handleOk = () => {
    setIsSnblIdentified(userDetails);
    navigate(`${SNBL_PATH}/savings-setup?${searchParamsString}`);
  };

  const {
    isLoading: validateOTPLoading,
    response: validateOTPResponse,
    reset: validateOTPReset,
    error,
  } = useFetch({
    method: 'POST',
    data: userDetails,
    skip: !!validateOTPtrigger,
    url: '/users/v1/validateBvnOtp',
  });

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setUserDetails({
          phoneNumber,
          otp: values.otp,
        });
      })
      .then(() => {
        setValidateOTPtrigger(true);
      })
      .catch((info) => {
        message.error('Validation Failed:', info);
      })
      .finally(() => {
        setValidateOTPtrigger(false);
        form.resetFields();
      });
  };

  React.useEffect(() => {
    if (!!validateOTPResponse) {
      successCallback();
      setValidateOTPtrigger(false);
      message.success(validateOTPResponse?.message);
    }

    return () => {
      validateOTPReset();
    };
  }, [validateOTPResponse]);

  React.useEffect(() => {
    if (!!error) {
      message.error(error);
    }
  }, [error]);

  return (
    <Modal
      {...modalProps}
      footer={null}
      onOk={handleOk}
      destroyOnClose
      title="Enter the OTP sent to your phone number"
    >
      <Form form={form} layout="vertical" name="validate-otp-form" onFinish={onSubmit}>
        <Form.Item hasFeedback name="otp" label="OTP" rules={Validation.antFormRules.phoneNumber}>
          <Input autoFocus maxLength={6} placeholder="Enter OTP" />
        </Form.Item>
        <Button loading={validateOTPLoading || loading} htmlType="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
};

export default ValidateOTPModal;
