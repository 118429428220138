import { DEFAULT_API_PARAMS } from 'src/constants/ApiConstant';

/* eslint-disable */
export const getSearchCriteriaKeys = (searchCriteria: Array<any> = []) => {
  return [...DEFAULT_API_PARAMS, ...searchCriteria].flatMap((field) => {
    if (typeof field === 'string') {
      return field;
    }
    if (Boolean(field['hasComparators' as keyof typeof field])) {
      return [`${field['key']}`, `${field['key' as keyof typeof field]}_comparator`];
    } else {
      return field['key' as keyof typeof field];
    }
  });
};
