export const categories = [
  {
    id: 1,
    createdAt: '2021-02-02T18:44:44.803+01:00',
    updatedAt: '2021-02-02T18:44:44.803+01:00',
    name: 'MPOS_FUNDING',
    categoryID: 1,
    description: 'POS Funding',
  },
  {
    id: 2,
    createdAt: '2021-02-02T18:44:44.81+01:00',
    updatedAt: '2021-02-02T18:44:44.81+01:00',
    name: 'BANK_TRANSFER',
    categoryID: 2,
    description: 'Bank Transfer',
  },
  {
    id: 3,
    createdAt: '2021-02-02T18:44:44.816+01:00',
    updatedAt: '2021-02-02T18:44:44.816+01:00',
    name: 'REVERSAL_FUNDING',
    categoryID: 3,
    description: 'Reversal',
  },
  {
    id: 4,
    createdAt: '2021-02-02T18:44:44.821+01:00',
    updatedAt: '2021-02-02T18:44:44.821+01:00',
    name: 'SAVINGS_COLLECTION_BY_AGENT',
    categoryID: 4,
    description: 'Savings Collection',
  },
  {
    id: 5,
    createdAt: '2021-02-02T18:44:44.828+01:00',
    updatedAt: '2021-02-02T18:44:44.828+01:00',
    name: 'SAVINGS_WITHDRAWAL_BY_AGENT',
    categoryID: 5,
    description: 'Savings Withdrawal',
  },
  {
    id: 6,
    createdAt: '2021-02-02T18:44:44.833+01:00',
    updatedAt: '2021-02-02T18:44:44.833+01:00',
    name: 'SAVINGS_CYCLE_CHARGE',
    categoryID: 6,
    description: 'Savings Cycle Charge',
  },
  {
    id: 7,
    createdAt: '2021-02-02T18:44:44.839+01:00',
    updatedAt: '2021-02-02T18:44:44.839+01:00',
    name: 'LOAN_DISBURSEMENT',
    categoryID: 7,
    description: 'Loan Disbursement',
  },
  {
    id: 8,
    createdAt: '2021-02-02T18:44:44.844+01:00',
    updatedAt: '2021-02-02T18:44:44.844+01:00',
    name: 'LOAN_COLLECTION',
    categoryID: 8,
    description: 'Loan Collection',
  },
  {
    id: 9,
    createdAt: '2021-02-02T18:44:44.849+01:00',
    updatedAt: '2021-02-02T18:44:44.849+01:00',
    name: 'EXTERNAL_WALLET_FUNDING',
    categoryID: 9,
    description: 'External Wallet Funding',
  },
  {
    id: 10,
    createdAt: '2021-02-02T18:44:44.857+01:00',
    updatedAt: '2021-02-02T18:44:44.857+01:00',
    name: 'VAS_AIRTIME',
    categoryID: 10,
    description: 'Airtime Purchase',
  },
  {
    id: 11,
    createdAt: '2021-02-02T18:44:44.862+01:00',
    updatedAt: '2021-02-02T18:44:44.862+01:00',
    name: 'COMMISSION',
    categoryID: 11,
    description: 'Service Payment Commission',
  },
  {
    id: 12,
    createdAt: '2021-02-02T18:44:44.868+01:00',
    updatedAt: '2021-02-02T18:44:44.868+01:00',
    name: 'LOAN_MASTER_WALLET_FUND',
    categoryID: 12,
    description: 'Loan Master Wallet Funding',
  },
  {
    id: 13,
    createdAt: '2021-02-02T18:44:44.873+01:00',
    updatedAt: '2021-02-02T18:44:44.873+01:00',
    name: 'VAS_BILL_PAYMENT',
    categoryID: 13,
    description: 'Bill Payment',
  },
  {
    id: 14,
    createdAt: '2021-02-02T18:44:44.879+01:00',
    updatedAt: '2021-02-02T18:44:44.879+01:00',
    name: 'CSR_MASTER_WALLET_FUND',
    categoryID: 14,
    description: 'CSR Master Wallet Funding',
  },
  {
    id: 15,
    createdAt: '2021-02-02T18:44:44.886+01:00',
    updatedAt: '2021-02-02T18:44:44.886+01:00',
    name: 'CSR_PAYMENT',
    categoryID: 15,
    description: 'Csr Payment',
  },
  {
    id: 16,
    createdAt: '2021-02-02T18:44:44.892+01:00',
    updatedAt: '2021-02-02T18:44:44.892+01:00',
    name: 'FEE',
    categoryID: 16,
    description: 'Transaction Fee',
  },
  {
    id: 17,
    createdAt: '2021-02-02T18:44:44.899+01:00',
    updatedAt: '2021-02-02T18:44:44.899+01:00',
    name: 'MASTER_WALLET_FUND',
    categoryID: 17,
    description: 'Master Wallet Funding',
  },
  {
    id: 18,
    createdAt: '2021-02-02T18:44:44.905+01:00',
    updatedAt: '2021-02-02T18:44:44.905+01:00',
    name: 'MOVE_WALLET',
    categoryID: 100,
    description: 'Wallet Migration',
  },
  {
    id: 19,
    createdAt: '2021-02-02T18:44:44.91+01:00',
    updatedAt: '2021-02-02T18:44:44.91+01:00',
    name: 'PENDING_TRANSACTION',
    categoryID: 101,
    description: 'Old Pending Transactions',
  },
  {
    id: 20,
    createdAt: '2021-06-02T16:57:44.91+01:00',
    updatedAt: '2021-06-02T16:57:44.91+01:00',
    name: 'PROMOTIONS',
    categoryID: 18,
    description: 'Promotional transactions',
  },
  {
    id: 21,
    createdAt: '2021-07-22T16:57:44+01:00',
    updatedAt: '2021-07-22T16:57:44+01:00',
    name: 'FARMERS_FUND',
    categoryID: 19,
    description: 'Funding for farmers',
  },
  {
    id: 22,
    createdAt: '2021-08-03T14:57:44+01:00',
    updatedAt: '2021-08-03T14:57:44+01:00',
    name: 'SAVINGS_INTEREST',
    categoryID: 20,
    description: 'Savings interest',
  },
  {
    id: 23,
    createdAt: '2022-01-31T12:27:44+01:00',
    updatedAt: '2022-01-31T12:27:44+01:00',
    name: 'P2P_TRANSFER',
    categoryID: 21,
    description: 'Wallet to wallet transfer',
  },
  {
    id: 24,
    createdAt: '2022-03-25T11:31:44+01:00',
    updatedAt: '2022-03-25T11:31:44+01:00',
    name: 'MPOS_LEASE_COLLECTION',
    categoryID: 22,
    description: 'MPOS Lease Collection',
  },
  {
    id: 25,
    createdAt: '2022-03-25T11:31:44+01:00',
    updatedAt: '2022-03-25T11:31:44+01:00',
    name: 'FUNDS_RECOVERY',
    categoryID: 23,
    description: 'Funds recovery',
  },
  {
    id: 26,
    createdAt: '2022-11-03T11:31:44+01:00',
    updatedAt: '2022-11-03T11:31:44+01:00',
    name: 'WALLET_TO_WALLET',
    categoryID: 24,
    description: 'Wallet to wallet transfer for a user',
  },
  {
    id: 27,
    createdAt: '2020-12-05T13:29:40.593+01:00',
    updatedAt: '2020-12-05T13:29:40.593+01:00',
    name: 'P2P_BANK_TRANSFER',
    categoryID: 25,
    description: 'P2P Bank Transfer',
  },
];
