/* eslint-disable */
import React from 'react';
import { Col, Row } from 'antd';
import Utils from 'src/utils';
import { selectDesignations } from 'src/redux/selectors/UserSelectors';
import { IStore } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { IDesginationStore } from 'src/redux/reducers/user';

export const Greeting = React.memo((props: any) => {
  const { user } = props;
  const roles = user?.roles;
  const designation = user?.designation;

  const designations = useSelector((store: IStore) =>
    selectDesignations(store)
  ) as IDesginationStore;

  const currentUserDesignation = designations.find((des) => des.id === designation)?.name;

  return (
    <Row gutter={12} className="d-flex align-baseline mb-4">
      <Col xs={24}>
        <h3 style={{ textTransform: 'capitalize', margin: 0 }}>Hi {user && user.firstName},</h3>
      </Col>
      <Col xs={24}>
        {designations.length && user && designation && designation !== ''
          ? `you are logged in as ${Utils.capitalizeFirstStringChar(
              currentUserDesignation || designation,
              undefined
            )} ${designation === 'super agent' ? '🚀' : ''}`
          : roles.join('').includes('admin')
            ? 'you are logged in as Admin'
            : user && roles?.join('').includes('aggregator')
              ? 'you are logged in as Aggregator'
              : !roles.join('').includes('admin') && roles.length > 1
                ? `your roles are: ${roles.join().split('*:')[1]} 
                  ${roles
                    .join()
                    .split('*:')
                    .filter((_: string, i: number) => i !== 1)
                    .join(' ')}`
                : ` you are logged in as${roles.join().split('*:').join(' ')}`}
      </Col>
    </Row>
  );
});
