/* eslint-disable */
import React from 'react';
import { SettingOutlined, LogoutOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Button } from 'antd';
import { connect } from 'react-redux';
import { signOut } from '../../redux/actions/Auth';
import { APP_PREFIX_PATH, THEME_CONFIG } from '../../configs/AppConfig';
import { useNavigate } from 'react-router-dom';

const NavPanel = ({ signOut }: { signOut: any }) => {
  const navigate = useNavigate();
  const handleNavigation = (url: string) => () => {
    navigate(url);
  };

  const menu = (
    <Menu>
      <Menu.Item icon={<UserOutlined />} onClick={handleNavigation(`${APP_PREFIX_PATH}/profile`)}>
        Profile
      </Menu.Item>
      <Menu.Item
        icon={<LockOutlined />}
        onClick={handleNavigation(`${APP_PREFIX_PATH}/change-password`)}
      >
        Change Password
      </Menu.Item>
      <Menu.Item icon={<LogoutOutlined />} onClick={signOut}>
        Log Out
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <Button
        style={{ border: 'none', background: 'transparent' }}
        className="align-self-center"
        type="text"
        onClick={() => {}}
      >
        <SettingOutlined style={{ color: THEME_CONFIG.primary }} className="mr-0" />
      </Button>
    </Dropdown>
  );
};

const mapStateToProps = ({ theme }: { theme: any }) => {
  const { locale } = theme;
  return { locale };
};

const mapDispatchToProps = {
  signOut,
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(NavPanel));
