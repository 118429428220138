/* eslint-disable */
import React from 'react';

interface ILogoProps extends React.SVGProps<SVGSVGElement> {
  style?: React.CSSProperties;
}

export const LogoWhite = (props: ILogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="200"
    zoomAndPan="magnify"
    viewBox="0 0 750 374.999991"
    style={{ marginTop: 10 }}
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    {...props}
  >
    <defs>
      <clipPath id="80314b1c71">
        <path
          d="M 332 132 L 365 132 L 365 250.917969 L 332 250.917969 Z M 332 132 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="b9a412fd6b">
        <path
          d="M 14.097656 229 L 190 229 L 190 241 L 14.097656 241 Z M 14.097656 229 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="dce6b9f286">
        <path
          d="M 74 124.167969 L 129 124.167969 L 129 172 L 74 172 Z M 74 124.167969 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <path
      fill="#000000"
      d="M 303.65625 131.933594 L 276.960938 131.933594 L 242.621094 224.488281 L 262.835938 224.488281 L 269.976562 204.519531 L 309.710938 204.519531 L 316.71875 224.488281 L 337.59375 224.488281 Z M 275.769531 188.382812 L 290.054688 148.578125 L 304.050781 188.382812 L 275.765625 188.382812 Z M 275.769531 188.382812 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <g clipPath="url(#80314b1c71)">
      <path
        fill="#000000"
        d="M 343.675781 132.4375 L 343.675781 149.082031 L 364.550781 149.082031 L 364.550781 132.4375 Z M 343.941406 157.164062 L 343.941406 228.179688 C 343.941406 232.25 341.84375 234.269531 337.59375 234.269531 L 332.574219 234.269531 L 332.574219 250.914062 L 339.824219 250.914062 C 355.414062 250.914062 364.152344 243.257812 364.152344 228.179688 L 364.152344 157.164062 Z M 343.941406 157.164062 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>
    <path
      fill="#000000"
      d="M 442.925781 186.757812 C 441.09375 168.304688 426.9375 156.234375 408.105469 156.234375 C 387.789062 156.234375 373 170.117188 373 190.878906 C 373 210.394531 386.066406 223.71875 404.496094 225.230469 C 405.691406 225.335938 406.886719 225.390625 408.109375 225.390625 C 428.320312 225.390625 443.113281 211.644531 443.113281 190.875 C 443.113281 189.46875 443.03125 188.085938 442.925781 186.753906 Z M 393.871094 190.878906 C 393.871094 178.328125 398.867188 171.871094 408.105469 171.871094 C 417.347656 171.871094 422.367188 178.328125 422.367188 190.878906 C 422.367188 203.429688 417.246094 209.785156 408.105469 209.785156 C 398.96875 209.785156 393.871094 203.457031 393.871094 190.878906 Z M 393.871094 190.878906 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      fill="#000000"
      d="M 515.378906 190.214844 C 513.253906 202.128906 505.070312 208.082031 495.035156 208.082031 C 481.808594 208.082031 472.914062 199.308594 470.734375 184.605469 C 470.390625 182.265625 470.203125 179.765625 470.203125 177.132812 C 470.203125 159.929688 479.578125 148.3125 493.707031 148.3125 C 497.769531 148.3125 501.222656 149.320312 504.117188 151.207031 C 509.136719 154.371094 512.507812 159.984375 514.421875 167.347656 L 534.765625 162.851562 C 532.003906 151.765625 526.214844 143.015625 517.792969 137.484375 C 512.378906 133.976562 505.898438 131.769531 498.460938 131.160156 C 497.054688 131.054688 495.621094 131 494.105469 131 C 467.042969 131 448.664062 149.878906 448.664062 178.195312 C 448.664062 179.128906 448.691406 180.03125 448.714844 180.933594 C 449.035156 188.804688 450.683594 195.824219 453.574219 201.777344 C 460.667969 216.75 475.246094 225.390625 494.765625 225.390625 C 515.910156 225.390625 530.941406 212.574219 535.71875 194.996094 L 515.378906 190.210938 Z M 515.378906 190.214844 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      fill="#000000"
      d="M 573.21875 156.234375 C 556.195312 156.234375 544.804688 165.378906 540.871094 176.335938 L 559.089844 180.5625 C 561.722656 174.898438 565.679688 171.871094 571.761719 171.871094 C 577.84375 171.871094 581.269531 174.898438 581.269531 180.035156 C 581.269531 185.855469 577.070312 187.453125 565.28125 188.621094 C 546.421875 190.480469 538.21875 195.90625 538.21875 208.351562 C 538.21875 219.570312 547.726562 225.398438 558.160156 225.398438 C 568.597656 225.398438 576.382812 220.902344 581.265625 212.980469 L 581.265625 224.492188 L 601.476562 224.492188 L 601.476562 180.699219 C 601.476562 164.457031 590.269531 156.238281 573.21875 156.238281 Z M 567.300781 211.511719 C 562.914062 211.511719 559.625 209.519531 559.625 205.558594 C 559.625 200.796875 563.050781 198.933594 570.832031 198.164062 C 575.71875 197.632812 579.167969 196.4375 581.269531 194.46875 C 581.003906 207.28125 573.621094 211.507812 567.300781 211.507812 Z M 567.300781 211.511719 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      fill="#000000"
      d="M 657.0625 156.234375 C 644.789062 156.234375 637.515625 165.886719 633.71875 178.703125 L 633.71875 157.167969 L 613.503906 157.167969 L 613.503906 224.488281 L 633.71875 224.488281 L 633.71875 201.464844 C 633.71875 181.386719 640.964844 175.9375 657.324219 175.9375 L 657.324219 156.234375 Z M 657.0625 156.234375 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      fill="#000000"
      d="M 710.578125 131.933594 L 710.578125 171.871094 C 706.621094 162.324219 699.210938 156.234375 688.640625 156.234375 C 673.472656 156.234375 660.511719 168.917969 660.511719 190.347656 C 660.511719 211.773438 673.207031 225.390625 689.722656 225.390625 C 700.535156 225.390625 707.121094 219.035156 710.574219 210.710938 L 710.574219 224.488281 L 730.785156 224.488281 L 730.785156 131.933594 L 710.574219 131.933594 Z M 695.386719 210.050781 C 686.808594 210.050781 681.394531 202.523438 681.394531 190.347656 C 681.394531 178.835938 686.945312 171.601562 695.386719 171.601562 C 704.628906 171.601562 710.578125 179.363281 710.578125 191.410156 C 710.578125 202.789062 704.761719 210.050781 695.386719 210.050781 Z M 695.386719 210.050781 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <g clipPath="url(#b9a412fd6b)">
      <path
        fill="#fdbd28"
        d="M 183.785156 229.988281 L 19.734375 229.988281 C 16.792969 229.988281 14.40625 232.375 14.40625 235.320312 C 14.40625 238.269531 16.792969 240.65625 19.734375 240.65625 L 183.785156 240.65625 C 186.726562 240.65625 189.113281 238.269531 189.113281 235.320312 C 189.113281 232.375 186.726562 229.988281 183.785156 229.988281 Z M 183.785156 229.988281 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>
    <path
      fill="#fdbd28"
      d="M 22.667969 190.871094 L 86.195312 215.28125 C 91.195312 217.195312 96.449219 218.152344 101.699219 218.152344 C 106.949219 218.152344 112.21875 217.195312 117.222656 215.273438 L 180.652344 190.863281 C 183.402344 189.808594 184.777344 186.722656 183.722656 183.972656 C 182.667969 181.21875 179.589844 179.847656 176.839844 180.898438 L 113.410156 205.308594 C 105.863281 208.207031 97.550781 208.207031 90.003906 205.316406 L 26.476562 180.910156 C 23.730469 179.855469 20.648438 181.234375 19.597656 183.984375 C 18.546875 186.738281 19.921875 189.820312 22.671875 190.871094 Z M 22.667969 190.871094 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      fill="#fdbd28"
      d="M 43.019531 158.890625 L 85.203125 189.523438 C 90.148438 193.121094 95.945312 194.917969 101.742188 194.917969 C 107.539062 194.917969 113.363281 193.109375 118.320312 189.496094 L 160.320312 158.804688 C 162.699219 157.070312 163.226562 153.734375 161.492188 151.351562 C 159.761719 148.972656 156.425781 148.445312 154.046875 150.179688 L 112.050781 180.875 C 105.898438 185.359375 97.625 185.363281 91.464844 180.890625 L 49.277344 150.25 C 46.894531 148.519531 43.5625 149.050781 41.832031 151.4375 C 40.105469 153.820312 40.636719 157.15625 43.015625 158.890625 Z M 43.019531 158.890625 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <g clipPath="url(#dce6b9f286)">
      <path
        fill="#fdbd28"
        d="M 92.164062 165.726562 C 93.996094 169.320312 97.625 171.542969 101.652344 171.542969 C 101.683594 171.542969 101.71875 171.542969 101.753906 171.542969 C 105.820312 171.507812 109.453125 169.210938 111.230469 165.554688 L 127.636719 131.878906 C 128.925781 129.234375 127.828125 126.035156 125.183594 124.746094 C 122.542969 123.453125 119.347656 124.554688 118.058594 127.203125 L 101.652344 160.875 L 84.417969 127.078125 C 83.085938 124.453125 79.875 123.410156 77.25 124.753906 C 74.628906 126.09375 73.589844 129.304688 74.929688 131.929688 L 92.164062 165.730469 Z M 92.164062 165.726562 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
