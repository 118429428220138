/* eslint-disable */
import { notification } from 'antd';
import { Reducer } from 'redux';
import { IUser } from 'src/services/UserService';
import * as actionTypes from '../constants';
import { DefaultInitialState } from './types';

export interface IAuthState {
  loading: boolean;
  error: any;
  token: string;
  userId: string;
  user: IUser;
  updatedUser: boolean;
  updatedUserBank: boolean;
  updatedTransactionPin: boolean;
  changePassword: DefaultInitialState;
}

const initState = {
  loading: false,
  error: null,
  token: localStorage.getItem(actionTypes.AUTH_TOKEN),
  userId: localStorage.getItem(actionTypes.AUTH_TOKEN_ID),
  user: localStorage.getItem(actionTypes.AUTH_USER)
    ? JSON.parse(localStorage.getItem(actionTypes.AUTH_USER) || '')?.user
    : null,
  updatedUser: false,
  updatedUserBank: false,
  updatedTransactionPin: false,
  changePassword: {},
} as IAuthState;

const key = actionTypes.KEY;

const authReducer: Reducer<IAuthState, { type: string; payload: any }> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case actionTypes.LOGIN_PENDING:
    case actionTypes.REGISTER_PENDING:
    case actionTypes.VERIFY_EMAIL_OTP_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_USER_CURRENT_BY_ID_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.VERIFY_EMAIL_OTP_SUCCESS:
      notification.destroy();
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.REGISTER_SUCCESS:
    case actionTypes.LOGIN_SUCCESS:
      notification.success({
        message: 'Successful',
        key,
      });
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };
    case actionTypes.GET_USER_CURRENT_BY_ID_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };
    case actionTypes.GET_USER_CURRENT_BY_ID_FAILED:
    case actionTypes.REGISTER_FAILED:
    case actionTypes.LOGIN_FAILED:
    case actionTypes.VERIFY_EMAIL_OTP_FAILED:
      notification.error({
        message: action?.payload && action?.payload.message,
        key,
      });
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.EXTRACT_TOKEN_PAYLOAD_PENDING:
      return {
        ...state,
        laoding: true,
        error: null,
      };
    case actionTypes.EXTRACT_TOKEN_PAYLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        ...state.user,
        permissions: action.payload,
      };
    case actionTypes.EXTRACT_TOKEN_PAYLOAD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.RESET_USER_PASSWORD_PENDING:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: true,
          error: null,
        },
      };
    case actionTypes.RESET_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          error: null,
        },
      };
    case actionTypes.RESET_USER_PASSWORD_FAILED:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default authReducer;
