import React, { memo, useEffect, useMemo } from 'react';
import { Modal, Descriptions, DescriptionsProps, Collapse, CollapseProps, Typography, Skeleton } from 'antd';
import { selectLoanAccountEnquiry, selectLoanRepaymentSchedule } from 'src/redux/selectors/LoanManagement';
import { useAppSelector } from 'src/redux/store/hooks';
import Utils from 'src/utils';
import { ILoanRepaymentSchedulePayload } from 'src/services/VFDService';
import { useDispatch } from 'react-redux';
import { getLoanAccountEnquiry, getLoanRepaymentSchedule } from 'src/redux/actions/loanManagement';
import camelCaseToTitleCase from 'src/utils/camelCaseToTitleCase';
import { ILoanAccountEnquiryPayload } from '../../../../../services/VFDService';

interface ILoanDetailsProps extends ILoanRepaymentSchedulePayload, ILoanAccountEnquiryPayload {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewLoanDetails: React.FC<ILoanDetailsProps> = ({ open, setOpen, identification, accountNumber }) => {
  const dispatch = useDispatch();
  const { data: repaymentSchedule, loading } = useAppSelector((store) => selectLoanRepaymentSchedule(store));
  const { loading: accountDetailsLoading, data: accountDetails } = useAppSelector((store) => selectLoanAccountEnquiry(store));
  const periods = repaymentSchedule?.periods;

  const handleCancel = () => {
    setOpen(false);
  };

  const accountDetailsItems: DescriptionsProps['items'] = [
    { key: 'client', label: 'Client', children: accountDetails?.client },
    { key: 'accountNo', label: 'Account Number', children: accountDetails?.accountNo },
    { key: 'accountBalance', label: 'Account Balance', children: Utils.Money(Number(accountDetails?.accountBalance)) },
    { key: 'accountId', label: 'Account ID', children: accountDetails?.accountId },
    { key: 'clientId', label: 'client ID', children: accountDetails?.clientId },
    { key: 'savingsProductName', label: 'Savings Product Name', children: accountDetails?.savingsProductName },
  ];

  const loanTermItems: DescriptionsProps['items'] = [
    { key: 'loanTermInDays', label: 'Loan Term In Days', children: Utils.Money(repaymentSchedule?.loanTermInDays) },
    { key: 'totalPrincipalDisbursed', label: 'Total Principal Disbursed', children: Utils.Money(repaymentSchedule?.totalPrincipalDisbursed) },
    { key: 'totalPrincipalExpected', label: 'Total Principal Expected', children: Utils.Money(repaymentSchedule?.totalPrincipalExpected) },
    { key: 'totalPrincipalPaid', label: 'Total Principal Paid', children: Utils.Money(repaymentSchedule?.totalPrincipalPaid) },
    { key: 'totalInterestCharged', label: 'Total Interest Charged', children: Utils.Money(repaymentSchedule?.totalInterestCharged) },
    { key: 'totalFeeChargesCharged', label: 'Total Fee Charges Charged', children: Utils.Money(repaymentSchedule?.totalFeeChargesCharged) },
    {
      key: 'totalPenaltyChargesCharged',
      label: 'Total Penalty Charges Charged',
      children: Utils.Money(repaymentSchedule?.totalPenaltyChargesCharged),
    },
    { key: 'totalWaived', label: 'Total Waived', children: Utils.Money(repaymentSchedule?.totalWaived) },
    { key: 'totalWrittenOff', label: 'Total Written Off', children: Utils.Money(repaymentSchedule?.totalWrittenOff) },
    { key: 'totalRepaymentExpected', label: 'Total Repayment Expected', children: Utils.Money(repaymentSchedule?.totalRepaymentExpected) },
    { key: 'totalRepayment', label: 'Total Repayment', children: Utils.Money(repaymentSchedule?.totalRepayment) },
    { key: 'totalPaidInAdvance', label: 'Total Paid In Advance', children: Utils.Money(repaymentSchedule?.totalPaidInAdvance) },
    { key: 'totalPaidLate', label: 'Total Paid Late', children: Utils.Money(repaymentSchedule?.totalPaidLate) },
    { key: 'totalOutstanding', label: 'Total Outstanding', children: Utils.Money(repaymentSchedule?.totalOutstanding) },
    { key: 'totalOverdueInterest', label: 'Total Overdue Interest', children: Utils.Money(repaymentSchedule?.totalOverdueInterest) },
  ];

  const loanItems: CollapseProps['items'] = useMemo(
    () =>
      periods?.map((period, index) => {
        return {
          key: `${index}`,
          label: `Period ${index + 1}`,
          children: (
            <Descriptions
              bordered
              size="small"
              className="mb-4"
              layout="vertical"
              key={`period-${index}`}
              items={Object.entries(period)?.map((entry) => {
                return {
                  key: entry[0],
                  label: camelCaseToTitleCase(entry[0]),
                  children: typeof entry[1] === 'number' ? Utils.Money(entry[1]) : Array.isArray(entry[1]) ? entry[1]?.join('-') : entry[1],
                };
              })}
            />
          ),
        };
      }),
    [periods]
  );

  useEffect(() => {
    if (accountNumber) dispatch(getLoanAccountEnquiry({ accountNumber }));
    if (identification) dispatch(getLoanRepaymentSchedule({ identification }));
  }, [identification, accountNumber]);

  return (
    <Modal onClose={handleCancel} centered open={open} onCancel={handleCancel} width="60vw" style={{ top: '1rem' }} height="80vh">
      <Skeleton loading={loading || accountDetailsLoading} active>
        <Descriptions bordered title="Account Details" layout="vertical" items={accountDetailsItems} className="mb-4" size="small" />
        <Descriptions bordered title="Loan Details" layout="vertical" items={loanTermItems} className="mb-4" size="small" />
        <Typography.Title level={5}>Repayment Schedule</Typography.Title>
        <Collapse defaultActiveKey={['0']} accordion items={loanItems} />
      </Skeleton>
    </Modal>
  );
};

export default memo(ViewLoanDetails);

ViewLoanDetails.displayName = 'ViewLoanDetails';
