import React from 'react';
import { PieChartOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [{ key: '1', icon: <PieChartOutlined />, label: 'Dashboard' }];

const LoanSidePane: React.FC = () => {
  return (
    <div>
      <Menu defaultSelectedKeys={['1']} mode="inline" theme="light" style={{ background: 'white' }} items={items} />
    </div>
  );
};

export default LoanSidePane;
