/* eslint-disable */
import React, { useState } from 'react';
import { Card, Row, Col, Form, Input, Button, message, Popover } from 'antd';
import PasswordValidator from 'password-validator';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import authService from 'src/services/AuthService';
import { Link } from 'react-router-dom';
import { LogoWhite } from 'src/assets/svg/logo-white';
import PasswordValidationChecklist from 'src/components/util-components/PasswordValidationChecklist';
import usePasswordValidator from 'src/hooks/usePasswordValidator';
import { passwordCriteria } from 'src/utils/validatePassword';

const formatPasswordValidateError = function (errors: Array<string>) {
  for (let i = 0; i < errors.length; i++) {
    if (errors[i] === 'min') {
      return 'password length should be a at least 8 characters';
      // eslint-disable-next-line
    } else if (errors[i] === 'lowercase') {
      return 'password should contain lowercase letters';
    } else if (errors[i] === 'uppercase') {
      return 'password should contain uppercase letters';
    } else if (errors[i] === 'digits') {
      return 'password should contain digits';
    } else if (errors[i] === 'symbols') {
      return 'password should contain symbols';
    }
    return '';
  }
  return '';
};

// create a password schema
const Schema = new PasswordValidator();

Schema.is().min(8).has().uppercase().has().lowercase().has().digits().has().symbols();

const ForgotPassword: React.FC = (props: any) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [userEmail, setUserEmail] = useState(false);

  const onSend = (values: any) => {
    setLoading(true);
    authService
      .forgotPassword({ email: values.email })
      .then((resp: any) => {
        setUserEmail(values.email);
        setLoading(false);
        setUserEmail(values.email);
        message.success('A new password has been sent to your email!');
        setSent(true);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };

  const onSetPassword = () => {
    setLoading(true);
    form.validateFields().then((values: any) => {
      authService
        .forgotPasswordSubmit({ ...values, email: userEmail })
        .then((resp: any) => {
          setLoading(false);
          message.success('Password Changed, you can now log in.');
          props.history.push('/auth/login');
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message);
        });
    });
  };

  const title = 'Password Policy';

  const passwordPolicyContent = (
    <>
      <h4>Your password should contain: </h4>
      <ul>
        <li>Minimum length of 8 characters</li>
        <li>Numerical characters (0-9)</li>
        <li>Special characters</li>
        <li>Uppercase letter</li>
        <li>Lowercase letter</li>
      </ul>
    </>
  );

  const { isChecked, setValue } = usePasswordValidator(
    form.getFieldValue('password'),
    passwordCriteria.minLength
  );

  const handlePasswordChange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <div className="auth-container">
      <div className="h-100">
        <div className="container d-flex flex-column justify-content-center h-100">
          <Row justify="center">
            <Col xs={20} sm={20} md={20} lg={9}>
              <Card>
                {!sent ? (
                  <div className="my-2">
                    <div className="text-center">
                      <LogoWhite
                        style={{
                          width: '16rem',
                        }}
                      />
                      <h3 role="heading" className="mt-3 font-weight-bold">
                        Forgot Password?
                      </h3>
                      <p className="mb-4">Enter your email to reset password</p>
                    </div>
                    <Row justify="center">
                      <Col xs={24} sm={24} md={20} lg={20}>
                        <Form
                          form={form}
                          layout="vertical"
                          name="forget-password"
                          onFinish={onSend}
                        >
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: 'Please input your email address',
                              },
                              {
                                type: 'email',
                                message: 'Please enter a valid email!',
                              },
                            ]}
                          >
                            <Input
                              placeholder="Email Address"
                              prefix={<MailOutlined className="text-primary" />}
                            />
                          </Form.Item>
                          <Form.Item>
                            <Button loading={loading} type="primary" htmlType="submit" block>
                              {loading ? 'Sending' : 'Send'}
                            </Button>
                          </Form.Item>
                        </Form>
                        <div className="d-flex justify-content-between">
                          <p>
                            Have an account? <Link to="/auth/login">Login</Link>
                          </p>
                          <p>
                            New to AjoCard? <Link to="/auth/register">Sign Up</Link>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div className="my-2">
                    <div className="text-center">
                      <LogoWhite
                        style={{
                          width: '16rem',
                        }}
                      />
                      <br />
                      <br />
                      <p className="mb-4 font-weight-bold">
                        Enter the code sent to your email and your new password twice.
                      </p>
                    </div>
                    <Row justify="center">
                      <Col xs={24} sm={24} md={20} lg={20}>
                        <Form
                          form={form}
                          layout="vertical"
                          name="forget-password"
                          onFinish={onSetPassword}
                        >
                          <Form.Item
                            name="confirmationCode"
                            label="Confirmation Code"
                            rules={[
                              {
                                required: true,
                                message: 'Please the code sent to your email.',
                              },
                            ]}
                          >
                            <Input placeholder="Confirmation Code" />
                          </Form.Item>
                          <Popover
                            placement="topLeft"
                            title={title}
                            content={passwordPolicyContent}
                            trigger="focus"
                          >
                            <Form.Item
                              name="password"
                              label="Create Password"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input your password',
                                },
                                ({ getFieldValue }: { getFieldValue: any }) => ({
                                  validator(rule: any, value: any) {
                                    const validationRulesErrors = Schema.validate(
                                      value || getFieldValue('password'),
                                      { list: true }
                                    );
                                    if (validationRulesErrors.length > 0) {
                                      return Promise.reject(
                                        formatPasswordValidateError(validationRulesErrors)
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                              hasFeedback
                            >
                              <Input.Password
                                onChange={handlePasswordChange}
                                prefix={<LockOutlined className="text-primary" />}
                              />
                            </Form.Item>
                            <div className="mb-4">
                              <PasswordValidationChecklist isChecked={isChecked} />
                            </div>
                          </Popover>
                          <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            rules={[
                              {
                                required: true,
                                message: 'Please confirm your password!',
                              },
                              ({ getFieldValue }: { getFieldValue: any }) => ({
                                validator(rule: any, value: any) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject('Passwords do not match!');
                                },
                              }),
                            ]}
                            hasFeedback
                          >
                            <Input.Password prefix={<LockOutlined className="text-primary" />} />
                          </Form.Item>
                          <Form.Item>
                            <Button loading={loading} type="primary" htmlType="submit" block>
                              {loading ? 'Submitting' : 'Submit'}
                            </Button>
                          </Form.Item>
                        </Form>
                      </Col>
                    </Row>
                    <div className="text-center">
                      <p>
                        Haven't Received the code?{' '}
                        <button
                          type="button"
                          onClick={() => onSend({ email: userEmail })}
                          className="text-primary border-0 bg-white"
                        >
                          Click here
                        </button>
                      </p>
                    </div>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
