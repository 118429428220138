import { SIDE_NAV_LIGHT, NAV_TYPE_TOP } from '../constants/ThemeConstant';
import { env } from './EnvironmentConfig';

export const APP_NAME = 'AjoCard Console';
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const { INSURANCE_BASE_URL } = env;
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const SNBL_PATH = '/snbl';

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_TOP,
  topNavColor: '#000000',
  headerNavColor: '#fcbd28',
  success: '#08bd48',
  primary: '#fdbd28',
  error: '#ff4d4f',
  mobileNav: false,
  black: "#000000",
  white: '#FFFFFF',
  accents: ['#fcbd28', '#000000', '#8C80F8', '#76F1AE', '#9295A3'],
};

export const awsCognitoCredentials = {
  region: process.env.REACT_APP_AWS_REGION || '',
  userPoolId: process.env.REACT_APP_AWS_POOL_ID || '',
  userPoolClientId: process.env.REACT_APP_AWS_WEB_CLIENT_ID || '',
  userPoolWebClientId: process.env.REACT_APP_AWS_WEB_CLIENT_ID || '',
};
