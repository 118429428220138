/* eslint-disable */
import React from 'react';
import { captureException } from '@sentry/react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Row } from 'antd';

const ErrorFallBackMessage = React.memo((props: { reset?: () => void }) => {
  const { reset } = props;

  return (
    <div>
      <Row gutter={[16, 16]} justify="space-between" align="middle" className="ml-1">
        <Col xs={24} md={reset ? 12 : 24}>
          <span>Oops... something broke here!</span>
        </Col>
        {reset && (
          <Col xs={24} md={12} className="justify-content-end d-flex">
            <Button size="small" type="primary" onClick={reset} style={{ color: 'black' }}>
              Try again
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
});

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error?: any;
  resetErrorBoundary?: () => void;
}) {
  React.useEffect(() => {
    captureException(error);
  }, []);

  return (
    <Alert
      showIcon
      type="error"
      className="mb-4 h-100 p-3"
      message={<ErrorFallBackMessage reset={resetErrorBoundary} />}
      icon={<InfoCircleOutlined style={{ fontSize: '2.5rem' }} />}
    />
  );
}

export default React.memo(ErrorFallback);
