/* eslint-disable */
import React from 'react';
import { Button, Form, Input, message } from 'antd';
import { IVerifyUserBySmileId } from 'src/services/UserService';
import useFetch from 'src/hooks/useFetch';
import Validation from 'src/utils/validation';

interface SNBLIdentifyFormProps {
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  successCallback: (args?: any) => void;
  errorCallback?: (args?: any) => void;
}

export const SNBLIdentifyForm: React.FC<SNBLIdentifyFormProps> = ({
  successCallback,
  setPhoneNumber,
}) => {
  const [form] = Form.useForm<IVerifyUserBySmileId>();
  const [formPhoneNumber, setFormPhoneNumber] = React.useState<string>();
  const [generateOTPtrigger, setGenerateOTPTrigger] = React.useState<boolean>(false);

  const {
    isLoading: generateOTPLoading,
    response: generateOTPResponse,
    reset: generateOTPReset,
  } = useFetch({
    method: 'POST',
    data: { phoneNumber: formPhoneNumber },
    skip: generateOTPtrigger,
    url: '/users/v1/sendBvnOtp',
  });

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setFormPhoneNumber(values.phoneNumber);
        setPhoneNumber(values.phoneNumber);
      })
      .then(() => {
        setGenerateOTPTrigger(true);
      })
      .catch((info) => {
        message.error('Validate Failed:', info);
      })
      .finally(() => {
        setGenerateOTPTrigger(false);
      });
  };

  React.useEffect(() => {
    if (!!generateOTPResponse) {
      successCallback();
    }

    return () => {
      generateOTPReset();
    };
  }, [generateOTPResponse]);

  React.useEffect(() => {
    if (!!generateOTPResponse) {
      successCallback();
    }

    return () => {
      generateOTPReset();
    };
  }, [generateOTPResponse]);

  return (
    <Form form={form} layout="vertical" name="snpl-form" onFinish={onSubmit}>
      <Form.Item
        hasFeedback
        name="phoneNumber"
        label="Phone Number"
        rules={Validation.antFormRules.phoneNumber}
      >
        <Input placeholder="Enter an NIN registered phone number" />
      </Form.Item>
      <Button loading={generateOTPLoading} htmlType="submit">
        Submit
      </Button>
    </Form>
  );
};
