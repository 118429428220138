import React from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_DARK,
  NAV_TYPE_SIDE,
} from '../../constants/ThemeConstant';
import MenuContent from './MenuContent';

const { Sider } = Layout;

export const SideNav: React.FC<{
  navCollapsed?: any;
  sideNavTheme?: any;
  hideGroupTitle?: any;
  localization?: boolean;
  routeInfo?: any;
  user?: any;
}> = ({
  navCollapsed,
  sideNavTheme,
  routeInfo,
  hideGroupTitle,
  localization = true,
  user,
}) => {
  const props = {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
  };
  return (
    <Sider
      className={`side-nav ${
        sideNavTheme === SIDE_NAV_DARK ? 'side-nav-dark' : ''
      }`}
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed}
    >
      <Scrollbars autoHide>
        <MenuContent user={user} type={NAV_TYPE_SIDE} {...props} />
      </Scrollbars>
    </Sider>
  );
};

const mapStateToProps = ({ theme }: { theme: any }) => {
  const { navCollapsed, sideNavTheme } = theme;
  return { navCollapsed, sideNavTheme };
};

export default connect(mapStateToProps)(SideNav);
