/* eslint-disable */
import React from 'react';
import { Container } from '@material-ui/core';
import { SNBLIdentifyForm } from './register-form';
import VerifyIdentityModal from './modal/verify-identity-modal';
import { IEnhancedKYC } from 'src/services/Types';
import useAppSearchParams from 'src/hooks/useAppSearchParams';
import useLastUrl from 'src/hooks/useLastUrl';
import ValidateOTPModal from './modal/validate-otp-modal';
import useFetch from 'src/hooks/useFetch';
import { message } from 'antd';

export const SNBLIdentify: React.FC = () => {
  const { getSearchParam } = useAppSearchParams();

  const [validateOTPModal, setValidateOTPModal] = React.useState<boolean>(false);
  const [verifyIdentityModal, setVerifyIdentityModal] = React.useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [smileIDtrigger, setSmileIDtrigger] = React.useState<boolean>(false);
  const [userDetails, setUserDetails] = React.useState<IEnhancedKYC>({
    source_sdk: 'rest_api',
    source_sdk_version: '1.0.0',
    signature: process.env.REACT_APP_SMILE_ID_SIGNATURE as string,
    timestamp: '2023-11-10T10:33:51.594Z',
    partner_params: {
      user_id: '',
      job_id: '',
      job_type: 5,
    },
    country: 'NG',
    id_type: 'PHONE_NUMBER',
    id_number: '',
    first_name: '',
    last_name: '',
    dob: '',
    bank_code: '',
    callback_url: 'https://www.ajocard.com',
    partner_id: '127',
  });

  const merchantId = getSearchParam('merchantId');
  const proxy_url = 'https://thingproxy.freeboard.io/fetch/';

  const {
    error,
    isLoading: smileIdLoading,
    response: smileIDResponse,
  } = useFetch({
    url: `${proxy_url}${process.env.REACT_APP_SMILE_ID_BASE_URL_PROD}/v1/id_verification`,
    method: 'POST',
    data: userDetails,
    skip: smileIDtrigger,
  });

  const handleGenerateOTPSuccess = () => {
    setValidateOTPModal(true);
    setUserDetails((prev) => {
      return {
        ...prev,
        id_number: phoneNumber,
      };
    });
  };

  const handleValidateOTPSuccess = () => {
    setSmileIDtrigger(true);
  };

  const handleCancelVerifyIdentity = () => {
    setVerifyIdentityModal(false);
  };

  const handleCancelValidateOtpModal = () => {
    setVerifyIdentityModal(false);
    setValidateOTPModal(false);
  };

  React.useEffect(() => {
    if (!!smileIDResponse) {
      setValidateOTPModal(false);
      setVerifyIdentityModal(true);
    }
  }, [smileIDResponse]);

  React.useEffect(() => {
    if (!!error) {
      message.error(error);
    }
  }, [error]);

  useLastUrl({ noAuthFeature: true });

  return (
    <Container maxWidth="sm" style={{ marginTop: 80 }}>
      <h1>{merchantId}</h1>
      <SNBLIdentifyForm
        setPhoneNumber={setPhoneNumber}
        successCallback={handleGenerateOTPSuccess}
      />
      <ValidateOTPModal
        loading={smileIdLoading}
        phoneNumber={phoneNumber}
        successCallback={handleValidateOTPSuccess}
        modalProps={{ visible: validateOTPModal, onCancel: handleCancelValidateOtpModal }}
      />
      <VerifyIdentityModal
        userData={smileIDResponse}
        modalProps={{ visible: verifyIdentityModal, onCancel: handleCancelVerifyIdentity }}
      />
    </Container>
  );
};

export default SNBLIdentify;
