/* eslint-disable */
import { Action, Reducer } from 'redux';
import { DOWNLOAD_TYPE } from 'src/utils/enums';
import { IDocHeader } from '../actions/Utils';
import { SET_DOCUMENT_DATA } from '../constants/index';

export interface IUtilsState {
  documentData: IDocumentData | null;
}

export interface IDocumentData {
  headers: IDocHeader[];
  format?: DOWNLOAD_TYPE;
  fileName: string;
  data: any[];
}

const initialState: IUtilsState | null = {
  documentData: null,
};

const utilsReducer: Reducer<IUtilsState, { type: string; payload: any }> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_DOCUMENT_DATA:
      return {
        ...state,
        documentData: action.payload,
      };
      break;

    default:
      return state;
      break;
  }
};

export default utilsReducer;
