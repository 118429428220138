/**
 *
 * @param fullPath string
 * @returns
 */
const isValidLastUrl = (fullPath: string): boolean => {
  if (typeof fullPath === 'undefined' || fullPath === '/' || !fullPath) {
    return false;
  }
  return true;
};

export default isValidLastUrl;
