/* eslint-disable */
import React, { Profiler, ProfilerOnRenderCallback } from 'react';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import store from './redux/store';
import Views from './views';
import { THEME_CONFIG, awsCognitoCredentials } from './configs/AppConfig';
import { env } from './configs/EnvironmentConfig';
import { ConfigProvider } from 'antd';
import useMediaQuery from './hooks/useMediaQuery';
import LockScreen from './components/shared-components/LockScreen';

// TODO: Deprecated in antd v5
// import 'antd/dist/antd.less';

Amplify.configure({
  Auth: {
    Cognito: awsCognitoCredentials,
  },
});

function App() {
  const handleRender: ProfilerOnRenderCallback = (
    id,
    phase,
    actualDuration,
    baseDuration,
    startTime,
    commitTime
  ) => {
    if (env?.ENV === 'dev') {
    }
  };

  const { isMobile, isTablet } = useMediaQuery();

  return (
    <Profiler id="app-profiler" onRender={handleRender}>
      <div className="App">
        <Provider store={store}>
          <LockScreen />
          <BrowserRouter>
            <ConfigProvider
              theme={{
                token: { colorPrimary: THEME_CONFIG.primary },
                components: {
                  Menu: {
                    popupBg: THEME_CONFIG.black,
                    colorBgContainer: isMobile || isTablet ? undefined : THEME_CONFIG.black,
                  },
                },
              }}
            >
              <Views />
            </ConfigProvider>
          </BrowserRouter>
        </Provider>
      </div>
    </Profiler>
  );
}

export default App;
