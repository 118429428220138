/* eslint-disable */
import React from 'react';
import { IStore } from './../redux/store/index';
import { selectCurrentUser } from './../redux/selectors/UserSelectors';
import { useSelector } from 'react-redux';
import { IUser } from 'src/services/UserService';
import Utils from 'src/utils';
import { Routes } from 'src/configs/NavigationConfig';

const useCurrentUser = () => {
  const user = useSelector((store: IStore) => selectCurrentUser(store)).user as IUser;

  const isAdmin = user?.roles?.includes('*:admin');

  const routes = React.useMemo(
    () =>
      Utils.filterRoutesNavtree(user, Routes)?.map(
        (route: (typeof Routes)[0]) => route.path
      ) as Array<string>,
    [user, Routes]
  );

  return {
    user,
    routes,
    isAdmin,
    userId: user?.userId,
    phoneNumber: user?.phoneNumber,
    isHR: user?.roles?.includes('*:human-resources'),
    isSavingsSDK: user?.roles?.includes('*:savingssdk'),
    isSupport: user?.roles?.includes('*:support') || isAdmin,
    isOperations: user?.roles?.includes('*:operations') || isAdmin,
    isKycApprover: user?.roles?.includes('*:kycapprover') || isAdmin,
    isKycReviewer: user?.roles?.includes('*:kycreviewer') || isAdmin,
    isEngineer: user?.roles?.includes('*:engineering'),
  };
};

export default useCurrentUser;
