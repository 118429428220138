/**
 *
 * @param value string or number
 * @returns
 */
const isValid = (value?: string | number | HTMLElement | null): boolean => {
  if (typeof value !== 'undefined' && value !== '' && value !== null) {
    return true;
  }
  return false;
};

export default isValid;
