/* eslint-disable */
import { UserById } from './../../services/UserService';
import * as Redux from 'redux';
import userService from 'src/services/UserService';
import * as actionTypes from '../constants';
import LendingService from 'src/services/LendingService';
import WalletService from 'src/services/WalletService';
import { message } from 'antd';

export const SearchAgent =
  (data: any, withLoader: boolean = true, abortSignal?: AbortSignal) =>
  async (dispatch: Redux.Dispatch<any>) => {
    if (withLoader) {
      dispatch({
        type: actionTypes.SEARCH_AGENT_PENDING,
      });
    }

    let wallets = [];

    try {
      const userFoundByPhoneNumber = await userService.findUserByPhoneNumber(data, abortSignal);

      dispatch({
        type: actionTypes.SEARCH_AGENT_SUCCESS,
        payload: userFoundByPhoneNumber,
      });

      try {
        const userWalletFound = await LendingService.getWallets({
          walletOwner: userFoundByPhoneNumber.userId,
        });

        wallets = userWalletFound;

        dispatch({
          type: actionTypes.SEARCH_AGENT_SUCCESS,
          payload: {
            wallets: userWalletFound || [],
            ...userFoundByPhoneNumber,
          },
        });
      } catch (userWalletErr) {
        message.error('You do not have permissions to view wallet');
      }

      try {
        const userActivityLogs = await userService.getActivityLogs({
          affectedUser: userFoundByPhoneNumber.userId,
        });

        dispatch({
          type: actionTypes.SEARCH_AGENT_SUCCESS,
          payload: {
            wallets,
            logs: userActivityLogs.items || [],
            ...userFoundByPhoneNumber,
          },
        });
      } catch (userActivityLogsErr) {}
    } catch (userNotFoundErr) {
      dispatch({
        type: actionTypes.SEARCH_AGENT_ERROR,
        payload: userNotFoundErr,
      });
    }
  };

export const SearchAgentByWallet =
  (data: any, withLoader: boolean = true, abortSignal?: AbortSignal) =>
  async (dispatch: Redux.Dispatch<any>) => {
    if (withLoader) {
      dispatch({
        type: actionTypes.SEARCH_AGENT_PENDING,
      });
    }

    await WalletService.getWalletByWalletId(data.userId, abortSignal)
      .then(async (agentsearch: any) => {
        if (agentsearch.count === 0) {
          return dispatch({
            type: actionTypes.SEARCH_AGENT_ERROR,
            payload: 'No user with the wallet id',
          });
        }
        const { walletOwner } = agentsearch.items[0];
        dispatch(
          SearchAgent({
            userId: walletOwner,
            searchType: '',
          })
        );
      })
      .catch((err: any) => {
        dispatch({
          type: actionTypes.SEARCH_AGENT_ERROR,
          payload: err,
        });
      });
  };

export const SearchAgents =
  (data: any, withLoader: boolean = true, abortSignal?: AbortSignal) =>
  async (dispatch: Redux.Dispatch<any>) => {
    if (withLoader) {
      dispatch({
        type: actionTypes.SEARCH_AGENT_PENDING,
      });
    }

    await userService
      .adminGetUsers(data, abortSignal)
      .then(async (agentsearch: any) => {
        dispatch({
          type: actionTypes.SEARCH_AGENT_SUCCESS,
          payload: { agents: agentsearch },
        });
      })
      .catch((err: any) => {
        dispatch({
          type: actionTypes.SEARCH_AGENT_ERROR,
          payload: err,
        });
      });
  };

export const searchAgentByUserId =
  (data: UserById, withLoader: boolean = true, abortSignal?: AbortSignal) =>
  async (dispatch: Redux.Dispatch<any>) => {
    if (withLoader) {
      dispatch({
        type: actionTypes.SEARCH_AGENT_PENDING,
      });
    }
    await userService
      .getUserById(data, abortSignal)
      .then(async (agent) => {
        dispatch({
          type: actionTypes.SEARCH_AGENT_SUCCESS,
          payload: agent,
        });
      })
      .catch((err: any) => {
        dispatch({
          type: actionTypes.SEARCH_AGENT_ERROR,
          payload: err,
        });
      });
  };

export const searchAgent =
  (data: any, withLoader?: boolean, abortSignal?: AbortSignal) =>
  async (dispatch: Redux.Dispatch<any>) => {
    if (data.searchType === 'userId') {
      return dispatch(searchAgentByUserId(data, withLoader, abortSignal));
    }
    data.walletSearch
      ? dispatch(SearchAgentByWallet(data, withLoader, abortSignal))
      : data.agents
      ? dispatch(SearchAgents(data, withLoader, abortSignal))
      : dispatch(SearchAgent(data, withLoader, abortSignal));
  };

export const PlacePnd = (data: any) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: actionTypes.PLACE_PND_PENDING,
  });
  await WalletService.placePnd(data)
    .then(() => {
      dispatch({
        type: actionTypes.PLACE_PND_SUCCESS,
      });
      if (data.phone) {
        dispatch(searchAgent({ userId: data?.phone }));
      }
    })
    .catch((err: any) => {
      dispatch({
        type: actionTypes.PLACE_PND_ERROR,
        payload: err?.message,
      });
    });
};

export const placePnd = (data: any) => async (dispatch: Redux.Dispatch<any>) => {
  dispatch(PlacePnd(data));
};
