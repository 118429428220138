/**
 *
 * @param camelCaseString string
 * @returns
 */

const camelCaseToTitleCase = (camelCaseString: string): string => {
  return camelCaseString
    .replace(/([A-Z])/g, ' $1') // Insert a space before each uppercase letter
    .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first character of the string
    .split(' ') // Split the string into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first character of each word
    .join(' '); // Join the words back into a single string with spaces
};

export default camelCaseToTitleCase;
